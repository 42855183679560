<template>
  <div>
    <FormLabel>{{
      hasSelectedAddress
        ? `What is ${pronoun} home address?`
        : `What is ${pronoun} home postcode?`
    }}</FormLabel>
    <template v-if="!hasSelectedAddress">
      <PersonalPostcode
        v-model="postcode"
        :tagId="id"
        :zukoId="zukoId"
        :loading="loading"
        :pronoun="pronoun"
        @findAddress="handleFindAddress"
      />
      <template v-if="addressHasSearched">
        <BaseError v-if="!hasAddresses" :id="`${id}-error`" class="error--text"
          >No addresses found for this postcode</BaseError
        >
        <PersonalAddresses
          v-else
          v-model="selectedAddress"
          ref="addressSelect"
          :id="id"
          :zukoId="zukoId"
          :pronoun="pronoun"
          :addresses="getAddresses"
        />
      </template>
      <template v-if="shouldValidate && !addressHasSearched">
        <BaseError v-if="!hasAddresses" :id="`${id}-error`" class="error--text"
          >Please search for and select an address to continue</BaseError
        >
      </template>
    </template>
    <PersonalAddressInfoCard
      class="mt-2 mb-4"
      :tagId="id"
      :zukoId="zukoId"
      :address="getSelectedAddress"
      @editAddress="clearAddress"
      v-else
    />
  </div>
</template>

<script>
export default {
  name: "PersonalAddressFinder",
  data() {
    return {
      shouldValidate: false,
      loading: false,
    };
  },
  props: {
    id: {
      type: String,
    },
    zukoId: {
      type: String,
    },
    value: {
      type: String,
    },
    pronoun: {
      type: String,
    },
    type: {
      type: String,
      default: "personaldetails",
    },
  },
  computed: {
    postcode: {
      get() {
        return this.$store.getters[
          "modules/" + this.type + "/getInputPostcode"
        ];
      },
      set(value) {
        this.$store.commit("modules/" + this.type + "/setInputPostcode", value);
      },
    },
    selectedAddress: {
      get() {
        return this.$store.getters[
          "modules/" + this.type + "/getSelectedAddress"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/" + this.type + "/setSelectedAddress",
          value
        );
      },
    },
    addressHasSearched: {
      get() {
        return this.$store.getters[
          "modules/" + this.type + "/getAddressHasSearched"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/" + this.type + "/setAddressHasSearched",
          value
        );
      },
    },
    hasAddresses() {
      return this.$store.getters["modules/" + this.type + "/hasAddresses"];
    },
    getAddresses() {
      return this.$store.getters["modules/" + this.type + "/getAddresses"];
    },
    hasSelectedAddress() {
      return this.$store.getters[
        "modules/" + this.type + "/hasSelectedAddress"
      ];
    },
    getSelectedAddress() {
      return this.$store.getters[
        "modules/" + this.type + "/getSelectedAddress"
      ];
    },
  },
  methods: {
    async handleFindAddress() {
      this.addressHasSearched = false;
      this.loading = true;
      await this.$store.dispatch("modules/" + this.type + "/findAddress");
      this.addressHasSearched = true;
      this.loading = false;
      if (this.hasAddresses) {
        this.openAndScroll();
      }
    },
    clearAddress() {
      this.addressHasSearched = false;
      this.$store.dispatch("modules/" + this.type + "/clearAddress");
    },
    openAndScroll() {
      this.$nextTick(() => {
        const el = this.$refs.addressSelect?.$el;
        if (el) {
          const offset = 300;
          const scrollToPosition =
            el.getBoundingClientRect().top + window.scrollY - offset;
          window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
          this.$refs.addressSelect.open();
        }
      });
    },
    forceValidation() {
      this.shouldValidate = true;
    },
  },
};
</script>
