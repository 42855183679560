var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wide px-3" },
    [
      _c(
        "v-expansion-panels",
        { attrs: { flat: "" } },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "brandBackgroundDark" },
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "d-flex px-0 pt-3 pb-5",
                  attrs: { "hide-actions": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row white--text px-0",
                      attrs: { cols: "6", sm: "9" },
                    },
                    [
                      _vm.isImpound
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "mb-0 font-weight-bold brandText--text",
                              class: _vm.$vuetify.breakpoint.xs
                                ? "text-body-2"
                                : "text-body-1",
                            },
                            [
                              _vm._v(
                                "\n            Third Party Only Cover for\n            " +
                                  _vm._s(
                                    _vm.selectedCoverDurationLength?.value
                                  ) +
                                  " starting:\n            " +
                                  _vm._s(
                                    _vm.selectedStartDate?.text ===
                                      "Immediate Start"
                                      ? "Immediately"
                                      : _vm.selectedStartDate?.text
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isImpound
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "mb-0 font-weight-bold brandText--text",
                              class: _vm.$vuetify.breakpoint.xs
                                ? "text-body-2"
                                : "text-body-1",
                            },
                            [
                              _vm._v(
                                "\n            Fully Comprehensive Cover for\n            " +
                                  _vm._s(
                                    _vm.selectedCoverDurationLength?.value
                                  ) +
                                  " starting:\n            " +
                                  _vm._s(
                                    _vm.selectedStartDate?.text ===
                                      "Immediate Start"
                                      ? "Immediately"
                                      : _vm.selectedStartDate?.text
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6", sm: "3" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "brandPrimary brandButtonText--text text-body-1 font-weight-bold edit-btn ml-3",
                          attrs: {
                            rounded: "",
                            block: "",
                            small: "",
                            "max-width": "192px",
                            "min-width": "100px",
                            id: "gtm--quoteForm-edit-quote",
                            "data-zuko-id": "zuko--quoteForm-edit-quote",
                          },
                        },
                        [_vm._v("\n            Edit quote\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { staticClass: "brandText--text" },
                [
                  _c("v-divider", { staticClass: "mb-6" }),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    { ref: "quoteForm", staticClass: "wide" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "text-body-2 text-md-body-1 brandNavy pt-2",
                        },
                        [
                          !_vm.isImpound
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0 py-md-2",
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: !_vm.isImmediate ? 6 : 4,
                                  },
                                },
                                [
                                  _c("FormLabel", [_vm._v("Duration Type:")]),
                                  _vm._v(" "),
                                  _c("CoverDurationType", {
                                    attrs: {
                                      id: "gtm--quoteForm-duration-type",
                                      zukoId: "zuko--quoteForm-duration-type",
                                      small: "",
                                    },
                                    model: {
                                      value: _vm.selectedCoverDurationType,
                                      callback: function ($$v) {
                                        _vm.selectedCoverDurationType = $$v
                                      },
                                      expression: "selectedCoverDurationType",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isImpound
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0 py-md-2",
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: !_vm.isImmediate ? 6 : 4,
                                  },
                                },
                                [
                                  _c("FormLabel", [
                                    _vm._v("Change Policy Duration:"),
                                  ]),
                                  _vm._v(" "),
                                  _c("CoverDurationLength", {
                                    attrs: {
                                      id: "zuko--quoteForm-cover-length",
                                      zukoId: "zuko--quoteForm-cover-length",
                                      isTweak: "",
                                    },
                                    model: {
                                      value: _vm.selectedCoverDurationLength,
                                      callback: function ($$v) {
                                        _vm.selectedCoverDurationLength = $$v
                                      },
                                      expression: "selectedCoverDurationLength",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              class: !_vm.isImmediate ? "py-0" : "py-md-2",
                              attrs: {
                                cols: "12",
                                sm: "6",
                                md: !_vm.isImmediate ? 6 : 4,
                              },
                            },
                            [
                              _c("FormLabel", [_vm._v("Start Date:")]),
                              _vm._v(" "),
                              _c("CoverStartDate", {
                                attrs: {
                                  id: "gtm--quoteForm--start-date",
                                  zukoId: "zuko--quoteForm--start-date",
                                },
                                model: {
                                  value: _vm.selectedStartDate,
                                  callback: function ($$v) {
                                    _vm.selectedStartDate = $$v
                                  },
                                  expression: "selectedStartDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.isImmediate
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "12", sm: "6", lg: "6" },
                                },
                                [
                                  _c("FormLabel", [_vm._v("Start Time:")]),
                                  _vm._v(" "),
                                  _c("CoverStartTime", {
                                    attrs: {
                                      id: "gtm--quoteForm-start-time",
                                      "data-zuko-id":
                                        "zuko--quoteForm-start-time",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-4", attrs: { justify: "center" } },
                    [
                      _vm.getJourneyIsTweaked
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "cover-submit-button brandButtonText--text mb-4",
                              class: {
                                "red white--text": _vm.journeyCurrentErrors,
                                "brandPrimary brandButtonText--text":
                                  !_vm.journeyCurrentErrors,
                              },
                              attrs: {
                                rounded: "",
                                id: "gtm--quoteForm-submit",
                                "data-zuko-id": "zuko--quoteForm-submit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.requote()
                                },
                              },
                            },
                            [
                              !_vm.journeyCurrentErrors
                                ? [
                                    _vm._v(
                                      "\n              Get Quotes\n              "
                                    ),
                                    _c("v-icon", { staticClass: "ml-2" }, [
                                      _vm._v("mdi-arrow-right-circle-outline "),
                                    ]),
                                  ]
                                : [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.journeyCurrentErrors) +
                                        " error"
                                    ),
                                    _vm.journeyCurrentErrors > 1
                                      ? _c("span", [_vm._v("s ")])
                                      : _vm._e(),
                                    _vm._v(
                                      "\n              on the page\n            "
                                    ),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "white mb-5" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }