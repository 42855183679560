var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.hasQuotePromo,
        expression: "hasQuotePromo",
      },
    ],
    staticClass: "promo-box",
    domProps: { innerHTML: _vm._s(_vm.getQuotePromoText) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }