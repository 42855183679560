<template>
  <v-row class="mt-md-4">
    <v-col class="pt-0 pb-6 pb-md-8">
      <div
        ref="trustpilotRef"
        class="trustpilot-widget"
        data-locale="en-GB"
        :data-template-id="dataTemplateId"
        data-businessunit-id="601bf83da023a000010814c1"
        data-style-height="20px"
        data-style-width="100%"
        data-theme="light"
      >
        <a
          href="https://uk.trustpilot.com/review/goshorty.co.uk"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TrustpilotWidget",
  mounted() {
    if (process.client) {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(this.$refs.trustpilotRef, true);
      }
    }
  },
  computed: {
    dataTemplateId() {
      if (process.client) {
        if (window.innerWidth < "600") {
          return "5419b732fbfb950b10de65e5";
        } else {
          return "5419b6ffb0d04a076446a9af";
        }
      }
    },
  },
};
</script>

<style lang="scss">
.trustpilot-widget {
  @media (min-width: 1264px) {
    position: relative;
    left: 6%;
    &::before {
      content: "Our customers say";
      position: absolute;
      bottom: -2px;
      left: 223px;
    }
  }
  @media (min-width: 960px) and (max-width: 1264px) {
    position: relative;
    left: 10%;
    &::before {
      content: "Our customers say";
      position: absolute;
      bottom: -2px;
      left: 80px;
    }
  }
}
</style>
