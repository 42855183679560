var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseButtonToggle", {
    attrs: {
      value: _vm.value,
      items: _vm.$selectors.additionalDriver,
      zukoId: _vm.zukoId,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }