const DEBUG = "DEBUG";
const INFO = "INFO";
const WARN = "WARNING";
const ERROR = "ERROR";
let $goshorty;

function createLogEntry(args) {
  return $goshorty.logger(args);
}

const api = {
  debug: (args) => {
    args.level = INFO;

    return createLogEntry(args);
  },
  info: (args) => {
    args.level = INFO;

    return createLogEntry(args);
  },
  warn: (args) => {
    args.level = WARN;

    return createLogEntry(args);
  },
  error: (args) => {
    args.level = ERROR;

    return createLogEntry(args);
  },
};

export { api as $logger, DEBUG, INFO, WARN, ERROR };

export default (context, inject) => {
  $goshorty = context.$goshorty;
  inject("logger", api);
  return api;
};
