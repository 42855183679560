var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseCard",
    {
      staticClass: "mb-4 breakdown-protection-container",
      scopedSlots: _vm._u(
        [
          _vm.value
            ? {
                key: "title",
                fn: function () {
                  return [_vm._v(_vm._s(_vm.title))]
                },
                proxy: true,
              }
            : {
                key: "title",
                fn: function () {
                  return [
                    _vm._v("Add " + _vm._s(_vm.title.toLowerCase()) + "?"),
                  ]
                },
                proxy: true,
              },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "px-0 align-start" },
        [
          _c("v-col", { staticClass: "pt-0", attrs: { cols: "8" } }, [
            _c("p", {
              class:
                _vm.$vuetify.breakpoint.mdOnly &&
                "font-weight-bold text-md-body-1 brandButtonText--text",
              domProps: { innerHTML: _vm._s(_vm.wording) },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pt-0 d-flex flex-column align-center",
              attrs: { cols: "4" },
            },
            [
              _c("p", { staticClass: "mb-2" }, [_vm._v("Only")]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "text-h5 text-sm-h4 text-md-h5 text-lg-h4 font-weight-bold brandDarkestGrey--text",
                },
                [_vm._v("£" + _vm._s(_vm.premium))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c("v-col", { staticClass: "pt-0", attrs: { cols: "2" } }),
          _vm._v(" "),
          _c("v-col", { staticClass: "pl-0", attrs: { cols: "6" } }, [
            _vm.value
              ? _c(
                  "p",
                  { staticClass: "black--text mt-1" },
                  [
                    _vm._v("\n        Added\n        "),
                    _c("v-icon", { attrs: { color: "#24c622" } }, [
                      _vm._v("mdi-checkbox-marked"),
                    ]),
                  ],
                  1
                )
              : _c(
                  "p",
                  { staticClass: "black--text mt-1" },
                  [
                    _vm._v("\n        Tap to add "),
                    _c("v-icon", { staticClass: "black--text" }, [
                      _vm._v("mdi-arrow-right"),
                    ]),
                  ],
                  1
                ),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "d-flex justify-center", attrs: { cols: "4" } },
            [
              _c("v-switch", {
                staticClass: "mt-0 pl-4 v-switch--large",
                attrs: {
                  color: "brandPrimary",
                  "hide-details": "",
                  disabled: _vm.disabled,
                  inset: "",
                  "data-zuko-id": `zuko--confirmationForm-opex-${_vm.opex}`,
                },
                model: {
                  value: _vm.localValue,
                  callback: function ($$v) {
                    _vm.localValue = $$v
                  },
                  expression: "localValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "brandPrimary brandButtonText--text",
              attrs: {
                id: "further-information",
                "data-zuko-id": "further-information",
                block: "",
                small: "",
              },
              on: {
                click: function ($event) {
                  _vm.expanded = !_vm.expanded
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.expanded
                    ? "Hide further Information"
                    : "Show further Information"
                )
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expanded,
              expression: "expanded",
            },
          ],
          staticClass: "mt-4 mx-2",
        },
        [
          _c("v-row", {
            domProps: { innerHTML: _vm._s(_vm.extraInformation) },
          }),
          _vm._v(" "),
          _c("v-row", [
            _c(
              "p",
              { staticClass: "warning-label text-center py-2 black--text" },
              [_vm._v("\n        Please, read the documents carefully\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "black--text px-0", attrs: { cols: "7" } },
                [_vm._v("Insurance Product Information Document (IPID)")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "5" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "brandPrimary brandButtonText--text",
                      attrs: {
                        small: "",
                        block: "",
                        id: "ipid",
                        "data-zuko-id": "ipid",
                      },
                      on: { click: _vm.showIpid },
                    },
                    [_vm._v("Click to read")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "black--text px-0", attrs: { cols: "7" } },
                [_vm._v("Policy wording")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "5" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "brandPrimary brandButtonText--text",
                      attrs: {
                        small: "",
                        block: "",
                        id: "policy-wording",
                        "data-zuko-id": "policy-wording",
                      },
                      on: { click: _vm.showPolicyWording },
                    },
                    [_vm._v("Click to read")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }