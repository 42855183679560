var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "site-footer pt-8", attrs: { fluid: "" } },
    [
      _c(
        "v-container",
        { staticClass: "pb-8 pb-md-0" },
        [
          _c(
            "v-row",
            { class: _vm.thankYouPage ? "justify-space-between" : "" },
            [
              _c(
                "v-col",
                {
                  class:
                    _vm.$vuetify.breakpoint.mdAndDown &&
                    " d-flex justify-start",
                  attrs: { cols: "6", md: "6" },
                },
                [
                  _vm.thankYouPage
                    ? _c(
                        "a",
                        { attrs: { href: "https://www.goshorty.co.uk" } },
                        [
                          _vm.thankYouPage
                            ? _c("nuxt-img", {
                                staticClass: "pr-8 px-sm-0",
                                attrs: {
                                  src: "/imgs/goshorty-logo-master-rgb-white.svg",
                                  alt: "GoShorty short term vehicle insurance",
                                  width: "200",
                                  height: "43",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("nuxt-img", {
                        staticClass: "pr-8 px-sm-0",
                        attrs: {
                          src: "/imgs/goshorty-logo-master-rgb-white.svg",
                          alt: "GoShorty short term vehicle insurance",
                          width: "200",
                          height: "43",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.thankYouPage
                ? _c("v-col", { attrs: { cols: "6", md: "6" } }, [
                    _c("div", { staticClass: "d-flex justify-end" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://facebook.com/goshortyinsurance",
                            target: "_blank",
                            rel: "noopener",
                          },
                        },
                        [
                          _c("nuxt-img", {
                            attrs: {
                              src: "/imgs/icons/facebook.svg",
                              width: "40",
                              height: "40",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://instagram.com/goshortyuk",
                            target: "_blank",
                            rel: "noopener",
                          },
                        },
                        [
                          _c("nuxt-img", {
                            staticClass: "ml-4",
                            attrs: {
                              src: "/imgs/icons/instagram.svg",
                              width: "40",
                              height: "40",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.thankYouPage
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$vuetify.breakpoint.mdAndUp,
                          expression: "$vuetify.breakpoint.mdAndUp",
                        },
                      ],
                      attrs: { cols: "12", md: "3" },
                    },
                    [
                      _c("div", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://facebook.com/goshortyinsurance",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [
                            _c("nuxt-img", {
                              attrs: {
                                src: "/imgs/icons/facebook.svg",
                                width: "40",
                                height: "40",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://instagram.com/goshortyuk",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [
                            _c("nuxt-img", {
                              staticClass: "ml-4",
                              attrs: {
                                src: "/imgs/icons/instagram.svg",
                                width: "40",
                                height: "40",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.links, function (section, i) {
                    return _c(
                      "v-col",
                      {
                        key: i,
                        staticClass: "botton-nav-container pb-0 pb-md-4",
                        attrs: { cols: "12", md: "3" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-decoration-none white--text text-body-1",
                          },
                          [
                            _c("h3", { staticClass: "mb-2" }, [
                              _vm._v(_vm._s(section.title)),
                            ]),
                            _vm._v(" "),
                            section.title
                              ? _c("v-divider", {
                                  staticClass: "grey lighten-1",
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(section.links, function (link, j) {
                          return _c(
                            "div",
                            { key: j, staticClass: "footer-section" },
                            [
                              _c("div", { staticClass: "my-2" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "text-decoration-none white--text text-body-1",
                                    attrs: {
                                      href: link.href,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(link.title) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-divider", { staticClass: "grey darken-2" }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$vuetify.breakpoint.smAndDown,
                              expression: "$vuetify.breakpoint.smAndDown",
                            },
                          ],
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://facebook.com/goshortyinsurance",
                                target: "_blank",
                                rel: "noopener",
                              },
                            },
                            [
                              _c("nuxt-img", {
                                staticClass: "pl-2",
                                attrs: {
                                  src: "/imgs/icons/facebook.svg",
                                  width: "40",
                                  height: "40",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://instagram.com/goshortyuk",
                                target: "_blank",
                                rel: "noopener",
                              },
                            },
                            [
                              _c("nuxt-img", {
                                staticClass: "pl-2",
                                attrs: {
                                  src: "/imgs/icons/instagram.svg",
                                  width: "40",
                                  height: "40",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }