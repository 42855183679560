var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "progress-bar-wrapper" } }, [
    _c(
      "div",
      { class: { "progress-bar-container": _vm.isFixed } },
      [
        _c("v-progress-linear", {
          attrs: {
            value: _vm.value,
            color: _vm.colour,
            height: "15",
            rounded: "",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }