<template functional>
  <div class="cover-details-container-label formLabel">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FormLabel",
};
</script>

<style lang="scss" scoped>
.formLabel {
  font-family: "Font Theme Bold", sans-serif;
}
</style>
