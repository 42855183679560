var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      staticClass: "text-center mt-3 black--text",
      attrs: { type: "error", color: "orange", prominent: "", dense: "" },
    },
    [
      _c("div", [
        _vm._v(
          "\n    " + _vm._s(_vm.getMaintenanceMessages.warningMessage) + "\n  "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }