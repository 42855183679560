var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseCard",
    {
      staticClass: "confirmation-documents-container",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_vm._v(" Important Documents ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { staticClass: "py-0" },
        [
          _c(
            "v-subheader",
            {
              staticClass:
                "d-block brandBackgroundLight brandDarkGrey--text text-subtitle text-center mb-2",
              staticStyle: { "min-width": "100%" },
            },
            [_vm._v("Click below to read")]
          ),
          _vm._v(" "),
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "v-list-item",
              {
                key: index,
                staticClass: "d-block brandBackgroundLight",
                attrs: { ripple: false },
                on: {
                  click: function ($event) {
                    return _vm.onClick(item)
                  },
                },
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "pt-2 text-wrap" },
                          [_vm._v(_vm._s(item.text))]
                        ),
                        _vm._v(" "),
                        item.subtitle
                          ? _c(
                              "v-list-item-subtitle",
                              { staticClass: "confirmation-label" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.subtitle) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item-action",
                      { class: index !== 0 && "mt-6" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              color: "brandPrimary",
                              small: "",
                              rounded: "",
                              icon: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onClick(item)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                index !== _vm.items.length - 1
                  ? _c("v-row", [_c("v-divider")], 1)
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }