<template>
  <v-select
    :items="$selectors.under21.drivingLocations"
    :value="value"
    :rules="rules"
    @change="$emit('input', $event)"
    return-object
    placeholder="Please select"
    :data-zuko-id="zukoId"
  ></v-select>
</template>

<script>
export default {
  name: "VehicleDrivingLocation",
  props: {
    value: {
      type: [Object, String],
      default: () => ({}),
    },
    zukoId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    rules: [
      (v) =>
        (!!v && !!v.value) ||
        "Please select a valid value for vehicle driving locations.",
    ],
  }),
};
</script>
