var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.bottomNavVisible && !_vm.isWhiteLabeled
        ? _c("BaseBottomNavigation")
        : _vm._e(),
      _vm._v(" "),
      _vm.bottomNavVisible && !_vm.isWhiteLabeled
        ? _c(
            "v-footer",
            { staticClass: "white-footer" },
            [
              _c(
                "v-container",
                { staticClass: "footer-container" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex px-6",
                          attrs: { cols: "12", md: "4" },
                        },
                        [
                          !_vm.isWhiteLabeled
                            ? _c("img", {
                                attrs: {
                                  src: "/imgs/goshorty-logo.png",
                                  alt: "GoShorty short term vehicle insurance",
                                  height: "45",
                                },
                              })
                            : _c("WhiteLabeledLogo"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex align-center justify-center flex-wrap px-0",
                          attrs: { cols: "12", md: "8" },
                        },
                        [
                          _c("img", {
                            staticClass: "payment-logos",
                            attrs: {
                              src: "/imgs/payment-logos-new.png",
                              fit: "contain",
                              alt: "GoShorty payment logos",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-row", { staticClass: "px-6" }, [
                    _c("p", { staticClass: "smallText" }, [
                      _vm._v(
                        "\n          We are a UK based insurance broker and we are authorised and\n          regulated by the Financial Conduct Authority under reference number\n          751221. GoShorty is a registered trademark. GoShorty is a trading\n          style of Complex to Clear Group Limited registered in England and\n          Wales. Company Registration Number 05044963. Data Protection\n          Registration ZA456686.\n        "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (!_vm.bottomNavVisible && !_vm.thankYouPage) || _vm.isWhiteLabeled
        ? _c(
            "v-footer",
            { staticClass: "footer white--text" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "0", md: "3" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "9" } },
                        [
                          _c("v-divider", {
                            staticClass: "grey darken-2 mb-4",
                          }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "d-flex justify-center" },
                            [
                              _vm.isWhiteLabeled
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex mb-4",
                                          class: _vm.$vuetify.breakpoint.smAndUp
                                            ? "justify-space-around"
                                            : "flex-column",
                                        },
                                        _vm._l(
                                          _vm.whiteLabeledLinks,
                                          function (link, j) {
                                            return _c(
                                              "div",
                                              {
                                                key: j,
                                                class:
                                                  _vm.$vuetify.breakpoint.xs &&
                                                  "mb-2",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-decoration-none white--text text-body-1",
                                                    attrs: {
                                                      href: link.href,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(link.title) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "smallText text-left" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getWhiteLabeledBrandName
                                              ) +
                                              " act as an introducer to GoShorty\n                for temporary insurance. GoShorty arrange and administer\n                temporary car insurance on behalf of\n                " +
                                              _vm._s(
                                                _vm.getWhiteLabeledBrandName
                                              ) +
                                              ". GoShorty are a UK based insurance\n                broker and are authorised and regulated by the Financial\n                Conduct Authority under reference number 751221. GoShorty is a\n                registered trademark. GoShorty is a trading style of Complex\n                to Clear Group Limited registered in England and Wales.\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "smallText text-left" },
                                        [
                                          _vm._v(
                                            "\n                Company Registration Number 05044963. Data Protection\n                Registration ZA456686.\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "smallText text-left" },
                                        [
                                          _vm._v(
                                            "\n                We are a UK based insurance broker and we are authorised and\n                regulated by the Financial Conduct Authority under reference\n                number 751221.\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "smallText text-left" },
                                        [
                                          _vm._v(
                                            "\n                GoShorty is a registered trademark. GoShorty is a trading\n                style of Complex to Clear Group Limited registered in England\n                and Wales.\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "smallText text-left" },
                                        [
                                          _vm._v(
                                            "\n                Company Registration Number 05044963. Data Protection\n                Registration ZA456686.\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          size: "1.25rem",
                                          color: "white",
                                        },
                                      },
                                      [_vm._v("mdi-lock-outline")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("100% Secure")]),
                                    _vm._v(" "),
                                    _c("v-divider", {
                                      staticClass: "ml-2 grey darken-2",
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$vuetify.breakpoint.mdAndUp,
                                        expression:
                                          "$vuetify.breakpoint.mdAndUp",
                                      },
                                    ],
                                    staticClass: "d-flex",
                                  },
                                  [
                                    _c("BasePaymentSheet", [
                                      _c("img", {
                                        attrs: {
                                          src: "/imgs/apple-pay.png",
                                          alt: "Apple Pay Logo",
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("BasePaymentSheet", [
                                      _c("img", {
                                        attrs: {
                                          src: "/imgs/google-pay-logo.png",
                                          alt: "Google Pay Logo",
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("BasePaymentSheet", [
                                      _c("img", {
                                        attrs: {
                                          src: "/imgs/amex-logo.png",
                                          height: "40",
                                          alt: "Amex Logo",
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("BasePaymentSheet", [
                                      _c("img", {
                                        attrs: {
                                          src: "/imgs/world-pay.png",
                                          alt: "World Pay Logo",
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("BasePaymentSheet", [
                                      _c("img", {
                                        attrs: {
                                          src: "/imgs/visa-logo.png",
                                          alt: "Visa Logo",
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("BasePaymentSheet", [
                                      _c("img", {
                                        attrs: {
                                          src: "/imgs/maestro.png",
                                          alt: "Maestro Logo",
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("BasePaymentSheet", [
                                      _c("img", {
                                        attrs: {
                                          src: "/imgs/mastercard.png",
                                          alt: "Mastercard Logo",
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("BasePaymentSheet", [
                                      _c("img", {
                                        attrs: {
                                          src: "/imgs/electron.png",
                                          alt: "Electron Logo",
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }