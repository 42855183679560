var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseCard",
    {
      staticClass: "mb-4 confirmation-costs-container",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_vm._v("Details of costs")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "px-0", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { cols: "6", sm: "6", lg: "4" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "costs-title text-body-1 text-md-body-1 font-weight-bold",
                },
                [_vm._v("\n        TOTAL TO PAY:\n      ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pl-0 pl-md-2",
              attrs: { cols: "6", sm: "6", lg: "8" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ml-auto text-h5 text-sm-h4 text-md-h5 text-lg-h4 font-weight-bold brandDarkestGrey--text text-right currency-flex",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.getSelectedQuoteTotalToPayWithOpex) +
                      "\n        "
                  ),
                  _c(
                    "v-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isExpanded,
                          expression: "!isExpanded",
                        },
                      ],
                      attrs: { color: "brandPrimary", size: "3rem" },
                      on: {
                        click: function ($event) {
                          _vm.isExpanded = !_vm.isExpanded
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          mdi-arrow-right-bold-circle\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isExpanded,
                          expression: "isExpanded",
                        },
                      ],
                      attrs: { color: "brandPrimary", size: "3rem" },
                      on: {
                        click: function ($event) {
                          _vm.isExpanded = !_vm.isExpanded
                        },
                      },
                    },
                    [_vm._v("\n          mdi-arrow-down-bold-circle\n        ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isExpanded,
              expression: "isExpanded",
            },
          ],
          staticClass: "mt-0",
        },
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12" } },
            [
              _c(
                "v-list",
                { staticClass: "brandBackgroundLight" },
                [
                  _vm._l(_vm.items, function (item, index) {
                    return _c(
                      "v-list-item-group",
                      { key: index },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-content",
                              { style: { fontSize: _vm.contentFontSize } },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.text)),
                                ]),
                                _vm._v(" "),
                                _vm.hasOpexSelected && index === 0
                                  ? _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "ml-3 font-weight-bold" },
                                      [_vm._v(_vm._s(item.total))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.hasOpexSelected
                              ? _c(
                                  "v-list-item-content",
                                  { style: { fontSize: _vm.contentFontSize } },
                                  [
                                    _c("v-img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.img,
                                          expression: "item.img",
                                        },
                                      ],
                                      attrs: {
                                        src: item.img,
                                        "max-width": "50px",
                                      },
                                    }),
                                    _vm._v(" "),
                                    index === 0
                                      ? _c("div", [_vm._v("Temp. insurance")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.selectedOpexBreakdownCover &&
                                    item.breakdown
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                Breakdown\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.selectedExcessProtection &&
                                    item.excessProtection
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                Excess protect\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.selectedLegalExpenses &&
                                    item.legalExpenses
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                Legal expenses\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              {
                                style: {
                                  fontSize: _vm.contentFontSize,
                                  maxWidth: _vm.contentWidth,
                                },
                                attrs: { align: "right" },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.value) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.selectedOpexBreakdownCover && item.breakdown
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.breakdown) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.selectedExcessProtection &&
                                item.excessProtection
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.excessProtection) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.selectedLegalExpenses && item.legalExpenses
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.legalExpenses) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-divider"),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center pt-6" }, [
                    _vm._v(
                      "\n          Insurance Premium tax (IPT) is included where applicable\n        "
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [_c("v-col", { attrs: { cols: "12" } }, [_c("QuotePromoBox")], 1)],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "brandPrimary brandButtonText--text",
              attrs: {
                id: "further-information",
                "data-zuko-id": "further-information",
                block: "",
                small: "",
              },
              on: {
                click: function ($event) {
                  _vm.documentsExpanded = !_vm.documentsExpanded
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.documentsExpanded
                    ? "Hide important documents"
                    : "Show important documents"
                )
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.documentsExpanded,
              expression: "documentsExpanded",
            },
          ],
          staticClass: "mt-4 mx-2",
        },
        [
          _c("v-row", [
            _c(
              "p",
              {
                staticClass:
                  "warning-label text-center py-2 mb-0 mt-1 brandButtonText--text font-weight-black",
              },
              [_vm._v("\n        Please, read the documents carefully\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "black--text px-0", attrs: { cols: "7" } },
                [_vm._v("Insurance Product Information Document (IPID)")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "5" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "brandPrimary black--text",
                      attrs: {
                        small: "",
                        block: "",
                        id: "ipid",
                        "data-zuko-id": "ipid",
                      },
                      on: { click: _vm.showIpid },
                    },
                    [_vm._v("Click to read")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "black--text px-0", attrs: { cols: "7" } },
                [_vm._v("Policy wording")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "5" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "brandPrimary black--text",
                      attrs: {
                        small: "",
                        block: "",
                        id: "policy-wording",
                        "data-zuko-id": "policy-wording",
                      },
                      on: { click: _vm.showPolicyWording },
                    },
                    [_vm._v("Click to read")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "black--text px-0", attrs: { cols: "7" } },
                [_vm._v("Terms of business")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "5" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "brandPrimary black--text",
                      attrs: {
                        small: "",
                        block: "",
                        id: "policy-wording",
                        "data-zuko-id": "policy-wording",
                      },
                      on: { click: _vm.showTermsOfBusiness },
                    },
                    [_vm._v("Click to read")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }