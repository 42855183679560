<template>
  <v-text-field
    :value="value"
    :rules="rules"
    @input="$emit('input', $event)"
    validate-on-blur
    hide-spin-buttons
    @wheel.prevent
    placeholder="07xxxxxxxxx"
    type="tel"
    :data-zuko-id="zukoId"
    :data-sentry-mask="true"
  ></v-text-field>
</template>

<script>
export default {
  name: "PersonalMobilePhone",
  props: {
    value: {
      type: String,
      default: () => null,
    },
    zukoId: {
      type: String,
      required: true,
    },
    pronoun: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          !!v ||
          `Please enter a valid value for ${this.pronoun} mobile number.`,
        (v) =>
          (v && v.length === 11) ||
          `Please enter a valid value for ${this.pronoun} mobile number.`,
        (v) =>
          /^07\d{9}$/.test(v) ||
          `Please enter a valid value for ${this.pronoun} mobile number.`,
      ],
    };
  },
};
</script>
