<template>
  <v-container fluid class="site-footer pt-8">
    <v-container class="pb-8 pb-md-0">
      <v-row :class="thankYouPage ? 'justify-space-between' : ''">
        <v-col
          cols="6"
          md="6"
          :class="$vuetify.breakpoint.mdAndDown && ' d-flex justify-start'"
        >
          <a v-if="thankYouPage" href="https://www.goshorty.co.uk">
            <nuxt-img
              v-if="thankYouPage"
              class="pr-8 px-sm-0"
              src="/imgs/goshorty-logo-master-rgb-white.svg"
              alt="GoShorty short term vehicle insurance"
              width="200"
              height="43"
            />
          </a>

          <nuxt-img
            v-else
            class="pr-8 px-sm-0"
            src="/imgs/goshorty-logo-master-rgb-white.svg"
            alt="GoShorty short term vehicle insurance"
            width="200"
            height="43"
          />
        </v-col>
        <v-col v-if="thankYouPage" cols="6" md="6">
          <div class="d-flex justify-end">
            <a
              href="https://facebook.com/goshortyinsurance"
              target="_blank"
              rel="noopener"
            >
              <nuxt-img src="/imgs/icons/facebook.svg" width="40" height="40" />
            </a>
            <a
              href="https://instagram.com/goshortyuk"
              target="_blank"
              rel="noopener"
            >
              <nuxt-img
                src="/imgs/icons/instagram.svg"
                width="40"
                height="40"
                class="ml-4"
              />
            </a>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!thankYouPage">
        <v-col cols="12" md="3" v-show="$vuetify.breakpoint.mdAndUp">
          <div>
            <a
              href="https://facebook.com/goshortyinsurance"
              target="_blank"
              rel="noopener"
            >
              <nuxt-img src="/imgs/icons/facebook.svg" width="40" height="40" />
            </a>
            <a
              href="https://instagram.com/goshortyuk"
              target="_blank"
              rel="noopener"
            >
              <nuxt-img
                src="/imgs/icons/instagram.svg"
                width="40"
                height="40"
                class="ml-4"
              />
            </a>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="3"
          v-for="(section, i) of links"
          :key="i"
          class="botton-nav-container pb-0 pb-md-4"
        >
          <div class="text-decoration-none white--text text-body-1">
            <h3 class="mb-2">{{ section.title }}</h3>
            <v-divider v-if="section.title" class="grey lighten-1"></v-divider>
          </div>

          <div
            v-for="(link, j) of section.links"
            :key="j"
            class="footer-section"
          >
            <div class="my-2">
              <a
                :href="link.href"
                target="_blank"
                class="text-decoration-none white--text text-body-1"
              >
                {{ link.title }}
              </a>
            </div>
            <v-divider class="grey darken-2"></v-divider>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div v-show="$vuetify.breakpoint.smAndDown">
            <a
              href="https://facebook.com/goshortyinsurance"
              target="_blank"
              rel="noopener"
            >
              <nuxt-img
                src="/imgs/icons/facebook.svg"
                width="40"
                height="40"
                class="pl-2"
              />
            </a>
            <a
              href="https://instagram.com/goshortyuk"
              target="_blank"
              rel="noopener"
            >
              <nuxt-img
                src="/imgs/icons/instagram.svg"
                width="40"
                height="40"
                class="pl-2"
              />
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "BaseBottomNaviation",
  computed: {
    isFirstColumn(column) {
      return column === 0;
    },
    thankYouPage() {
      return this.$route.path === "/payment-success";
    },
  },
  data: () => ({
    whiteLabeledLinks: [
      {
        title: "Privacy Policy",
        href: "https://goshorty.co.uk/privacy-policy",
      },
      {
        title: "Cookie Policy",
        href: "https://goshorty.co.uk/cookies-policy",
      },
      {
        title: "Terms of Business",
        href: "https://goshorty.co.uk/terms-of-business/",
      },
    ],
    links: [
      {
        title: "Useful Links",
        links: [
          { title: "Home", href: "https://goshorty.co.uk/" },

          {
            title: "Get a Quote",
            href: "https://goshorty.co.uk/temporary-car-insurance/",
          },

          { title: "About us", href: "https://goshorty.co.uk/about-us/" },

          { title: "Blog", href: "https://goshorty.co.uk/blog/" },

          { title: "Contact", href: "https://goshorty.co.uk/contact" },

          {
            title: "See Your Quotes and Purchases",
            href: process.env.portalUrl,
          },
        ],
        appendSocialMedia: true,
      },
      {
        title: "Vehicle Insurance",
        links: [
          {
            title: "Temporary Car Insurance",
            href: "https://goshorty.co.uk/temporary-car-insurance",
          },

          {
            title: "Temporary Van Insurance",
            href: "https://goshorty.co.uk/temporary-van-insurance",
          },

          {
            title: "Temporary Learner Insurance",
            href: "https://goshorty.co.uk/temporary-learner-driver-insurance",
          },
        ],
        appendSocialMedia: true,
      },
      {
        title: "Key Information",
        links: [
          {
            title: "Assumptions & Eligibility Criteria",
            href: "https://goshorty.co.uk/assumptions-eligibility-criteria/",
          },
          {
            title: "Policy Wording",
            href: "https://goshorty.co.uk/policy-wording/",
          },
          {
            title: "IPID",
            href: "https://goshorty.co.uk/ipid/",
          },
          {
            title: "Privacy Policy",
            href: "https://goshorty.co.uk/privacy-policy",
          },
          {
            title: "Cookie Policy",
            href: "https://goshorty.co.uk/cookies-policy",
          },
          {
            title: "Terms of Business",
            href: "https://goshorty.co.uk/terms-of-business/",
          },
          {
            title: "Website Terms of Use",
            href: "https://goshorty.co.uk/website-terms-of-use",
          },
        ],
      },
      {},
      {
        title: "Work With Us",
        links: [
          { title: "Brokers", href: "https://goshorty.co.uk/brokers" },
          { title: "Introducers", href: "https://goshorty.co.uk/introducers" },
          { title: "Affiliates", href: "https://goshorty.co.uk/affiliates" },
        ],
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.footer-button {
  font-family: "Font Theme Serif DemiBold", serif;
  border: 2px solid var(--v-brandPrimary-base);
  border-radius: 25px;
  min-width: 150px;
  height: 40px;
  @media (max-width: 400px) {
    min-width: auto;
    width: calc(100% / 4);
  }
}

.site-footer {
  background: var(--v-brandFooter-base);
  .botton-nav-container {
    min-width: 100%;

    @media (min-width: 1026px) {
      min-width: auto;
      width: calc(100% / 5);
    }
  }
  .container {
    max-width: 1200px;
  }

  h3 {
    margin-bottom: 1rem;
    font-size: 16px;
  }

  .footer-section {
    a {
      font-size: 15px !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
.text-h6 {
  min-height: 32px;
}
</style>
