<template>
  <BaseCard class="confirmation-documents-container">
    <template #title> Important Documents </template>
    <v-list class="py-0">
      <v-subheader
        style="min-width: 100%"
        class="d-block brandBackgroundLight brandDarkGrey--text text-subtitle text-center mb-2"
        >Click below to read</v-subheader
      >
      <v-list-item
        v-for="(item, index) of items"
        :key="index"
        class="d-block brandBackgroundLight"
        @click="onClick(item)"
        :ripple="false"
      >
        <v-row>
          <v-list-item-content>
            <v-list-item-title class="pt-2 text-wrap">{{
              item.text
            }}</v-list-item-title>
            <v-list-item-subtitle
              v-if="item.subtitle"
              class="confirmation-label"
            >
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action :class="index !== 0 && 'mt-6'">
            <v-btn
              color="brandPrimary"
              @click="onClick(item)"
              class="mb-0"
              small
              rounded
              icon
            >
              <v-icon>mdi-open-in-new</v-icon></v-btn
            >
          </v-list-item-action>
        </v-row>
        <v-row v-if="index !== items.length - 1">
          <v-divider />
        </v-row>
      </v-list-item>
    </v-list>
  </BaseCard>
</template>

<script>
export default {
  name: "ConfirmationDocuments",
  computed: {
    items() {
      return [
        {
          text: "Insurance Product Information Document (IPID)",
          value: this.getSelectedQuoteIPIDUrl,
        },
        {
          text: "Policy wording",
          value: this.getSelectedQuotePolicyWordingUrl,
        },
        {
          text: "Terms of business",
          value: this.getSelectedQuoteTobaUrl,
        },
      ];
    },
  },
  methods: {
    onClick(item) {
      window.open(item.value, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-documents-container::v-deep {
  .v-card__text {
    padding-bottom: 0px;
  }
  .confirmation-label {
    color: #000 !important;
  }
  .text-h6 {
    font-weight: bold;
  }
}
</style>
