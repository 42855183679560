<template>
  <div>
    <BaseButtonToggle
      v-if="getManualFuel.length < 5"
      :value="value"
      :items="getManualFuel"
      :zukoId="zukoId"
      :wrapButtons="getManualFuel.length > 3 && !isMobile"
      :wrapAll="isMobile"
      @input="$emit('input', $event)"
    />
    <v-autocomplete
      v-else
      :items="getManualFuel"
      :value="value"
      :rules="rules"
      return-object
      placeholder="Please select"
      :data-zuko-id="zukoId"
      @input="$emit('input', $event)"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "VehicleFuel",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Please select a valid value for vehicle fuel."],
  }),
  watch: {
    getManualFuel: function (newVal) {
      if (!newVal.includes(this.value) && newVal.length) {
        this.$emit("input", newVal[0]);
      }
    },
  },
};
</script>
