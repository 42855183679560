const defaults = require("~/assets/json/defaults.json");
const selectors = require("~/assets/json/selectors.json");

const initialState = () => ({
  licenceSearch: {
    isSearching: false,
    hasSearched: false,
    hasExpired: false,
    hasFound: false,
    hasError: false,
    validFrom: "",
  },
  licenceLegacy: {
    GSMULRD1: "",
    GSMULRD2: "",
    GSMYLCNT: "",
    GSMYLDIS: "",
    GSMYLPTS: "",
  },
  ...defaults.additional.licenceDetails,
});
export const state = initialState;

const transformLicence = (country, type) => {
  const code = country + type;
  const lookup = {
    UKFull: {
      value: "Full UK licence",
      abbr: "Full Licence",
    },
    NIFull: {
      value: "Northern Ireland full licence",
      abbr: "Full Ireland License",
    },
    "EU/EEAFull": {
      value: "Full EU or EEA licence",
      abbr: "European Community",
    },
    InternationalFull: {
      value: "International",
      abbr: "International",
    },
  };
  const result = lookup[code];
  return result ? { code, country, type, ...result } : null;
};
const convertLicence = (abbr, country) => {
  if (!country) {
    return {
      country: "UK",
      type: abbr === "Full Licence" ? "Full" : "Provisional",
    };
  } else if (country?.toLowerCase() === "ireland") {
    return {
      country: "NI",
      type: abbr === "Full Licence" ? "Full" : "Provisional",
    };
  } else if (country?.toLowerCase() === "international") {
    return {
      country: "International",
      type: "Full",
    };
  } else {
    return {
      country: "EU/EEA",
      type: "Full",
    };
  }
};
const convertMonthsBetween = (date1, date2) => {
  const [year1, month1] = date1.split("-").map(Number);
  const [year2, month2] = date2.split("-").map(Number);
  const yearDiff = year2 - year1;
  const monthDiff = month2 - month1;
  return yearDiff * 12 + monthDiff;
};
const subtractMonthsFromDate = (dateStr, monthsToSubtract) => {
  let date = new Date(dateStr);
  date.setMonth(date.getMonth() - monthsToSubtract);
  return date.toISOString().split("T")[0];
};
const getClosestDuration = (months) => {
  const durationHeld = selectors.licence.durationHeld;
  const monthsInt = Number(months);
  let closestMatch = durationHeld[0];
  for (const element of durationHeld) {
    const duration = Number(element.value);
    if (monthsInt >= duration) {
      closestMatch = element;
    }
  }
  return closestMatch;
};

export const getters = {
  getSelectedCountry: (state) => state.selectedCountry,
  getSelectedIssuedCountry: (state) => state.selectedIssuedCountry,
  getSelectedLicence: (state) => state.selectedLicence,
  getSelectedDuration: (state) => state.selectedDuration,
  getInputLicenceNumberUK: (state) => state.inputLicenceNumberUK,
  getInputLicenceNumberNI: (state) => state.inputLicenceNumberNI,
  getInputLicenceNumberOther: (state) => state.inputLicenceNumberOther,
  getLicenceIsSearching: (state) => state.licenceSearch.isSearching,
  getLicenceHasSearched: (state) => state.licenceSearch.hasSearched,
  getLicenceHasExpired: (state) => state.licenceSearch.hasExpired,
  getLicenceHasError: (state) => state.licenceSearch.hasError,
  getLicenceHasFound: (state) => state.licenceSearch.hasFound,
  getLicenceLegacy: (state) => state.licenceLegacy,
  getLicenceValidFrom: (state) => state.licenceSearch.validFrom,
  getLicenceNumber: (state, getters) => {
    if (getters.getSelectedCountry === "UK") {
      return getters.getInputLicenceNumberUKCombined ?? "";
    }

    if (getters.getSelectedCountry === "NI") {
      return getters.getInputLicenceNumberNI ?? "";
    }

    return getters.getInputLicenceNumberOther ?? "";
  },
  getLicenceDate: (state, getters, rootState, rootGetters) => {
    if (
      state.licenceSearch.validFrom &&
      rootGetters["modules/additional/licencedetails/getSelectedCountry"] ===
      "UK"
    ) {
      return state.licenceSearch.validFrom;
    }

    const dateStr =
      rootGetters["modules/datetimedetails/getServerTime"]?.now_iso;

    if (dateStr) {
      const monthsToSubtract = getters.getSelectedDuration?.value ?? 0;
      const newDateStr = subtractMonthsFromDate(dateStr, monthsToSubtract);
      return newDateStr;
    } else {
      return "";
    }
  },
  getLicenceDurationInMonthsAtStartOfPolicy: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const licenceDate = new Date(getters.getLicenceDate);
    const nowString =
      rootGetters["modules/datetimedetails/getSelectedISO"] ||
      rootGetters["modules/datetimedetails/getServerTime"].now_iso;
    const now = new Date(nowString);

    const totalMonthsDifference =
      (now.getFullYear() - licenceDate.getFullYear()) * 12 +
      (now.getMonth() - licenceDate.getMonth());

    return totalMonthsDifference;
  },
  getInputLicenceNumberUKCombined: (state) => {
    return (
      state.inputLicenceNumberUK.licencePart1 +
      state.inputLicenceNumberUK.licencePart2 +
      state.inputLicenceNumberUK.licencePart3
    );
  },
  getSelectedLicenceTransformed: (state, getters) => {
    return transformLicence(
      getters.getSelectedCountry,
      getters.getSelectedLicence
    );
  },
  getDuqValue: (state, getters) => {
    const country = getters.getSelectedLicenceTransformed?.country;

    switch (country) {
      case "NI":
        return "Ireland";
      case "International":
        return "International";
      case "EU/EEA":
        return getters.getSelectedIssuedCountry?.text ?? null;
      default:
        return null;
    }
  },
  showLicenceExpiredWarning: (state, getters) => {
    return getters.getLicenceHasSearched && getters.getLicenceHasExpired;
  },
  showLicenceNotFoundWarning: (state, getters) => {
    return getters.getLicenceHasSearched && !getters.getLicenceHasFound;
  },
  showLicenceHasErrorWarning: (state, getters) => {
    return getters.getLicenceHasSearched && getters.getLicenceHasError;
  },
};

export const mutations = {
  setSelectedCountry(state, value) {
    state.selectedCountry = value;
  },
  setSelectedIssuedCountry(state, value) {
    state.selectedIssuedCountry = value;
  },
  setSelectedLicence(state, value) {
    state.selectedLicence = value;
  },
  setSelectedDuration(state, value) {
    state.selectedDuration = value;
  },
  setInputLicenceNumberUK(state, value) {
    state.inputLicenceNumberUK = value;
    state.licenceSearch.hasSearched = false;
  },
  setInputLicenceNumberNI(state, value) {
    state.inputLicenceNumberNI = value;
  },
  setInputLicenceNumberOther(state, value) {
    state.inputLicenceNumberOther = value;
  },
  setLicenceIsSearching(state, value) {
    state.licenceSearch.isSearching = value;
  },
  setLicenceHasFound(state, value) {
    state.licenceSearch.hasFound = value;
  },
  setLicenceHasExpired(state, value) {
    state.licenceSearch.hasExpired = value;
  },
  setLicenceHasSearched(state, value) {
    state.licenceSearch.hasSearched = value;
  },
  setLicenceHasError(state, value) {
    state.licenceSearch.hasError = value;
  },
  setLicenceLegacy(state, value) {
    state.licenceLegacy.GSMULRD1 = value?.GSMULRD1 ?? "";
    state.licenceLegacy.GSMULRD2 = value?.GSMULRD2 ?? "";
    state.licenceLegacy.GSMYLCNT = value?.GSMYLCNT ?? "";
    state.licenceLegacy.GSMYLDIS = value?.GSMYLDIS ?? "";
    state.licenceLegacy.GSMYLPTS = value?.GSMYLPTS ?? "";
  },
  setLicenceValidFrom(state, value) {
    state.licenceSearch.validFrom = value;
  },
  resetState: (state) => {
    Object.assign(state, {
      ...initialState(),
    });
  },
};

export const actions = {
  async mapRecall({ commit, dispatch, getters, rootGetters }, riskData) {
    let allFieldsPresent = true;
    const checkAndCommit = (commitName, value) => {
      if (value !== undefined && value !== null) {
        commit(commitName, value);
      } else {
        allFieldsPresent = false;
      }
    };

    // Get licence data
    const licenceData = convertLicence(
      riskData?.additionalInsured,
      riskData?.duqsDuq939Response || riskData?.duqsDuq462Response
    );
    if (licenceData) {
      checkAndCommit("setSelectedCountry", licenceData.country);
      checkAndCommit("setSelectedLicence", licenceData.type);

      const licenceNumber = riskData?.userfieldsUserfieldGOIDDLN2Response;
      const date1 = riskData?.additionalInsuredLicenceDate;
      const date2 =
        rootGetters["modules/datetimedetails/getServerTime"]?.now_iso ||
        new Date().toISOString();
      const months = convertMonthsBetween(date1, date2);

      if (licenceData.country === "UK") {
        const licencePart1 = licenceNumber.slice(0, 5);
        const licencePart2 = licenceNumber.slice(5, 11);
        const licencePart3 = licenceNumber.slice(11, 16);

        checkAndCommit("setInputLicenceNumberUK", {
          licencePart1,
          licencePart2,
          licencePart3,
        });

        const licenceResult = await dispatch("fetchLicence");
        if (!licenceResult.success) allFieldsPresent = false;
      } else if (licenceData.country === "NI") {
        checkAndCommit("setInputLicenceNumberNI", licenceNumber);
        checkAndCommit("setSelectedDuration", getClosestDuration(months));
      } else if (licenceData.country === "International") {
        checkAndCommit("setInputLicenceNumberOther", licenceNumber);
        checkAndCommit("setSelectedDuration", getClosestDuration(months));
      } else {
        checkAndCommit("setInputLicenceNumberOther", licenceNumber);
        checkAndCommit("setSelectedDuration", getClosestDuration(months));
        checkAndCommit(
          "setSelectedIssuedCountry",
          selectors.licence.countries.find(
            (c) =>
              c.text?.toLowerCase() ===
              riskData?.duqsDuq939Response?.toLowerCase() ||
              riskData?.duqsDuq462Response?.toLowerCase()
          )
        );
      }
    } else {
      allFieldsPresent = false;
    }

    return allFieldsPresent;
  },
  async fetchLicence({ commit, getters, rootGetters }) {
    commit("setLicenceIsSearching", true);
    commit("setLicenceHasSearched", false);
    const request = {
      policyHolderOccupation: `${this.$config?.environment?.toLowerCase() === "production"
          ? "PROD"
          : "DEV"
        }${rootGetters["modules/personaldetails/getInputOccupation"]?.description
        }`,
      policyInceptionDate:
        rootGetters["modules/datetimedetails/getSelectedISO"],
      namedDriverDLN: getters.getInputLicenceNumberUKCombined,
      namedDriverDeclaredLicenceType:
        getters.getSelectedLicence === "Full" ? "F" : "P",
    };

    if (rootGetters["modules/licencedetails/getSelectedCountry"] === "UK") {
      request.policyHolderDLN =
        rootGetters["modules/licencedetails/getInputLicenceNumberUKCombined"];
      request.policyHolderDeclaredLicenceType =
        rootGetters["modules/personaldetails/getSelectedLicence"] === "Full"
          ? "F"
          : "P";
    }

    try {
      const myLicence = await this.$goshorty.licence(request);
      commit("setLicenceHasError", false);
      commit("setLicenceHasExpired", !!myLicence?.namedDriver?.expired);
      commit("setLicenceHasFound", !!myLicence?.namedDriver?.validFrom);
      commit("setLicenceLegacy", myLicence?.legacy);
      commit("setLicenceValidFrom", myLicence?.namedDriver?.validFrom || "");
      return { success: true };
    } catch (err) {
      commit("setLicenceHasError", true);
      return {
        success: false,
        error: err.message,
      };
    } finally {
      commit("setLicenceHasSearched", true);
      commit("setLicenceIsSearching", false);
    }
  },
};
