<template>
  <v-autocomplete
    :items="getManualMakes"
    :value="value"
    :rules="rules"
    return-object
    placeholder="Please select"
    :data-zuko-id="zukoId"
    @input="$emit('input', $event)"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "VehicleMake",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Please select a valid value for vehicle make."],
  }),
  async fetch() {
    await this.$store.dispatch("modules/manualsearchdetails/fetchMakes");
  },
};
</script>
