<template>
  <v-row justify="center" align="center">
    <v-col
      cols="12"
      sm="7"
      class="vehicle-details-container__title-col pb-0 pb-md-4"
    >
      <h1
        class="vehicle-details-container__title-col__title font-weight-regular font-weight-normal"
      >
        <slot></slot>
      </h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FormTitle",
};
</script>
