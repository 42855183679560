var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          items: _vm.items,
          value: _vm.value,
          "return-object": "",
          rules: _vm.rules,
          placeholder: "Please select",
          "menu-props": {
            maxHeight: "320px",
          },
          "data-zuko-id": _vm.zukoId,
        },
        on: {
          change: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }