<template>
  <div class="occupation-container">
    <v-text-field
      :value="value.description"
      :rules="rules"
      clearable
      :placeholder="placeholder"
      @input="onInput"
      @click:clear="onClear"
      validate-on-blur
      ref="occupationField"
      :data-zuko-id="zukoId"
      :error-messages="
        !hasMatchingOccupation() && value.description?.length > 2
          ? [`Please retype a valid value for ${this.pronoun} occupation.`]
          : []
      "
    />
    <div
      v-if="value.description && value.description.length && items.length"
      class="occupation-results-container"
    >
      <div class="mb-2">
        <FormLabel>Select an occupation from the list: </FormLabel>
      </div>
      <v-btn
        v-for="(occupation, index) in items"
        :key="index"
        @click="onSelect(occupation)"
        class="brandPrimary brandButtonText--text"
        block
      >
        {{ occupation.description }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import occupationData from "~/assets/json/occupations.json";
export default {
  name: "PersonalOccupation",
  props: {
    value: {
      type: [Object, String],
      default: () => ({}),
    },
    pronoun: {
      type: String,
      default: "your",
    },
    zukoId: {
      type: String,
      required: true,
    },
  },
  data(dt) {
    return {
      items: [],
      loggedOccupations: [],
      placeholder: `Type ${this.pronoun} occupation...`,
      rules: [
        (v) =>
          (!!v && v.length > 2) ||
          `Please enter and select a valid value for ${this.pronoun} occupation.`,
        () =>
          dt.items.length === 0 ||
          `Please enter and select a valid value for ${this.pronoun} occupation.`,
      ],
    };
  },
  methods: {
    hasMatchingOccupation() {
      return !(
        this.value.description?.length > 2 &&
        this.items.length === 0 &&
        !this.value.code
      );
    },
    logMismatchedOccupation() {
      const occupationHasBeenLogged = (occupation) => {
        return this.loggedOccupations.includes(occupation);
      };
      if (!occupationHasBeenLogged(this.value.description)) {
        this.loggedOccupations.push(this.value.description);
        const sessionId = this.$store.getters["modules/session/sessionId"];
      }
    },
    async onInput(event) {
      if (!event || event.length < 3) {
        this.items = [];
        this.$refs.occupationField.validate();
        return;
      }

      this.$emit("input", {
        description: event,
      });

      const occupations = this.filterOccupations(event);

      if (occupations.length > 0) {
        this.items = occupations;
      }
    },
    onSelect(event) {
      this.$emit("input", event);
      this.items = [];
      this.$refs.occupationField.validate();
      this.checkForErrors();
    },
    onClear() {
      this.items = [];
      this.$emit("input", {});
      if (!this.hasMatchingOccupation() && this.value.description?.length > 2) {
        this.logMismatchedOccupation();
      }
    },
    filterOccupations(searchTerm) {
      return occupationData.occupation_list.filter((item) =>
        item.description.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    },
    onBlur() {
      if (!this.hasMatchingOccupation() && this.value.description?.length > 2) {
        this.logMismatchedOccupation();
      }
      this.$emit("blur");
    },
  },
};
</script>

<style lang="scss" scoped>
.occupation-container::v-deep {
  .theme--light.v-btn.v-btn--has-bg {
    height: 40px !important;
    margin-bottom: 6px !important;
    text-transform: none !important;

    .v-btn__content {
      font-size: 1.125rem !important;
    }
  }

  .v-input__icon--clear {
    opacity: 1;
  }

  .occupation-results-container {
    margin-bottom: 1rem;
    max-height: 210px;
    overflow-y: auto;

    .v-btn {
      max-width: 530px;
      width: 100%;
      &__content {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .mdi-close {
    padding-top: 25px;
    color: #64b5f6 !important;

    @media (max-width: 425px) {
      padding-top: 50%;
    }
  }
}
</style>
