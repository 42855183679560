<template>
  <v-row v-if="quote && isDisplayed" class="quote-card-container">
    <v-col cols="12" sm="12" lg="12">
      <v-card fill-height class="quote-card py-3">
        <v-row align="center" class="px-3">
          <v-col
            cols="6"
            :style="$vuetify.breakpoint.lgAndUp ? ' flex: 1 0 20%;' : ''"
            align="center"
          >
            <v-img
              :src="logo"
              max-width="140px"
              max-height="100px"
              height="100px"
              contain
            />
          </v-col>
          <v-col
            cols="6"
            :style="$vuetify.breakpoint.lgAndUp ? ' flex: 1 0 20%;' : ''"
            class="text-h5 text-xs-h4 text-sm-h3 font-weight-bold brandSecondary white--text text-center d-flex align-center justify-center px-0"
            align-self="stretch"
          >
            <span>{{ price }}</span>
          </v-col>
          <v-col
            cols="6"
            :style="$vuetify.breakpoint.lgAndUp ? ' flex: 1 0 20%;' : ''"
          >
            <div
              class="vehicle-value-label text-body-1 text-md-h6 text-center mb-0"
            >
              Max Vehicle Value
            </div>
            <div
              class="text-h6 text-xs-h5 brandDarkGrey--text font-weight-bold text-center"
            >
              {{ maxVehicleValue }}
            </div>
          </v-col>
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            vertical
            inset
            class="my-7"
          ></v-divider>
          <v-col
            cols="6"
            :style="$vuetify.breakpoint.lgAndUp ? ' flex: 1 0 20%;' : ''"
          >
            <div
              class="compulsory-excess-label text-body-1 text-md-h6 text-center"
            >
              Compulsory Excess
            </div>
            <div
              class="text-h6 text-xs-h5 brandDarkGrey--text font-weight-bold text-center"
            >
              {{ compulsoryExcess }}
            </div>
          </v-col>
          <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? ' flex: 1 0 20%;' : ''"
          >
            <v-btn
              rounded
              block
              :loading="
                quote?.insurer_code === buttonClicked?.insurerCode &&
                quote?.scheme_code === buttonClicked?.schemeCode
              "
              :disabled="
                Object.keys(buttonClicked).length > 0 &&
                (buttonClicked?.insurerCode !== quote.insurer_code ||
                  buttonClicked?.schemeCode !== quote.scheme_code)
              "
              @click="onClick"
              class="brandPrimary brandButtonText--text mb-2"
              :id="`gtm--quoteForm-quote-${quote.insurer_code}-buy`"
              :data-zuko-id="`zuko--quoteForm-quote-${quote.insurer_code}-buy`"
              >Buy Now

              <v-icon class="ml-2">mdi-arrow-right-circle-outline </v-icon>
            </v-btn>
            <a
              @click="onClick"
              class="black--text font-weight-bold text-decoration-underline"
              :id="`gtm--quoteForm-quote-${quote.insurer_code}-more-information`"
              :data-zuko-id="`zuko--quoteForm-quote-${quote.insurer_code}-more-information`"
              >More Information</a
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-else-if="quoteTweaked" justify="center">
    <v-col class="w-full mx-4 mb-4 text-center">
      <v-card outlined>
        <div class="white px-8 py-8 px-sm-16 pt--sm-16">
          <div class="text-h5 font-weight-bold text-center">
            Click the Get Quotes button to refresh your quotes
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-else justify="center">
    <v-col class="w-full mx-4 mb-4">
      <v-card outlined>
        <div class="white pa-16">
          <div class="text-h4 font-weight-bold text-center">
            Sorry, but we were unable to provide a quote. Please, check your
            details and try again.
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "QuoteCard",
  props: {
    quote: {
      type: Object,
      default: () => ({}),
    },
    quoteTweaked: {
      type: Boolean,
      default: false,
    },
    buttonClicked: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    logo() {
      return this.quote.front_end.logo_url;
    },
    price() {
      return this.$formatters.currency(
        this.quote.front_end?.premiums?.total_to_pay
      );
    },
    maxVehicleValue() {
      return this.$formatters.currencyNoDecimal(
        this.quote.front_end?.values?.maximum_vehicle_value_raw
      );
    },
    compulsoryExcess() {
      return this.$formatters.currencyNoDecimal(
        this.quote.front_end?.values?.excess
      );
    },
    isDisplayed() {
      return !!this.price && !!this.maxVehicleValue && !!this.compulsoryExcess;
    },
  },
  methods: {
    onClick() {
      this.$emit("input", {
        insurerCode: this.quote.insurer_code,
        schemeCode: this.quote.scheme_code,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-card-container::v-deep {
  .quote-card {
    border: 2px solid var(--v-brandPrimary-base);
  }
  .compulsory-excess-label {
    font-family: "Font Theme Regular", sans-serif !important;
  }
  .vehicle-value-label {
    font-family: "Font Theme Regular", sans-serif !important;
  }
  .v-btn {
    height: 40px !important;
    margin-bottom: 0;
    min-width: 100%;
    width: auto !important;

    @media (min-width: 426px) {
      min-width: auto;
    }

    &__content {
      font-size: 1.25rem;
    }
  }
}
</style>
