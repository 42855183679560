<template>
  <v-text-field
    :value="value"
    :rules="rules"
    :placeholder="label === 'first name' ? 'First name' : 'Surname'"
    :data-zuko-id="zukoId"
    :data-sentry-mask="true"
    @input="$emit('input', $event)"
  ></v-text-field>
</template>

<script>
export default {
  name: "PersonalName",
  props: {
    value: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      validator(value) {
        return !!value.match(/surname|first name/gi);
      },
      required: true,
    },
    zukoId: {
      type: String,
      required: true,
    },
    pronoun: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          !!v ||
          `Please enter a valid value for ${this.pronoun} ${this.label}.`,
        (v) =>
          !/\d/.test(v) ||
          `Please enter a valid value for ${this.pronoun} ${this.label}.`,
      ],
    };
  },
};
</script>
