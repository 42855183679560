<template>
  <div
    v-show="hasQuotePromo"
    class="promo-box"
    v-html="getQuotePromoText"
  ></div>
</template>

<script>
export default {
  name: "PromoBox",
};
</script>

<style scoped>
.promo-box {
  border: double 4px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    radial-gradient(
      circle at top left,
      #d240ff,
      #b64aff,
      #506fff,
      #4183f0,
      #51b8c0,
      #71ed9b
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 5px 10px;
  text-align: center;
  color: #000000;
}
</style>
