<template>
  <v-select
    ref="addressSelector"
    :items="addresses"
    :value="value"
    item-text="address_selector"
    return-object
    :rules="rules"
    :placeholder="`Please select ${pronoun} address`"
    :id="`${id}-select`"
    :data-zuko-id="`${zukoId}-select`"
    :data-sentry-mask="true"
    :data-sentry-block="true"
    @input="$emit('input', $event)"
  >
    <template
      #selection="{ item }"
      :data-sentry-mask="true"
      :data-sentry-block="true"
    >
      <div
        class="v-select__selection v-select__selection--comma text-wrap"
        style="max-width: 430px"
        :data-sentry-mask="true"
        :data-sentry-block="true"
      >
        <div :data-sentry-mask="true" :data-sentry-block="true">
          {{ item.address_selector }}
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "PersonalAddresses",
  props: {
    id: {
      type: String,
    },
    zukoId: {
      type: String,
    },
    addresses: {
      type: Array,
    },
    value: {
      type: [Object, String],
    },
    pronoun: {
      type: String,
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          !!v || `Please select a valid value for ${this.pronoun} address.`,
      ],
    };
  },
  methods: {
    open() {
      this.$refs.addressSelector.activateMenu();
    },
  },
};
</script>
