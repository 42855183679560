var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-checkbox", {
    class: !_vm.value ? "custom-checkbox" : "",
    attrs: {
      color: "brandText",
      id: "gtm--coverDetailsForm-vehicleKeeper",
      rules: _vm.rules,
      "data-zuko-id": "'zuko--coverDetailsForm-vehicleKeeper",
    },
    on: {
      change: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function () {
          return [
            _c(
              "label",
              {
                staticClass: "text-body-2 brandText--text",
                attrs: { for: "declarationz" },
              },
              [
                _vm._v(
                  "I declare that I am the owner of the insured vehicle and am in\n      possession of a V5 document bearing my name, or in the event that the\n      vehicle is owned by a leasing company, can provide evidence of a current\n      lease for the insured vehicle in my name. I also understand that the\n      policy must be purchased in the name of the vehicle owner/ lease\n      holder."
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.internalValue,
      callback: function ($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }