var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.quote && _vm.isDisplayed
    ? _c(
        "v-row",
        { staticClass: "quote-card-container" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", lg: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "quote-card py-3",
                  attrs: { "fill-height": "" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-3", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          style: _vm.$vuetify.breakpoint.lgAndUp
                            ? " flex: 1 0 20%;"
                            : "",
                          attrs: { cols: "6", align: "center" },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.logo,
                              "max-width": "140px",
                              "max-height": "100px",
                              height: "100px",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-h5 text-xs-h4 text-sm-h3 font-weight-bold brandSecondary white--text text-center d-flex align-center justify-center px-0",
                          style: _vm.$vuetify.breakpoint.lgAndUp
                            ? " flex: 1 0 20%;"
                            : "",
                          attrs: { cols: "6", "align-self": "stretch" },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.price))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          style: _vm.$vuetify.breakpoint.lgAndUp
                            ? " flex: 1 0 20%;"
                            : "",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-value-label text-body-1 text-md-h6 text-center mb-0",
                            },
                            [
                              _vm._v(
                                "\n            Max Vehicle Value\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-h6 text-xs-h5 brandDarkGrey--text font-weight-bold text-center",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.maxVehicleValue) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("v-divider", {
                            staticClass: "my-7",
                            attrs: { vertical: "", inset: "" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          style: _vm.$vuetify.breakpoint.lgAndUp
                            ? " flex: 1 0 20%;"
                            : "",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "compulsory-excess-label text-body-1 text-md-h6 text-center",
                            },
                            [
                              _vm._v(
                                "\n            Compulsory Excess\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-h6 text-xs-h5 brandDarkGrey--text font-weight-bold text-center",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.compulsoryExcess) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          style: _vm.$vuetify.breakpoint.lgAndUp
                            ? " flex: 1 0 20%;"
                            : "",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "brandPrimary brandButtonText--text mb-2",
                              attrs: {
                                rounded: "",
                                block: "",
                                loading:
                                  _vm.quote?.insurer_code ===
                                    _vm.buttonClicked?.insurerCode &&
                                  _vm.quote?.scheme_code ===
                                    _vm.buttonClicked?.schemeCode,
                                disabled:
                                  Object.keys(_vm.buttonClicked).length > 0 &&
                                  (_vm.buttonClicked?.insurerCode !==
                                    _vm.quote.insurer_code ||
                                    _vm.buttonClicked?.schemeCode !==
                                      _vm.quote.scheme_code),
                                id: `gtm--quoteForm-quote-${_vm.quote.insurer_code}-buy`,
                                "data-zuko-id": `zuko--quoteForm-quote-${_vm.quote.insurer_code}-buy`,
                              },
                              on: { click: _vm.onClick },
                            },
                            [
                              _vm._v("Buy Now\n\n            "),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("mdi-arrow-right-circle-outline "),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "black--text font-weight-bold text-decoration-underline",
                              attrs: {
                                id: `gtm--quoteForm-quote-${_vm.quote.insurer_code}-more-information`,
                                "data-zuko-id": `zuko--quoteForm-quote-${_vm.quote.insurer_code}-more-information`,
                              },
                              on: { click: _vm.onClick },
                            },
                            [_vm._v("More Information")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.quoteTweaked
    ? _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "w-full mx-4 mb-4 text-center" },
            [
              _c("v-card", { attrs: { outlined: "" } }, [
                _c(
                  "div",
                  { staticClass: "white px-8 py-8 px-sm-16 pt--sm-16" },
                  [
                    _c(
                      "div",
                      { staticClass: "text-h5 font-weight-bold text-center" },
                      [
                        _vm._v(
                          "\n          Click the Get Quotes button to refresh your quotes\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "w-full mx-4 mb-4" },
            [
              _c("v-card", { attrs: { outlined: "" } }, [
                _c("div", { staticClass: "white pa-16" }, [
                  _c(
                    "div",
                    { staticClass: "text-h4 font-weight-bold text-center" },
                    [
                      _vm._v(
                        "\n          Sorry, but we were unable to provide a quote. Please, check your\n          details and try again.\n        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }