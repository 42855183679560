var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          staticClass:
            "text-body-1 font-weight-medium text-center mx-auto brandText--text",
          attrs: { cols: "12" },
        },
        [
          _c("p", { staticClass: "font-weight-bold" }, [
            _vm._v("\n      By clicking Get Quotes you agree to the\n      "),
            _c(
              "a",
              {
                staticClass: "brandText--text",
                attrs: {
                  href: "https://goshorty.co.uk/terms-of-business/",
                  target: "_blank",
                  rel: "noopener",
                  id: "gtm--licenceDetailsForm-terms",
                  "data-zuko-id": "zuko--licenceDetailsForm-terms",
                },
              },
              [_vm._v("Terms & Conditions")]
            ),
            _vm._v("\n      and\n      "),
            _c(
              "a",
              {
                staticClass: "brandText--text",
                attrs: {
                  href: "https://goshorty.co.uk/privacy-policy/",
                  target: "_blank",
                  rel: "noopener",
                  id: "gtm--licenceDetailsForm-privacy",
                  "data-zuko-id": "zuko--licenceDetailsForm-privacy",
                },
              },
              [_vm._v("\n        Privacy Policy\n      ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }