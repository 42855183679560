<template>
  <v-row>
    <v-col
      cols="12"
      class="text-body-1 font-weight-medium text-center mx-auto brandText--text"
    >
      <p class="font-weight-bold">
        By clicking Get Quotes you agree to the
        <a
          class="brandText--text"
          href="https://goshorty.co.uk/terms-of-business/"
          target="_blank"
          rel="noopener"
          id="gtm--licenceDetailsForm-terms"
          data-zuko-id="zuko--licenceDetailsForm-terms"
          >Terms & Conditions</a
        >
        and
        <a
          class="brandText--text"
          href="https://goshorty.co.uk/privacy-policy/"
          target="_blank"
          rel="noopener"
          id="gtm--licenceDetailsForm-privacy"
          data-zuko-id="zuko--licenceDetailsForm-privacy"
        >
          Privacy Policy
        </a>
      </p>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BaseTermsAndPrivacy",
};
</script>
