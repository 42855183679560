var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      staticClass: "text-left mt-3 black--text",
      class: {
        "px-10": _vm.isMdAndAbove,
      },
      attrs: {
        prominent: "",
        type: _vm.isMdAndAbove ? "error" : null,
        color: "orange",
      },
    },
    [
      _c(
        "div",
        {
          class: {
            "pl-10": _vm.isMdAndAbove,
          },
        },
        [
          _c("h2", [
            _vm._v(
              _vm._s(_vm.getMaintenanceMessages.maintenanceMessage.headline)
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "pt-3",
            domProps: {
              innerHTML: _vm._s(
                _vm.getMaintenanceMessages.maintenanceMessage.information
              ),
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }