var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass:
        "cover-details-container-label formLabel border-md rounded white pa-2 my-2 text-center",
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }