var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      items: _vm.coverLengthItems,
      id: _vm.id,
      "data-zuko-id": _vm.zukoId,
      value: _vm.value,
      rules: _vm.rules,
      "item-text": "value",
      "item-value": "abbr",
      "return-object": "",
      placeholder: "Please select",
      "no-data-text": "Please select a Duration Type first",
    },
    on: {
      change: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }