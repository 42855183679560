<template>
  <BaseCard class="mb-4 breakdown-protection-container">
    <template #title v-if="value">{{ title }}</template>
    <template #title v-else>Add {{ title.toLowerCase() }}?</template>
    <v-row class="px-0 align-start">
      <v-col cols="8" class="pt-0">
        <p
          :class="
            $vuetify.breakpoint.mdOnly &&
            'font-weight-bold text-md-body-1 brandButtonText--text'
          "
          v-html="wording"
        ></p>
      </v-col>
      <v-col cols="4" class="pt-0 d-flex flex-column align-center">
        <p class="mb-2">Only</p>
        <span
          class="text-h5 text-sm-h4 text-md-h5 text-lg-h4 font-weight-bold brandDarkestGrey--text"
          >£{{ premium }}</span
        >
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="2" class="pt-0"></v-col>
      <v-col cols="6" class="pl-0">
        <p v-if="value" class="black--text mt-1">
          Added
          <v-icon color="#24c622">mdi-checkbox-marked</v-icon>
        </p>
        <p v-else class="black--text mt-1">
          Tap to add <v-icon class="black--text">mdi-arrow-right</v-icon>
        </p>
      </v-col>
      <v-col cols="4" class="d-flex justify-center">
        <v-switch
          color="brandPrimary"
          v-model="localValue"
          hide-details
          :disabled="disabled"
          inset
          class="mt-0 pl-4 v-switch--large"
          :data-zuko-id="`zuko--confirmationForm-opex-${opex}`"
        ></v-switch>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn
        class="brandPrimary brandButtonText--text"
        id="further-information"
        data-zuko-id="further-information"
        @click="expanded = !expanded"
        block
        small
        >{{
          expanded ? "Hide further Information" : "Show further Information"
        }}</v-btn
      >
    </v-card-actions>
    <div v-show="expanded" class="mt-4 mx-2">
      <v-row v-html="extraInformation"></v-row>
      <v-row
        ><p class="warning-label text-center py-2 black--text">
          Please, read the documents carefully
        </p></v-row
      >
      <v-row>
        <v-col cols="7" class="black--text px-0"
          >Insurance Product Information Document (IPID)</v-col
        >
        <v-col cols="5" class="d-flex align-center"
          ><v-btn
            class="brandPrimary brandButtonText--text"
            small
            block
            id="ipid"
            data-zuko-id="ipid"
            @click="showIpid"
            >Click to read</v-btn
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="7" class="black--text px-0">Policy wording</v-col>
        <v-col cols="5" class="d-flex align-center"
          ><v-btn
            class="brandPrimary brandButtonText--text"
            small
            block
            id="policy-wording"
            data-zuko-id="policy-wording"
            @click="showPolicyWording"
            >Click to read</v-btn
          ></v-col
        >
      </v-row>
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: "OptionalExtraCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    premium: {
      type: [String, Number],
      default: "",
    },
    frontend: {
      type: Object,
      default: () => {},
    },
    opex: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    wording() {
      return new DOMParser().parseFromString(
        this.frontend?.content?.wording?.main,
        "text/html"
      ).body.textContent;
    },
    extraInformation() {
      return this.frontend?.content?.wording?.extra_information;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    showIpid() {
      window.open(this.frontend?.content?.pdf?.ipid_url, "_blank");
    },
    showPolicyWording() {
      window.open(this.frontend?.content?.pdf?.policy_wording_url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.v-switch--large {
  transform: scale(1.5);
  transform-origin: center;
  max-width: 100px;
}
.breakdown-protection-container::v-deep {
  border-color: #7890b9 !important;
  border-radius: 0px;
  .costs-title {
    color: #000;
    min-width: 100%;

    @media (min-width: 475px) {
      min-width: auto;
    }
  }
  .text-h6 {
    font-weight: bold;
  }
  p {
    font-family: "Font Theme DemiBold", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .v-input--switch__track {
    color: #404040;
  }
  .v-input--selection-controls__ripple {
    color: #404040;
  }
  .warning-label {
    border: 2px solid var(--v-brandSecondary-base);
    border-radius: 8px;
    min-width: 100%;
  }
}
</style>
