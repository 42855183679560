<template>
  <div>
    <v-btn-toggle
      :value="value"
      @change="$emit('input', $event)"
      class="btnGroup d-flex justify-space-between transparent mt-2 mb-1"
      :class="{
        'flex-wrap': wrapButtons || wrapAll,
      }"
      :mandatory="!notMandatory"
    >
      <v-col
        :cols="cols"
        class="d-flex align-center justify-center py-1 px-0"
        v-for="(item, index) in items"
        :key="index"
        :class="{
          'px-1': wrapButtons,
          'pl-0': wrapButtons && (index === 0 || index === 2),
          'pr-0': wrapButtons && (index === 1 || index === 3),
          'pl-1': !wrapButtons && !wrapAll && index !== 0,
          'pr-1': !wrapButtons && !wrapAll && index !== items.length - 1,
        }"
      >
        <v-btn
          :value="item"
          active-class="active-button brandPrimary brandButtonText--text"
          class="toggle-button brandButtonText--text px-0 mb-1 widthClass"
          :ripple="false"
          :rounded="rounded"
          :disabled="disabled && value.value !== item?.value"
          :max-height="small ? '46px' : ''"
          :style="getButtonStyle(applyBorder, small)"
          :data-zuko-id="getZukoId(item)"
        >
          <span v-if="item?.text">{{ item?.text }}</span>
          <span v-else-if="item?.value">{{ item?.value }}</span>
          <span v-else>{{ item }}</span>
        </v-btn>
      </v-col>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: "BaseButtonToggle",
  props: {
    value: {
      type: [Object, String, Number],
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    notMandatory: {
      type: Boolean,
      default: () => false,
    },
    wrapButtons: {
      type: Boolean,
      default: () => false,
    },
    wrapAll: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    small: {
      type: Boolean,
      default: () => false,
    },
    zukoId: {
      type: String,
      default: "zuko--",
    },
    applyBorder: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getZukoId(item) {
      if (item?.text) {
        return `${this.zukoId}-${item?.text}`.replace(/ /g, "");
      } else if (item?.value) {
        return `${this.zukoId}-${item?.value}`.replace(/ /g, "");
      } else {
        return `${this.zukoId}-${item}`.replace(/ /g, "");
      }
    },
    getButtonStyle(applyBorder, small) {
      let style = {};
      if (applyBorder) {
        style.border = "2px solid red !important";
      }
      if (small) {
        style.marginTop = "2px";
        style.maxHeight = "46px";
      }
      return style;
    },
  },
  computed: {
    cols() {
      if (this.wrapAll) {
        return 12;
      }
      if (this.wrapButtons) {
        return 24 / this.items.length;
      }
      return 12 / this.items.length;
    },
  },
};
</script>

<style scoped lang="scss">
.active-button {
  opacity: 1;
}

.errorBorder {
  border-width: 2px;
  border-style: solid;
  border-color: red !important;
}

.btnGroup ::v-deep .toggle-button {
  background-color: var(--v-brandButton-base);
  border-color: #fff !important;

  .toggle-button {
    background-color: var(--v-brandButton-base) !important;
    font-family: "Font Theme Serif Regular", serif !important;
  }
}
.btnGroup ::v-deep .square-btn {
  max-height: 46px !important;
  font-size: 1rem;
  margin-top: 2px;
  .v-btn__content {
    font-size: 1rem;
  }
}

.v-btn--active {
  &::before {
    opacity: 0;
  }
}
.widthClass {
  width: 100%;
  max-width: 100%;
}
</style>
