import { render, staticRenderFns } from "./AnnualInsurance.vue?vue&type=template&id=1376254d&"
import script from "./AnnualInsurance.vue?vue&type=script&lang=js&"
export * from "./AnnualInsurance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/buy.v2.goshorty.co.uk/httpdocs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1376254d')) {
      api.createRecord('1376254d', component.options)
    } else {
      api.reload('1376254d', component.options)
    }
    module.hot.accept("./AnnualInsurance.vue?vue&type=template&id=1376254d&", function () {
      api.rerender('1376254d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/vehicle/AnnualInsurance.vue"
export default component.exports