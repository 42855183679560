<template>
  <div class="wide px-3">
    <v-expansion-panels flat>
      <v-expansion-panel class="brandBackgroundDark">
        <v-expansion-panel-header class="d-flex px-0 pt-3 pb-5" hide-actions>
          <v-col cols="6" sm="9" class="d-flex flex-row white--text px-0">
            <p
              class="mb-0 font-weight-bold brandText--text"
              :class="$vuetify.breakpoint.xs ? 'text-body-2' : 'text-body-1'"
              v-if="isImpound"
            >
              Third Party Only Cover for
              {{ selectedCoverDurationLength?.value }} starting:
              {{
                selectedStartDate?.text === "Immediate Start"
                  ? "Immediately"
                  : selectedStartDate?.text
              }}
            </p>
            <p
              class="mb-0 font-weight-bold brandText--text"
              :class="$vuetify.breakpoint.xs ? 'text-body-2' : 'text-body-1'"
              v-if="!isImpound"
            >
              Fully Comprehensive Cover for
              {{ selectedCoverDurationLength?.value }} starting:
              {{
                selectedStartDate?.text === "Immediate Start"
                  ? "Immediately"
                  : selectedStartDate?.text
              }}
            </p>
          </v-col>
          <v-col cols="6" sm="3">
            <v-btn
              class="brandPrimary brandButtonText--text text-body-1 font-weight-bold edit-btn ml-3"
              rounded
              block
              small
              max-width="192px"
              min-width="100px"
              id="gtm--quoteForm-edit-quote"
              data-zuko-id="zuko--quoteForm-edit-quote"
            >
              Edit quote
            </v-btn>
          </v-col>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="brandText--text">
          <v-divider class="mb-6"></v-divider>
          <v-form ref="quoteForm" class="wide">
            <v-row class="text-body-2 text-md-body-1 brandNavy pt-2">
              <!-- Duration Type -->
              <v-col
                v-if="!isImpound"
                cols="12"
                sm="6"
                :md="!isImmediate ? 6 : 4"
                class="py-0 py-md-2"
              >
                <FormLabel>Duration Type:</FormLabel>
                <CoverDurationType
                  v-model="selectedCoverDurationType"
                  id="gtm--quoteForm-duration-type"
                  zukoId="zuko--quoteForm-duration-type"
                  small
                />
              </v-col>

              <!-- Duration Length -->
              <v-col
                v-if="!isImpound"
                cols="12"
                sm="6"
                :md="!isImmediate ? 6 : 4"
                class="py-0 py-md-2"
              >
                <FormLabel>Change Policy Duration:</FormLabel>
                <CoverDurationLength
                  id="zuko--quoteForm-cover-length"
                  zukoId="zuko--quoteForm-cover-length"
                  isTweak
                  v-model="selectedCoverDurationLength"
                />
              </v-col>

              <!-- Duration Start Date -->
              <v-col
                cols="12"
                sm="6"
                :md="!isImmediate ? 6 : 4"
                :class="!isImmediate ? 'py-0' : 'py-md-2'"
              >
                <FormLabel>Start Date:</FormLabel>
                <CoverStartDate
                  v-model="selectedStartDate"
                  id="gtm--quoteForm--start-date"
                  zukoId="zuko--quoteForm--start-date"
                />
              </v-col>

              <!-- Duration Start Time -->
              <v-col v-if="!isImmediate" cols="12" sm="6" lg="6" class="py-0">
                <FormLabel>Start Time:</FormLabel>
                <CoverStartTime
                  id="gtm--quoteForm-start-time"
                  data-zuko-id="zuko--quoteForm-start-time"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-row justify="center" class="mt-4">
            <v-btn
              v-if="getJourneyIsTweaked"
              rounded
              class="cover-submit-button brandButtonText--text mb-4"
              :class="{
                'red white--text': journeyCurrentErrors,
                'brandPrimary brandButtonText--text': !journeyCurrentErrors,
              }"
              @click="requote()"
              id="gtm--quoteForm-submit"
              data-zuko-id="zuko--quoteForm-submit"
            >
              <template v-if="!journeyCurrentErrors">
                Get Quotes
                <v-icon class="ml-2">mdi-arrow-right-circle-outline </v-icon>
              </template>
              <template v-else>
                {{ journeyCurrentErrors }} error<span
                  v-if="journeyCurrentErrors > 1"
                  >s&nbsp;</span
                >
                on the page
              </template>
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider class="white mb-5" />
  </div>
</template>

<script>
export default {
  name: "QuoteTweakPanel",
  methods: {
    async requote() {
      /* Form Checks */
      const isValid = this.$refs.quoteForm.validate();

      /* Error Checks */
      await this.checkForErrors({ shouldScroll: true });

      /* Move On */
      if (isValid && !this.journeyCurrentErrors) {
        this.$store.dispatch("quote/fetchQuotes");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-btn {
  font-family: "Font Theme Demibold", sans-serif !important;
}
.wide {
  min-width: 100%;
}
.cover-submit-button {
  width: 75% !important;
  min-width: 200px !important;

  @media (min-width: 400px) {
    width: 55% !important;
  }
  @media (min-width: 1025px) {
    width: 30% !important;
  }
}
</style>
