<template>
  <v-btn
    :block="block"
    rounded
    class="brandButtonText--text mb-0 mb-sm-6"
    :class="getClasses"
    @click.prevent="click"
    :id="id"
    :disabled="disabled"
    :loading="loading"
    :data-zuko-id="zukoId"
    :max-height="
      type === 'Secondary' && $vuetify.breakpoint.xs ? '37px' : '56px'
    "
  >
    <v-icon v-if="icon && type.includes('Secondary')" class="mr-2">{{
      icon
    }}</v-icon>
    {{ text }}
    <v-icon v-if="icon && type.includes('Primary')" class="ml-2">{{
      icon
    }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ["Primary", "PrimaryAlt", "Secondary"].includes(value);
      },
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    zukoId: {
      type: String,
      required: true,
    },
    click: {
      type: Function,
      required: true,
    },
    block: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    getClasses() {
      const classes = [
        {
          name: "Primary",
          classes: "brandPrimary cta-submit-button cover-submit-button",
        },
        {
          name: "PrimaryAlt",
          classes: "brandPrimary cta-submit-button",
        },
        { name: "Secondary", classes: "back-button brandBack cta-back-button" },
      ];

      const matchingClass = classes.find((item) => item.name === this.type);

      return matchingClass ? matchingClass.classes : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.cta-submit-button {
  font-family: "Font Theme Serif Bold", serif;
  font-size: 20px !important;
}

.cta-back-button {
  font-family: "Font Theme Serif DemiBold", serif;
}

.cover-submit-button {
  width: 75% !important;

  @media (min-width: 1025px) {
    width: 60% !important;
  }
}
.back-button {
  max-width: 200px;
  border: 1px solid var(--v-brandBack-base);
}
</style>
