const defaults = require("~/assets/json/defaults.json");
const occupations = require("~/assets/json/occupations.json");
const selectors = require("~/assets/json/selectors.json");

const initialState = () => ({
  addresses: [],
  addressHasSearched: false,
  ...defaults.additional.personalDetails,
});
export const state = initialState;

function convertDateOfBirth(dob, checkAndCommit) {
  if (dob) {
    const [year, month, day] = dob.split("-");
    checkAndCommit("setInputDateOfBirthDay", day);
    checkAndCommit("setInputDateOfBirthMonth", month);
    checkAndCommit("setInputDateOfBirthYear", year);
    return true;
  } else {
    return false;
  }
}

function findOccupationByCode(code, checkAndCommit) {
  const occupation = occupations.occupation_list.find((o) => o.code === code);
  if (occupation) {
    checkAndCommit("setInputOccupation", occupation);
    return true;
  }
  return false;
}

export const getters = {
  hasAddresses: (state) => !!state.addresses?.length,
  hasSelectedAddress: (state) => !!state.selectedAddress != "",
  getAddressHasSearched: (state) => state.addressHasSearched,
  getSelectedTitle: (state) => state.selectedTitle,
  getInputFirstName: (state) => state.inputFirstName,
  getInputSurname: (state) => state.inputSurname,
  getInputPostcode: (state) => state.inputPostcode,
  getInputDateOfBirthDay: (state) => state.inputDateOfBirthDay,
  getInputDateOfBirthMonth: (state) => state.inputDateOfBirthMonth,
  getInputDateOfBirthYear: (state) => state.inputDateOfBirthYear,
  getInputDateOfBirth: (state) => {
    return `${state.inputDateOfBirthYear}-${state.inputDateOfBirthMonth}-${state.inputDateOfBirthDay}`;
  },
  getInputDateOfBirthHumanized: (state) => {
    return `${state.inputDateOfBirthDay}/${state.inputDateOfBirthMonth}/${state.inputDateOfBirthYear}`;
  },
  getInputOccupation: (state) => state.inputOccupation,
  getInputMobileNumber: (state) => state.inputMobileNumber,
  getDateOfBirthISO: (state, getters) => new Date(getters.getInputDateOfBirth),
  getAddresses: (state) => state.addresses,
  getSelectedAddress: (state) => state.selectedAddress,
  getSelectedAddressSameAsMainDriver: (state) =>
    state.selectedAddressSameAsMainDriver,
  getSelectedRelationship: (state) => state.selectedRelationship,
  getMaritalStatus: (state, getters) => {
    const status = getters.getSelectedRelationship?.value ?? "";
    if (/CivPrtnr/i.test(status)) {
      return "V";
    }
    if (/COMSPOUSE/i.test(status)) {
      return "S";
    }
    return "M";
  },
  getGender: (state) => {
    return ["Mr"].includes(state.selectedTitle) ? "M" : "F";
  },
  getLicenceExport: (state, getters) => {
    return {
      firstname: state.inputFirstName,
      surname: state.inputSurname,
      title: state.selectedTitle,
      dob: getters.getInputDateOfBirth,
    };
  },
  getAgeAtStartOfPolicy(state, getters, rootState, rootGetters) {
    const startDateIsoString =
      rootGetters["modules/datetimedetails/getSelectedISO"] ||
      rootGetters["modules/datetimedetails/getServerTime"].now_iso;
    const startDate = new Date(startDateIsoString);

    const dob = getters.getDateOfBirthISO;
    let age = startDate.getFullYear() - dob.getFullYear();

    if (
      startDate.getMonth() < dob.getMonth() ||
      (startDate.getMonth() === dob.getMonth() &&
        startDate.getDate() < dob.getDate())
    ) {
      age--;
    }
    if (isNaN(age) || age < 0) {
      return 0;
    }

    return age;
  },
};

export const mutations = {
  setAddressHasSearched(state, value) {
    state.addressHasSearched = value;
  },
  setSelectedTitle(state, value) {
    state.selectedTitle = value;
  },
  setInputFirstName(state, value) {
    state.inputFirstName = value;
  },
  setInputSurname(state, value) {
    state.inputSurname = value;
  },
  setInputPostcode(state, value) {
    state.inputPostcode = value;
  },
  setInputDateOfBirthDay(state, value) {
    state.inputDateOfBirthDay = value;
  },
  setInputDateOfBirthMonth(state, value) {
    state.inputDateOfBirthMonth = value;
  },
  setInputDateOfBirthYear(state, value) {
    state.inputDateOfBirthYear = value;
  },
  setInputOccupation(state, value) {
    state.inputOccupation = value;
  },
  setInputMobileNumber(state, value) {
    state.inputMobileNumber = value;
  },
  setAddresses(state, value) {
    state.addresses = value;
  },
  setSelectedAddress(state, value) {
    state.selectedAddress = value;
  },
  setSelectedAddressSameAsMainDriver(state, value) {
    state.selectedAddressSameAsMainDriver = value;
  },
  setSelectedRelationship(state, value) {
    state.selectedRelationship = value;
  },
  resetState: (state) => {
    Object.assign(state, {
      ...initialState(),
    });
  },
};

export const actions = {
  async mapRecall({ commit, dispatch, getters, rootGetters }, riskData) {
    let allFieldsPresent = true;
    const checkAndCommit = (commitName, value) => {
      if (value !== undefined && value !== null) {
        commit(commitName, value);
      } else {
        allFieldsPresent = false;
      }
    };

    checkAndCommit("setSelectedTitle", riskData?.additionalInsuredNameTitle);
    checkAndCommit(
      "setInputFirstName",
      riskData?.additionalInsuredNameForenames
    );
    checkAndCommit("setInputSurname", riskData?.additionalInsuredNameSurname);
    checkAndCommit(
      "setInputMobileNumber",
      riskData?.additionalInsuredTelephoneNumbersTelephonePhoneNumber
    );
    checkAndCommit(
      "setInputPostcode",
      riskData?.additionalInsuredAddressPostCode
    );
    checkAndCommit(
      "setSelectedRelationship",
      selectors.personal.relationships.find(
        (r) => r.value === riskData?.additionalInsuredRelationshipToProposer
      )
    );

    allFieldsPresent = findOccupationByCode(
      riskData?.additionalInsuredFullTimeOccupationOccCode,
      checkAndCommit
    );
    allFieldsPresent = convertDateOfBirth(
      riskData?.additionalInsuredDateOfBirth,
      checkAndCommit
    );
    allFieldsPresent = await dispatch(
      "findAndSelectAddress",
      riskData?.userfieldsUserfieldUDPRNResponse?.split(" ")[1]
    );

    if (
      riskData?.userfieldsUserfieldUDPRNResponse?.split(" ")[0] ===
      riskData?.userfieldsUserfieldUDPRNResponse?.split(" ")[1]
    ) {
      checkAndCommit("setSelectedAddressSameAsMainDriver", "Yes");
    } else {
      checkAndCommit("setSelectedAddressSameAsMainDriver", "No");
    }

    return allFieldsPresent;
  },
  async findAndSelectAddress({ dispatch, getters, commit }, udprnResponse) {
    const addressResult = await dispatch("findAddress");
    if (!addressResult.success) {
      return false;
    }
    commit("setAddressHasSearched", true);

    if (!udprnResponse) {
      return false;
    }

    const address = getters.getAddresses.find((a) => a.udprn === udprnResponse);
    if (!address) {
      return false;
    }

    commit("setSelectedAddress", address);
    return true;
  },
  async findAddress({ commit, state }) {
    try {
      commit("setAddresses", []);
      const response = await this.$goshorty.address(state.inputPostcode.trim());
      commit("setAddresses", response);
      return { success: true, data: response };
    } catch (err) {
      return { success: false, error: err.message };
    }
  },
  clearAddress({ commit }) {
    commit("setAddresses", []);
    commit("setSelectedAddress", "");
    commit("setInputPostcode", "");
    commit("setAddressHasSearched", false);
  },
  changeSelectedAddressSameAsMainDriver(
    { commit, dispatch, rootGetters },
    value
  ) {
    commit("setSelectedAddressSameAsMainDriver", value);
    if (value === "Yes") {
      commit(
        "setSelectedAddress",
        rootGetters["modules/personaldetails/getSelectedAddress"]
      );
    } else {
      dispatch("clearAddress");
    }
  },
};
