var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "logo-link nuxt-link",
      attrs: { href: _vm.link, "data-nuxt-link": "" },
    },
    [
      _vm.isSvg
        ? _c("img", {
            attrs: { src: _vm.logo, alt: _vm.alt, height: "40", width: "200" },
          })
        : _c("img", { attrs: { src: _vm.logo, alt: _vm.alt, width: "200" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }