<template>
  <div class="tooltip-container">
    <template>
      <span
        class="tooltip-icon"
        @mouseover="tooltipSelected = true"
        @mouseleave="tooltipSelected = false"
      >
        <v-icon color="blue darken-2" class="circle"> mdi-help-circle </v-icon>
      </span>
    </template>

    <p v-if="tooltipSelected" class="tooltip-message">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "BaseTooltips",
  data() {
    return {
      tooltipSelected: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.tooltip-container::v-deep {
  display: inline;

  position: relative;

  .tooltip-icon i {
    text-align: center;
    background-color: #20a7e8;
    border-radius: 50%;
    width: 24px !important;
    height: 24px !important;
    font-size: 14px;
    line-height: 24px;
    cursor: default;
    position: relative;
    margin-top: 2px;
    margin-left: 5px;

    &::before {
      content: "?";
      font-weight: bold;
      color: var(--v-white-base);
      margin: auto;
    }
  }

  .tooltip-message {
    text-align: left;
    background-color: #1e2021;
    padding: 20px !important;
    width: 300px !important;
    position: absolute;

    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

    color: var(--v-white-base);
    font-size: 13px;
    line-height: 1.4;
    top: 29px;
    z-index: 10;

    &:hover {
      display: block;
      transform-origin: 100% 0%;
      -webkit-animation: fadeIn 0.3s ease-in-out;
      animation: fadeIn 0.3s ease-in-out;
    }
  }
}
</style>
