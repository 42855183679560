export const namespaced = true;

export const state = () => ({
  has_maintenance: false,
  has_started: false,
  close_app: false,
  headline: "",
  further_information: "",
  maintenance_start: "",
  maintenance_end: "",
});

export const getters = {
  getMaintenanceMessages: (state) => {
    return {
      warningMessage: `Please note, that the system is scheduled to start maintenance at ${
        state.maintenance_start
      } and end at ${state.maintenance_end}. ${
        state.close_app
          ? "GoShorty will be unavailable at this time"
          : "GoShorty will still be available during this time, but services may run slower than expected"
      }.`,
      maintenanceMessage: {
        headline: state.headline,
        information: state.further_information,
      },
    };
  },
  isMaintenance: (state) => {
    return state.has_maintenance && state.has_started;
  },
  isMaintenanceClosed: (state) => {
    return state.has_maintenance && state.has_started && state.close_app;
  },
  isMaintenanceWarning: (state) => {
    return state.has_maintenance && !state.has_started;
  },
};

export const mutations = {
  reset(state) {
    state.has_maintenance = false;
    state.has_started = false;
    state.close_app = false;
    state.headline = "";
    state.further_information = "";
    state.maintenance_start = "";
    state.maintenance_end = "";
  },
  setMaintenance(state, maintenanceData) {
    state.has_maintenance = maintenanceData.has_maintenance;
    state.has_started = maintenanceData.has_started;
    state.close_app = maintenanceData.close_app;
    state.headline = maintenanceData.headline;
    state.further_information = maintenanceData.further_information;
    state.maintenance_start = maintenanceData.maintenance_start;
    state.maintenance_end = maintenanceData.maintenance_end;
  },
};

export const actions = {
  async fetchMaintenance({ commit }) {
    try {
      const result = await this.$goshorty.sendRequest("maintenance", {});

      if (result.success && result?.data?.has_maintenance) {
        commit("setMaintenance", result.data);
      } else {
        commit("reset");
      }
    } catch (error) {
      console.error("Error during maintenance update", error);
    }
  },
  async updateMaintenance({ dispatch }) {
    dispatch("fetchMaintenance");
    setInterval(() => {
      dispatch("fetchMaintenance");
    }, 70000);
  },
};
