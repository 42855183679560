<template>
  <div>
    <BaseButtonToggle
      :items="$selectors.vehicle.annualInsurance"
      :value="value"
      :applyBorder="hasError"
      @input="$emit('input', $event)"
      placeholder="Please select"
      :data-zuko-id="zukoId"
      notMandatory
    ></BaseButtonToggle>
    <div
      class="error--text"
      style="font-size: 12px; margin-top: -10px"
      v-if="hasError"
    >
      Please select a valid value for if the vehicle is annually insured.
    </div>
  </div>
</template>

<script>
export default {
  name: "VehicleAnnualInsurance",
  props: {
    value: {
      type: String,
      default: "",
    },
    zukoId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    hasError: false,
  }),
  methods: {
    forceValidation() {
      this.hasError = !this.value;
    },
  },
  watch: {
    value() {
      this.forceValidation();
    },
  },
};
</script>
