<template>
  <div>
    <BaseBottomNavigation v-if="!bottomNavVisible && !isWhiteLabeled" /> 
    <v-footer v-if="bottomNavVisible && !isWhiteLabeled" class="white-footer">
      <v-container class="footer-container">
        <v-row>
          <v-col cols="12" md="4" class="d-flex px-6">
            <img
              v-if="!isWhiteLabeled"
              src="/imgs/goshorty-logo.png"
              alt="GoShorty short term vehicle insurance"
              height="45"
            />
            <WhiteLabeledLogo v-else />
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="d-flex align-center justify-center flex-wrap px-0"
          >
            <img
            class="payment-logos"
              src="/imgs/payment-logos-new.png"
              fit="contain"
              alt="GoShorty payment logos"
            />
          </v-col>
        </v-row>
        <v-row class="px-6">
          <p class="smallText">
            We are a UK based insurance broker and we are authorised and
            regulated by the Financial Conduct Authority under reference number
            751221. GoShorty is a registered trademark. GoShorty is a trading
            style of Complex to Clear Group Limited registered in England and
            Wales. Company Registration Number 05044963. Data Protection
            Registration ZA456686.
          </p>
        </v-row>
      </v-container></v-footer
    >
    <v-footer
      v-if="(!bottomNavVisible && !thankYouPage) || isWhiteLabeled"
      class="footer white--text"
    >
      <v-container>
        <v-row>
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="9">
            <v-divider class="grey darken-2 mb-4"></v-divider>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="8" v-if="isWhiteLabeled">
                <div
                  class="d-flex mb-4"
                  :class="
                    $vuetify.breakpoint.smAndUp
                      ? 'justify-space-around'
                      : 'flex-column'
                  "
                >
                  <div
                    v-for="(link, j) of whiteLabeledLinks"
                    :key="j"
                    :class="$vuetify.breakpoint.xs && 'mb-2'"
                  >
                    <a
                      :href="link.href"
                      target="_blank"
                      class="text-decoration-none white--text text-body-1"
                    >
                      {{ link.title }}
                    </a>
                  </div>
                </div>

                <p class="smallText text-left">
                  {{ getWhiteLabeledBrandName }} act as an introducer to GoShorty
                  for temporary insurance. GoShorty arrange and administer
                  temporary car insurance on behalf of
                  {{ getWhiteLabeledBrandName }}. GoShorty are a UK based insurance
                  broker and are authorised and regulated by the Financial
                  Conduct Authority under reference number 751221. GoShorty is a
                  registered trademark. GoShorty is a trading style of Complex
                  to Clear Group Limited registered in England and Wales.
                </p>

                <p class="smallText text-left">
                  Company Registration Number 05044963. Data Protection
                  Registration ZA456686.
                </p>
              </v-col>
              <v-col cols="12" md="8" v-else>
                <p class="smallText text-left">
                  We are a UK based insurance broker and we are authorised and
                  regulated by the Financial Conduct Authority under reference
                  number 751221.
                </p>
                <p class="smallText text-left">
                  GoShorty is a registered trademark. GoShorty is a trading
                  style of Complex to Clear Group Limited registered in England
                  and Wales.
                </p>
                <p class="smallText text-left">
                  Company Registration Number 05044963. Data Protection
                  Registration ZA456686.
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-flex align-center">
                  <v-icon size="1.25rem" color="white">mdi-lock-outline</v-icon>
                  <span>100% Secure</span>
                  <v-divider class="ml-2 grey darken-2"></v-divider>
                </div>
                <div class="d-flex" v-show="$vuetify.breakpoint.mdAndUp">
                  <BasePaymentSheet>
                    <img src="/imgs/apple-pay.png" alt="Apple Pay Logo"></img>
                  </BasePaymentSheet>
                  <BasePaymentSheet>
                    <img src="/imgs/google-pay-logo.png" alt="Google Pay Logo"></img>
                  </BasePaymentSheet>
                  <BasePaymentSheet
                    ><img src="/imgs/amex-logo.png" height="40"  alt="Amex Logo"></img
                  ></BasePaymentSheet>
                </div>
                <div class="d-flex">
                  <BasePaymentSheet>
                    <img src="/imgs/world-pay.png"  alt="World Pay Logo"></img>
                  </BasePaymentSheet>
                  <BasePaymentSheet>
                    <img src="/imgs/visa-logo.png"  alt="Visa Logo"></img>
                  </BasePaymentSheet>
                  <BasePaymentSheet>
                    <img src="/imgs/maestro.png"  alt="Maestro Logo"></img>
                  </BasePaymentSheet>
                  <BasePaymentSheet>
                    <img src="/imgs/mastercard.png"  alt="Mastercard Logo"></img>
                  </BasePaymentSheet>
                  <BasePaymentSheet>
                    <img src="/imgs/electron.png"  alt="Electron Logo"></img>
                  </BasePaymentSheet>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "BaseFooter",
  data: () => ({
    isLoading: true,
    whiteLabeledLinks: [
      {
        title: "Privacy Policy",
        href: "https://goshorty.co.uk/privacy-policy",
      },
      {
        title: "Cookie Policy",
        href: "https://goshorty.co.uk/cookies-policy",
      },
      {
        title: "Terms of Business",
        href: "https://goshorty.co.uk/terms-of-business/",
      },
    ],
  }),
  computed: {
    bottomNavVisible() {
      return (
        [
          "/quotes",
          "/confirmation",
          "/acceptance",
          "/payment",
        ].includes(this.$route.path)
      );
    },
    thankYouPage() {
      return this.$route.path === "/payment-success";
    },
  },
};
</script>

<style lang="scss">
.footer-container {
  max-width: 1197px;
}

.footer {
  background-color: var(--v-brandFooter-base) !important;
}
.white-footer{
  background-color:#fff !important;
}
.payment-logos {
  max-width: 100%;
  height: auto;
}
</style>
