<template>
  <v-card color="brandBackgroundLight" tile flat class="py-4 mb-2">
    <v-row>
      <v-col cols="1" class="pt-4 pl-10">
        <v-icon class="font-weight-black" color="black"
          >mdi-home-variant-outline
        </v-icon>
      </v-col>
      <v-col :data-sentry-mask="true" :id="`${tagId}-details`">
        <div
          v-if="
            address &&
            address.address_line_1?.length < 5 &&
            address.address_line_2?.length
          "
        >
          <v-card-title class="font-weight-bold py-0 wrap-text"
            >{{ address.address_line_1 }}
            {{ address.address_line_2 }}</v-card-title
          >
        </div>
        <div v-else>
          <v-card-title class="font-weight-bold py-0 wrap-text"
            >{{ address.address_line_1 }}
          </v-card-title>
          <v-card-text class="font-weight-bold text-body-2 py-0 wrap-text">
            {{ address.address_line_2 }}</v-card-text
          >
        </div>
        <v-card-text
          v-if="address && address.address_line_3?.length"
          class="py-0 text-body-2 font-weight-bold wrap-text"
          >{{ address.address_line_3 }}</v-card-text
        >
        <v-card-text
          v-if="address && address.address_line_4?.length"
          class="py-0 text-body-2 font-weight-bold wrap-text"
          >{{ address.address_line_4 }}</v-card-text
        >
        <v-card-text
          v-if="address && address.postcode"
          class="py-0 text-body-2 font-weight-bold"
          >{{ address.postcode }}</v-card-text
        >
      </v-col>
    </v-row>
    <v-row class="d-block px-8 pb-2 mt-0">
      <div class="d-flex justify-end">
        <a
          @click="$emit('editAddress')"
          :id="`${tagId}-edit`"
          :data-zuko-id="`${zukoId}-edit`"
          class="font-weight-bold"
          >Edit address</a
        >
      </div>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "PersonalAddressInfoCard",
  props: {
    tagId: {
      type: String,
    },
    zukoId: {
      type: String,
    },
    address: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
