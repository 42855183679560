export const state = () => ({
  additionalDriver: "",
  isLoading: true,
  isTweaked: false,
  isNavigating: false,
  isRecall: false,
  currentStep: 0,
  currentErrors: 0,
  domain: "",
  clientIP: "",
  entranceRoute: "",
  forwardButtonClicked: false,
  steps: [
    {
      id: 0,
      page: "/quote-recall",
      canLeave: true,
    },
    {
      id: 1,
      page: "/",
      canLeave: true,
    },
    {
      id: 2,
      page: "/manual-lookup",
      canLeave: false,
    },
    {
      id: 3,
      page: "/cover-details",
      canLeave: false,
    },
    {
      id: 4,
      page: "/personal-details",
      canLeave: false,
    },
    {
      id: 5,
      page: "/licence-details",
      canLeave: false,
    },
    {
      id: 6,
      page: "/additional-driver/personal-details",
      canLeave: false,
    },
    {
      id: 7,
      page: "/additional-driver/licence-details",
      canLeave: false,
    },
    {
      id: 8,
      page: "/quotes",
      canLeave: false,
    },
    {
      id: 9,
      page: "/confirmation",
      canLeave: false,
    },
    {
      id: 10,
      page: "/acceptance",
      canLeave: false,
    },
    {
      id: 11,
      page: "/payment",
      canLeave: false,
    },
    { id: 12 }, // SPM?
    { id: 13 }, // 13 - Payment Status
    {
      id: 14,
      page: "/payment-success",
      canLeave: true,
    },
    {
      id: 14.1,
      page: "/payment-issue",
      canLeave: true,
    },
  ],
});

export const mutations = {
  setIsTweaked(state, value) {
    state.isTweaked = value;
  },
  setCurrentStep(state, value) {
    state.currentStep = value;
  },
  setAdditionalDriver(state, value) {
    state.additionalDriver = value;
  },
  setDomain(state, value) {
    state.domain = value;
  },
  setClientIP(state, value) {
    state.clientIP = value;
  },
  setEntranceRoute(state, value) {
    state.entranceRoute = value;
  },
  setForwardButtonClicked(state, value) {
    state.forwardButtonClicked = value;
  },
  setJourneyIsLoading(state, value) {
    state.isLoading = value;
  },
  setCurrentErrors(state, value) {
    state.currentErrors = value;
  },
  setIsRecall(state, value) {
    state.isRecall = value;
  },
  setIsNavigating(state, value) {
    state.isNavigating = value;
  },
};

export const getters = {
  hasAdditionalDriver: (state, getters) =>
    state.additionalDriver === "Yes" &&
    !getters.isLearnerDriver &&
    !getters.isImpound,
  getDomain: (state) => state.domain,
  getIsRecall: (state) => state.isRecall,
  getIsTweaked: (state) => state.isTweaked,
  getIsNavigating: (state) => state.isNavigating,
  getCurrentErrors: (state) => state.currentErrors,
  getClientIP: (state) => state.clientIP,
  getAdditionalDriver: (state) => state.additionalDriver,
  getSteps: (state) => state.steps,
  getEntranceRoute: (state) => state.entranceRoute,
  getJourneyIsLoading: (state) => state.isLoading,
  getCurrentStepNumber: (state) => state.currentStep,
  getCurrentStep(state) {
    return state.steps[state.currentStep] ?? null;
  },
  getForwardButtonClicked: (state) => state.forwardButtonClicked,
  getPrevStepNumber(state, getters, rootState, rootGetters) {
    switch (state.currentStep) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 3:
        return rootGetters["modules/vehicledetails/getVehicleIsManualLookup"]
          ? 2
          : 1;
      case 4:
        return 3;
      case 5:
        return 4;
      case 6:
        return 5;
      case 7:
        return 6;
      case 8:
        return getters.hasAdditionalDriver ? 7 : 5;
      case 9:
        return 8;
      case 10:
        return 9;
      case 11:
        return 10;
      case 14:
        return 14;
      case 15:
        return 15;
      default:
        return -1;
    }
  },
  getNextStepNumber(state, getters, rootState, rootGetters) {
    switch (state.currentStep) {
      case 0:
        if (!rootGetters["modules/recall/getRecallVehicleLookupStatus"]) {
          return 1;
        }
      // Fall through intentionally to case 1
      case 1:
        return rootGetters["modules/vehicledetails/getVehicleIsManualLookup"]
          ? 2
          : 3;
      case 2:
        return 3;
      case 3:
        return 4;
      case 4:
        return 5;
      case 5:
        return getters.hasAdditionalDriver ? 6 : 8;
      case 6:
        return 7;
      case 7:
        return 8;
      case 8:
        return 9;
      case 9:
        return 10;
      case 10:
        return 11;
      case 11:
        return rootGetters["quote/getWrapUpResult"] ? 14 : 15;
      default:
        return -1;
    }
  },
  getPrevStep(state, getters) {
    return state.steps[getters.getPrevStepNumber] ?? null;
  },
  getNextStep(state, getters) {
    return state.steps[getters.getNextStepNumber] ?? null;
  },
  getCurrentProgress(state) {
    let index = state.currentStep;
    if (index > 1) index--;
    return index * 10;
  },
  getLatestDOB(state, getters, rootState, rootGetters) {
    const isoString =
      rootGetters["modules/datetimedetails/getSelectedISO"] ||
      rootGetters["modules/datetimedetails/getServerTime"].now_iso;
    const date = new Date(isoString);

    date.setFullYear(date.getFullYear() - 17);
    date.setHours(23, 59, 59, 999);

    return date;
  },
  getEarliestDOB(state, getters, rootState, rootGetters) {
    const isoString =
      rootGetters["modules/datetimedetails/getSelectedISO"] ||
      rootGetters["modules/datetimedetails/getServerTime"].now_iso;
    const date = new Date(isoString);

    date.setFullYear(date.getFullYear() - 125);
    date.setHours(0, 0, 0, 0);

    return date;
  },
  isLearnerDriver(state, getters, rootState, rootGetters) {
    return (
      ["I", "J", "I/J"].includes(
        rootGetters["modules/coverdetails/getSelectedCoverReason"]?.value
      ) || rootGetters["modules/querydetails/hasLearner"]
    );
  },
  isImpound(state, getters, rootState, rootGetters) {
    return (
      ["Q"].includes(
        rootGetters["modules/coverdetails/getSelectedCoverReason"]?.value
      ) || rootGetters["modules/querydetails/hasImpound"]
    );
  },
  isVan(state, getters, rootState, rootGetters) {
    return rootGetters["modules/vehicledetails/getVehicleIsVan"];
  },
  isUnder21(state, getters, rootState, rootGetters) {
    return rootGetters["modules/personaldetails/getAgeAtStartOfPolicy"] < 21;
  },
  is17OrOver(state, getters, rootState, rootGetters) {
    return rootGetters["modules/personaldetails/getAgeAtStartOfPolicy"] >= 17;
  },
  isBetween19And20(state, getters, rootState, rootGetters) {
    return (
      rootGetters["modules/personaldetails/getAgeAtStartOfPolicy"] >= 19 &&
      rootGetters["modules/personaldetails/getAgeAtStartOfPolicy"] <= 20
    );
  },
  showUnder21Questions(state, getters, rootState, rootGetters) {
    return (
      getters.isUnder21 &&
      getters.is17OrOver &&
      !getters.isLearnerDriver &&
      !getters.isVan &&
      !getters.isImpound
    );
  },
  getServerDate(state, getters, rootState, rootGetters) {
    return new Date(
      rootGetters["modules/datetimedetails/getServerTime"]?.now_iso
    );
  },
  getStartDate(state, getters, rootState, rootGetters) {
    return rootGetters["modules/datetimedetails/getSelectedISO"];
  },
  getCoverType(state, getters, rootState, rootGetters) {
    return getters.isVan ? "GV" : "PC";
  },
  getCoverSubType(state, getters, rootState, rootGetters) {
    if (getters.isImpound && getters.isVan) {
      return "ImpVan";
    } else if (getters.isImpound && !getters.isVan) {
      return "ImpCar";
    } else if (getters.isLearnerDriver) {
      return "Learner";
    } else if (getters.isVan) {
      return "Van";
    } else {
      return "Car";
    }
  },
  getCombinedUDPRN(state, getters, rootState, rootGetters) {
    let r = rootGetters["modules/personaldetails/getSelectedAddress"]?.udprn;

    if (
      getters.isLearnerDriver &&
      rootGetters["modules/vehicledetails/getSelectedLearnerOwner"]?.value ===
        "I"
    ) {
      r += ` ${
        rootGetters["modules/vehicledetails/getSelectedAddress"]?.udprn ?? ""
      }`;
    } else if (getters.hasAdditionalDriver) {
      r += ` ${
        rootGetters["modules/additional/personaldetails/getSelectedAddress"]
          ?.udprn ?? ""
      }`;
    }

    return r;
  },
  getMyLicence(state, getters, rootState, rootGetters) {
    if (
      getters.hasAdditionalDriver &&
      rootGetters["modules/additional/licencedetails/getSelectedCountry"] ===
        "UK"
    ) {
      const legacyDetails =
        rootGetters["modules/additional/licencedetails/getLicenceLegacy"];
      return {
        GSMULRD1: legacyDetails?.GSMULRD1,
        GSMULRD2: legacyDetails?.GSMULRD2,
        GSMYLCNT: legacyDetails?.GSMYLCNT,
        GSMYLDIS: legacyDetails?.GSMYLDIS,
        GSMYLPTS: legacyDetails?.GSMYLPTS,
      };
    } else if (
      rootGetters["modules/licencedetails/getSelectedCountry"] === "UK"
    ) {
      const legacyDetails =
        rootGetters["modules/licencedetails/getLicenceLegacy"];
      return {
        GSMULRD1: legacyDetails?.GSMULRD1,
        GSMULRD2: legacyDetails?.GSMULRD2,
        GSMYLCNT: legacyDetails?.GSMYLCNT,
        GSMYLDIS: legacyDetails?.GSMYLDIS,
        GSMYLPTS: legacyDetails?.GSMYLPTS,
      };
    } else {
      return {
        GSMULRD1: "",
        GSMULRD2: "",
        GSMYLCNT: "",
        GSMYLDIS: "",
        GSMYLPTS: "",
      };
    }
  },
};

export const actions = {
  goToNextPage({ commit, getters, dispatch }, { route }) {
    if (getters.getIsNavigating) return;
    commit("setIsNavigating", true);

    const nextStep = getters.getNextStep;
    if (nextStep) {
      commit("setForwardButtonClicked", true);
      const { hasExpired, hasTmxBlocked, ...query } = route.query;
      this.$router.push({ path: nextStep.page, query: query });
    }
  },
  goToPrevPage({ commit, getters, dispatch }, { route }) {
    if (getters.getIsNavigating) return;
    commit("setIsNavigating", true);

    const prevStep = getters.getPrevStep;
    if (prevStep) {
      this.$router.push({ path: prevStep.page, query: route.query });
    }
  },
  goToQuotes({ commit, getters, dispatch }, { route }) {
    if (getters.getIsNavigating) return;
    commit("setIsNavigating", true);

    const nextStep = getters.getSteps[8];
    if (nextStep) {
      commit("setForwardButtonClicked", true);
      const { hasExpired, hasTmxBlocked, ...query } = route.query;
      this.$router.push({ path: nextStep.page, query: query });
    }
  },
  changeCurrentStep({ commit }, step) {
    commit("setCurrentStep", step);
  },
  changeJourneyIsTweaked({ commit }, isTweaked) {
    commit("setIsTweaked", isTweaked);
    if (isTweaked) {
      commit("quote/resetState", {}, { root: true });
    }
  },
  async updateClientIp({ commit }) {
    const ip = await this.$goshorty.getClientIp();
    commit("setClientIP", ip);
  },
};
