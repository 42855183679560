export const state = () => ({
  inputWebReference: "",
  inputDateOfBirthDay: "",
  inputDateOfBirthMonth: "",
  inputDateOfBirthYear: "",
  recalled: {
    policyData: {},
    riskData: {},
    vehicle: {},
    dates: {},
    opex: {},
  },
  status: {
    vehicleLookup: false,
    proposerPersonal: false,
    proposerLicence: false,
    additionalPersonal: false,
    additionalLicence: false,
    coverDetails: false,
    date: false,
    tracking: false,
    opex: false,
  },
});

export const getters = {
  getInputWebReference: (state) => state.inputWebReference,
  getInputDateOfBirthDay: (state) => state.inputDateOfBirthDay,
  getInputDateOfBirthMonth: (state) => state.inputDateOfBirthMonth,
  getInputDateOfBirthYear: (state) => state.inputDateOfBirthYear,
  getRecalledPolicyData: (state) => state.recalled.policyData,
  getRecalledRiskData: (state) => state.recalled.riskData,
  getRecalledVehicle: (state) => state.recalled.vehicle,
  getRecalledDates: (state) => state.recalled.dates,
  getRecalledOpex: (state) => state.recalled.opex,
  getRecallVehicleLookupStatus: (state) => state.status.vehicleLookup,
  getRecallProposerPersonalStatus: (state) => state.status.proposerPersonal,
  getRecallCoverStatus: (state) => state.status.coverDetails,
  getRecallDateStatus: (state) => state.status.date,
  getRecallProposerLicenceStatus: (state) => state.status.proposerLicence,
  getRecallAdditionalPersonalStatus: (state) => state.status.additionalPersonal,
  getRecallAdditionalLicenceStatus: (state) => state.status.additionalLicence,
  getRecallTrackingStatus: (state) => state.status.tracking,
  getRecallOpexStatus: (state) => state.status.opex,
  getRecallAllPassed: (state) =>
    state.status.vehicleLookup &&
    state.status.proposerPersonal &&
    state.status.proposerLicence &&
    state.status.additionalPersonal &&
    state.status.additionalLicence &&
    state.status.coverDetails &&
    state.status.date &&
    state.status.tracking &&
    state.status.opex,
};

export const mutations = {
  setInputWebReference: (state, value) => {
    state.inputWebReference = value;
  },
  setInputDateOfBirthDay: (state, value) => {
    state.inputDateOfBirthDay = value;
  },
  setInputDateOfBirthMonth: (state, value) => {
    state.inputDateOfBirthMonth = value;
  },
  setInputDateOfBirthYear: (state, value) => {
    state.inputDateOfBirthYear = value;
  },
  setRecalledPolicyData: (state, value) => {
    state.recalled.policyData = value;
  },
  setRecalledRiskData: (state, value) => {
    state.recalled.riskData = value;
  },
  setRecalledDates: (state, value) => {
    state.recalled.dates = value;
  },
  setRecalledVehicle: (state, value) => {
    state.recalled.vehicle = value;
  },
  setRecalledOpex: (state, value) => {
    state.recalled.opex = value;
  },
  setRecallVehicleLookupStatus: (state, value) => {
    state.status.vehicleLookup = value;
  },
  setRecallProposerPersonalStatus: (state, value) => {
    state.status.proposerPersonal = value;
  },
  setRecallAdditionalPersonalStatus: (state, value) => {
    state.status.additionalPersonal = value;
  },
  setRecallCoverStatus: (state, value) => {
    state.status.coverDetails = value;
  },
  setRecallDateStatus: (state, value) => {
    state.status.date = value;
  },
  setRecallProposerLicenceStatus: (state, value) => {
    state.status.proposerLicence = value;
  },
  setRecallAdditionalLicenceStatus: (state, value) => {
    state.status.additionalLicence = value;
  },
  setTrackingStatus: (state, value) => {
    state.status.tracking = value;
  },
  setRecallOpexStatus: (state, value) => {
    state.status.opex = value;
  },
};

export const actions = {
  async mapRecall({ commit, getters, dispatch, rootGetters }) {
    const riskData = getters.getRecalledRiskData;
    const vehicleData = getters.getRecalledVehicle;
    const dateData = getters.getRecalledDates;
    const opex = getters.getRecalledOpex;

    commit("journey/setIsRecall", true, { root: true });
    if (riskData?.additionalInsuredDriverType) {
      commit("journey/setAdditionalDriver", "Yes", { root: true });
    }

    /* Dates & Tracking*/
    commit(
      "modules/loader/show",
      {
        message: "Checking date information...",
      },
      { root: true }
    );
    const trackingStatus = await dispatch(
      "modules/querydetails/mapRecall",
      riskData,
      { root: true }
    );
    commit("setTrackingStatus", trackingStatus);

    const dateStatus = await dispatch(
      "modules/datetimedetails/mapRecall",
      dateData,
      { root: true }
    );
    commit("setRecallDateStatus", dateStatus);

    /* Cover Data */
    commit(
      "modules/loader/show",
      {
        message: "Checking cover information...",
      },
      { root: true }
    );
    const coverStatus = await dispatch(
      "modules/coverdetails/mapRecall",
      riskData,
      { root: true }
    );
    commit("setRecallCoverStatus", coverStatus);

    /* Proposer Data */
    commit(
      "modules/loader/show",
      {
        message: "Checking personal information...",
      },
      { root: true }
    );
    const proposerPersonalStatus = await dispatch(
      "modules/personaldetails/mapRecall",
      riskData,
      { root: true }
    );
    commit("setRecallProposerPersonalStatus", proposerPersonalStatus);

    const proposerLicenceStatus = await dispatch(
      "modules/licencedetails/mapRecall",
      riskData,
      { root: true }
    );
    commit("setRecallProposerLicenceStatus", proposerLicenceStatus);

    /* Vehicle Data */
    commit(
      "modules/loader/show",
      {
        message: "Checking vehicle information...",
      },
      { root: true }
    );
    const mappedVehicleData = vehicleData?.error ? null : vehicleData;
    const vehicleStatus = await dispatch(
      "modules/vehicledetails/mapRecall",
      { riskData, vehicleData: mappedVehicleData },
      { root: true }
    );
    commit("setRecallVehicleLookupStatus", vehicleStatus);

    if (vehicleStatus) {
      this.$goshorty.insuranceIntelligence.step({
        ...rootGetters["quote/getQuoteData"],
        stepOverride: 1,
      });
    } else {
      commit("modules/vehicledetails/setHasCompletedSearch", false, {
        root: true,
      });
    }

    if (rootGetters["journey/hasAdditionalDriver"]) {
      commit(
        "modules/loader/show",
        {
          message: "Adding additional driver...",
        },
        { root: true }
      );
      const additionalDriverPersonal = await dispatch(
        "modules/additional/personaldetails/mapRecall",
        riskData,
        { root: true }
      );
      commit("setRecallAdditionalPersonalStatus", additionalDriverPersonal);
      const additionalDriverLicence = await dispatch(
        "modules/additional/licencedetails/mapRecall",
        riskData,
        { root: true }
      );
      commit("setRecallAdditionalLicenceStatus", additionalDriverLicence);
    } else {
      commit("setRecallAdditionalLicenceStatus", true);
      commit("setRecallAdditionalPersonalStatus", true);
    }

    commit(
      "modules/loader/show",
      {
        message: "Checking optional extras...",
      },
      { root: true }
    );
    const opexStatus = await dispatch("modules/opex/mapRecall", opex, {
      root: true,
    });
    commit("setRecallOpexStatus", opexStatus);

    dispatch(
      "journey/goToNextPage",
      { route: this.$router.currentRoute },
      { root: true }
    );
    commit("modules/loader/hide", {}, { root: true });
  },
  async fetchRecall({ commit, dispatch, getters, rootGetters }) {
    commit(
      "modules/loader/show",
      {
        message: "Checking your quote details...",
      },
      { root: true }
    );
    try {
      const result = await this.$goshorty.recall({
        webReference: getters.getInputWebReference,
        dob: `${getters.getInputDateOfBirthYear}-${getters.getInputDateOfBirthMonth}-${getters.getInputDateOfBirthDay}`,
        extend: rootGetters["modules/querydetails/hasExtendpolicy"],
      });

      if (
        result?.recalled?.policy_data &&
        result?.recalled?.risk_data &&
        result?.recalled?.vehicle
      ) {
        commit("setRecalledPolicyData", result.recalled.policy_data);
        commit("setRecalledRiskData", result.recalled.risk_data);
        commit("setRecalledVehicle", result.recalled.vehicle);
        commit("setRecalledDates", result.recalled.dates);
        commit("setRecalledOpex", result.recalled.risk_data.opex);
        dispatch("mapRecall");
      } else {
        throw new Error(
          "One or more required properties (policy_data, risk_data, vehicle) are missing."
        );
      }
      return true;
    } catch (error) {
      dispatch(
        "modal/show",
        {
          title: "Unable to retrieve quote",
          body: "The details you provided don't match our records. Please try again.",
          cancellable: false,
          button: "Retry",
        },
        { root: true }
      );
      commit("modules/loader/hide", {}, { root: true });
      return false;
    }
  },
  async fetchRecallData({ commit, rootGetters, dispatch }) {
    try {
      commit(
        "modules/loader/show",
        {
          message: "Checking your quote details...",
        },
        { root: true }
      );

      const result = await this.$goshorty.recallott({
        ott: rootGetters["modules/querydetails/getSecret"],
      });

      if (result.record.web_reference) {
        const dateOfBirth = {
          day: result.record.date_of_birth.split("-")[2],
          month: result.record.date_of_birth.split("-")[1],
          year: result.record.date_of_birth.split("-")[0],
        };
        commit("setInputDateOfBirthDay", dateOfBirth.day);
        commit("setInputDateOfBirthMonth", dateOfBirth.month);
        commit("setInputDateOfBirthYear", dateOfBirth.year);
        commit("setInputWebReference", result.record.web_reference);
        dispatch("fetchRecall");
      } else {
        commit("modules/loader/hide", {}, { root: true });
        dispatch(
          "modal/show",
          {
            title: "Unable to retrieve quote details",
            body: "We were unable to fetch your quote details automatically, please manually enter your web reference and date of birth.",
            cancellable: false,
            button: "Continue",
          },
          { root: true }
        );
      }
    } catch (error) {
      commit("modules/loader/hide", {}, { root: true });
      dispatch(
        "modal/show",
        {
          title: "Error",
          body: "An error occurred while fetching your details. Please try again later.",
          cancellable: false,
          button: "Retry",
        },
        { root: true }
      );
    }
  },
};
