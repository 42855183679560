<template>
  <BaseButtonToggle
    :items="coverLengthItems"
    :id="id"
    :zukoId="zukoId"
    :value="value"
    :wrapButtons="$vuetify.breakpoint.xs"
    @input="$emit('input', $event)"
  >
  </BaseButtonToggle>
</template>

<script>
export default {
  name: "CoverLengthButtons",
  props: {
    id: {
      type: String,
      required: true,
    },
    zukoId: {
      type: String,
      required: true,
    },
    coverLengthItems: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, Number],
    },
  },
};
</script>
