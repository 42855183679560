var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "licence-number-container" },
    [
      _c(
        "v-row",
        { staticClass: "ukfields" },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                ref: "licence-input-0",
                attrs: {
                  value: _vm.value.licencePart1,
                  rules: [_vm.licencePart1Valid],
                  counter: 5,
                  disabled: _vm.loading,
                  "validate-on-blur": "",
                  id: `${_vm.id}-uk-1`,
                  "data-zuko-id": `${_vm.zukoId}-uk-1`,
                  maxLength: "5",
                },
                on: {
                  input: function ($event) {
                    return _vm.handleInput("1", $event, 5)
                  },
                  blur: function ($event) {
                    _vm.hasInput1 = true
                  },
                  focus: function ($event) {
                    _vm.hasInput1 = false
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                ref: "licence-input-1",
                attrs: {
                  value: _vm.value.licencePart2,
                  rules: [_vm.licencePart2Valid],
                  counter: 6,
                  disabled: _vm.loading,
                  "validate-on-blur": "",
                  id: `${_vm.id}-uk-2`,
                  "data-zuko-id": `${_vm.zukoId}-uk-2`,
                  maxLength: "6",
                },
                on: {
                  input: function ($event) {
                    return _vm.handleInput("2", $event, 6)
                  },
                  blur: function ($event) {
                    _vm.hasInput2 = true
                  },
                  focus: function ($event) {
                    _vm.hasInput2 = false
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                ref: "licence-input-2",
                attrs: {
                  value: _vm.value.licencePart3,
                  rules: [_vm.licencePart3Valid],
                  counter: 5,
                  disabled: _vm.loading,
                  "validate-on-blur": "",
                  id: `${_vm.id}-uk-3`,
                  "data-zuko-id": `${_vm.zukoId}-uk-3`,
                  maxLength: "5",
                },
                on: {
                  input: function ($event) {
                    return _vm.handleInput("3", $event, 5)
                  },
                  blur: function ($event) {
                    _vm.hasInput3 = true
                  },
                  focus: function ($event) {
                    _vm.hasInput3 = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showError
        ? _c(
            "div",
            {
              staticClass: "error--text",
              staticStyle: { "font-size": "12px" },
            },
            [
              _vm._v(
                "\n    Please enter a valid value for " +
                  _vm._s(_vm.pronoun) +
                  " driving licence number.\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasTriggeredError && !_vm.showNotFound && !_vm.showExpired
        ? _c("div", {
            staticStyle: { display: "none" },
            attrs: {
              id: `${_vm.id}-uk-corrected`,
              "data-zuko-id": `${_vm.zukoId}-uk-corrected`,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showExpired || _vm.showNotFound || _vm.showLicenceError
        ? _c(
            "v-row",
            {
              staticClass: "error--text",
              attrs: {
                id: `${_vm.id}-error`,
                "data-zuko-id": `${_vm.zukoId}-error`,
              },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.showLicenceError
                    ? _c("BaseError", { staticClass: "error--text" }, [
                        _vm._v(
                          "There has been an issue validating that licence number."
                        ),
                      ])
                    : _vm.showExpired
                    ? _c("BaseError", { staticClass: "error--text" }, [
                        _vm._v(
                          "It looks like your licence is expired, please enter a valid driving\n        licence number."
                        ),
                      ])
                    : _vm.showNotFound
                    ? _c("BaseError", { staticClass: "error--text" }, [
                        _vm._v(
                          "We could not find a licence matching the licence number entered\n        above."
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }