var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseCard",
    {
      staticClass: "confirmation-driver-details-container",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_vm._v("Driver Details")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasAdditionalDriver,
              expression: "hasAdditionalDriver",
            },
          ],
          attrs: { color: "black" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab",
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("mdi-account")]),
              _vm._v(" Main "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("mdi-account-multiple"),
              ]),
              _vm._v(" Additional\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.items, function (driver, i) {
          return _c(
            "v-tab-item",
            { key: i },
            [
              _c(
                "v-list",
                {
                  staticClass: "brandBackgroundLight",
                  attrs: { tile: "", flat: "" },
                },
                _vm._l(driver, function (item, j) {
                  return _c(
                    "v-list-item-group",
                    { key: j },
                    [
                      !(i === 1 && item.text === "Email")
                        ? _c(
                            "v-list-item",
                            { attrs: { disabled: "" } },
                            [
                              item.text === "Address"
                                ? [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "align-start py-0" },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "confirmation-label" },
                                          [_vm._v(_vm._s(item.text))]
                                        ),
                                        _vm._v(" "),
                                        item.subtitle
                                          ? _c(
                                              "v-list-item-subtitle",
                                              {
                                                attrs: {
                                                  "data-sentry-mask": true,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item.subtitle) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      {
                                        staticClass: "py-0",
                                        attrs: { "data-sentry-mask": true },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-weight-bold text-body-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  item.value.address_line_1
                                                ) +
                                                "\n                  " +
                                                _vm._s(
                                                  item.value.address_line_2
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.value.address_line_3
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-body-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      item.value.address_line_3
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.value.postcode
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-body-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      item.value.postcode
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                : item.text === "Email"
                                ? [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "confirmation-label" },
                                          [_vm._v(_vm._s(item.text))]
                                        ),
                                        _vm._v(" "),
                                        item.subtitle
                                          ? _c("v-list-item-subtitle", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.subtitle) +
                                                  "\n                "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-body-2",
                                            attrs: { "data-sentry-mask": true },
                                          },
                                          [_vm._v(_vm._s(item.value))]
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "py-0 d-flex" },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "confirmation-label" },
                                          [_vm._v(_vm._s(item.text))]
                                        ),
                                        _vm._v(" "),
                                        item.subtitle
                                          ? _c("v-list-item-subtitle", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.subtitle) +
                                                  "\n                "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-body-2",
                                            attrs: { "data-sentry-mask": true },
                                          },
                                          [_vm._v(_vm._s(item.value))]
                                        ),
                                      ]
                                    ),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "mb-1" }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }