<template>
  <v-select
    :items="coverLengthItems"
    :id="id"
    :data-zuko-id="zukoId"
    :value="value"
    :rules="rules"
    item-text="value"
    item-value="abbr"
    return-object
    placeholder="Please select"
    no-data-text="Please select a Duration Type first"
    @change="$emit('input', $event)"
  ></v-select>
</template>

<script>
export default {
  name: "CoverLengthSelection",
  props: {
    id: {
      type: String,
      required: true,
    },
    zukoId: {
      type: String,
      required: true,
    },
    coverLengthItems: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          (!!v && !!v.value) ||
          "Please select a valid value for duration of cover.",
      ],
    };
  },
};
</script>
