<template functional>
  <div
    class="cover-details-container-label formLabel border-md rounded white pa-2 my-2 text-center"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FormLabel",
};
</script>

<style lang="scss" scoped>
.formLabel {
  font-family: "Font Theme Bold", sans-serif;
  color: var(--v-brandDarkGrey-base);
}
</style>
