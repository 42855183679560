import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const goshortyDateTimePlugin = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.goshortyDateTime = function () {
    return this.tz("Europe/London");
  };

  dayjsClass.prototype.toGoshortyFormat = function () {
    return this.format("YYYY-MM-DDTHH:mm:ssZZ");
  };

  dayjsClass.prototype.updateTime = function (time) {
    const wip = this.clone();
    const date = wip.goshortyDateTime().toGoshortyFormat().substring(0, 10);
    const timeZone = this.goshortyDateTime()
      .toGoshortyFormat()
      .substring(19, 24);

    const reconstructed = `${date}T${time}:00${timeZone}`;

    const result = dayjs(reconstructed).goshortyDateTime();

    return result;
  };

  dayjsClass.prototype.absoluteDurationInDays = function (datetime) {
    const durationInHours = Math.abs(this.diff(datetime, "hour", true));
    const durationInDays = Math.round(durationInHours / 24);
    return durationInDays;
  };

  dayjsClass.prototype.toCustomerFormat = function () {
    return this.format("DD/MM/YYYY h:mm A");
  };

  dayjsClass.prototype.toVerboseCustomerFormat = function () {
    return this.format("dddd, MMMM Do YYYY, h:mm A");
  }

  dayjsClass.prototype.cloneWithoutOffset = function () {
    return dayjs()
      .year(this.year())
      .month(this.month())
      .date(this.date())
      .hour(this.hour())
      .minute(this.minute())
      .second(this.second())
      .millisecond(this.millisecond());
  };

  dayjsClass.prototype.addAcrossDaylightSavings = function (value, unit) {
    const workingValue = this.cloneWithoutOffset().add(value, unit);

    if (this.hasCrossedFromSummerToWinter(workingValue) && unit === "hour") {
      return workingValue.add(1, "hour");
    }

    if (this.hasCrossedFromWinterToSummer(workingValue) && unit === "hour") {
      const candidate = workingValue.subtract(1, "hour");

      if (workingValue.hasCrossedFromSummerToWinter(candidate)) {
        return workingValue.utc().hour(1);
      }

      return candidate;
    }

    return workingValue;
  };

  dayjsClass.prototype.hasCrossedFromSummerToWinter = function (other) {
    const beforeOffset = this.goshortyDateTime().utcOffset();
    const afterOffset = other.goshortyDateTime().utcOffset();

    return beforeOffset > afterOffset;
  };

  dayjsClass.prototype.hasCrossedFromWinterToSummer = function (other) {
    const beforeOffset = this.goshortyDateTime().utcOffset();
    const afterOffset = other.goshortyDateTime().utcOffset();

    return beforeOffset < afterOffset;
  };

  dayjsClass.prototype.diffInHoursIgnoringDaylightSavings = function (other) {
    const candidate = this.diff(other, "hour");

    if (this.hasCrossedFromSummerToWinter(other)) {
      return candidate + 1;
    }

    if (this.hasCrossedFromWinterToSummer(other)) {
      return candidate - 1;
    }

    return candidate;
  };

  dayjsClass.prototype.isValidStartDateTime = function () {
    return this.diff(dayjs(), "seconds") >= 60;
  };
};

dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(goshortyDateTimePlugin);

export default (context, inject) => {
  inject("dayjs", dayjs);

  return dayjs;
};

export { dayjs as $dayjs };
