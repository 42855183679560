<template>
  <div ref="animationContainer" />
</template>

<script>
export default {
  name: "SpinnerBranded",
  mounted() {
    this.$lottie.loadAnimation({
      container: this.$refs.animationContainer,
      path: "/imgs/GOSH-Loading-animation.json",
    });
  },
};
</script>
