<template>
  <BaseButtonToggle
    :value="value"
    :items="$selectors.cover.types"
    :small="small"
    :zukoId="zukoId"
    @input="$emit('input', $event)"
  ></BaseButtonToggle>
</template>

<script>
export default {
  name: "CoverDurationType",
  props: {
    small: {
      type: Boolean,
      default: () => false,
    },
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
    },
  },
};
</script>
