var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.isVisible, "overlay-opacity": "0.8", fullscreen: "" },
    },
    [
      _c(
        "v-row",
        { staticClass: "pt-10" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: { cols: "11", md: "6", align: "center" },
            },
            [
              _c(
                "v-card",
                [
                  _vm.hasTitle
                    ? _c(
                        "v-toolbar",
                        {
                          staticClass: "text-h6 white--text font-weight-bold",
                          attrs: { color: "#dd2c00" },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.title) + "\n          "
                          ),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.isCancellable
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { large: "" },
                                  on: { click: _vm.onCancel },
                                },
                                [_vm._v("\n            mdi-close\n          ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hasTitle
                    ? _c(
                        "v-card-title",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.isCancellable
                            ? _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    border: "2px solid rgb(30, 137, 202)",
                                    "border-radius": "50%",
                                  },
                                  attrs: { large: "", color: "primary" },
                                  on: { click: _vm.onCancel },
                                },
                                [_vm._v("\n            mdi-close\n          ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-card-text", {
                    staticClass:
                      "blue--text text--darken-2 text-h5 font-weight-bold text-center mt-2",
                    domProps: { innerHTML: _vm._s(_vm.body) },
                  }),
                  _vm._v(" "),
                  _c("v-card-actions", { staticClass: "justify-center pb-8" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "button purple--text text--darken-4 text-h5 font-weight-bold px-10",
                        attrs: { color: "white" },
                        on: { click: _vm.onClose },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(_vm.cta) + "\n          "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }