var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-col",
        {
          staticClass: "vehicle-details-container__title-col pb-0 pb-md-4",
          attrs: { cols: "12", sm: "7" },
        },
        [
          _c(
            "h1",
            {
              staticClass:
                "vehicle-details-container__title-col__title font-weight-regular font-weight-normal",
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }