var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    ref: "addressSelector",
    attrs: {
      items: _vm.addresses,
      value: _vm.value,
      "item-text": "address_selector",
      "return-object": "",
      rules: _vm.rules,
      placeholder: `Please select ${_vm.pronoun} address`,
      id: `${_vm.id}-select`,
      "data-zuko-id": `${_vm.zukoId}-select`,
      "data-sentry-mask": true,
      "data-sentry-block": true,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              {
                staticClass:
                  "v-select__selection v-select__selection--comma text-wrap",
                staticStyle: { "max-width": "430px" },
                attrs: { "data-sentry-mask": true, "data-sentry-block": true },
              },
              [
                _c(
                  "div",
                  {
                    attrs: {
                      "data-sentry-mask": true,
                      "data-sentry-block": true,
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(item.address_selector) + "\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }