const defaults = require("~/assets/json/defaults.json");

const initialState = () => ({
  loading: {
    makes: false,
    models: false,
    descriptions: false,
  },
  makes: [],
  models: [],
  descriptions: [],
  ...defaults.manualsearchDetails,
});
export const state = initialState;

const convertVehicleFuel = (vehicleFuel) => {
  let fuel = String(vehicleFuel).toLowerCase();
  switch (fuel) {
    case "fuelpetrol":
      return "Petrol";
    case "fueldiesel":
      return "Diesel";
    case "fuelelec":
      return "Electric";
    default:
      return "Unknown";
  }
};
const convertCCtoL = (number) => {
  const roundedNumber = Math.ceil(number / 100);
  const result = roundedNumber.toString();
  return `${result[0]}.${result[1]}L`;
};
const convertVehicleBodyStyle = (vehicleBodyStyle) => {
  if (vehicleBodyStyle === undefined || typeof vehicleBodyStyle !== "string") {
    return "Unknown";
  }
  vehicleBodyStyle = vehicleBodyStyle.toLowerCase();
  switch (vehicleBodyStyle) {
    case "u":
      return "Coupe";
    case "e":
      return "Estate";
    case "s":
      return "Saloon";
    case "h":
      return "Hatchback";
    case "c":
      return "Convertible";
    case "p":
      return "Pickup";
    case "t":
    case "tipper":
      return "Tipper";
    case "v":
    case "van":
      return "Van";
    default:
      return "Unknown";
  }
};
const convertVehicleTransmission = (transformedTransmission) => {
  switch (transformedTransmission) {
    case "TransAuto":
      return "Automatic";
    case "TransMan":
      return "Manual";
    default:
      return "Unknown";
  }
};

export const getters = {
  getSelectedMake: (state) => state.selectedMake,
  getSelectedModel: (state) => state.selectedModel,
  getSelectedYear: (state) => state.selectedYear,
  getSelectedFuel: (state) => state.selectedFuel,
  getSelectedEngineSize: (state) => state.selectedEngineSize,
  getSelectedType: (state) => state.selectedType,
  getSelectedTransmission: (state) => state.selectedTransmission,
  getSelectedDoors: (state) => state.selectedDoors,
  getSelectedVehicle: (state) => state.selectedVehicle,
  isCar: (state) => {
    return ["convertible", "estate", "hatchback", "saloon", "coupe"].includes(
      state.selectedType.toLowerCase()
    );
  },
  isElectric: (state) => {
    return state.selectedFuel.toLowerCase() === "electric";
  },
  getMakesLoading: (state) => {
    return state.loading.makes;
  },
  getModelsLoading: (state) => {
    return state.loading.models;
  },
  getDescriptionsLoading: (state) => {
    return state.loading.descriptions;
  },
  getMakes: (state) => {
    return state.makes;
  },
  getModels: (state) => {
    return state.models;
  },
  getDescriptions: (state) => {
    return state.descriptions;
  },
  getFilteredDescriptions: (state, getters) => {
    return state.descriptions.filter((description) => {
      const from = parseInt(description.details.from);
      const to = parseInt(description.details.to);
      const selectedYear = parseInt(state.selectedYear);

      const matchesYear =
        !state.selectedYear || (selectedYear >= from && selectedYear <= to);

      const matchesFuel =
        !state.selectedFuel || description.details.fuel === state.selectedFuel;

      const matchesEngineSize =
        getters.isElectric ||
        !state.selectedEngineSize ||
        description.details.engine_size === state.selectedEngineSize;

      const matchesType =
        !state.selectedType || description.details.type === state.selectedType;

      const matchesTransmission =
        !getters.isCar ||
        !state.selectedTransmission ||
        description.details.transmission === state.selectedTransmission;

      const matchesDoors =
        !getters.isCar ||
        !state.selectedDoors ||
        description.details.doors === state.selectedDoors;

      return (
        matchesYear &&
        matchesFuel &&
        matchesEngineSize &&
        matchesType &&
        matchesTransmission &&
        matchesDoors
      );
    });
  },
  getAvailableFilters: (state, getters) => {
    const filters = {
      years: new Set(),
      fuels: new Set(),
      engineSizes: new Set(),
      types: new Set(),
      transmissions: new Set(),
      doors: new Set(),
    };

    const {
      selectedYear,
      selectedFuel,
      selectedEngineSize,
      selectedType,
      selectedTransmission,
    } = state;
    const isCar = getters.isCar;
    const isElectric = getters.isElectric;

    state.descriptions.forEach((description) => {
      const {
        from,
        to,
        fuel,
        engine_size: engineSize,
        type,
        transmission,
        doors,
      } = description.details;

      // Add years to the filter
      for (let year = parseInt(from); year <= parseInt(to); year++) {
        filters.years.add(year.toString());
      }

      if (selectedYear && (selectedYear < from || selectedYear > to)) {
        return;
      }

      // Add fuel to the filter
      filters.fuels.add(fuel);

      if (isElectric) {
        if (selectedFuel && selectedFuel === fuel) {
          filters.types.add(type);

          if (isCar && selectedType && selectedType === type) {
            filters.transmissions.add(transmission);

            if (selectedTransmission && selectedTransmission === transmission) {
              filters.doors.add(doors);
            }
          }
        }
      } else if (selectedFuel && selectedFuel === fuel) {
        filters.engineSizes.add(engineSize);

        if (selectedEngineSize && selectedEngineSize === engineSize) {
          filters.types.add(type);

          if (isCar && selectedType && selectedType === type) {
            filters.transmissions.add(transmission);

            if (selectedTransmission && selectedTransmission === transmission) {
              filters.doors.add(doors);
            }
          }
        }
      }
    });

    const convertSetToSortedArrayAsc = (set) =>
      Array.from(set).sort((a, b) => a.localeCompare(b));

    const convertSetToSortedArrayDesc = (set) =>
      Array.from(set).sort((a, b) => b.localeCompare(a));

    return {
      years: convertSetToSortedArrayDesc(filters.years),
      fuels: convertSetToSortedArrayAsc(filters.fuels),
      engineSizes: convertSetToSortedArrayAsc(filters.engineSizes),
      types: convertSetToSortedArrayAsc(filters.types),
      transmissions: convertSetToSortedArrayAsc(filters.transmissions),
      doors: convertSetToSortedArrayAsc(filters.doors),
    };
  },
  getYears: (_, getters) => {
    return getters.getAvailableFilters.years;
  },
  getFuel: (_, getters) => {
    return getters.getAvailableFilters.fuels;
  },
  getEngineSizes: (_, getters) => {
    return getters.getAvailableFilters.engineSizes;
  },
  getTypes: (_, getters) => {
    return getters.getAvailableFilters.types;
  },
  getTransmissions: (_, getters) => {
    return getters.getAvailableFilters.transmissions;
  },
  getDoors: (_, getters) => {
    return getters.getAvailableFilters.doors;
  },
  showModel: (state) => {
    return !!(state.selectedMake?.length && state.models?.length);
  },
  showYear: (state, getters) => {
    return !!(
      getters.showModel &&
      state.selectedModel &&
      getters.getYears.length
    );
  },
  showFuel: (state, getters) => {
    return !!(
      getters.showYear &&
      state.selectedYear?.length &&
      getters.getFuel.length
    );
  },
  showEngineSize: (state, getters) => {
    return !!(
      getters.showFuel &&
      state.selectedFuel?.length &&
      getters.getEngineSizes.length &&
      !getters.isElectric
    );
  },
  showType: (state, getters) => {
    return !!(
      (getters.isElectric ||
        (getters.showEngineSize && state.selectedEngineSize?.length)) &&
      getters.getTypes.length
    );
  },
  showTransmission: (state, getters) => {
    return !!(
      getters.showType &&
      state.selectedType?.length &&
      getters.getTransmissions.length &&
      getters.isCar
    );
  },
  showDoors: (state, getters) => {
    return !!(
      getters.showTransmission &&
      state.selectedTransmission.length &&
      getters.getDoors.length
    );
  },
  showDescription: (state, getters) => {
    return !!(
      ((getters.isCar && getters.showDoors && state.selectedDoors?.length) ||
        (!getters.isCar && getters.showType && state.selectedType?.length)) &&
      getters.getFilteredDescriptions.length
    );
  },
  showNoVehicles: (state, getters) => {
    if (
      state.loading?.makes ||
      state.loading?.models ||
      state.loading?.descriptions
    ) {
      return false;
    }

    if (!state.selectedMake?.length || !state.selectedModel?.length) {
      return false;
    }

    return getters.getFilteredDescriptions.length < 1;
  },
  getLoaders: (_, getters) => {
    return {
      makesLoading: getters.getMakesLoading,
      modelsLoading: getters.getModelsLoading,
      descriptionsLoading: getters.getDescriptionsLoading,
    };
  },
  getShowers: (_, getters) => {
    return {
      showModel: getters.showModel,
      showYear: getters.showYear,
      showFuel: getters.showFuel,
      showEngineSize: getters.showEngineSize,
      showType: getters.showType,
      showTransmission: getters.showTransmission,
      showDoors: getters.showDoors,
      showDescription: getters.showDescription,
      showNoVehicles: getters.showNoVehicles,
    };
  },
};

export const mutations = {
  setMakesLoading(state, event) {
    state.loading.makes = event;
  },
  setModelsLoading(state, event) {
    state.loading.models = event;
  },
  setDescriptionsLoading(state, event) {
    state.loading.descriptions = event;
  },
  setMakes(state, event) {
    state.makes = event;
  },
  setModels(state, event) {
    state.models = event;
  },
  setDescriptions(state, event) {
    state.descriptions = event;
  },
  setSelectedMake(state, event) {
    state.selectedMake = event;
  },
  setSelectedModel(state, event) {
    state.selectedModel = event;
  },
  setSelectedYear(state, event) {
    state.selectedYear = event;
  },
  setSelectedFuel(state, event) {
    state.selectedFuel = event;
  },
  setSelectedEngineSize(state, event) {
    state.selectedEngineSize = event;
  },
  setSelectedType(state, event) {
    state.selectedType = event;
  },
  setSelectedTransmission(state, event) {
    state.selectedTransmission = event;
  },
  setSelectedDoors(state, event) {
    state.selectedDoors = event;
  },
  setSelectedVehicle(state, event) {
    state.selectedVehicle = event;
  },
  resetState: (state) => {
    Object.assign(state, {
      ...initialState(),
    });
  },
};

export const actions = {
  async mapRecall({ commit, dispatch, getters }, riskData) {
    /* Vehicle Makes */
    await dispatch("fetchMakes");
    if (getters.getMakes.includes(riskData?.vehicleManufacturer)) {
      commit("setSelectedMake", riskData?.vehicleManufacturer);
    } else {
      return false;
    }

    /* Vehicle Models */
    await dispatch("fetchModels");
    const matchingModel = getters.getModels.find((model) =>
      riskData?.vehicleModelDescription?.includes(model)
    );
    if (matchingModel) {
      commit("setSelectedModel", matchingModel);
    } else {
      return false;
    }

    /* Vehicle Decriptions */
    await dispatch("fetchDescriptions");

    /* Vehicle Years */
    if (getters.getYears.includes(riskData?.vehicleYearOfManufacture)) {
      commit("setSelectedYear", riskData?.vehicleYearOfManufacture);
    } else {
      return false;
    }

    /* Vehicle Fuel */
    const fuel = convertVehicleFuel(riskData?.vehicleFuel);
    if (getters.getFuel.includes(fuel)) {
      commit("setSelectedFuel", fuel);
    } else {
      return false;
    }

    /* Vehicle Engine Size */
    const engineSize = convertCCtoL(riskData?.vehicleCc);
    if (getters.getEngineSizes.includes(engineSize)) {
      commit("setSelectedEngineSize", engineSize);
    } else {
      return false;
    }

    /* Vehicle Body Style */
    const type = convertVehicleBodyStyle(riskData?.vehicleBodyStyle);
    if (getters.getTypes.includes(type)) {
      commit("setSelectedType", type);
    } else {
      return false;
    }

    /* Vehicle Transmission */
    if (getters.getTransmissions.length > 0) {
      const transmission = convertVehicleTransmission(
        riskData?.vehicleTransmission
      );
      if (getters.getTransmissions.includes(transmission)) {
        commit("setSelectedTransmission", transmission);
      } else {
        return false;
      }
    }

    /* Vehicle Doors */
    if (getters.getDoors.length > 0) {
      if (getters.getDoors.includes(riskData?.vehicleDoors)) {
        commit("setSelectedDoors", riskData?.vehicleDoors);
      } else {
        return false;
      }
    }

    /* Vehicle Code */
    const abiCode = riskData?.vehicleAbiCode;
    const vehicle = getters.getDescriptions.find((v) => v.value === abiCode);

    if (vehicle) {
      commit("setSelectedVehicle", vehicle);
      dispatch(
        "modules/vehicledetails/setVehicleDetails",
        vehicle?.details?.vehicle_transformed,
        { root: true }
      );
    } else {
      return false;
    }

    return true;
  },
  async fetchMakes({ commit }) {
    commit("setMakesLoading", true);
    try {
      const result = await this.$goshorty.vehicle.make();
      commit("setMakes", result);
    } catch (error) {
      console.error("Error during makes update", error);
    }
    commit("setMakesLoading", false);
  },
  async fetchModels({ commit, state }) {
    commit("setModelsLoading", true);
    try {
      const result = await this.$goshorty.vehicle.model(state.selectedMake);
      commit("setModels", result);
    } catch (error) {
      console.error("Error during models update", error);
    }
    commit("setModelsLoading", false);
  },
  async fetchDescriptions({ commit, state }) {
    commit("setDescriptionsLoading", true);
    try {
      const result = await this.$goshorty.vehicle.description(
        state.selectedMake,
        state.selectedModel
      );

      commit("setDescriptions", result.descriptions);
    } catch (error) {
      console.error("Error during descriptions update", error);
    }
    commit("setDescriptionsLoading", false);
  },
  async changeMake({ commit, dispatch }, make) {
    commit("setModels", []);
    commit("setDescriptions", []);
    commit("setSelectedModel", "");
    commit("setSelectedYear", "");
    commit("setSelectedFuel", "");
    commit("setSelectedEngineSize", "");
    commit("setSelectedType", "");
    commit("setSelectedTransmission", "");
    commit("setSelectedDoors", "");
    commit("setSelectedMake", make);
    commit("setSelectedVehicle", null);
    await dispatch("fetchModels");
  },
  async changeModel({ commit, dispatch }, model) {
    commit("setDescriptions", []);
    commit("setSelectedYear", "");
    commit("setSelectedFuel", "");
    commit("setSelectedEngineSize", "");
    commit("setSelectedType", "");
    commit("setSelectedTransmission", "");
    commit("setSelectedDoors", "");
    commit("setSelectedModel", model);
    commit("setSelectedVehicle", null);
    await dispatch("fetchDescriptions");
  },
  async changeYear({ commit }, year) {
    commit("setSelectedYear", year);
    commit("setSelectedVehicle", null);
  },
  async changeFuel({ commit }, fuel) {
    commit("setSelectedFuel", fuel);
    commit("setSelectedVehicle", null);
  },
  async changeEngineSize({ commit }, engineSize) {
    commit("setSelectedEngineSize", engineSize);
    commit("setSelectedVehicle", null);
  },
  async changeType({ commit }, type) {
    commit("setSelectedType", type);
    commit("setSelectedVehicle", null);
  },
  async changeTransmission({ commit }, transmission) {
    commit("setSelectedTransmission", transmission);
    commit("setSelectedVehicle", null);
  },
  async changeDoors({ commit }, doors) {
    commit("setSelectedDoors", doors);
    commit("setSelectedVehicle", null);
  },
};
