export default ({ $config, $cookies }, inject) => {
  let browserId = null;

  function generate() {
    const timestamp = new Date().valueOf().toString(16);
    const random = Math.floor(Math.random() * 1000000000).toString(16);
    const buildTime = $config.buildTime.toString(16);

    return `${buildTime}-${timestamp}-${random}`;
  }

  function initialize() {
    browserId = $cookies.get("browserId");
    if (!browserId) {
      browserId = generate();
      $cookies.set("browserId", browserId);
    }
  }

  function get() {
    return browserId;
  }

  const api = {
    get,
    initialize,
  };

  initialize();
  inject("browser", api);

  return api;
};
