var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseCard",
    {
      staticClass: "confirmation-vehicle-details-container",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_vm._v("Vehicle & Insurance Details")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { staticClass: "brandBackgroundLight" },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "v-list-item-group",
            { key: index },
            [
              _c(
                "v-list-item",
                {
                  staticClass: "flex-wrap justify-start px-0 text-body-2",
                  attrs: { disabled: "" },
                },
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass:
                        "confirmation-details confirmation-label pb-0 px-0",
                    },
                    [
                      _c("v-list-item-title", { staticClass: "text-wrap" }, [
                        _vm._v(_vm._s(item.text)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    {
                      staticClass:
                        "confirmation-details d-flex flex-column align-start pt-0 px-0 pb-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "insurance-name font-weight-bold text-body-2",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.value) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.img,
                            expression: "item.img",
                          },
                        ],
                        staticClass: "my-1",
                        attrs: {
                          src: item.img,
                          "max-height": "50px",
                          "max-width": "100px",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mb-1" }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }