var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    attrs: {
      value: _vm.value,
      rules: _vm.rules,
      placeholder: _vm.label === "first name" ? "First name" : "Surname",
      "data-zuko-id": _vm.zukoId,
      "data-sentry-mask": true,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }