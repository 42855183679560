<template>
  <div class="errorContainer">
    <p class="errormessage"><slot /></p>
  </div>
</template>

<script>
export default {
  name: "BaseError",
  mounted() {
    this.checkForErrors({ shouldScroll: false });
  },
  destroyed() {
    this.checkForErrors({ shouldScroll: false });
  },
};
</script>
<style>
.errormessage {
  color: var(--v-white-base);
}
.errorContainer {
  background-color: red;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
}
</style>
