<template>
  <v-select
    :items="$selectors.personal.relationships"
    :rules="rules"
    :value="value"
    @change="$emit('input', $event)"
    return-object
    placeholder="Please select"
    :data-zuko-id="zukoId"
  >
  </v-select>
</template>

<script>
export default {
  name: "PersonalRelationship",
  props: {
    value: {
      type: [Object, String],
      default: () => ({}),
    },
    zukoId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    rules: [
      (v) =>
        Object.keys(v).length !== 0 ||
        "Please select a valid value for your relationship to the additional driver.",
    ],
  }),
};
</script>
