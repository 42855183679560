<template>
  <v-alert
    type="error"
    color="orange"
    prominent
    dense
    class="text-center mt-3 black--text"
    ><div>
      {{ getMaintenanceMessages.warningMessage }}
    </div></v-alert
  >
</template>

<script>
export default {
  name: "MaintenanceWarningBar",
};
</script>
