var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.unsubscribe ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "my-10 text-center" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "brandPrimary", size: "60" },
              }),
            ],
            1
          )
        : !_vm.unsubscribe
        ? _c("div", [_vm._m(0), _vm._v(" "), _vm._m(1)])
        : _c("div", [
            _c("p", { staticClass: "brandText--text text-center" }, [
              _vm._v("\n      Select how you want to hear from us.\n    "),
            ]),
          ]),
      _vm._v(" "),
      !_vm.loading
        ? [
            _c(
              "div",
              {
                staticStyle: {
                  width: "120px",
                  "margin-left": "auto",
                  "margin-right": "auto",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center brandText--text" },
                  [
                    _c("div", { staticStyle: { width: "100px" } }, [
                      _vm._v("By Email"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "checkbox",
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.inputConsentEmail },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("email", $event)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-center p-0 mb-5 mt-2 brandText--text",
                  },
                  [
                    _c("div", { staticStyle: { width: "100px" } }, [
                      _vm._v("By SMS"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "checkbox",
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.inputConsentSMS },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("sms", $event)
                        },
                      },
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.hasPreviousConsent
              ? _c("div", { staticClass: "text-center mb-3 text-caption" }, [
                  _vm._v(
                    "\n      Just to let you know, you previously updated your marketing opt-in\n      status on\n      " +
                      _vm._s(_vm.consentLastUpdated) +
                      ".\n    "
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "marketing-capture-heading text-center" }, [
      _vm._v("\n      Would you like to receive our special "),
      _c("br"),
      _vm._v("offers & discounts?\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-center" }, [
      _vm._v("\n      Tick the boxes below to hear from us with tips, news"),
      _c("br"),
      _vm._v("\n      and offers you won't want to miss.\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }