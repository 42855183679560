<template>
  <v-dialog :value="isVisible" overlay-opacity="0.8" fullscreen>
    <v-row class="pt-10">
      <v-col cols="11" md="6" class="mx-auto" align="center">
        <v-card>
          <v-toolbar
            v-if="hasTitle"
            color="#dd2c00"
            class="text-h6 white--text font-weight-bold"
          >
            {{ title }}
            <v-spacer />
            <v-icon v-if="isCancellable" large @click="onCancel">
              mdi-close
            </v-icon>
          </v-toolbar>
          <v-card-title v-if="!hasTitle">
            <v-spacer />
            <v-icon
              v-if="isCancellable"
              large
              color="primary"
              @click="onCancel"
              style="border: 2px solid rgb(30, 137, 202); border-radius: 50%"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text
            class="blue--text text--darken-2 text-h5 font-weight-bold text-center mt-2"
            v-html="body"
          >
          </v-card-text>
          <v-card-actions class="justify-center pb-8">
            <a
              @click="onClose"
              class="button purple--text text--darken-4 text-h5 font-weight-bold px-10"
              color="white"
            >
              {{ cta }}
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseModal",
  computed: {
    isVisible() {
      return this.$store.getters["modal/isVisible"];
    },
    isCancellable() {
      return this.$store.getters["modal/isCancellable"];
    },
    hasTitle() {
      return !!this.title;
    },
    title() {
      return this.$store.getters["modal/title"];
    },
    body() {
      return this.$store.getters["modal/body"];
    },
    cta() {
      return this.$store.getters["modal/button"] || "Close";
    },
  },
  methods: {
    onCancel() {
      this.$router.go(-1);
      this.$store.commit("modal/HIDE");
    },
    onClose() {
      this.$emit("close");
      this.$store.commit("modal/HIDE");
    },
  },
};
</script>

<style scoped>
.button {
  font-family: "Font Theme Serif Bold", serif;
  background: var(--v-white-base);
  color: var(--v-brandText-base);
  position: relative;
  border-radius: 5rem;
  background-clip: padding-box;
  border: solid 2px var(--v-brandText-base);
  font-weight: bold;
  font-size: 1rem;
  padding: 0.8rem 1.2rem;
  margin-top: 10px;
}
</style>
