const defaults = require("~/assets/json/defaults.json");
const selectors = require("~/assets/json/selectors.json");

const initialState = () => ({
  addresses: [],
  addressHasSearched: false,
  hasCompletedSearch: false,
  hasManuallySetValue: false,
  hasManuallySetWeight: false,
  vehicleAbiProductionYears: "",
  vehicleBodyStyle: "",
  vehicleCc: "",
  vehicleDoors: "",
  vehicleFuel: "",
  vehicleIsVan: false,
  vehicleManufacturer: "",
  vehicleModelDescription: "",
  vehicleSeats: "",
  vehicleTransmission: "",
  vehicleYearOfManufacture: "",
  ...defaults.vehicleDetails,
});
export const state = initialState;

// Transformers:
const transformVehicleFuel = (vehicleFuel) => {
  let fuel = String(vehicleFuel).toLowerCase();
  const petrolRegex = /petrol/i;
  const dieselRegex = /diesel|heavy oil/i;
  const electricRegex = /electric/i;

  if (fuel === "p" || petrolRegex.exec(fuel)) {
    return { vehicleFuel: "FuelPetrol", short: "P", long: "Petrol" };
  }
  if (fuel === "d" || dieselRegex.exec(fuel)) {
    return { vehicleFuel: "FuelDiesel", short: "D", long: "Diesel" };
  }
  if (fuel === "e" || fuel === "h" || electricRegex.exec(fuel)) {
    return { vehicleFuel: "FuelElec", short: "E", long: "Electric" };
  }
  return { vehicleFuel, short: "U", long: "Unknown" };
};
const transformVehicleBodyStyle = (vehicleBodyStyle) => {
  if (vehicleBodyStyle === undefined) {
    return null;
  }
  vehicleBodyStyle = vehicleBodyStyle.toLowerCase();

  if (vehicleBodyStyle === "u" || vehicleBodyStyle.match(/coupe/i)) {
    return "U";
  }

  if (vehicleBodyStyle === "e" || vehicleBodyStyle.match(/estate/i)) {
    return "E";
  }

  if (vehicleBodyStyle === "s" || vehicleBodyStyle.match(/saloon/i)) {
    return "S";
  }

  if (vehicleBodyStyle === "h" || vehicleBodyStyle.match(/hatchback/i)) {
    return "H";
  }

  if (vehicleBodyStyle === "c" || vehicleBodyStyle.match(/convertible/i)) {
    return "C";
  }

  if (vehicleBodyStyle.match(/pickup/i)) {
    return "Pickup";
  }

  if (vehicleBodyStyle.match(/tipper/i)) {
    return "Tipper";
  }

  if (vehicleBodyStyle.match(/van/i)) {
    return "Van";
  }

  return null;
};
const transformVehicleTransmission = (vehicleTransmission) => {
  let transmission = String(vehicleTransmission);

  const autoRegex = /^(a|automatic)/i;
  const semiAutoRegex = /^(s|semi auto)/i;

  if (autoRegex.exec(transmission) || semiAutoRegex.exec(transmission)) {
    return "TransAuto";
  }

  return "TransMan";
};
const convertUsage = (duq372, duq248) => {
  if (duq372 === "N" && duq248 === "Y") {
    return "SDP";
  } else if (duq372 === "Y" && duq248 === "Y") {
    return "SDPC";
  } else if (duq372 === "N" && duq248 === "N") {
    return "SDPCB";
  } else {
    return "LocahHaul";
  }
};

export const getters = {
  hasAddresses: (state) => !!state.addresses?.length,
  hasManuallySetValue: (state) => state.hasManuallySetValue,
  hasManuallySetWeight: (state) => state.hasManuallySetWeight,
  hasSelectedAddress: (state) => !!state.inputVehicleOwner.address != "",
  hasInputRegistrationNumber: (state) =>
    !!state.inputRegistrationNumber?.length,
  hasVehicleGrossWeight: (state) => state.vehicleGrossWeight > 0,
  hasVehicleValue: (state) => !!state.vehicleValue > 0,
  hasCompletedSearch: (state) => state.hasCompletedSearch,
  getAddresses: (state) => state.addresses,
  getAddressHasSearched: (state) => state.addressHasSearched,
  getSelectedAddress: (state) => state.inputVehicleOwner.address,
  getSelectedAddressSameAsMainDriver: (state) =>
    state.selectedAddressSameAsMainDriver,
  getSelectedVehicleKeeper: (state) => state.selectedVehicleKeeper,
  getVehicleAbiCode: (state) => state.vehicleAbiCode,
  getVehicleAbiProductionYears: (state) => state.vehicleAbiProductionYears,
  getSelectedLearnerOwner: (state) => state.selectedLearnerOwner,
  getVehicleBodyStyle: (state) => state.vehicleBodyStyle,
  getVehicleBodyStyleTransformed: (state) =>
    transformVehicleBodyStyle(state.vehicleBodyStyle),
  getVehicleCc: (state) => state.vehicleCc,
  getVehicleDoors: (state) => state.vehicleDoors,
  getVehicleDoorsTransformed: (state) =>
    !state.vehicleDoors || state.vehicleDoors === "0"
      ? "2"
      : state.vehicleDoors,
  getVehicleFuel: (state) => {
    return transformVehicleFuel(
      state.vehicleFuel?.vehicleFuel || state.vehicleFuel
    );
  },
  getVehicleGrossWeight: (state) => state.vehicleGrossWeight,
  getVehicleKeptPostcode: (state) => state.vehicleKeptPostcode,
  getInputPostcode: (state) => state.inputVehicleOwner.postcode,
  getVehicleManufacturer: (state) => state.vehicleManufacturer,
  getVehicleModelDescription: (state) => state.vehicleModelDescription,
  getVehicleSeats: (state) => state.vehicleSeats,
  getVehicleSeatsTransformed: (state) => state.vehicleSeats || "2",
  getVehicleTransmission: (state) => state.vehicleTransmission,
  getVehicleTransmissionTransformed: (state) =>
    transformVehicleTransmission(state.vehicleTransmission),
  getVehicleValue: (state) => state.vehicleValue,
  getVehicleIsVan: (state) => state.vehicleIsVan,
  getVehicleIsManualLookup: (state) => state.vehicleIsManualLookup,
  getVehicleYearOfManufacture: (state) => state.vehicleYearOfManufacture,
  getVehicleYearOfManufactureTransformed: (state) =>
    state.vehicleYearOfManufacture.substring(0, 4),
  getInputRegistrationNumber: (state) => state.inputRegistrationNumber,
  getSelectedVehicleUsage: (state) => state.selectedVehicleUsage,
  getSelectedVehicleOwner: (state, getters, rootState, rootGetters) => {
    if (rootGetters["journey/isImpound"]) {
      return { text: "Proposer/Policyholder", value: "1" };
    } else {
      return state.selectedVehicleOwner;
    }
  },
  getSelectedVehicleAnnuallyInsured: (state) =>
    state.selectedVehicleAnnuallyInsured,
  getInputVehicleOwnerFirstname: (state) => state.inputVehicleOwner.firstname,
  getInputVehicleOwnerSurname: (state) => state.inputVehicleOwner.surname,
  getInputVehicleOwnerCombined: (state) =>
    `${state.inputVehicleOwner.firstname} ${state.inputVehicleOwner.surname}`,
  isManualLookup: (state) => state.vehicleIsManualLookup,
  isLearningNotOwnCar: (state, getters, rootState, rootGetters) =>
    rootGetters["journey/isLearnerDriver"] &&
    state.selectedLearnerOwner?.value === "I",
};

export const mutations = {
  setAddressHasSearched(state, value) {
    state.addressHasSearched = value;
  },
  setAddresses: (state, value) => {
    state.addresses = value;
  },
  setVehicleAbiCode: (state, value) => {
    state.vehicleAbiCode = value;
  },
  setVehicleAbiProductionYears: (state, value) => {
    state.vehicleAbiProductionYears = value;
  },
  setVehicleBodyStyle: (state, value) => {
    state.vehicleBodyStyle = value;
  },
  setVehicleCc: (state, value) => {
    state.vehicleCc = value;
  },
  setVehicleDoors: (state, value) => {
    state.vehicleDoors = value;
  },
  setVehicleFuel: (state, value) => {
    state.vehicleFuel = value;
  },
  setVehicleGrossWeight: (state, value) => {
    state.vehicleGrossWeight = value;
  },
  setVehicleKeptPostcode: (state, value) => {
    state.vehicleKeptPostcode = value;
  },
  setVehicleManufacturer: (state, value) => {
    state.vehicleManufacturer = value;
  },
  setVehicleModelDescription: (state, value) => {
    state.vehicleModelDescription = value;
  },
  setVehiclePurchased: (state, value) => {
    state.vehiclePurchased = value;
  },
  setVehicleSeats: (state, value) => {
    state.vehicleSeats = value;
  },
  setVehicleTransmission: (state, value) => {
    state.vehicleTransmission = value;
  },
  setVehicleValue: (state, value) => {
    state.vehicleValue = value;
  },
  setVehicleYearOfManufacture: (state, value) => {
    state.vehicleYearOfManufacture = value;
  },
  setInputRegistrationNumber: (state, value) => {
    state.inputRegistrationNumber = value.toUpperCase();
  },
  setSelectedVehicleUsage: (state, value) => {
    state.selectedVehicleUsage = value;
  },
  setSelectedVehicleOwner: (state, value) => {
    state.selectedVehicleOwner = value;
  },
  setVehicleIsManualLookup: (state, value) => {
    state.vehicleIsManualLookup = value;
  },
  setSelectedLearnerOwner: (state, value) => {
    state.selectedLearnerOwner = value;
  },
  setVehicleIsVan: (state, value) => {
    state.vehicleIsVan = value;
  },
  setHasCompletedSearch: (state, value) => {
    state.hasCompletedSearch = value;
  },
  setHasManuallySetWeight: (state, value) => {
    state.hasManuallySetWeight = value;
  },
  setHasManuallySetValue: (state, value) => {
    state.hasManuallySetValue = value;
  },
  setInputVehicleOwnerFirstname: (state, value) => {
    state.inputVehicleOwner.firstname = value;
  },
  setInputVehicleOwnerSurname: (state, value) => {
    state.inputVehicleOwner.surname = value;
  },
  setSelectedAddress(state, value) {
    state.inputVehicleOwner.address = value;
  },
  setSelectedAddressSameAsMainDriver(state, value) {
    state.selectedAddressSameAsMainDriver = value;
  },
  setSelectedVehicleAnnuallyInsured(state, value) {
    state.selectedVehicleAnnuallyInsured = value;
  },
  setSelectedVehicleKeeper(state, value) {
    state.selectedVehicleKeeper = value;
  },
  setInputPostcode(state, value) {
    state.inputVehicleOwner.postcode = value;
  },
  resetState(state) {
    const { inputRegistrationNumber } = state;
    Object.assign(state, {
      ...initialState(),
      inputRegistrationNumber,
    });
  },
};

export const actions = {
  async mapRecall(
    { commit, dispatch, rootGetters, getters },
    { riskData, vehicleData }
  ) {
    let cleanRegistration = rootGetters["modules/querydetails/hasRegoverride"]
      ? rootGetters["modules/querydetails/getRegoverride"]
      : riskData?.vehicleRegNumber;
    cleanRegistration = cleanRegistration
      ?.toUpperCase()
      .replace(/[^A-Z0-9]/g, "");

    let allFieldsPresent = true;
    const checkAndCommit = (commitName, value) => {
      if (value !== undefined && value !== null) {
        commit(commitName, value);
      } else {
        allFieldsPresent = false;
      }
    };

    if (vehicleData) {
      const vehicleResult = await dispatch(
        "fetchVehicleDetails",
        cleanRegistration
      );
      allFieldsPresent = vehicleResult?.success;
    } else {
      // Manual Route
      commit("setVehicleIsManualLookup", true);
      commit("setInputRegistrationNumber", cleanRegistration);
      allFieldsPresent = await dispatch(
        "modules/manualsearchdetails/mapRecall",
        riskData,
        {
          root: true,
        }
      );
    }

    if (
      rootGetters["journey/isUnder21"] &&
      !rootGetters["journey/isLearnerDriver"]
    ) {
      checkAndCommit(
        "setSelectedVehicleOwner",
        selectors.vehicle.owner.under21.find(
          (l) => l.text === riskData?.userfieldsUserfieldGSYDRQ3Response
        )
      );
    }

    if (rootGetters["journey/isLearnerDriver"]) {
      checkAndCommit(
        "setSelectedVehicleAnnuallyInsured",
        selectors.vehicle.annualInsurance.find(
          (a) => a === riskData?.duqsDuq901Response
        )
      );
      checkAndCommit(
        "setSelectedLearnerOwner",
        selectors.vehicle.learnerCarOwner.find(
          (a) => a.value === riskData?.duqsDuq936ShortResponse
        )
      );

      if (getters.isLearningNotOwnCar) {
        const carOwnerSplit =
          riskData?.userfieldsUserfieldGOIDCONResponse.split(" ");
        checkAndCommit(
          "setInputVehicleOwnerFirstname",
          carOwnerSplit.slice(0, -1).join(" ")
        );
        checkAndCommit(
          "setInputVehicleOwnerSurname",
          carOwnerSplit[carOwnerSplit.length - 1]
        );
        checkAndCommit(
          "setInputPostcode",
          riskData?.userfieldsUserfieldGOIDCOAResponse
        );

        allFieldsPresent = await dispatch(
          "findAndSelectAddress",
          riskData?.userfieldsUserfieldUDPRNResponse?.split(" ")[1]
        );

        if (
          riskData?.userfieldsUserfieldUDPRNResponse?.split(" ")[0] ===
          riskData?.userfieldsUserfieldUDPRNResponse?.split(" ")[1]
        ) {
          checkAndCommit("setSelectedAddressSameAsMainDriver", "Yes");
        } else {
          checkAndCommit("setSelectedAddressSameAsMainDriver", "No");
        }
      }
    }

    if (rootGetters["journey/isImpound"]) {
      allFieldsPresent = false;
    }

    if (rootGetters["journey/isVan"]) {
      checkAndCommit(
        "setSelectedVehicleUsage",
        selectors.vehicle.usage.find(
          (a) =>
            a.value ===
            convertUsage(
              riskData?.duqsDuq372ShortResponse,
              riskData?.duqsDuq248ShortResponse
            )
        )
      );
      checkAndCommit(
        "setSelectedVehicleOwner",
        selectors.vehicle.owner.standard.find(
          (a) => a.value === riskData?.duqsDuq463ShortResponse
        )
      );

      if (getters.getVehicleGrossWeight.length < 1) {
        checkAndCommit("setVehicleGrossWeight", riskData?.vehicleGrossWeight);
        commit("setHasManuallySetWeight", true);
      }
    }

    if (
      getters.getVehicleValue.length < 1 ||
      getters.getVehicleValue > 65000 ||
      getters.getVehicleIsManualLookup
    ) {
      checkAndCommit(
        "setVehicleValue",
        selectors.vehicle.value.find(
          (v) => v.value === Number(riskData?.vehicleValue)
        )?.value
      );
      commit("setHasManuallySetValue", true);
    }

    return allFieldsPresent;
  },
  async fetchVehicleDetails({ commit, dispatch }, registration) {
    commit("resetState");

    const cleanRegistration = registration.toUpperCase().replace(/\s/g, "");
    commit("setHasCompletedSearch", true);
    commit("setInputRegistrationNumber", cleanRegistration);

    try {
      const result = await this.$goshorty.vehicle.registration(
        cleanRegistration
      );

      dispatch("setVehicleDetails", result);
      commit("setVehicleIsManualLookup", false);

      return {
        success: true,
        vehicleIsVan: result.vehicleIsVan,
      };
    } catch (err) {
      if (err.response?.status === 400) {
        return { success: false };
      }
      commit("setVehicleIsManualLookup", true);

      return {
        success: true,
      };
    }
  },
  setVehicleDetails({ commit }, vehicleDetails) {
    const mutationMap = {
      vehicleAbiCode: "setVehicleAbiCode",
      vehicleAbiProductionYears: "setVehicleAbiProductionYears",
      vehicleBodyStyle: "setVehicleBodyStyle",
      vehicleCc: "setVehicleCc",
      vehicleDoors: "setVehicleDoors",
      vehicleFuel: "setVehicleFuel",
      vehicleGrossWeight: "setVehicleGrossWeight",
      vehicleIsVan: "setVehicleIsVan",
      vehicleManufacturer: "setVehicleManufacturer",
      vehicleModelDescription: "setVehicleModelDescription",
      vehicleRegNumber: "setInputRegistrationNumber",
      vehicleSeats: "setVehicleSeats",
      vehicleTransmission: "setVehicleTransmission",
      vehicleValue: "setVehicleValue",
      vehicleYearOfManufacture: "setVehicleYearOfManufacture",
    };

    Object.keys(mutationMap).forEach((key) => {
      if (key in vehicleDetails) {
        commit(mutationMap[key], vehicleDetails[key] ?? "");
      }
    });
  },
  async findAndSelectAddress({ dispatch, getters, commit }, udprnResponse) {
    const addressResult = await dispatch("findAddress");
    if (!addressResult.success) {
      return false;
    }
    commit("setAddressHasSearched", true);

    if (!udprnResponse) {
      return false;
    }

    const address = getters.getAddresses.find((a) => a.udprn === udprnResponse);
    if (!address) {
      return false;
    }

    commit("setSelectedAddress", address);
    return true;
  },
  async findAddress({ commit, state }) {
    try {
      commit("setAddresses", []);
      const response = await this.$goshorty.address(
        state.inputVehicleOwner.postcode.trim()
      );
      commit("setAddresses", response);
      return { success: true, data: response };
    } catch (err) {
      return { success: false, error: err.message };
    }
  },
  clearAddress({ commit }) {
    commit("setAddresses", []);
    commit("setSelectedAddress", "");
    commit("setInputPostcode", "");
    commit("setAddressHasSearched", false);
  },
  changeSelectedAddressSameAsMainDriver(
    { commit, dispatch, rootGetters },
    value
  ) {
    commit("setSelectedAddressSameAsMainDriver", value);
    if (value === "Yes") {
      commit(
        "setSelectedAddress",
        rootGetters["modules/personaldetails/getSelectedAddress"]
      );
    } else {
      dispatch("clearAddress");
    }
  },
};
