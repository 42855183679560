<template>
  <div>
    <div v-if="popoutLoaded" class="overlay"></div>
    <div
      v-if="popoutLoaded"
      class="licence-details-popout-container b-white rounded d-flex flex-column px-12 py-6"
    >
      <button @click="$emit('close')" type="button">
        <v-icon class="icon">mdi-close </v-icon>
      </button>
      <h4 class="text-h4 pt-4 text-center font-weight-bold">
        Find Your 16 Digit Licence Number
      </h4>
      <nuxt-img
        v-if="isProvisional"
        class="pr-8 px-sm-0"
        src="/imgs/driving-licence-helper-provisional.png"
        alt="GoShorty provisional driving licence helper"
      />
      <nuxt-img
        v-else
        class="pr-8 px-sm-0"
        src="/imgs/driving-licence-helper.png"
        alt="GoShorty driving licence helper"
      />
      <p class="text-body-1 font-weight-bold text-center font-weight-bold">
        We only need the details shown in the highlighted box.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LicenceDetailsPopoutCard",
  props: {
    isProvisional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popoutLoaded: true,
    };
  },
  methods: {
    popout() {
      this.popoutLoaded = !this.popoutLoaded;
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999; // Adjust the z-index to ensure it covers other elements
}

.licence-details-popout-container::v-deep {
  color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    padding: 2rem !important;
  }

  @media (max-width: 375px) {
    padding: 0 !important;
  }

  h4 {
    line-height: 2rem;
    margin-bottom: 1rem;

    @media (max-width: 767px) {
      font-size: 1.5rem !important;
      margin-bottom: 0;
    }

    @media (max-width: 375px) {
      font-size: 1.25rem !important;
      padding: 0 1.75rem;
    }
  }

  p {
    @media (max-width: 375px) {
      padding: 0 1.75rem;
    }
  }

  button {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .icon {
    color: black;
  }

  img {
    max-width: 500px;

    @media (max-width: 767px) {
      max-width: 350px;
    }

    @media (max-width: 375px) {
      max-width: 300px;
    }
  }
}
</style>
