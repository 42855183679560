var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.isWarningVisible,
        "overlay-opacity": "0.8",
        fullscreen: "",
        persistent: "",
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "pt-10" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: { cols: "11", md: "6", align: "center" },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "text-h6 white--text font-weight-bold",
                      attrs: { color: "#dd2c00" },
                    },
                    [
                      _vm._v("\n          Are you still there?\n          "),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    {
                      staticClass:
                        "blue--text text--darken-2 text-h5 font-weight-bold text-center mt-2",
                    },
                    [
                      _vm._v(
                        "\n          You’re so close… but for security reasons your session will reset at\n          " +
                          _vm._s(_vm.resetTime) +
                          "\n          "
                      ),
                      _c("p", [
                        _vm._v(
                          "Complete your quote before then and be on your way!"
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-card-actions", { staticClass: "justify-center pb-8" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "button purple--text text--darken-4 text-h5 font-weight-bold px-10",
                        attrs: { color: "white" },
                        on: { click: _vm.onContinue },
                      },
                      [_vm._v("\n            Continue\n          ")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }