<template>
  <v-card class="d-flex flex-column align-center">
    <v-card-title class="py-2 font-weight-black title-font">
      Optional Extras:
    </v-card-title>
    <v-card-text class="text-center black--text px-2">
      Check out our optional extras such as
      <span class="font-weight-bold">breakdown cover</span> on the next page!
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "OptionalExtrasCard",
};
</script>

<style lang="scss" scoped>
.title-font {
  font-family: "Font Theme Bold", sans-serif !important;
}
.v-card {
  border: 2px solid var(--v-brandPrimary-base);
}
</style>
