const defaults = require("~/assets/json/defaults.json");

const initialState = () => ({
  serverTime: {},
  calculatedDates: {},
  datePicker: [],
  hourPicker: [],
  minutePicker: [],
  daysToReturn: null,
  ...defaults.datetimeDetails,
});
export const state = initialState;

export const getters = {
  hasDates: (state) => {
    return !!state.datePicker.length;
  },
  hasHours: (state) => {
    return !!state.hourPicker.length;
  },
  hasMinutes: (state) => {
    return !!state.minutePicker.length;
  },
  getHours: (state) => {
    return state.hourPicker;
  },
  getMinutes: (state) => {
    return state.minutePicker;
  },
  getDates: (state) => {
    return state.datePicker;
  },
  getSelectedDate: (state) => {
    return state.selectedDate;
  },
  getSelectedHour: (state, getters) => {
    return getters.isImmediate
      ? state.serverTime?.now_iso?.substring(11, 13)
      : state.selectedHour;
  },
  getSelectedMinute: (state, getters) => {
    return getters.isImmediate
      ? state.serverTime?.now_iso?.substring(14, 16)
      : state.selectedMinute;
  },
  getServerTime: (state) => {
    return state.serverTime;
  },
  getSelectedTime: (state) => {
    if (state.selectedHour && state.selectedMinute) {
      return `${state.selectedHour}:${state.selectedMinute}`;
    }
    return null;
  },
  getAmOrPm: (_, getters) => {
    if (getters.getSelectedTime) {
      const [hour] = getters.getSelectedTime.split(":").map(Number);
      return hour < 12 ? "AM" : "PM";
    }
    return null;
  },
  getSelectedISO: (state) => {
    const selectedDate = state.selectedDate
      ? new Date(state.selectedDate.value)
      : null;

    if (selectedDate) {
      if (state.selectedHour) {
        selectedDate.setHours(state.selectedHour);
      } else {
        selectedDate.setHours(new Date(state.serverTime.now_iso).getHours());
      }
      if (state.selectedMinute) {
        selectedDate.setMinutes(state.selectedMinute);
      } else {
        selectedDate.setMinutes(
          new Date(state.serverTime.now_iso).getMinutes()
        );
      }

      return selectedDate;
    }

    return null;
  },
  getSelectedDateObject: (state, getters) => {
    function convertDateFormat(dateString) {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    }

    return {
      selections: {
        selectedDate: state.selectedDate,
        selectedHour: state.selectedHour,
        selectedMinute: state.selectedMinute,
        selectedTime: getters.getSelectedTime,
        selectedISO: getters.getSelectedISO,
      },
      isImmediate: state.isImmediate,
      humanized: {
        date: state.selectedDate
          ? convertDateFormat(state.selectedDate.value)
          : null,
        time: getters.getSelectedTime,
        amOrPm: getters.getAmOrPm,
      },
    };
  },
  getCalculatedDates: (state, getters) => {
    return state.calculatedDates;
  },
  isImmediate: (state) => {
    return state.selectedDate?.text === "Immediate Start";
  },
};

export const mutations = {
  setServerTime(state, data) {
    state.serverTime = data;
  },
  setDatePicker(state, data) {
    state.datePicker = data;
  },
  setHourPicker(state, data) {
    state.hourPicker = data;
  },
  setMinutePicker(state, data) {
    state.minutePicker = data;
  },
  setSelectedDate(state, data) {
    state.selectedDate = data;
  },
  setSelectedHour(state, data) {
    state.selectedHour = data;
  },
  setSelectedMinute(state, data) {
    state.selectedMinute = data;
  },
  setIsImmediate(state, data) {
    state.isImmediate = data;
  },
  setCalculatedDates(state, data) {
    state.calculatedDates = data;
  },
  resetState: (state) => {
    Object.assign(state, {
      ...initialState(),
    });
  },
};

export const actions = {
  async mapRecall({ commit, dispatch, getters }, dateData) {
    let allFieldsPresent = true;
    const checkAndCommit = (commitName, value) => {
      if (value !== undefined && value !== null) {
        commit(commitName, value);
      } else {
        allFieldsPresent = false;
      }
    };

    if (dateData.start_date !== "Immediate") {
      const dateToSelect = getters.getDates.find(
        (d) => d.value === dateData?.start_date
      );
      if (dateToSelect) {
        checkAndCommit("setSelectedDate", dateToSelect);
        checkAndCommit("setSelectedHour", dateData?.start_hour);
        checkAndCommit("setSelectedMinute", dateData?.start_minute);
        await dispatch("fetchDateTimes");

        if (
          !getters.getHours.find((h) => h.value === getters.getSelectedHour) ||
          !getters.getMinutes.find((m) => m.value === getters.getSelectedMinute)
        ) {
          allFieldsPresent = false;
        }
      } else {
        allFieldsPresent = false;
      }
    }

    return allFieldsPresent;
  },
  async fetchDateTimes({ commit, state, rootGetters, getters, dispatch }) {
    try {
      const result = await this.$goshorty.sendRequest("datetimes", {
        chosen_date: state.selectedDate?.value,
        chosen_hour: getters.isImmediate ? 99 : state.selectedHour,
        chosen_minute: getters.isImmediate ? 99 : state.selectedMinute,
        days_to_return: state.daysToReturn,
        chosen_duration_type:
          rootGetters["modules/coverdetails/getSelectedCoverDurationType"].text,
        chosen_duration:
          rootGetters["modules/coverdetails/getSelectedCoverLength"]?.abbr ||
          null,
        chosen_duration_in_hours:
          rootGetters["modules/coverdetails/getSelectedCoverLength"]?.hours ||
          null,
        immediate_start: getters.isImmediate,
      });

      if (result.success) {
        commit("setServerTime", result?.data?.server_time);
        commit("setDatePicker", result?.data?.date_picker);
        commit("setHourPicker", result?.data?.hour_picker);
        commit("setMinutePicker", result?.data?.minute_picker);
        commit("setCalculatedDates", result?.data?.calculated_dates);

        if (!state.selectedDate && result?.data?.date_picker?.length) {
          commit("setSelectedDate", result?.data?.date_picker[0]);
        }

        if (result?.data?.calculated_dates?.start_date_moved) {
          dispatch("switchToImmediate");
        }
      }
    } catch (error) {
      console.error("Error during datetime update", error);
    }
  },
  async changeSelectedDate({ commit, dispatch }, { date }) {
    commit("setSelectedDate", date);
    commit("setSelectedHour", null);
    commit("setSelectedMinute", null);
    await dispatch("fetchDateTimes");
  },
  async changeSelectedHour({ commit, dispatch }, hour) {
    commit("setSelectedHour", hour);
    commit("setSelectedMinute", null);
    await dispatch("fetchDateTimes");
  },
  async changeSelectedMinute({ commit, dispatch }, minute) {
    commit("setSelectedMinute", minute);
    await dispatch("fetchDateTimes");
  },
  switchToImmediate({ commit, getters }) {
    commit("setSelectedHour", null);
    commit("setSelectedMinute", null);
    commit(
      "setSelectedDate",
      getters.getDates.find((d) => d.text === "Immediate Start")
    );
  },
};
