<template>
  <v-sheet
    rounded
    class="d-flex align-center justify-center payment-sheet"
    color="white"
    min-height="28"
    height="28"
    min-width="56"
    width="56"
  >
    <slot></slot>
  </v-sheet>
</template>

<script>
export default {
  name: "BasePaymentSheet",
};
</script>

<style lang="scss" scoped>
.payment-sheet {
  margin: 1px !important;
}
</style>
