export const namespaced = true;

export const state = () => ({
  visible: true,
  firstload: true,
  message: null,
  headline: null,
});

export const getters = {
  isVisible(state) {
    return state.visible;
  },
  isFirstLoad(state) {
    return state.firstload;
  },
  message(state) {
    return state.message;
  },
  headline(state) {
    return state.headline;
  },
};

export const mutations = {
  headline(state, headline) {
    state.headline = headline;
  },
  message(state, message) {
    state.message = message;
  },
  show(state, { message = null, headline = null }) {
    state.visible = true;
    state.message = message;
    state.headline = headline;
  },
  hide(state) {
    state.visible = false;
    state.message = null;
    state.headline = null;
    state.firstload = false;
  },
};
