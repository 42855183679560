var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BaseButtonToggle", {
        attrs: {
          value: _vm.value,
          applyBorder: _vm.hasError,
          items: _vm.$selectors.personal.SameAsMainDriver,
          zukoId: _vm.zukoId,
          notMandatory: "",
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
      _vm._v(" "),
      _vm.hasError
        ? _c(
            "div",
            {
              staticClass: "error--text",
              staticStyle: { "font-size": "12px", "margin-top": "-10px" },
            },
            [
              _vm._v(
                "\n    Please select a valid value for if the address is the same as the main\n    driver.\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }