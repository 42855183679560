<template>
  <v-checkbox
    v-model="internalValue"
    @change="$emit('input', $event)"
    color="brandText"
    id="gtm--coverDetailsForm-vehicleKeeper"
    :rules="rules"
    :class="!value ? 'custom-checkbox' : ''"
    data-zuko-id="'zuko--coverDetailsForm-vehicleKeeper"
  >
    <template v-slot:label>
      <label for="declarationz" class="text-body-2 brandText--text"
        >I declare that I am the owner of the insured vehicle and am in
        possession of a V5 document bearing my name, or in the event that the
        vehicle is owned by a leasing company, can provide evidence of a current
        lease for the insured vehicle in my name. I also understand that the
        policy must be purchased in the name of the vehicle owner/ lease
        holder.</label
      >
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "VehicleKeeper",
  props: {
    value: {
      type: Boolean,
    },
  },
  data: () => ({
    internalValue: false,
    rules: [
      (v) => !!v || "You must read and confirm the declaration to continue.",
    ],
  }),
  mounted() {
    this.internalValue = this.value ?? false;
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-checkbox::v-deep .v-input--selection-controls__input i {
  color: red !important;
  caret-color: red !important;
}
</style>
