<template>
  <v-row>
    <v-col>
      <v-text-field
        :value="value"
        :rules="rules"
        @input="$emit('input', $event)"
        :id="`${id}-non-uk`"
        validate-on-blur
        :data-zuko-id="`${zukoId}-non-uk`"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LicenceNumberOther",
  props: {
    value: {
      type: String,
    },
    id: {
      type: String,
    },
    zukoId: {
      type: String,
    },
    pronoun: {
      type: String,
      default: "your",
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          !!v ||
          `Please enter a valid value for ${this.pronoun} licence number.`,
      ],
    };
  },
};
</script>
