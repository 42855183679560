<template>
  <div ref="animationContainer" />
</template>

<script>
export default {
  name: "SpinnerBranded",
  created() {
    this.loadAnimation();
  },
  methods: {
    async loadAnimation() {
      try {
        if (this.isWhiteLabeled) {
          const modifiedJsonAnimation = await this.changeAnimationColour();
          this.$lottie.loadAnimation({
            container: this.$refs.animationContainer,
            animationData: modifiedJsonAnimation,
          });
        } else {
          this.$lottie.loadAnimation({
            container: this.$refs.animationContainer,
            path: "/imgs/Whitelabel-animation.json",
          });
        }
      } catch (error) {
        console.error("Error loading animation:", error);
      }
    },
    async changeAnimationColour() {
      const primaryBrandColour =
        this.$vuetify.theme.themes.light.brandPrimary.replace(/^#/, "");

      const bigint = parseInt(primaryBrandColour, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      const lottieR = r / 255;
      const lottieG = g / 255;
      const lottieB = b / 255;

      try {
        const response = await fetch("/imgs/Whitelabel-animation.json");
        const jsonData = await response.json();
        jsonData.layers[0].ef[0].ef[2].v.k = [lottieR, lottieG, lottieB, 1];
        jsonData.layers[1].ef[0].ef[2].v.k = [lottieR, lottieG, lottieB, 1];
        return jsonData;
      } catch (error) {
        console.error("Error fetching or modifying JSON:", error);
        this.$lottie.loadAnimation({
          container: this.$refs.animationContainer,
          path: "/imgs/Whitelabel-animation.json",
        });
        throw error;
      }
    },
  },
};
</script>
