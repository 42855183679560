<template>
  <v-select
    :items="$selectors.vehicle.value"
    :value="value"
    :rules="rules"
    placeholder="Please select"
    :data-zuko-id="zukoId"
    @change="$emit('input', $event)"
  ></v-select>
</template>

<script>
export default {
  name: "VehicleValuation",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String, Object],
      required: true,
    },
  },
  data: () => ({
    rules: [
      (v) => {
        if (v < 2000 || v > 65000) {
          return "Please select a valid value for vehicle value.";
        } else {
          return true;
        }
      },
    ],
  }),
};
</script>
