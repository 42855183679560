<template>
  <v-overlay
    :value="isVisible"
    color="grey darken-4"
    :opacity="isFirstLoad ? 0.95 : message ? 0.9 : 0.8"
  >
    <v-row>
      <v-col
        v-if="headline"
        cols="10"
        md="5"
        class="mx-auto text-center text-h6"
      >
        {{ headline }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" md="3" class="mx-auto">
        <v-progress-circular
          v-if="isFirstLoad"
          indeterminate
          color="brandPrimary"
          size="60"
        />
        <spinner-unbranded v-else-if="isWhiteLabeled" />
        <spinner-branded v-else />
      </v-col>
    </v-row>
    <v-row v-if="headline"> </v-row>
    <v-row>
      <v-col
        v-if="message || isFirstLoad"
        cols="10"
        md="5"
        class="mx-auto text-center font-weight-bold"
      >
        {{ isFirstLoad ? "Loading..." : message }}
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: "BaseLoader",
  watch: {
    isVisible(newVal, oldVal) {
      if (newVal === false) {
        this.isLoaded = false;
      }
    },
  },
  computed: {
    isVisible() {
      return this.$store.getters["modules/loader/isVisible"];
    },
    message() {
      return this.$store.getters["modules/loader/message"];
    },
    headline() {
      return this.$store.getters["modules/loader/headline"];
    },
    isFirstLoad() {
      return this.$store.getters["modules/loader/isFirstLoad"];
    },
  },
};
</script>
