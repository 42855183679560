export default (_, inject) => {
  const ignoreList = [
    "gtm--VehicleManualLookupForm",
    "gtm--coverDetailsForm",
    "gtm--personalDetailsForm",
    "gtm--licenceDetailsForm",
    "gtm--additionalDriverPersonalDetailsForm",
    "gtm--additionalDriverLicenceDetailsForm",
    "gtm--quoteForm",
    "gtm--quoteRecall",
    "gtm--quoteRecallForm",
  ];
  function checkForErrorText(element) {
    return element &&
      (element.classList.contains("error--text") ||
        element.querySelector(".error--text") ||
        element.closest(".error--text"))
      ? element
      : false;
  }
  function checkForErrors(shouldScroll = true) {
    let elementToScrollTo = null;
    const elements = Array.from(
      document.querySelectorAll("[id^='gtm--']")
    ).filter((element) => !ignoreList.includes(element.id));

    const errors = elements.reduce((errorCount, element) => {
      const elementHasErrors = checkForErrorText(element);
      if (elementHasErrors) {
        elementToScrollTo = elementToScrollTo || elementHasErrors;
        return errorCount + 1;
      }
      return errorCount;
    }, 0);

    if (elementToScrollTo && shouldScroll) {
      const yOffset = -50;
      const yPosition =
        elementToScrollTo.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }

    return errors;
  }
  inject("errorManager", { checkForErrors });
};
