<template>
  <div class="dob-container">
    <v-row class="dateOfBirthFields">
      <v-col cols="4">
        <v-text-field
          ref="dateOfBirthDay"
          :id="`${id}-day`"
          :data-zuko-id="`${zukoId}-day`"
          v-model="day"
          :rules="rules.day"
          suffix="/"
          placeholder="DD"
          type="tel"
          maxlength="2"
          class="centered-input date-field"
          @keypress="validateKeyPress($event, 2)"
          @input="validateDayInput"
          @blur="handleDayBlur"
          :data-sentry-mask="true"
          validate-on-blur
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          ref="dateOfBirthMonth"
          :id="`${id}-month`"
          :data-zuko-id="`${zukoId}-month`"
          v-model="month"
          :rules="rules.month"
          placeholder="MM"
          type="tel"
          maxlength="2"
          class="centered-input month-field"
          @keypress="validateKeyPress($event, 2)"
          @input="validateMonthInput"
          @blur="handleMonthBlur"
          :data-sentry-mask="true"
          validate-on-blur
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          ref="dateOfBirthYear"
          :id="`${id}-year`"
          :data-zuko-id="`${zukoId}-year`"
          v-model="year"
          :rules="rules.year"
          maxlength="4"
          prefix="/"
          :error="hasError"
          placeholder="YYYY"
          type="tel"
          class="centered-input year-field"
          @keypress="validateKeyPress($event, 4)"
          :data-sentry-mask="true"
          validate-on-blur
        ></v-text-field>
      </v-col>
    </v-row>
    <BaseError
      v-if="hasError"
      :id="`${id}-errorMessage`"
      :data-zuko-id="`${zukoId}-errorMessage`"
      class="error--text"
      >{{ errorMessage }}</BaseError
    >
  </div>
</template>

<script>
export default {
  name: "PersonalDateOfBirth",
  props: {
    id: {
      type: String,
      required: true,
    },
    zukoId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        day: [
          (v) => {
            const regex = /^0?[1-9]$|^[12]\d$|^3[01]$/;
            return regex.test(v) ? true : "Please enter a valid day";
          },
        ],
        month: [
          (v) => {
            const regex = /^0?[1-9]$|^1[0-2]$/;
            return regex.test(v) ? true : "Please enter a valid month";
          },
        ],
        year: [
          (v) => {
            const regex = /^\d{4}$/;
            return regex.test(v) ? true : "Please enter a valid year";
          },
        ],
      },
    };
  },
  computed: {
    hasError() {
      return !!this.errorMessage.length;
    },
    day: {
      get() {
        return this.$store.getters[
          "modules/" + this.type + "/getInputDateOfBirthDay"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/" + this.type + "/setInputDateOfBirthDay",
          value
        );
      },
    },
    month: {
      get() {
        return this.$store.getters[
          "modules/" + this.type + "/getInputDateOfBirthMonth"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/" + this.type + "/setInputDateOfBirthMonth",
          value
        );
      },
    },
    year: {
      get() {
        return this.$store.getters[
          "modules/" + this.type + "/getInputDateOfBirthYear"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/" + this.type + "/setInputDateOfBirthYear",
          value
        );
      },
    },
    fullDOB() {
      return this.$store.getters[
        "modules/" + this.type + "/getInputDateOfBirth"
      ];
    },
    errorMessage() {
      if (!this.validDOB) {
        return "";
      }

      if (this.calculatedDOB.getTime() < this.earliestDOB.getTime()) {
        return "You must be 125 or under at the start of the policy to apply for a quote.";
      }

      if (this.calculatedDOB.getTime() > this.latestDOB.getTime()) {
        return "You must be 17 or over at the start of the policy to apply for a quote.";
      }

      return "";
    },
    calculatedDOB() {
      if (this.validDOB) {
        return new Date(this.fullDOB);
      } else {
        return new Date();
      }
    },
    earliestDOB() {
      return this.$store.getters["journey/getEarliestDOB"];
    },
    latestDOB() {
      return this.$store.getters["journey/getLatestDOB"];
    },
    validDOB() {
      return /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(this.fullDOB);
    },
  },

  methods: {
    onEnter() {
      this.$emit("enter");
    },
    validateKeyPress(event, length) {
      const allowedKeys = ["Backspace", "Tab"];

      if (
        event.target.length > length ||
        (!/\d/.test(event.key) && !allowedKeys.includes(event.key))
      ) {
        event.preventDefault();
      }
    },
    validateDayInput(event) {
      if (event.length === 2) {
        this.$refs.dateOfBirthMonth.focus();
      }
    },
    validateMonthInput(event) {
      if (event.length === 2) {
        this.$refs.dateOfBirthYear.focus();
      }
    },
    handleDayBlur() {
      if (this.day?.length === 1) {
        this.day = "0" + this.day;
      }
      this.$refs.dateOfBirthDay.validate();
    },
    handleMonthBlur() {
      if (this.month?.length === 1) {
        this.month = "0" + this.month;
      }
    },
  },
};
</script>

<style scoped>
.dateOfBirthFields {
  padding: 12px;

  .col {
    padding: 0px;
  }

  .centered-input >>> input {
    text-align: center;
  }
}

.month-field::v-deep .v-text-field__slot {
  border-radius: 0px;
}

.date-field::v-deep .v-text-field__slot {
  border-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.year-field::v-deep .v-text-field__slot {
  border-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
