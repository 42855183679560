var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      staticClass: "d-flex align-center justify-center payment-sheet",
      attrs: {
        rounded: "",
        color: "white",
        "min-height": "28",
        height: "28",
        "min-width": "56",
        width: "56",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }