var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "d-flex flex-column align-center" },
    [
      _c("v-card-title", { staticClass: "py-2 font-weight-black title-font" }, [
        _vm._v("\n    Optional Extras:\n  "),
      ]),
      _vm._v(" "),
      _c("v-card-text", { staticClass: "text-center black--text px-2" }, [
        _vm._v("\n    Check out our optional extras such as\n    "),
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v("breakdown cover"),
        ]),
        _vm._v(" on the next page!\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }