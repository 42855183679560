var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dob-container" },
    [
      _c(
        "v-row",
        { staticClass: "dateOfBirthFields" },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                ref: "dateOfBirthDay",
                staticClass: "centered-input date-field",
                attrs: {
                  id: `${_vm.id}-day`,
                  "data-zuko-id": `${_vm.zukoId}-day`,
                  rules: _vm.rules.day,
                  suffix: "/",
                  placeholder: "DD",
                  type: "tel",
                  maxlength: "2",
                  "data-sentry-mask": true,
                  "validate-on-blur": "",
                },
                on: {
                  keypress: function ($event) {
                    return _vm.validateKeyPress($event, 2)
                  },
                  input: _vm.validateDayInput,
                  blur: _vm.handleDayBlur,
                },
                model: {
                  value: _vm.day,
                  callback: function ($$v) {
                    _vm.day = $$v
                  },
                  expression: "day",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                ref: "dateOfBirthMonth",
                staticClass: "centered-input month-field",
                attrs: {
                  id: `${_vm.id}-month`,
                  "data-zuko-id": `${_vm.zukoId}-month`,
                  rules: _vm.rules.month,
                  placeholder: "MM",
                  type: "tel",
                  maxlength: "2",
                  "data-sentry-mask": true,
                  "validate-on-blur": "",
                },
                on: {
                  keypress: function ($event) {
                    return _vm.validateKeyPress($event, 2)
                  },
                  input: _vm.validateMonthInput,
                  blur: _vm.handleMonthBlur,
                },
                model: {
                  value: _vm.month,
                  callback: function ($$v) {
                    _vm.month = $$v
                  },
                  expression: "month",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                ref: "dateOfBirthYear",
                staticClass: "centered-input year-field",
                attrs: {
                  id: `${_vm.id}-year`,
                  "data-zuko-id": `${_vm.zukoId}-year`,
                  rules: _vm.rules.year,
                  maxlength: "4",
                  prefix: "/",
                  error: _vm.hasError,
                  placeholder: "YYYY",
                  type: "tel",
                  "data-sentry-mask": true,
                  "validate-on-blur": "",
                },
                on: {
                  keypress: function ($event) {
                    return _vm.validateKeyPress($event, 4)
                  },
                },
                model: {
                  value: _vm.year,
                  callback: function ($$v) {
                    _vm.year = $$v
                  },
                  expression: "year",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasError
        ? _c(
            "BaseError",
            {
              staticClass: "error--text",
              attrs: {
                id: `${_vm.id}-errorMessage`,
                "data-zuko-id": `${_vm.zukoId}-errorMessage`,
              },
            },
            [_vm._v(_vm._s(_vm.errorMessage))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }