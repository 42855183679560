export const namespaced = true;

export const state = () => ({
  visible: false,
  cancellable: true,
  title: null,
  body: null,
  button: null,
});

export const getters = {
  isVisible(state) {
    return state.visible;
  },
  isCancellable(state) {
    return state.cancellable;
  },
  title(state) {
    return state.title;
  },
  body(state) {
    return state.body;
  },
  button(state) {
    return state.button;
  },
};

export const mutations = {
  SHOW(state, { cancellable = true, title, body, button }) {
    state.visible = true;
    state.cancellable = cancellable;
    state.title = title;
    state.body = body;
    state.button = button ?? "Close";
  },
  HIDE(state) {
    state.visible = false;
    state.title = null;
    state.body = null;
    state.button = null;
  },
};

export const actions = {
  show({ commit }, { cancellable, title, body, button }) {
    commit("SHOW", { cancellable, title, body, button });
  },
};
