<template>
  <v-card>
    <v-card-text
      class="brandBackgroundLight brandDarkGrey--text text-h6 text-center"
    >
      <slot name="title"></slot>
    </v-card-text>
    <v-card-text class="brandBackgroundLight">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
};
</script>
