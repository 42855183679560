var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vehicle-details-container__vehicle-details-col" },
    [
      _c(
        "div",
        {
          staticClass:
            "registration-header__form-container__registration-input",
        },
        [
          _c("img", {
            staticClass:
              "registration-header__form-container__registration-input__image",
            attrs: {
              src: "/imgs/veh-reg-uk.png",
              alt: "Vehicle Registration Logo",
            },
          }),
          _vm._v(" "),
          _c("v-text-field", {
            staticClass:
              "registration-header__form-container__registration-input__input",
            attrs: {
              value: _vm.value,
              rules: _vm.rules,
              placeholder: "ENTER REG",
              required: "",
              autocomplete: "off",
              id: "gtm--registrationForm-registration-number",
              "data-zuko-id": "zuko--registrationForm-registration-number",
              readonly: _vm.disabled,
            },
            on: {
              input: _vm.onInput,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.emitKeydown()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }