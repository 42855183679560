var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "py-4 mb-2",
      attrs: { color: "brandBackgroundLight", tile: "", flat: "" },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-4 pl-10", attrs: { cols: "1" } },
            [
              _c(
                "v-icon",
                { staticClass: "font-weight-black", attrs: { color: "black" } },
                [_vm._v("mdi-home-variant-outline\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { "data-sentry-mask": true, id: `${_vm.tagId}-details` } },
            [
              _vm.address &&
              _vm.address.address_line_1?.length < 5 &&
              _vm.address.address_line_2?.length
                ? _c(
                    "div",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-bold py-0 wrap-text" },
                        [
                          _vm._v(
                            _vm._s(_vm.address.address_line_1) +
                              "\n          " +
                              _vm._s(_vm.address.address_line_2)
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-bold py-0 wrap-text" },
                        [
                          _vm._v(
                            _vm._s(_vm.address.address_line_1) + "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "font-weight-bold text-body-2 py-0 wrap-text",
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.address.address_line_2)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.address && _vm.address.address_line_3?.length
                ? _c(
                    "v-card-text",
                    {
                      staticClass:
                        "py-0 text-body-2 font-weight-bold wrap-text",
                    },
                    [_vm._v(_vm._s(_vm.address.address_line_3))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.address && _vm.address.address_line_4?.length
                ? _c(
                    "v-card-text",
                    {
                      staticClass:
                        "py-0 text-body-2 font-weight-bold wrap-text",
                    },
                    [_vm._v(_vm._s(_vm.address.address_line_4))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.address && _vm.address.postcode
                ? _c(
                    "v-card-text",
                    { staticClass: "py-0 text-body-2 font-weight-bold" },
                    [_vm._v(_vm._s(_vm.address.postcode))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-row", { staticClass: "d-block px-8 pb-2 mt-0" }, [
        _c("div", { staticClass: "d-flex justify-end" }, [
          _c(
            "a",
            {
              staticClass: "font-weight-bold",
              attrs: {
                id: `${_vm.tagId}-edit`,
                "data-zuko-id": `${_vm.zukoId}-edit`,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("editAddress")
                },
              },
            },
            [_vm._v("Edit address")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }