var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "occupation-container" },
    [
      _c("v-text-field", {
        ref: "occupationField",
        attrs: {
          value: _vm.value.description,
          rules: _vm.rules,
          clearable: "",
          placeholder: _vm.placeholder,
          "validate-on-blur": "",
          "data-zuko-id": _vm.zukoId,
          "error-messages":
            !_vm.hasMatchingOccupation() && _vm.value.description?.length > 2
              ? [`Please retype a valid value for ${this.pronoun} occupation.`]
              : [],
        },
        on: { input: _vm.onInput, "click:clear": _vm.onClear },
      }),
      _vm._v(" "),
      _vm.value.description && _vm.value.description.length && _vm.items.length
        ? _c(
            "div",
            { staticClass: "occupation-results-container" },
            [
              _c(
                "div",
                { staticClass: "mb-2" },
                [
                  _c("FormLabel", [
                    _vm._v("Select an occupation from the list: "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.items, function (occupation, index) {
                return _c(
                  "v-btn",
                  {
                    key: index,
                    staticClass: "brandPrimary brandButtonText--text",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.onSelect(occupation)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(occupation.description) + "\n    "
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }