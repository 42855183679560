var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    ref: "input",
    attrs: {
      value: _vm.value,
      rules: _vm.disabled ? [] : _vm.rules,
      placeholder: "Please enter your email",
      "validate-on-blur": "",
      type: "email",
      "data-zuko-id": _vm.zukoId,
      "data-sentry-mask": true,
      disabled: _vm.disabled,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
      blur: function ($event) {
        return _vm.$emit("triggerMatch")
      },
      paste: function ($event) {
        $event.preventDefault()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }