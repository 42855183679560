<template>
  <v-row>
    <v-col>
      <div>
        <v-select
          v-model="selectedDateTimeHour"
          :items="getDateTimeHours"
          :rules="[(v) => !!v || 'Please select a valid value for hour']"
          placeholder="Hour"
          validate-on-blur
          no-data-text="Please select a date first"
          :id="`${id}-hour`"
          :data-zuko-id="`${dataZukoId}-hour`"
          :menu-props="{ contentClass: `${id}-hour` }"
        ></v-select>
      </div>
    </v-col>
    <v-col>
      <div>
        <v-select
          v-model="selectedDateTimeMinute"
          :items="getDateTimeMinutes"
          :rules="[(v) => !!v || 'Please select a valid value for minute']"
          placeholder="Minute"
          validate-on-blur
          no-data-text="Please select an hour first"
          :id="`${id}-minute`"
          :data-zuko-id="`${dataZukoId}-minute`"
          :menu-props="{ contentClass: `${id}-minute` }"
        ></v-select>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CoverStartTime",
  props: {
    dataZukoId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
