<template>
  <div
    class="licence-details-info-container rounded pa-4 d-flex justify-center"
  >
    <v-icon class="icon mr-4">mdi-card-account-details-outline </v-icon>
    <p class="mb-0">
      We need your driving licence to confirm your identity and driving record.
      <button @click="popout()" tabindex="-1" type="button">
        <b><u>Where is my licence number?</u></b>
      </button>
    </p>
    <LicenceDetailsPopout
      v-if="popoutLoaded"
      :isProvisional="isProvisional"
      @close="popout()"
    />
  </div>
</template>

<script>
export default {
  name: "LicenceDetailsInfoCard",
  props: {
    isProvisional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popoutLoaded: false,
    };
  },
  methods: {
    popout() {
      this.popoutLoaded = !this.popoutLoaded;
    },
  },
};
</script>

<style lang="scss" scoped>
.licence-details-info-container::v-deep {
  background: #707182;
  width: 100%;

  .icon {
    color: white;
    font-size: 2.5rem; // Adjust the size as needed
  }
}
</style>
