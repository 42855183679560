<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card v-if="isDebugMode" class="yellow lighten-5 text-center">
          <v-card-text>
            <div v-show="sessionId">
              <div>
                {{ sessionId }} / {{ clientIp }} / {{ buildTime }} /
                {{ browserId }}
              </div>
              <div>{{ result }}</div>
            </div>
            <v-icon @click="onDebug">mdi-debug-step-into</v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BaseDebugger",
  data: () => ({
    sessionId: null,
    browserId: null,
    clientIp: null,
    buildTime: null,
    result: null,
  }),
  computed: {
    isDebugMode() {
      return this.$config.debug;
    },
  },
  methods: {
    async onDebug() {
      this.sessionId = this.$store.getters["modules/session/sessionId"];
      this.clientIp = await this.$goshorty.getClientIp();
      this.buildTime = this.$config.buildTime;
      this.browserId = this.$browser.get();
    },
  },
};
</script>
