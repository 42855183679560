export default function(to, from, savedPosition) {
      if (process.client) {
        const viewportWidth = window.innerWidth;
        if (viewportWidth < 768) {
          return { x: 0, y: 120 };
        } else if (viewportWidth > 768) {
          return { x: 0, y: 130 };
        } else {
          return { x: 0, y: 168 };
        }
      }
    }
