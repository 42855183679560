const initialState = () => ({
  errorMessage: "",
  errorType: "",
  policyId: "",
  policyReference: "",
  proposerId: "",
  webReference: "",
  activeSchemes: [],
  visitorCounters: {},
  promoText: "",
  isLoading: false,
  selectedQuote: {},
  paymentLink: "",
  paymentStatus: "",
  wrapUpComplete: false,
  wrapUpResult: false,
  wrapUpMessages: [
    "Contacting your insurer to confirm your cover",
    "This could take up to 60 seconds so please bear with us",
    "Visit your.goshorty.co.uk to see all of your quotes and purchases",
    "What movie featured the flux capacitor?",
    "We're rated Excellent on Trustpilot!",
    "Who famously said 'I'll be back'?",
    "Almost there....",
  ],
  wrapUpOtt: "",
});

export const state = initialState;

function convertValuesToString(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = value instanceof Date ? value : String(value);
    return acc;
  }, {});
}

export const getters = {
  hasFilteredSchemes: (state, getters) => !!getters.getFilteredSchemes.length,
  hasPromo: (state) => state.promoText.length > 0,
  hasPaymentLink: (state) => state.paymentLink?.length > 0,
  hasPaymentStatus: (state) => state.paymentStatus?.length > 0,
  hasWrapUpOtt: (state) => state.wrapUpOtt?.length > 0,
  getIsLoading: (state) => state.isLoading,
  getErrorMessage: (state) => state.errorMessage,
  getPaymentLink: (state) => state.paymentLink,
  getPaymentStatus: (state) => state.paymentStatus,
  getWrapUpComplete: (state) => state.wrapUpComplete,
  getWrapUpResult: (state) => state.wrapUpResult,
  getWrapUpMessages: (state) => state.wrapUpMessages,
  getWrapUpOtt: (state) => state.wrapUpOtt,
  getErrorType: (state) => state.errorType,
  getPolicyId: (state) => state.policyId,
  getSelectedQuote: (state) => state.selectedQuote,
  getPolicyReference: (state) => state.policyReference,
  getProposerId: (state) => state.proposerId,
  getWebReference: (state) => state.webReference,
  getActiveSchemes: (state) => state.activeSchemes,
  getActiveSchemesSorted: (state, getters) => {
    return [...getters.getActiveSchemes].sort((a, b) => {
      return (
        a.front_end.premiums.total_to_pay - b.front_end.premiums.total_to_pay
      );
    });
  },
  getVisitorCounters: (state) => state.visitorCounters,
  getPromoText: (state) => state.promoText,
  getIsLearner(state, getters, rootState, rootGetters) {
    return !!rootGetters["journey/isLearnerDriver"];
  },
  getShowUnder21(state, getters, rootState, rootGetters) {
    return rootGetters["journey/showUnder21Questions"];
  },
  getIsVan(state, getters, rootState, rootGetters) {
    return !!rootGetters["modules/vehicledetails/getVehicleIsVan"];
  },
  getConsentSubType(state, getters) {
    const subTypeMap = {
      getIsImpound: "Impound",
      getIsLearner: "Learner",
      getIsVan: "Van",
    };
    for (const key in subTypeMap) {
      if (getters[key]) {
        return subTypeMap[key];
      }
    }
    return "Car";
  },
  getIsImpound(state, getters, rootState, rootGetters) {
    return !!rootGetters["journey/isImpound"];
  },
  getHasAdditionalDriver(state, getters, rootState, rootGetters) {
    return !!rootGetters["journey/hasAdditionalDriver"];
  },
  getIsHours(state, getters, rootState, rootGetters) {
    return rootGetters["modules/coverdetails/isHours"];
  },
  getIsDays(state, getters, rootState, rootGetters) {
    return rootGetters["modules/coverdetails/isDays"];
  },
  getIsWeeks(state, getters, rootState, rootGetters) {
    return rootGetters["modules/coverdetails/isWeeks"];
  },
  getRestrict(state, getters, rootState, rootGetters) {
    return getters.getHasAdditionalDriver ? "NMDDRVRS" : "INSONLY";
  },
  getBusinessMileage(state, getters, rootState, rootGetters) {
    return getters.getIsLearner ? "0" : "1";
  },
  getClassOfUse(state, getters, rootState, rootGetters) {
    if (getters.getIsImpound) {
      return "SDP";
    } else if (getters.getIsLearner) {
      return "SDPexC";
    } else if (
      getters.getIsVan &&
      rootGetters["modules/vehicledetails/getSelectedVehicleUsage"]?.value ===
        "LocalHaul"
    ) {
      return "LocalHaul";
    }
    return "C1";
  },
  getCover(state, getters, rootState, rootGetters) {
    if (getters.getIsImpound) {
      return "TPO";
    } else {
      return "C";
    }
  },
  getDuqs(state, getters, rootState, rootGetters) {
    const r = {};

    let annuallyInsuredResponse = "";
    if (getters.getIsLearner) {
      annuallyInsuredResponse =
        rootGetters[
          "modules/vehicledetails/getSelectedVehicleAnnuallyInsured"
        ] === "Yes"
          ? "Y"
          : "N";
    }
    r.duqsDuq901ShortResponse = annuallyInsuredResponse;

    if (getters.getIsVan) {
      r.duqsDuq64ShortResponse =
        rootGetters["modules/vehicledetails/getVehicleFuel"]?.short || "";
      r.duqsDuq64Response =
        rootGetters["modules/vehicledetails/getVehicleFuel"]?.long || "";

      r.duqsDuq463ShortResponse =
        rootGetters["modules/vehicledetails/getSelectedVehicleOwner"]?.value ??
        "";

      r.duqsDuq372ShortResponse =
        rootGetters["modules/vehicledetails/getSelectedVehicleUsage"]?.value ===
        "SDPC"
          ? "Y"
          : "N";

      r.duqsDuq248ShortResponse = ["SDP", "SDPC"].includes(
        rootGetters["modules/vehicledetails/getSelectedVehicleUsage"]?.value
      )
        ? "Y"
        : "N";

      r.duqsDuq445ShortResponse = "N";

      r.duqsDuq455Response =
        rootGetters["modules/datetimedetails/getSelectedDate"]?.weekday_full ||
        "";
      r.duqsDuq455ShortResponse =
        rootGetters["modules/datetimedetails/getSelectedDate"]
          ?.weekday_indicator || "";

      r.duqsDuq456Response =
        rootGetters["modules/datetimedetails/getSelectedHour"] || "";
      r.duqsDuq456ShortResponse =
        rootGetters["modules/datetimedetails/getSelectedHour"] || "";

      r.duqsDuq457Response = getters.getIsHours
        ? ""
        : (rootGetters["modules/coverdetails/getSelectedCoverLength"]?.hours ||
            0) / 24;

      r.duqsDuq458Response = getters.getIsHours
        ? rootGetters["modules/coverdetails/getSelectedCoverLength"]?.hours +
          "Hour"
        : "";
      r.duqsDuq458ShortResponse = getters.getIsHours
        ? rootGetters["modules/coverdetails/getSelectedCoverLength"]?.hours
        : "";

      r.duqsDuq459Response =
        rootGetters["modules/coverdetails/getSelectedCoverReason"]?.text || "";
      r.duqsDuq459ShortResponse =
        rootGetters["modules/coverdetails/getSelectedCoverReason"]?.value || "";

      r.duqsDuq460Response = "N";

      r.duqsDuq461Response =
        rootGetters["modules/licencedetails/getDuqValue"] || "";

      r.duqsDuq462Response =
        rootGetters["modules/additional/licencedetails/getDuqValue"] || "";

      r.duqsDuq464Response = "0";
    } else {
      r.duqsDuq932Response =
        rootGetters["modules/datetimedetails/getSelectedDate"]?.weekday_full ||
        "";
      r.duqsDuq932ShortResponse =
        rootGetters["modules/datetimedetails/getSelectedDate"]
          ?.weekday_indicator || "";

      r.duqsDuq933Response =
        rootGetters["modules/datetimedetails/getSelectedHour"] || "";
      r.duqsDuq933ShortResponse =
        rootGetters["modules/datetimedetails/getSelectedHour"] || "";

      if (getters.getIsLearner) {
        r.duqsDuq936Response =
          rootGetters["modules/vehicledetails/getSelectedLearnerOwner"]?.text ||
          "";
        r.duqsDuq936ShortResponse =
          rootGetters["modules/vehicledetails/getSelectedLearnerOwner"]
            ?.value || "";
      } else {
        r.duqsDuq936Response =
          rootGetters["modules/coverdetails/getSelectedCoverReason"]?.text ||
          "";
        r.duqsDuq936ShortResponse =
          rootGetters["modules/coverdetails/getSelectedCoverReason"]?.value ||
          "";
      }

      r.duqsDuq934Response = getters.getIsHours
        ? ""
        : `${
            (rootGetters["modules/coverdetails/getSelectedCoverLength"]
              ?.hours || 0) / 24
          }Day`;

      r.duqsDuq934ShortResponse = getters.getIsHours
        ? ""
        : (rootGetters["modules/coverdetails/getSelectedCoverLength"]?.hours ||
            0) / 24;

      r.duqsDuq935Response = getters.getIsHours
        ? rootGetters["modules/coverdetails/getSelectedCoverLength"]?.hours +
          "Hour"
        : "";
      r.duqsDuq935ShortResponse = getters.getIsHours
        ? rootGetters["modules/coverdetails/getSelectedCoverLength"]?.hours
        : "";

      r.duqsDuq938Response =
        rootGetters["modules/licencedetails/getDuqValue"] || "";

      r.duqsDuq939Response =
        rootGetters["modules/additional/licencedetails/getDuqValue"] || "";

      r.duqsDuq940Response = "0";
    }

    return r;
  },
  getProposer(state, getters, rootState, rootGetters) {
    const r = {
      proposerAccessToOtherVehicles: "BLANK",
      proposerConsentForConvictions: "NotKnown",
      proposerConsentForMedicalConditions: "NotKnown",
      proposerDriverType: "Prpsr",
      proposerMotoringOrg: "Blank",
      proposerRelationshipToProposer: "Proposer",
      proposerSmoker: "false",
      proposerTests: "1",
      proposerVehicleUse: "MainUser",
      proposerNonRTAConviction: "false",
      proposerNumberOfOtherVehicles: "0",
      proposerLicenceCategory: "CatB",
      proposerAddressAbodeType: "House",
      proposerAddressCountryCode: "GB",
      proposerAddressUsage: "Main",
      proposerClientStatus: "Prospect",
      proposerTelephoneNumbersTelephoneUsage: "Mobile",
      proposerFullTimeOccupationEmpCode: "904",
      proposerFullTimeOccupationType: "Employee",
      proposerPartTimeOccupationEmpCode: "186",
      proposerPartTimeOccupationOccCode: "220",
      proposerPartTimeOccupationType: "None",
      proposerAddressHouseNameOrNumber:
        rootGetters["modules/personaldetails/getSelectedAddress"]
          ?.address_line_1 || "",
      proposerAddressAddressLine1:
        rootGetters["modules/personaldetails/getSelectedAddress"]
          ?.address_line_2 || "",
      proposerAddressAddressLine2:
        rootGetters["modules/personaldetails/getSelectedAddress"]
          ?.address_line_3 || "",
      proposerAddressPostCode:
        rootGetters["modules/personaldetails/getSelectedAddress"]?.postcode ||
        "",
      proposerUDPRN:
        rootGetters["modules/personaldetails/getSelectedAddress"]?.udprn || "",
      proposerNameForenames:
        rootGetters["modules/personaldetails/getInputFirstName"] || "",
      proposerNameSurname:
        rootGetters["modules/personaldetails/getInputSurname"] || "",
      proposerNameTitle:
        rootGetters["modules/personaldetails/getSelectedTitle"] || "",
      proposerFullTimeOccupationOccCode:
        rootGetters["modules/personaldetails/getInputOccupation"]?.code || "",
      proposerFullTimeOccupationOccDesc:
        rootGetters["modules/personaldetails/getInputOccupation"]
          ?.description || "",
      proposerDateOfBirth:
        rootGetters["modules/personaldetails/getInputDateOfBirth"] || "",
      proposerEmail: rootGetters["modules/personaldetails/getInputEmail"] || "",
      proposerGender: rootGetters["modules/personaldetails/getGender"] || "",
      proposerMaritalStatus:
        rootGetters["modules/personaldetails/getMaritalStatus"] || "",
      proposerTelephoneNumbersTelephonePhoneNumber:
        rootGetters["modules/personaldetails/getInputMobileNumber"],
      proposerLicenceDate: rootGetters["modules/licencedetails/getLicenceDate"],
      proposerLicenceType:
        rootGetters["modules/licencedetails/getSelectedLicenceTransformed"]
          ?.abbr || "",
      proposerLicenceYears:
        rootGetters[
          "modules/licencedetails/getLicenceDurationInYearsAtStartOfPolicy"
        ] || "",
    };

    return r;
  },
  getAdditionalInsured(state, getters, rootState, rootGetters) {
    if (getters.getHasAdditionalDriver) {
      const r = {
        additionalInsuredAccessToOtherVehicles: "BLANK",
        additionalInsuredConsentForConvictions: "NotKnown",
        additionalInsuredConsentForMedicalConditions: "NotKnown",
        additionalInsuredDriverType: "Prpsr",
        additionalInsuredMotoringOrg: "Blank",
        additionalInsuredSmoker: "false",
        additionalInsuredTests: "1",
        additionalInsuredVehicleUse: "MainUser",
        additionalInsuredNonRTAConviction: "false",
        additionalInsuredNumberOfOtherVehicles: "0",
        additionalInsuredLicenceCategory: "CatB",
        additionalInsuredAddressAbodeType: "House",
        additionalInsuredAddressCountryCode: "GB",
        additionalInsuredAddressUsage: "Main",
        additionalInsuredTelephoneNumbersTelephoneUsage: "Mobile",
        additionalInsuredFullTimeOccupationEmpCode: "904",
        additionalInsuredFullTimeOccupationType: "Employee",
        additionalInsuredPartTimeOccupationEmpCode: "186",
        additionalInsuredPartTimeOccupationOccCode: "220",
        additionalInsuredPartTimeOccupationType: "None",
        additionalInsuredAddressHouseNameOrNumber:
          rootGetters["modules/additional/personaldetails/getSelectedAddress"]
            ?.address_line_1 || "",
        additionalInsuredAddressAddressLine1:
          rootGetters["modules/additional/personaldetails/getSelectedAddress"]
            ?.address_line_2 || "",
        additionalInsuredAddressAddressLine2:
          rootGetters["modules/additional/personaldetails/getSelectedAddress"]
            ?.address_line_3 || "",
        additionalInsuredAddressPostCode:
          rootGetters["modules/additional/personaldetails/getSelectedAddress"]
            ?.postcode || "",
        additionalInsuredUDPRN:
          rootGetters["modules/additional/personaldetails/getSelectedAddress"]
            ?.udprn || "",
        additionalInsuredNameForenames:
          rootGetters["modules/additional/personaldetails/getInputFirstName"] ||
          "",
        additionalInsuredNameSurname:
          rootGetters["modules/additional/personaldetails/getInputSurname"] ||
          "",
        additionalInsuredNameTitle:
          rootGetters["modules/additional/personaldetails/getSelectedTitle"] ||
          "",
        additionalInsuredFullTimeOccupationOccCode:
          rootGetters["modules/additional/personaldetails/getInputOccupation"]
            ?.code || "",
        additionalInsuredFullTimeOccupationOccDesc:
          rootGetters["modules/additional/personaldetails/getInputOccupation"]
            ?.description || "",
        additionalInsuredDateOfBirth:
          rootGetters[
            "modules/additional/personaldetails/getInputDateOfBirth"
          ] || "",
        additionalInsuredGender:
          rootGetters["modules/additional/personaldetails/getGender"] || "",
        additionalInsuredMaritalStatus:
          rootGetters["modules/additional/personaldetails/getMaritalStatus"] ||
          "",
        additionalInsuredTelephoneNumbersTelephonePhoneNumber:
          rootGetters[
            "modules/additional/personaldetails/getInputMobileNumber"
          ],
        additionalInsuredLicenceDate:
          rootGetters["modules/additional/licencedetails/getLicenceDate"],
        additionalInsuredLicenceType:
          rootGetters[
            "modules/additional/licencedetails/getSelectedLicenceTransformed"
          ]?.abbr || "",
        additionalInsuredRelationshipToProposer:
          rootGetters[
            "modules/additional/personaldetails/getSelectedRelationship"
          ]?.value || "",
      };

      return r;
    }
    return {};
  },
  getUserFields(state, getters, rootState, rootGetters) {
    const r = {};

    r.userfieldsUserfieldGOIDIPResponse =
      rootGetters["journey/getClientIP"] || "";
    r.userfieldsUserfieldGOIDSTResponse =
      rootGetters["modules/querydetails/getGoidst"] || "";
    r.userfieldsUserfieldGSPROMOResponse =
      rootGetters["modules/querydetails/getGspromo"] || "";
    r.userfieldsUserfieldGSPAR1Response =
      rootGetters["modules/querydetails/getGspar1"] || "";
    r.userfieldsUserfieldGSPAR2Response =
      rootGetters["modules/querydetails/getGspar2"] || "";
    r.userfieldsUserfieldGSDSCOREResponse =
      rootGetters["modules/querydetails/getGsdscore"] || "";
    r.userfieldsUserfieldGSAGENCYResponse =
      rootGetters["modules/querydetails/getGsagency"] || "";
    r.userfieldsUserfieldGSBRANDResponse =
      rootGetters["modules/querydetails/getGsbrand"] || "";
    r.userfieldsUserfieldGSSESSIDResponse =
      rootGetters["modules/session/sessionId"] || "";
    r.userfieldsUserfieldUDPRNResponse =
      rootGetters["journey/getCombinedUDPRN"] || "";
    r.userfieldsUserfieldGOIDCONResponse = rootGetters[
      "modules/vehicledetails/isLearningNotOwnCar"
    ]
      ? rootGetters["modules/vehicledetails/getInputVehicleOwnerCombined"]
      : "";

    if (getters.getIsLearner) {
      r.userfieldsUserfieldGSCVRRSNResponse =
        rootGetters["modules/vehicledetails/getSelectedLearnerOwner"]?.value ||
        "";
    } else {
      r.userfieldsUserfieldGSCVRRSNResponse =
        rootGetters["modules/coverdetails/getSelectedCoverReason"]?.value || "";
    }

    if (
      !getters.getIsVan &&
      rootGetters["modules/vehicledetails/isLearningNotOwnCar"]
    ) {
      r.userfieldsUserfieldGOIDCOAResponse =
        rootGetters["modules/vehicledetails/getSelectedAddress"]?.postcode ||
        "";

      r.userfieldsUserfieldGOIDCOA1Response =
        rootGetters["modules/vehicledetails/getSelectedAddress"]
          ?.address_line_1 || "";

      r.userfieldsUserfieldGOIDCOA2Response =
        rootGetters["modules/vehicledetails/getSelectedAddress"]
          ?.address_line_2 || "";

      r.userfieldsUserfieldGOIDCOA3Response =
        rootGetters["modules/vehicledetails/getSelectedAddress"]
          ?.address_line_3 || "";

      r.userfieldsUserfieldGOIDCOA4Response =
        rootGetters["modules/vehicledetails/getSelectedAddress"]
          ?.address_line_4 || "";
    } else {
      r.userfieldsUserfieldGOIDCOAResponse = "";
      r.userfieldsUserfieldGOIDCOA1Response = "";
      r.userfieldsUserfieldGOIDCOA2Response = "";
      r.userfieldsUserfieldGOIDCOA3Response = "";
      r.userfieldsUserfieldGOIDCOA4Response = "";
    }

    r.userfieldsUserfieldGOIDCOA5Response = "";

    r.userfieldsUserfieldGSRETURNResponse = "No";
    r.userfieldsUserfieldGOIDDLN1Response =
      rootGetters["modules/licencedetails/getLicenceNumber"] || "";
    r.userfieldsUserfieldGOIDDLN2Response = getters.getHasAdditionalDriver
      ? rootGetters["modules/additional/licencedetails/getLicenceNumber"]
      : "";

    r.userfieldsUserfieldGSMULRD1Response =
      rootGetters["journey/getMyLicence"]?.GSMULRD1 ?? "";
    r.userfieldsUserfieldGSMULRD2Response =
      rootGetters["journey/getMyLicence"]?.GSMULRD2 ?? "";
    r.userfieldsUserfieldGSMYLCNTResponse =
      rootGetters["journey/getMyLicence"]?.GSMYLCNT ?? "";
    r.userfieldsUserfieldGSMYLDISResponse =
      rootGetters["journey/getMyLicence"]?.GSMYLDIS ?? "";
    r.userfieldsUserfieldGSMYLPTSResponse =
      rootGetters["journey/getMyLicence"]?.GSMYLPTS ?? "";

    r.userfieldsUserfieldIMPAUTHResponse = getters.getIsImpound ? "Yes" : "No";

    if (getters.getShowUnder21) {
      r.userfieldsUserfieldGSYDRQ1Response =
        rootGetters["modules/personaldetails/getSelectedCarryingPassengers"] ||
        "";
      r.userfieldsUserfieldGSYDRQ2Response =
        rootGetters["modules/personaldetails/getSelectedDrivingHours"] || "";
      r.userfieldsUserfieldGSYDRQ3Response =
        rootGetters["modules/vehicledetails/getSelectedVehicleOwner"]?.text ||
        "";
      r.userfieldsUserfieldGSYDRQ4Response =
        rootGetters["modules/personaldetails/getSelectedDrivingLocations"]
          ?.text || "";
    }

    return r;
  },
  getVehicle(state, getters, rootState, rootGetters) {
    const r = {
      vehicleAlarm: "NONE",
      vehicleImmob: "NONE",
      vehicleKeeper: "true",
      vehicleTracker: "NONE",
      vehicleLhd: "false",
      vehiclePaid: "0",
      vehicleWhereKept: "Driveway",
      vehicleModificationInd: "false",
      vehicleAbs: "true",
    };

    r.vehiclePurchased = `${(
      rootGetters["modules/vehicledetails/getVehicleYearOfManufacture"] || ""
    ).substring(0, 4)}-12-01`;
    r.vehicleManualLookup =
      rootGetters["modules/vehicledetails/getVehicleIsManualLookup"] || "";
    r.vehicleAbiCode =
      rootGetters["modules/vehicledetails/getVehicleAbiCode"] || "";
    r.vehicleAbiProductionYears =
      rootGetters["modules/vehicledetails/getVehicleAbiProductionYears"] || "";
    r.vehicleBodyStyle =
      rootGetters["modules/vehicledetails/getVehicleBodyStyleTransformed"] ||
      "";
    r.vehicleCc = rootGetters["modules/vehicledetails/getVehicleCc"] || "";
    r.vehicleDoors =
      rootGetters["modules/vehicledetails/getVehicleDoorsTransformed"] || "";
    r.vehicleFuel =
      rootGetters["modules/vehicledetails/getVehicleFuel"]?.vehicleFuel || "";
    r.vehicleGrossWeight =
      rootGetters["modules/vehicledetails/getVehicleGrossWeight"] || 0;
    r.vehicleKeptPostcode =
      rootGetters["modules/personaldetails/getSelectedAddress"]?.postcode || "";
    r.vehicleManufacturer =
      rootGetters["modules/vehicledetails/getVehicleManufacturer"] || "";
    r.vehicleModelDescription =
      rootGetters["modules/vehicledetails/getVehicleModelDescription"] || "";
    r.vehicleOwner = rootGetters["modules/vehicledetails/isLearningNotOwnCar"]
      ? "Other"
      : "Proposer";
    r.vehicleSeats =
      rootGetters["modules/vehicledetails/getVehicleSeatsTransformed"] || "";
    r.vehicleTransmission =
      rootGetters["modules/vehicledetails/getVehicleTransmissionTransformed"] ||
      "";
    r.vehicleValue =
      rootGetters["modules/vehicledetails/getVehicleValue"] || "";
    r.vehicleYearOfManufacture =
      rootGetters[
        "modules/vehicledetails/getVehicleYearOfManufactureTransformed"
      ] || "";
    r.vehicleRegNumber =
      rootGetters["modules/vehicledetails/getInputRegistrationNumber"] || "";
    return r;
  },
  getQuoteData(state, getters, rootState, rootGetters) {
    const r = {
      // Defaults:
      do_not_send_step_8: true,
      is_recall: rootGetters["journey/getIsRecall"],
      affinity: "GOID",
      quoteType: "NewQuotTyp",
      totalMileage: "1",
      trailerCover: "TPFT",
      frequency: "Single",
      previousInsuranceNcbAppliedToOtherVehicle: "false",
      previousInsuranceNcbProtectedBonus: "false",
      previousInsuranceNcbYears: "0",
      previousInsurancePreviousPolicyBonusProtected: "false",

      //Referenced:
      domain: rootGetters["journey/getDomain"],
      quoteDate: rootGetters["modules/datetimedetails/getServerTime"].now_zulu,
      startDate:
        rootGetters["modules/datetimedetails/getCalculatedDates"]
          ?.calculated_start_date_zulu,
      endDate:
        rootGetters["modules/datetimedetails/getCalculatedDates"]
          ?.calculated_end_date_zulu,
      policyInceptionDate: rootGetters["journey/getStartDate"],
      previousInsuranceExpiryDate: (
        rootGetters["modules/datetimedetails/getServerTime"].now_iso || ""
      ).substring(0, 10),
      previousInsuranceType: rootGetters["journey/getCoverType"],
      policyTypeCode: rootGetters["journey/getCoverType"] || "",
      currentStep: rootGetters["journey/getCurrentStep"]?.id || "",

      // Calculated:
      restrict: getters.getRestrict,
      businessMileage: getters.getBusinessMileage,
      classOfUse: getters.getClassOfUse,
      cover: getters.getCover,

      // Proposer:
      ...getters.getProposer,

      //Duqs:
      ...getters.getDuqs,

      // UserFields
      ...getters.getUserFields,

      // Vehicle
      ...getters.getVehicle,

      // Helpers:
      webReference: getters.getWebReference || "", // For II
      has_additional_driver: !!rootGetters["journey/hasAdditionalDriver"],
      policy_subtype: rootGetters["journey/getCoverSubType"] || "", // For II
      duration_in_hours:
        rootGetters["modules/coverdetails/getSelectedCoverLength"]?.hours || "",
      humanized_duration:
        rootGetters["modules/coverdetails/getSelectedCoverLength"]?.value || "",

      transCode1: getters.hasPaymentStatus
        ? getters.getPaymentTransCodes.transCode1
        : "",
      transCode2: getters.hasPaymentStatus
        ? getters.getPaymentTransCodes.transCode2
        : "",
    };

    if (getters.getHasAdditionalDriver) {
      Object.assign(r, getters.getAdditionalInsured);
    }

    return convertValuesToString(r);
  },
  getFilteredSchemes(state, getters, rootState, rootGetters) {
    return state.activeSchemes
      .filter(
        (scheme) =>
          !!scheme?.front_end?.premiums &&
          scheme.front_end.premiums.total_to_pay < 99999 &&
          scheme.front_end.include_in_results !== false
      )
      .sort((a, b) =>
        a.front_end.premiums.total_to_pay < b.front_end.premiums.total_to_pay
          ? -1
          : 1
      );
  },
  getPaymentTransCodes(state, getters, rootState, rootGetters) {
    const status = getters.getPaymentStatus.toLowerCase();

    const statusMapping = {
      success: { transCode1: "COMPLETED", transCode2: "AUTHORISED" },
      error: { transCode1: "COMPLETED", transCode2: "ERROR" },
      exception: { transCode1: "COMPLETED", transCode2: "ERROR" },
      failure: { transCode1: "COMPLETED", transCode2: "REFUSED" },
      pending: { transCode1: "PENDING", transCode2: "XXXXXX" },
      cancelled_by_customer: {
        transCode1: "COMPLETED",
        transCode2: "CANCELLED",
      },
      session_expired: { transCode1: "COMPLETED", transCode2: "TIMEOUT" },
    };

    return (
      statusMapping[status] || {
        transCode1: "UNKNOWN",
        transCode2: "STATUSUNKNOWN",
      }
    );
  },
  getAnalyticsPost(state, getters, rootState, rootGetters) {
    return {
      event: "purchase",
      policy_type: rootGetters["journey/getCoverType"],
      sub_policy_type: rootGetters["journey/getCoverSubType"],
      em: rootGetters["modules/personaldetails/getInputEmail"],
      customer_type: getters.getVisitorCounters?.customer_type || "",
      ecommerce: {
        purchase: {
          actionField: {
            id: getters.getWebReference,
            affiliation: "GID website",
            revenue: Math.round(
              (getters.getSelectedQuote?.front_end?.premiums?.admin_fee || 0) *
                100
            ),
            totalPrice: Math.round(
              (getters.getSelectedQuote?.front_end?.premiums?.total_to_pay ||
                0) * 100
            ),
            tax: Math.round(
              (getters.getSelectedQuote?.front_end?.premiums?.ipt || 0) * 100
            ),
          },
          products: [
            {
              name: getters.getSelectedQuote?.front_end?.insurer_name || "",
              id: getters.getPolicyId || "",
              price: Math.round(
                (getters.getSelectedQuote?.front_end?.premiums?.total_to_pay ||
                  0) * 100
              ),
              brand: "GID",
              category: getters.getSelectedQuote?.scheme_code || "",
              variant: getters.getSelectedQuote?.insurer_code || "",
              quantity: 1,
            },
          ],
        },
      },
    };
  },
  getConsentPost(state, getters, rootState, rootGetters) {
    return {
      coverDuration:
        rootGetters[
          "modules/coverdetails/getSelectedCoverLength"
        ]?.value?.split(" ")[0] || "",
      coverDurationType:
        rootGetters[
          "modules/coverdetails/getSelectedCoverLength"
        ]?.value?.split(" ")[1] || "",
      coverReason:
        rootGetters["modules/coverdetails/getSelectedCoverReason"]?.text || "",
      coverStart:
        rootGetters["modules/datetimedetails/getSelectedDate"]?.value || "",
      dob: rootGetters["modules/personaldetails/getInputDateOfBirth"] || "",
      email:
        rootGetters["modules/personaldetails/getInputEmail"]?.toLowerCase() ||
        "",
      emailConsent:
        rootGetters["modules/personaldetails/getInputConsentEmail"] || false,
      firstname: rootGetters["modules/personaldetails/getInputFirstName"] || "",
      lastStatus: getters.getVisitorCounters?.Last_status || "",
      latestQuote: getters.getVisitorCounters?.newest_quote || "",
      latestSale: getters.getVisitorCounters?.newest_sale || "",
      licenceType:
        rootGetters["modules/licencedetails/getSelectedLicenceTransformed"]
          ?.value || "",
      occupation:
        rootGetters["modules/personaldetails/getInputOccupation"]
          ?.description || "",
      phoneNumber:
        rootGetters["modules/personaldetails/getInputMobileNumber"] || "",
      phoneConsent:
        rootGetters["modules/personaldetails/getInputConsentSMS"] || false,
      postcode: rootGetters["modules/personaldetails/getInputPostcode"] || "",
      productType: getters.getIsVan ? "Van" : "Car",
      productSubtype: getters.getConsentSubType,
      sessionId: rootGetters["modules/session/sessionId"] || "",
      source: "Unknown",
      surname: rootGetters["modules/personaldetails/getInputSurname"] || "",
      title: rootGetters["modules/personaldetails/getSelectedTitle"] || "",
      totalQuotes: getters.getVisitorCounters?.count_quotes || 0,
      totalSales: getters.getVisitorCounters?.count_sales || 0,
      webReference: getters.getWebReference,
    };
  },
};

export const mutations = {
  setErrorMessage: (state, value) => {
    state.errorMessage = value;
  },
  setErrorType: (state, value) => {
    state.errorType = value;
  },
  setPolicyId: (state, value) => {
    state.policyId = value;
  },
  setPolicyReference: (state, value) => {
    state.policyReference = value;
  },
  setProposerId: (state, value) => {
    state.proposerId = value;
  },
  setWebReference: (state, value) => {
    state.webReference = value;
  },
  setActiveSchemes: (state, value) => {
    state.activeSchemes = value;
  },
  setVisitorCounters: (state, value) => {
    state.visitorCounters = value;
  },
  setPromoText: (state, value) => {
    state.promoText = value;
  },
  setIsLoading: (state, value) => {
    state.isLoading = value;
  },
  setSelectedQuote: (state, value) => {
    state.selectedQuote = value;
  },
  setPaymentLink: (state, value) => {
    state.paymentLink = value;
  },
  setPaymentStatus: (state, value) => {
    state.paymentStatus = value;
  },
  setWrapUpComplete: (state, value) => {
    state.wrapUpComplete = value;
  },
  setWrapUpResult: (state, value) => {
    state.wrapUpResult = value;
  },
  setWrapUpOtt: (state, value) => {
    state.wrapUpOtt = value;
  },
  resetState: (state) => {
    Object.assign(state, {
      ...initialState(),
    });
  },
};

export const actions = {
  async fetchQuotes({ commit, getters, rootGetters, dispatch }) {
    commit(
      "modules/loader/show",
      {},
      {
        root: true,
      }
    );
    dispatch("journey/changeJourneyIsTweaked", false, { root: true });
    await commit("resetState", {});
    await commit("setIsLoading", true);

    await dispatch(
      "modules/datetimedetails/fetchDateTimes",
      {},
      { root: true }
    );

    const result = await this.$goshorty.quote.get(getters.getQuoteData);
    if (result.success) {
      commit("setErrorMessage", result?.data?.errorMessage || "");
      commit("setErrorType", result?.data?.errorType || "");
      commit("setPolicyId", result?.data?.policyId || "");
      commit("setPolicyReference", result?.data?.policyReference || "");
      commit("setProposerId", result?.data?.proposerId || "");
      commit("setWebReference", result?.data?.webReference || "");
      commit("setActiveSchemes", result?.data?.activeSchemes || []);
      commit("setVisitorCounters", result?.data?.visitorCounters || {});
      commit("setPromoText", result?.data?.promoText || "");

      if (result?.data?.opex) {
        commit("modules/opex/setOpex", result?.data?.opex, { root: true });
      } else {
        commit("modules/opex/resetState", null, { root: true });
      }

      this.$goshorty.insuranceIntelligence.step(
        getters.getQuoteData,
        getters.getActiveSchemesSorted
      );

      this.$goshorty.consent(getters.getConsentPost);
    }

    commit(
      "modules/loader/hide",
      {},
      {
        root: true,
      }
    );
    commit("setIsLoading", false);
  },
  async selectQuote({ commit, getters, rootGetters, dispatch }, { quote }) {
    const chosenQuote = getters.getFilteredSchemes.filter(
      (scheme) =>
        scheme.insurer_code === quote.insurerCode &&
        scheme.scheme_code === quote.schemeCode
    )[0];
    commit("setSelectedQuote", chosenQuote);

    dispatch("modules/datetimedetails/fetchDateTimes", {}, { root: true });

    await this.$goshorty.quote.index.update({
      policyEventReference: chosenQuote.policyEventReference,
    });
  },
  async getPaymentLink({ getters, commit, dispatch, rootGetters }) {
    commit("setPaymentLink", "");

    const Random = Math.floor(Math.random() * 1000000000).toString(16);
    const opexPremiums = rootGetters["modules/opex/getOpexPremiumTotal"];
    const options = {
      orderAmount: this.$formatters.convertPoundToPennies(
        getters.getSelectedQuote?.front_end?.premiums?.total_to_pay +
          opexPremiums
      ),
      opexAmount: this.$formatters.convertPoundToPennies(opexPremiums),
      orderCode: `${getters.getWebReference}_${rootGetters["modules/session/sessionId"]}_${Random}`,
    };
    try {
      const url = await this.$goshorty.checkout.init(options);
      commit("setPaymentLink", url);
    } catch (error) {
      this.errorType = error.message;
      if (this.errorType.match(/tmx/gi)) {
        dispatch("modules/session/abandon", true, { root: true });
      }
    }
  },
  async checkWrapUpStatus({ commit, getters, rootGetters }) {
    const retries = this.$config.wrapUpStatusRetries;
    const interval = this.$config.wrapUpStatusInterval;
    let attempts = 0;

    commit("setWrapUpComplete", false);
    commit("setWrapUpResult", false);
    commit(
      "modules/loader/show",
      {
        headline: "Processing your payment. Do not close your browser.",
      },
      {
        root: true,
      }
    );

    while (attempts < retries) {
      await new Promise((resolve) => setTimeout(resolve, interval * 1000));
      commit(
        "modules/loader/message",
        getters.getWrapUpMessages[attempts % getters.getWrapUpMessages.length],
        {
          root: true,
        }
      );

      const result = await this.$goshorty.wrapUp(getters.getWebReference);
      if (result.complete) {
        commit("setWrapUpResult", result.complete);
        if (result.wrap_up_ott) {
          commit("setWrapUpOtt", result.wrap_up_ott);
        }
        break;
      }
      attempts += 1;
    }

    commit("setWrapUpComplete", true);
    commit("modules/loader/hide", {}, { root: true });
  },
  async updatePaymentStatus({ commit, getters, dispatch }, status) {
    commit("setPaymentStatus", status);
    this.$goshorty.insuranceIntelligence.step({
      ...getters.getQuoteData,
      stepOverride: 13,
    });
    if (["success", "pending"].includes(status)) {
      dispatch("checkWrapUpStatus");
    }
  },
  async postThankYouTracking({ commit, getters, dispatch, rootGetters }) {
    let analytics;

    try {
      analytics = await this.$goshorty.analytics({
        webReference: getters.getWebReference,
      });
    } catch (error) {
      this.$logger.error({
        message: `Error with thank you page API call`,
        data: {
          error: error,
          webReference: getters.getWebReference,
        },
      });
    }

    if (analytics?.thank_you_page_requests === 1) {
      let analyticsSuccess = false;
      try {
        await this.$analytics.purchase(getters.getAnalyticsPost);
        analyticsSuccess = true;
      } catch (error) {
        this.$logger.error({
          message: "Error logging to analytics",
          data: error.message,
        });
      }
      if (analyticsSuccess) {
        try {
          await this.$goshorty.customerindex({
            webReference: getters.getWebReference,
          });
        } catch (error) {
          this.$logger.error({
            message: "Error logging to customerIndex",
            data: error.message,
          });
        }
      }
    }
  },
};
