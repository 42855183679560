<template>
  <v-container class="cover-details-container">
    <FormTitle :class="fullScreen ? 'pb-2' : ''">
      <slot name="title"></slot>
    </FormTitle>
    <v-row v-if="hasSubtitle" justify="center" align="center">
      <v-col cols="12" sm="8" md="6" xl="4">
        <div class="text-h6 text-center">
          <slot name="subtitle"> </slot>
        </div>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      align="center"
      class="mt-0 mt-md-2 text-body-2 text-md-body-1"
      :class="$vuetify.breakpoint.lgAndUp ? 'mx-16' : ''"
    >
      <v-col
        v-if="!fullScreen"
        cols="12"
        sm="12"
        md="8"
        xl="6"
        class="cover-details-container__cover-details-col pt-6"
        :class="{ outline: !noOutline }"
        :style="backgroundColor && { backgroundColor: backgroundColor }"
      >
        <slot></slot>
      </v-col>

      <v-col
        :style="backgroundColor && { backgroundColor: backgroundColor }"
        v-else
        cols="12"
        class="cover-details-container__cover-details-col"
        :class="{ outline: !noOutline }"
      >
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FormContainer",
  props: {
    noOutline: {
      type: Boolean,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
    },
  },
  computed: {
    hasSubtitle() {
      return !!this.$slots.subtitle;
    },
  },
};
</script>

