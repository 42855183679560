<template>
  <div class="vehicle-details-container__vehicle-details-col">
    <div class="registration-header__form-container__registration-input">
      <img
        src="/imgs/veh-reg-uk.png"
        alt="Vehicle Registration Logo"
        class="registration-header__form-container__registration-input__image"
      />
      <v-text-field
        :value="value"
        :rules="rules"
        class="registration-header__form-container__registration-input__input"
        placeholder="ENTER REG"
        required
        @input="onInput"
        autocomplete="off"
        id="gtm--registrationForm-registration-number"
        data-zuko-id="zuko--registrationForm-registration-number"
        @keydown.enter="emitKeydown()"
        :readonly="disabled"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationNumber",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Registration is required."],
  }),
  methods: {
    onInput(event) {
      this.$emit("input", event);
    },
    emitKeydown() {
      this.$emit("enterPressed");
    },
  },
};
</script>

<style lang="scss">
.vehicle-details-container__vehicle-details-col {
  .v-input {
    @media (max-width: 321px) {
      padding: 16px 8px 16px 8px;
    }
    input {
      font-family: "Number Plate", sans-serif;
      font-size: 2.4rem !important;
      border: none !important;
      max-height: 42px;

      @media (min-width: 321px) {
        font-size: 3.2rem !important;
      }

      @media (min-width: 768px) {
        font-size: 3.8rem !important;
      }

      &::placeholder {
        font-size: 2.4rem !important;
        color: black !important;
        -webkit-text-fill-color: black !important;
        @media (min-width: 321px) {
          font-size: 2.8rem !important;
        }

        @media (min-width: 768px) {
          font-size: 3.3rem !important;
        }
      }
    }

    .v-text-field__slot {
      padding: 0;
      background-color: var(--v-brandGold-base);
    }
    .v-messages__message {
      color: red !important;
    }
  }
}
.registration-header {
  background: linear-gradient(122deg, var(--brandBlue) 33%, #4f2f89 72%);
  color: var(--v-white-base);
  text-align: center;
  padding-bottom: 100px;

  h1 {
    font-size: 5vw !important;
  }

  &__form-container {
    position: relative;
    background: rgba(255, 255, 255, 0.4);
    padding: 1rem;
    margin: 0rem auto;
    border-radius: 5px;
    border: 2px solid #375eab;
    min-height: 210px;

    &__registration-input {
      display: flex;
      border: 3px #111 solid;
      border-radius: 7px;
      margin-bottom: 20px;
      max-height: 78px;
      min-height: 78px !important;
      max-width: 336px;

      &__image {
        background-color: #003399;
        padding-right: 4px;
        max-width: 54px;
      }

      &__input {
        width: calc(100% - 40.8px);
        font-size: 3.438rem;
        color: #111;
        font-family: "Number Plate", sans-serif;
        padding: 12px 16px 16px 16px;
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
        border: none;
        background: var(--v-brandGold-base);
        text-align: center;
      }

      &__input::placeholder {
        color: #fff;
      }

      &__input:focus {
        outline: none;
      }
    }

    &__submit {
      color: var(--v-brandPurple-base) !important;
      z-index: 1;
      padding: 1vw 1.6vw !important;
      min-width: 310px;
      border: solid 3px var(--v-brandPurple-base);
      height: 80px !important;
      font-family: "Font Theme DemiBold", serif;
      background-color: var(--v-white-base) !important;
      font-size: 1.5rem !important;
      margin-bottom: 0px !important;
      border-radius: 10px !important;
      font-weight: bolder;
      margin-left: 0px !important;
    }
  }
}

.registration-header__form-container__registration-input__input
  .v-text-field__slot {
  border: none !important;
}

@media (max-width: 411px) {
  .registration-header__form-container__submit {
    padding: 0px !important;
    margin-left: 0px !important;
    min-width: 100% !important;
  }
}

@media (min-width: 411px) {
  .registration-header__form-container__submit {
    padding: 0px !important;
    margin-left: 12px !important;
    max-width: 336px;
    min-width: 336px;
  }
}

@media (min-width: 768px) {
  .registration-header__form-container__registration-input {
    max-width: 336px;
    min-width: 336px;
  }

  .formFlex,
  .regForm_form {
    display: flex !important;
    width: 75%;
    justify-content: center;
  }
}
</style>
