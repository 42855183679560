<template>
  <v-select
    :items="getManualFilteredDescriptions"
    :value="value"
    :rules="rules"
    return-object
    placeholder="Please select"
    @change="$emit('input', $event)"
    ><template v-slot:item="{ item }">
      <div class="w-full py-2 font-weight-black">
        {{ item.details.model }}
        <div class="caption">
          {{
            `${item.details.from}-${item.details.to}, ${item.details.fuel}${
              isManualElectric ? "" : ", " + item.details.engine_size
            }, ${item.details.type}${
              isManualCar
                ? ", " +
                  item.details.transmission +
                  ", " +
                  item.details.doors +
                  " doors"
                : ""
            }`
          }}
        </div>
      </div>
    </template></v-select
  >
</template>

<script>
export default {
  name: "VehicleDescription",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    rules: [
      (v) => !!v || "Please select a valid value for vehicle description.",
    ],
  }),
  mounted() {
    if (this.value) {
      this.$store.dispatch(
        "modules/vehicledetails/setVehicleDetails",
        this.value.details.vehicle_transformed
      );
    }
  },
};
</script>
