<template>
  <div
    class="navigation-container"
    id="gtm--navigation"
    data-zuko-id="zuko--navigation"
  >
    <v-row
      class="grey lighten-2 d-none d-sm-flex header-bar mb-1"
      v-if="!isWhiteLabeled"
    >
      <v-col cols="12">
        <ul class="d-flex flex-row justify-end px-10 pt-2">
          <li v-for="(link, j) of links" :key="j" class="my-1 footer-section">
            <a
              :href="link.link"
              target="_blank"
              class="text-body-1"
              :id="'gtm--' + link.id"
              :data-zuko-id="'zuko--' + link.id"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-container class="px-5 py-8">
      <div class="d-flex justify-space-between align-center">
        <img
          v-if="!isWhiteLabeled"
          class="goshorty-logo"
          src="/imgs/goshorty-logo-master-rgb.svg"
          alt="GoShorty short term vehicle insurance"
        />
        <WhiteLabeledLogo v-else />
        <div class="d-block d-sm-none">
          <v-icon
            class="navigation-bars brandDarkestGrey--text"
            @click="navVisible = !navVisible"
            >mdi-menu
          </v-icon>
        </div>
      </div>
    </v-container>
    <div class="side-menu-container" :class="{ visible: navVisible }">
      <ul>
        <li v-for="(link, j) of links" :key="j" class="my-2 footer-section">
          <a
            :href="link.link"
            target="_blank"
            class="text-decoration-none text-body-1"
            :id="'gtm--' + link.id + '-mobile'"
            :data-zuko-id="'zuko--' + link.id + '-mobile'"
          >
            {{ link.title }}
          </a>
        </li>
      </ul>
      <div class="close-button-container" @click="navVisible = !navVisible">
        <v-icon class="close-button">mdi-close-circle-outline</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseNavigation",
  data() {
    return {
      local: false,
      navVisible: false,
      links: [
        {
          title: "Home",
          link: "https://goshorty.co.uk/",
          appendSocialMedia: true,
          id: "navigation-home",
        },
        {
          title: "FAQs",
          link: "https://goshorty.co.uk/faqs/",
          appendSocialMedia: true,
          id: "navigation-faqs",
        },
        {
          title: "Blog",
          link: "https://goshorty.co.uk/blog/",
          appendSocialMedia: true,
          id: "navigation-blog",
        },
        {
          title: "Contact us",
          link: "https://goshorty.co.uk/contact",
          appendSocialMedia: true,
          id: "navigation-contactUs",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.goshorty-logo {
  max-width: 200px;
  cursor: pointer;
}

.navigation-container {
  border-bottom: 4px solid #2e2e3a;
}

.navigation-bars {
  font-size: 3.5em;
  cursor: pointer;
}

.side-menu-container {
  background: linear-gradient(122deg, #118acb 33%, #522e88 72%);
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  padding: 60px 40px;
  z-index: 1000;
  transition: 0.25s ease-in-out;

  &.visible {
    right: 0;
  }

  ul {
    border-bottom: 1px solid #fff;
    padding-left: 0;
    padding-bottom: 1rem;
  }

  li {
    margin: 2px 0 !important;
    list-style: none;
    font-weight: 900;
    font-family: "Font Theme Bold", sans-serif !important;
  }

  a {
    color: #fff;
    font-size: 1.125rem !important;

    &::after,
    &::before {
      content: none;
    }
  }
}

.header-bar {
  ul {
    li {
      list-style: none;

      &:last-child {
        a::after {
          content: none;
        }
      }
    }

    a {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      --fa-style-family-classic: "Font Awesome 6 Free" !important;
      color: var(--v-journeyText-base);
      font-size: 14px !important;
      font-weight: bold !important;

      &::after {
        content: "  ";
        color: #b2afb7;
        padding: 0 1rem;
      }
    }
  }
}

.close-button-container {
  position: absolute;
  top: 0px;
  right: 0px;
}

.close-button {
  font-size: 2.5em;
  color: #ffffff;
  padding: 20px;
  cursor: pointer;
}
</style>
