<template>
  <div>
    <v-select
      :items="filteredItems"
      :rules="rules"
      :value="value"
      @change="$emit('input', $event)"
      return-object
      placeholder="Please select"
      :data-zuko-id="zukoId"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: "LicenceDurationHeld",
  props: {
    value: {
      type: [Object, String],
      default: () => ({}),
    },
    dob: {
      type: Date,
      required: true,
    },
    pronouns: {
      type: Array,
      default: () => ["you", "your"],
    },
    zukoId: {
      type: String,
      required: true,
    },
  },
  computed: {
    monthsAfter17Birthday() {
      return (
        (this.getJourneyServerDate.getFullYear() - this.dob.getFullYear()) *
          12 +
        this.getJourneyServerDate.getMonth() -
        this.dob.getMonth() -
        204
      );
    },
    filteredItems() {
      return this.items.filter(
        (i) => Number(i.value) <= this.monthsAfter17Birthday
      );
    },
  },
  data() {
    return {
      items: this.$selectors.licence.durationHeld,
      rules: [
        (v) =>
          Object.keys(v).length !== 0 ||
          `Please select a valid value for how long ${this.pronouns[0]} have held ${this.pronouns[1]} licence.`,
      ],
    };
  },
};
</script>
