var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "brandButtonText--text mb-0 mb-sm-6",
      class: _vm.getClasses,
      attrs: {
        block: _vm.block,
        rounded: "",
        id: _vm.id,
        disabled: _vm.disabled,
        loading: _vm.loading,
        "data-zuko-id": _vm.zukoId,
        "max-height":
          _vm.type === "Secondary" && _vm.$vuetify.breakpoint.xs
            ? "37px"
            : "56px",
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.click.apply(null, arguments)
        },
      },
    },
    [
      _vm.icon && _vm.type.includes("Secondary")
        ? _c("v-icon", { staticClass: "mr-2" }, [_vm._v(_vm._s(_vm.icon))])
        : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.text) + "\n  "),
      _vm.icon && _vm.type.includes("Primary")
        ? _c("v-icon", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.icon))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }