<template>
  <BaseCard class="mb-4 confirmation-costs-container">
    <template #title>Details of costs</template>
    <v-row align="center" class="px-0">
      <v-col cols="6" sm="6" lg="4" class="pr-0">
        <div class="costs-title text-body-1 text-md-body-1 font-weight-bold">
          TOTAL TO PAY:
        </div>
      </v-col>
      <v-col cols="6" sm="6" lg="8" class="pl-0 pl-md-2">
        <div
          class="ml-auto text-h5 text-sm-h4 text-md-h5 text-lg-h4 font-weight-bold brandDarkestGrey--text text-right currency-flex"
        >
          {{ getSelectedQuoteTotalToPayWithOpex }}
          <v-icon
            v-show="!isExpanded"
            color="brandPrimary"
            size="3rem"
            @click="isExpanded = !isExpanded"
          >
            mdi-arrow-right-bold-circle
          </v-icon>
          <v-icon
            v-show="isExpanded"
            color="brandPrimary"
            size="3rem"
            @click="isExpanded = !isExpanded"
          >
            mdi-arrow-down-bold-circle
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row v-show="isExpanded" class="mt-0">
      <v-col class="pt-0" cols="12">
        <v-list class="brandBackgroundLight">
          <v-list-item-group v-for="(item, index) of items" :key="index">
            <v-list-item>
              <v-list-item-content :style="{ fontSize: contentFontSize }">
                <v-list-item-title>{{ item.text }}</v-list-item-title>

                <v-list-item-subtitle
                  v-if="hasOpexSelected && index === 0"
                  class="ml-3 font-weight-bold"
                  >{{ item.total }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-content
                v-if="hasOpexSelected"
                :style="{ fontSize: contentFontSize }"
              >
                <v-img v-show="item.img" :src="item.img" max-width="50px" />
                <div v-if="index === 0">Temp. insurance</div>
                <div v-if="selectedOpexBreakdownCover && item.breakdown">
                  Breakdown
                </div>
                <div v-if="selectedExcessProtection && item.excessProtection">
                  Excess protect
                </div>
                <div v-if="selectedLegalExpenses && item.legalExpenses">
                  Legal expenses
                </div>
              </v-list-item-content>

              <v-list-item-content
                align="right"
                :style="{ fontSize: contentFontSize, maxWidth: contentWidth }"
              >
                <div>
                  {{ item.value }}
                </div>
                <div v-if="selectedOpexBreakdownCover && item.breakdown">
                  {{ item.breakdown }}
                </div>
                <div v-if="selectedExcessProtection && item.excessProtection">
                  {{ item.excessProtection }}
                </div>
                <div v-if="selectedLegalExpenses && item.legalExpenses">
                  {{ item.legalExpenses }}
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list-item-group>
          <div class="text-center pt-6">
            Insurance Premium tax (IPT) is included where applicable
          </div>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <QuotePromoBox />
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn
        class="brandPrimary brandButtonText--text"
        id="further-information"
        data-zuko-id="further-information"
        @click="documentsExpanded = !documentsExpanded"
        block
        small
        >{{
          documentsExpanded
            ? "Hide important documents"
            : "Show important documents"
        }}</v-btn
      >
    </v-card-actions>
    <div v-show="documentsExpanded" class="mt-4 mx-2">
      <v-row
        ><p
          class="warning-label text-center py-2 mb-0 mt-1 brandButtonText--text font-weight-black"
        >
          Please, read the documents carefully
        </p></v-row
      >

      <v-row>
        <v-col cols="7" class="black--text px-0"
          >Insurance Product Information Document (IPID)</v-col
        >
        <v-col cols="5" class="d-flex align-center"
          ><v-btn
            class="brandPrimary black--text"
            small
            block
            id="ipid"
            data-zuko-id="ipid"
            @click="showIpid"
            >Click to read</v-btn
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="7" class="black--text px-0">Policy wording</v-col>
        <v-col cols="5" class="d-flex align-center"
          ><v-btn
            class="brandPrimary black--text"
            small
            block
            id="policy-wording"
            data-zuko-id="policy-wording"
            @click="showPolicyWording"
            >Click to read</v-btn
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="7" class="black--text px-0">Terms of business</v-col>
        <v-col cols="5" class="d-flex align-center"
          ><v-btn
            class="brandPrimary black--text"
            small
            block
            id="policy-wording"
            data-zuko-id="policy-wording"
            @click="showTermsOfBusiness"
            >Click to read</v-btn
          ></v-col
        >
      </v-row>
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: "ConfirmationCosts",
  data() {
    return {
      isExpanded: false,
      documentsExpanded: false,
    };
  },
  computed: {
    items() {
      return [
        {
          text: this.hasOpexSelected ? "Premiums" : "Premium",
          value: this.getSelectedQuotePremiumFormatted,
          total: this.getSelectedQuotePremiumWithOpexFormatted,
          breakdown: this.getOpexBreakdownPremiumFormatted,
          excessProtection: this.getOpexExcessProtectionPremiumFormatted,
          legalExpenses: this.getOpexLegalExpensesPremiumFormatted,
        },
        {
          text: "Admin fee",
          value: this.getSelectedQuoteAdminFee,
        },
      ];
    },
    contentFontSize() {
      if (this.hasOpex) {
        if (this.$vuetify.breakpoint.mdOnly) {
          return "16px";
        }
        return "12px";
      } else {
        return "20px";
      }
    },
    contentWidth() {
      if (this.hasOpexSelected) {
        return "50px";
      }
      return "200px";
    },
  },
  methods: {
    showIpid() {
      window.open(this.getSelectedQuoteIPIDUrl, "_blank");
    },
    showPolicyWording() {
      window.open(this.getSelectedQuotePolicyWordingUrl, "_blank");
    },
    showTermsOfBusiness() {
      window.open(this.getSelectedQuoteTobaUrl, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.currency-flex {
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  align-items: center;
}
.no-truncate {
  overflow: visible !important;
  text-overflow: unset !important;
  white-space: unset !important;
}

.confirmation-costs-container::v-deep {
  border-color: #7890b9 !important;
  border-radius: 0px;
  .costs-title {
    color: #000;
    min-width: 100%;

    @media (min-width: 475px) {
      min-width: auto;
    }
  }
  .text-h6 {
    font-weight: bold;
  }
  .warning-label {
    border: 2px solid var(--v-brandSecondary-base);
    border-radius: 8px;
    min-width: 100%;
  }
}
</style>
