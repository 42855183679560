var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BaseButtonToggle", {
        attrs: { items: _vm.items, value: _vm.value, zukoId: _vm.zukoId },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
      _vm._v(" "),
      _vm.hasLicenceCache
        ? _c("FormLabelBox", [
            _c("span", [
              _vm._v(
                "\n      Selecting provisional will change your reason for cover to learning to\n      drive.\n    "
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }