export const namespaced = true;

export const state = () => ({
  currentComponent: "RegistrationForm",
  isWhiteLabeled: false,
  whiteLabeledTheme: {},
});

export const mutations = {
  setVisibility(state, event) {
    state.currentComponent = event;
  },
  setIsWhiteLabeled(state, event) {
    state.isWhiteLabeled = true;
  },
  setWhiteLabeledTheme(state, event) {
    state.whiteLabeledTheme = event;
  },
};

export const getters = {
  getVisibility(state) {
    return state.currentComponent;
  },
  isWhiteLabeled(state) {
    return state.isWhiteLabeled;
  },
  getWhiteLabeledTheme(state) {
    return state.whiteLabeledTheme;
  },
};
