<template>
  <v-select
    :items="$selectors.vehicle.usage"
    :rules="rules"
    :value="value"
    return-object
    placeholder="Please select"
    :data-zuko-id="zukoId"
    @change="$emit('input', $event)"
  ></v-select>
</template>

<script>
export default {
  name: "VehicleUsage",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    rules: [
      (v) =>
        (!!v && !!v.value) || "Please select a valid value for vehicle usage.",
    ],
  }),
};
</script>
