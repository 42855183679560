var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.isDebugMode
                ? _c(
                    "v-card",
                    { staticClass: "yellow lighten-5 text-center" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.sessionId,
                                  expression: "sessionId",
                                },
                              ],
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.sessionId) +
                                    " / " +
                                    _vm._s(_vm.clientIp) +
                                    " / " +
                                    _vm._s(_vm.buildTime) +
                                    " /\n              " +
                                    _vm._s(_vm.browserId) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(_vm.result))]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-icon", { on: { click: _vm.onDebug } }, [
                            _vm._v("mdi-debug-step-into"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }