var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navigation-container",
      attrs: { id: "gtm--navigation", "data-zuko-id": "zuko--navigation" },
    },
    [
      !_vm.isWhiteLabeled
        ? _c(
            "v-row",
            { staticClass: "grey lighten-2 d-none d-sm-flex header-bar mb-1" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "ul",
                  { staticClass: "d-flex flex-row justify-end px-10 pt-2" },
                  _vm._l(_vm.links, function (link, j) {
                    return _c(
                      "li",
                      { key: j, staticClass: "my-1 footer-section" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "text-body-1",
                            attrs: {
                              href: link.link,
                              target: "_blank",
                              id: "gtm--" + link.id,
                              "data-zuko-id": "zuko--" + link.id,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(link.title) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-container", { staticClass: "px-5 py-8" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-space-between align-center" },
          [
            !_vm.isWhiteLabeled
              ? _c("img", {
                  staticClass: "goshorty-logo",
                  attrs: {
                    src: "/imgs/goshorty-logo-master-rgb.svg",
                    alt: "GoShorty short term vehicle insurance",
                  },
                })
              : _c("WhiteLabeledLogo"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-block d-sm-none" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "navigation-bars brandDarkestGrey--text",
                    on: {
                      click: function ($event) {
                        _vm.navVisible = !_vm.navVisible
                      },
                    },
                  },
                  [_vm._v("mdi-menu\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "side-menu-container",
          class: { visible: _vm.navVisible },
        },
        [
          _c(
            "ul",
            _vm._l(_vm.links, function (link, j) {
              return _c("li", { key: j, staticClass: "my-2 footer-section" }, [
                _c(
                  "a",
                  {
                    staticClass: "text-decoration-none text-body-1",
                    attrs: {
                      href: link.link,
                      target: "_blank",
                      id: "gtm--" + link.id + "-mobile",
                      "data-zuko-id": "zuko--" + link.id + "-mobile",
                    },
                  },
                  [_vm._v("\n          " + _vm._s(link.title) + "\n        ")]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "close-button-container",
              on: {
                click: function ($event) {
                  _vm.navVisible = !_vm.navVisible
                },
              },
            },
            [
              _c("v-icon", { staticClass: "close-button" }, [
                _vm._v("mdi-close-circle-outline"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }