var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormLabel", [
        _vm._v(
          _vm._s(
            _vm.hasSelectedAddress
              ? `What is ${_vm.pronoun} home address?`
              : `What is ${_vm.pronoun} home postcode?`
          )
        ),
      ]),
      _vm._v(" "),
      !_vm.hasSelectedAddress
        ? [
            _c("PersonalPostcode", {
              attrs: {
                tagId: _vm.id,
                zukoId: _vm.zukoId,
                loading: _vm.loading,
                pronoun: _vm.pronoun,
              },
              on: { findAddress: _vm.handleFindAddress },
              model: {
                value: _vm.postcode,
                callback: function ($$v) {
                  _vm.postcode = $$v
                },
                expression: "postcode",
              },
            }),
            _vm._v(" "),
            _vm.addressHasSearched
              ? [
                  !_vm.hasAddresses
                    ? _c(
                        "BaseError",
                        {
                          staticClass: "error--text",
                          attrs: { id: `${_vm.id}-error` },
                        },
                        [_vm._v("No addresses found for this postcode")]
                      )
                    : _c("PersonalAddresses", {
                        ref: "addressSelect",
                        attrs: {
                          id: _vm.id,
                          zukoId: _vm.zukoId,
                          pronoun: _vm.pronoun,
                          addresses: _vm.getAddresses,
                        },
                        model: {
                          value: _vm.selectedAddress,
                          callback: function ($$v) {
                            _vm.selectedAddress = $$v
                          },
                          expression: "selectedAddress",
                        },
                      }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.shouldValidate && !_vm.addressHasSearched
              ? [
                  !_vm.hasAddresses
                    ? _c(
                        "BaseError",
                        {
                          staticClass: "error--text",
                          attrs: { id: `${_vm.id}-error` },
                        },
                        [
                          _vm._v(
                            "Please search for and select an address to continue"
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        : _c("PersonalAddressInfoCard", {
            staticClass: "mt-2 mb-4",
            attrs: {
              tagId: _vm.id,
              zukoId: _vm.zukoId,
              address: _vm.getSelectedAddress,
            },
            on: { editAddress: _vm.clearAddress },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }