import axios from "axios";

export default ({ store }, inject) => {
  const config = {
    baseURL: process.env.PROXY_URL,
    debug: !!process.env.DEBUG,
    timeout: process.env.AXIOS_TIMEOUT,
  };
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.request.use((config) => {
    const sessionId = store.getters["modules/session/sessionId"];
    if (sessionId) {
      config.headers["X-SESSION-ID"] = sessionId;
    }
    return config;
  });

  inject("axios", axiosInstance);
};
