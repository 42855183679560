var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    attrs: {
      value: _vm.value,
      rules: _vm.rules,
      "data-zuko-id": _vm.zukoId,
      placeholder: "Vehicle weight in kg or tons",
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }