var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      items: _vm.$selectors.licence.countries,
      rules: _vm.rules,
      value: _vm.value,
      "return-object": "",
      placeholder: "Please select",
      "data-zuko-id": _vm.zukoId,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }