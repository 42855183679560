var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mt-md-4" },
    [
      _c("v-col", { staticClass: "pt-0 pb-6 pb-md-8" }, [
        _c(
          "div",
          {
            ref: "trustpilotRef",
            staticClass: "trustpilot-widget",
            attrs: {
              "data-locale": "en-GB",
              "data-template-id": _vm.dataTemplateId,
              "data-businessunit-id": "601bf83da023a000010814c1",
              "data-style-height": "20px",
              "data-style-width": "100%",
              "data-theme": "light",
            },
          },
          [
            _c(
              "a",
              {
                attrs: {
                  href: "https://uk.trustpilot.com/review/goshorty.co.uk",
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v("\n        Trustpilot\n      ")]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }