<template>
  <div id="progress-bar-wrapper">
    <div :class="{ 'progress-bar-container': isFixed }">
      <v-progress-linear
        :value="value"
        :color="colour"
        height="15"
        rounded
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormProgressBar",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    colour: {
      type: String,
      default: "brandPrimary",
    },
  },
  data() {
    return {
      isFixed: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const element = document.getElementById("progress-bar-wrapper");
      const containerTop = element.getBoundingClientRect().top;

      if (containerTop <= 5) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
};
</script>

<style scoped>
.progress-bar-container {
  background: #e1e1dc;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  padding: 7px;
}
</style>
