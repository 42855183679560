<template>
  <BaseCard class="confirmation-driver-details-container">
    <template #title>Driver Details</template>

    <v-tabs v-show="hasAdditionalDriver" v-model="tab" color="black">
      <v-tab> <v-icon class="mr-2">mdi-account</v-icon> Main </v-tab>
      <v-tab>
        <v-icon class="mr-2">mdi-account-multiple</v-icon> Additional
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(driver, i) of items" :key="i">
        <v-list tile flat class="brandBackgroundLight">
          <v-list-item-group v-for="(item, j) of driver" :key="j">
            <v-list-item disabled v-if="!(i === 1 && item.text === 'Email')">
              <template v-if="item.text === 'Address'">
                <v-list-item-content class="align-start py-0">
                  <v-list-item-title class="confirmation-label">{{
                    item.text
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="item.subtitle"
                    :data-sentry-mask="true"
                  >
                    {{ item.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content class="py-0" :data-sentry-mask="true">
                  <p class="font-weight-bold text-body-2">
                    {{ item.value.address_line_1 }}
                    {{ item.value.address_line_2 }}
                  </p>
                  <p
                    v-if="item.value.address_line_3"
                    class="font-weight-bold text-body-2"
                  >
                    {{ item.value.address_line_3 }}
                  </p>
                  <p
                    v-if="item.value.postcode"
                    class="font-weight-bold text-body-2"
                  >
                    {{ item.value.postcode }}
                  </p>
                </v-list-item-content>
              </template>

              <template v-else-if="item.text === 'Email'">
                <v-list-item-content class="py-0">
                  <v-list-item-title class="confirmation-label">{{
                    item.text
                  }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.subtitle">
                    {{ item.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content class="py-0">
                  <span
                    class="font-weight-bold text-body-2"
                    :data-sentry-mask="true"
                    >{{ item.value }}</span
                  >
                </v-list-item-content>
              </template>

              <template v-else>
                <v-list-item-content class="py-0 d-flex">
                  <v-list-item-title class="confirmation-label">{{
                    item.text
                  }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.subtitle">
                    {{ item.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content class="py-0">
                  <span
                    class="font-weight-bold text-body-2"
                    :data-sentry-mask="true"
                    >{{ item.value }}</span
                  >
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-divider class="mb-1" />
          </v-list-item-group>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </BaseCard>
</template>

<script>
export default {
  name: "ConfirmationDriverDetails",
  props: {
    drivers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    items() {
      let returnArray = [];
      returnArray.push({
        name: `${this.inputProposerPersonalFirstName} ${this.inputProposerPersonalSurname}`,
        dob: this.getProposerDateOfBirthHumanized,
        age: this.getProposerAgeAtStartOfPolicy,
        address: this.getProposerSelectedAddress,
        email: this.inputProposerEmail,
        number: this.inputProposerPersonalMobileNumber,
      });
      if (this.hasAdditionalDriver) {
        returnArray.push({
          name: `${this.inputAdditionalPersonalFirstName} ${this.inputAdditionalPersonalSurname}`,
          dob: this.getAdditionalDateOfBirthHumanized,
          age: this.getAdditionalAgeAtStartOfPolicy,
          address: this.getAdditionalSelectedAddress,
          number: this.inputAdditionalPersonalMobileNumber,
        });
      }
      return returnArray.map((driver) => this.formatDriver(driver));
    },
  },
  methods: {
    formatDriver(driver) {
      return [
        { text: "Driver", value: driver.name },
        {
          text: "Date of birth",
          value: driver.dob,
        },
        { text: "Age", subtitle: " (at start of policy)", value: driver.age },
        { text: "Address", value: driver.address },
        { text: "Email", value: driver.email },
        { text: "Contact number", value: driver.number },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-driver-details-container::v-deep {
  border-color: #7890b9 !important;
  border-radius: 0px;

  .v-slide-group__wrapper {
    background-color: var(--v-brandBackgroundLight-base);
  }
  .v-slide-group__next {
    background-color: var(--v-brandBackgroundLight-base);
  }
  .v-list-item {
    .v-list-item__content {
      align-self: start;
    }
    .v-list-item__title {
      font-family: "Font Theme Regular", sans-serif !important;
    }
    flex-direction: column;
    padding: 0;

    @media (min-width: 475px) {
      flex-direction: row;
    }

    &::after,
    &::before {
      content: none;
    }
  }
  .v-list-item__content {
    min-width: 100%;

    @media (min-width: 475px) {
      min-width: 50%;
    }
  }
  .v-slide-group__prev {
    display: none !important;
  }

  span {
    word-break: break-word;
  }

  .confirmation-label {
    color: #000 !important;
  }

  .text-h6 {
    font-weight: bold;
  }
}
</style>
