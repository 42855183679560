<template>
  <div>
    <v-select
      :items="getDateTimeDates"
      item-value="index"
      :value="value"
      :rules="rules"
      return-object
      :data-zuko-id="zukoId"
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "CoverStartDate",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
    },
  },
  data: () => ({
    rules: [
      (v) =>
        (!!v && !!v.value) || "Please select a valid value for start date.",
    ],
  }),
};
</script>
