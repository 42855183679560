const defaults = require("~/assets/json/defaults.json");
const occupations = require("~/assets/json/occupations.json");
const selectors = require("~/assets/json/selectors.json");

const initialState = () => ({
  addresses: [],
  addressHasSearched: false,
  consentLastUpdated: "",
  consentLoading: false,
  consentChecked: false,
  ...defaults.proposer.personalDetails,
});
export const state = initialState;

function convertDateOfBirth(dob, checkAndCommit) {
  if (dob) {
    const [year, month, day] = dob.split("-");
    checkAndCommit("setInputDateOfBirthDay", day);
    checkAndCommit("setInputDateOfBirthMonth", month);
    checkAndCommit("setInputDateOfBirthYear", year);
    return true;
  } else {
    return false;
  }
}

function findOccupationByCode(code, checkAndCommit) {
  const occupation = occupations.occupation_list.find((o) => o.code === code);
  if (occupation) {
    checkAndCommit("setInputOccupation", occupation);
    return true;
  }
  return false;
}

export const getters = {
  hasAddresses: (state) => !!state.addresses?.length,
  hasSelectedAddress: (state) => !!state.selectedAddress != "",
  hasPreviousConsent: (state) => !!state.consentLastUpdated != "",
  getAddressHasSearched: (state) => state.addressHasSearched,
  getConsentChecked: (state) => state.consentChecked,
  getConsentLastUpdated: (state) => state.consentLastUpdated,
  getConsentLoading: (state) => state.consentLoading,
  getInputConsentSMS: (state) => state.inputConsentSMS,
  getInputConsentEmail: (state) => state.inputConsentEmail,
  getInputDateOfBirthDay: (state) => state.inputDateOfBirthDay,
  getInputDateOfBirthMonth: (state) => state.inputDateOfBirthMonth,
  getInputDateOfBirthYear: (state) => state.inputDateOfBirthYear,
  getInputDateOfBirth: (state) => {
    return `${state.inputDateOfBirthYear}-${state.inputDateOfBirthMonth}-${state.inputDateOfBirthDay}`;
  },
  getInputDateOfBirthHumanized: (state) => {
    return `${state.inputDateOfBirthDay}/${state.inputDateOfBirthMonth}/${state.inputDateOfBirthYear}`;
  },
  getDateOfBirthISO: (state, getters) => new Date(getters.getInputDateOfBirth),
  getInputFirstName: (state) => state.inputFirstName,
  getInputSurname: (state) => state.inputSurname,
  getInputPostcode: (state) => state.inputPostcode,
  getInputOccupation: (state, getters, rootState, rootGetters) => {
    if (rootGetters["journey/isImpound"]) {
      return {
        added: "2022-10-19 15:46:49.733000",
        code: "A01",
        description: "Accountant",
        id: 10,
        strata_id: 13728,
      };
    } else {
      return state.inputOccupation;
    }
  },
  getInputMobileNumber: (state) => state.inputMobileNumber,
  getInputEmail: (state) => state.inputEmail,
  getInputEmailConfirm: (state) => state.inputEmailConfirm,
  getSelectedTitle: (state) => state.selectedTitle,
  getSelectedCarryingPassengers: (state) => state.selectedCarryingPassengers,
  getSelectedDrivingHours: (state) => state.selectedDrivingHours,
  getSelectedDrivingLocations: (state) => state.selectedDrivingLocations,
  getMaritalStatus: (state, getters, rootState, rootGetters) => {
    const status =
      rootGetters["modules/additional/personaldetails/getSelectedRelationship"]
        ?.value ?? "";
    if (/CivPrtnr/i.test(status)) {
      return "V";
    }
    if (/COMSPOUSE/i.test(status)) {
      return "S";
    }
    return "M";
  },
  getGender: (state) => {
    return ["Mr"].includes(state.selectedTitle) ? "M" : "F";
  },
  getAddresses: (state) => state.addresses,
  getSelectedAddress: (state) => state.selectedAddress,
  getSelectedAddressSameAsMainDriver: (state) =>
    state.selectedAddressSameAsMainDriver,
  getLicenceExport: (state, getters) => {
    return {
      firstname: state.inputFirstName,
      surname: state.inputSurname,
      title: state.selectedTitle,
      dob: getters.getInputDateOfBirth,
    };
  },
  getAgeAtStartOfPolicy(state, getters, rootState, rootGetters) {
    const startDateIsoString =
      rootGetters["modules/datetimedetails/getSelectedISO"] ||
      rootGetters["modules/datetimedetails/getServerTime"].now_iso;
    const startDate = new Date(startDateIsoString);

    const dob = getters.getDateOfBirthISO;
    let age = startDate.getFullYear() - dob.getFullYear();

    if (
      startDate.getMonth() < dob.getMonth() ||
      (startDate.getMonth() === dob.getMonth() &&
        startDate.getDate() < dob.getDate())
    ) {
      age--;
    }
    if (isNaN(age) || age < 0) {
      return 0;
    }

    return age;
  },
};

export const mutations = {
  setAddressHasSearched(state, value) {
    state.addressHasSearched = value;
  },
  setInputDateOfBirthDay(state, value) {
    state.inputDateOfBirthDay = value;
  },
  setInputDateOfBirthMonth(state, value) {
    state.inputDateOfBirthMonth = value;
  },
  setInputDateOfBirthYear(state, value) {
    state.inputDateOfBirthYear = value;
  },
  setSelectedTitle(state, value) {
    state.selectedTitle = value;
  },
  setInputFirstName(state, value) {
    state.inputFirstName = value;
  },
  setInputSurname(state, value) {
    state.inputSurname = value;
  },
  setInputPostcode(state, value) {
    state.inputPostcode = value;
  },
  setInputOccupation(state, value) {
    state.inputOccupation = value;
  },
  setAddresses(state, value) {
    state.addresses = value;
  },
  setSelectedAddress(state, value) {
    state.selectedAddress = value;
  },
  setSelectedAddressSameAsMainDriver(state, value) {
    state.selectedAddressSameAsMainDriver = value;
  },
  setSelectedCarryingPassengers(state, value) {
    state.selectedCarryingPassengers = value;
  },
  setSelectedDrivingHours(state, value) {
    state.selectedDrivingHours = value;
  },
  setSelectedDrivingLocations(state, value) {
    state.selectedDrivingLocations = value;
  },
  setInputMobileNumber(state, value) {
    state.inputMobileNumber = value;
  },
  setInputEmail(state, value) {
    state.inputEmail = value?.toLowerCase();
  },
  setInputEmailConfirm(state, value) {
    state.inputEmailConfirm = value?.toLowerCase();
  },
  setInputConsentSMS(state, value) {
    state.inputConsentSMS = value;
  },
  setInputConsentEmail(state, value) {
    state.inputConsentEmail = value;
  },
  setConsentLastUpdated(state, value) {
    state.consentLastUpdated = value;
  },
  setConsentLoading(state, value) {
    state.consentLoading = value;
  },
  setConsentChecked(state, value) {
    state.consentChecked = value;
  },
  resetState: (state) => {
    Object.assign(state, {
      ...initialState(),
    });
  },
};

export const actions = {
  async mapRecall({ commit, dispatch, getters, rootGetters }, riskData) {
    let allFieldsPresent = true;
    const checkAndCommit = (commitName, value) => {
      if (value !== undefined && value !== null) {
        commit(commitName, value);
      } else {
        allFieldsPresent = false;
      }
    };

    checkAndCommit("setSelectedTitle", riskData?.proposerNameTitle);
    checkAndCommit("setInputFirstName", riskData?.proposerNameForenames);
    checkAndCommit("setInputSurname", riskData?.proposerNameSurname);
    checkAndCommit(
      "setInputMobileNumber",
      riskData?.proposerTelephoneNumbersTelephonePhoneNumber
    );
    checkAndCommit("setInputPostcode", riskData?.proposerAddressPostCode);
    checkAndCommit("setInputEmail", riskData?.proposerEmail);
    checkAndCommit("setInputEmailConfirm", riskData?.proposerEmail);

    allFieldsPresent = findOccupationByCode(
      riskData?.proposerFullTimeOccupationOccCode,
      checkAndCommit
    );
    allFieldsPresent = convertDateOfBirth(
      riskData?.proposerDateOfBirth,
      checkAndCommit
    );
    allFieldsPresent = await dispatch(
      "findAndSelectAddress",
      riskData?.userfieldsUserfieldUDPRNResponse?.split(" ")[0]
    );
    const consentStatus = await dispatch("fetchLatestConsent");
    allFieldsPresent = consentStatus.success;

    if (
      rootGetters["journey/isUnder21"] &&
      !rootGetters["journey/isLearnerDriver"]
    ) {
      checkAndCommit(
        "setSelectedCarryingPassengers",
        riskData?.userfieldsUserfieldGSYDRQ1Response
      );
      checkAndCommit(
        "setSelectedDrivingHours",
        riskData?.userfieldsUserfieldGSYDRQ2Response
      );
      checkAndCommit(
        "setSelectedDrivingLocations",
        selectors.under21.drivingLocations.find(
          (l) => l.text === riskData?.userfieldsUserfieldGSYDRQ4Response
        )
      );
    }

    return allFieldsPresent;
  },
  async findAndSelectAddress({ dispatch, getters, commit }, udprnResponse) {
    const addressResult = await dispatch("findAddress");
    if (!addressResult.success) {
      return false;
    }
    commit("setAddressHasSearched", true);

    if (!udprnResponse) {
      return false;
    }

    const address = getters.getAddresses.find((a) => a.udprn === udprnResponse);
    if (!address) {
      return false;
    }

    commit("setSelectedAddress", address);
    return true;
  },
  async findAddress({ commit, state }) {
    try {
      commit("setAddresses", []);
      const response = await this.$goshorty.address(state.inputPostcode.trim());
      commit("setAddresses", response);
      return { success: true, data: response };
    } catch (err) {
      return { success: false, error: err.message };
    }
  },
  clearAddress({ commit, dispatch }) {
    commit("setAddresses", []);
    commit("setSelectedAddress", "");
    commit("setInputPostcode", "");
    commit("setAddressHasSearched", false);

    // Mop up any addresses that may of been set from this:
    dispatch(
      "modules/additional/personaldetails/clearAddress",
      {},
      { root: true }
    );
    dispatch("modules/vehicledetails/clearAddress", {}, { root: true });
    commit(
      "modules/additional/personaldetails/setSelectedAddressSameAsMainDriver",
      "",
      { root: true }
    );
    commit("modules/vehicledetails/setSelectedAddressSameAsMainDriver", "", {
      root: true,
    });
  },
  async fetchLatestConsent({ commit, state }) {
    commit("setConsentLoading", true);
    commit("setConsentChecked", true);
    try {
      const result = await this.$goshorty.emailMatch({
        email: encodeURIComponent(state.inputEmail.toLowerCase()),
        guid: "",
      });

      if (result.matched) {
        commit("setInputConsentSMS", result.optin_sms);
        commit("setInputConsentEmail", result.optin_email);
        commit("setConsentLastUpdated", result.last_updated_humanized);
      } else {
        commit("setInputConsentSMS", false);
        commit("setInputConsentEmail", false);
        commit("setConsentLastUpdated", "");
      }
      commit("setConsentLoading", false);
      return { success: true, data: result };
    } catch (error) {
      return { sucess: false, error: error.message };
    }
  },
};
