var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    {
      attrs: {
        value: _vm.isVisible,
        color: "grey darken-4",
        opacity: _vm.isFirstLoad ? 0.95 : _vm.message ? 0.9 : 0.8,
      },
    },
    [
      _c(
        "v-row",
        [
          _vm.headline
            ? _c(
                "v-col",
                {
                  staticClass: "mx-auto text-center text-h6",
                  attrs: { cols: "10", md: "5" },
                },
                [_vm._v("\n      " + _vm._s(_vm.headline) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "10", md: "3" } },
            [
              _vm.isFirstLoad
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: "",
                      color: "brandPrimary",
                      size: "60",
                    },
                  })
                : _vm.isWhiteLabeled
                ? _c("spinner-unbranded")
                : _c("spinner-branded"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.headline ? _c("v-row") : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _vm.message || _vm.isFirstLoad
            ? _c(
                "v-col",
                {
                  staticClass: "mx-auto text-center font-weight-bold",
                  attrs: { cols: "10", md: "5" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.isFirstLoad ? "Loading..." : _vm.message) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }