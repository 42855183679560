var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.popoutLoaded ? _c("div", { staticClass: "overlay" }) : _vm._e(),
    _vm._v(" "),
    _vm.popoutLoaded
      ? _c(
          "div",
          {
            staticClass:
              "licence-details-popout-container b-white rounded d-flex flex-column px-12 py-6",
          },
          [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_c("v-icon", { staticClass: "icon" }, [_vm._v("mdi-close ")])],
              1
            ),
            _vm._v(" "),
            _c(
              "h4",
              { staticClass: "text-h4 pt-4 text-center font-weight-bold" },
              [_vm._v("\n      Find Your 16 Digit Licence Number\n    ")]
            ),
            _vm._v(" "),
            _vm.isProvisional
              ? _c("nuxt-img", {
                  staticClass: "pr-8 px-sm-0",
                  attrs: {
                    src: "/imgs/driving-licence-helper-provisional.png",
                    alt: "GoShorty provisional driving licence helper",
                  },
                })
              : _c("nuxt-img", {
                  staticClass: "pr-8 px-sm-0",
                  attrs: {
                    src: "/imgs/driving-licence-helper.png",
                    alt: "GoShorty driving licence helper",
                  },
                }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-body-1 font-weight-bold text-center font-weight-bold",
              },
              [
                _vm._v(
                  "\n      We only need the details shown in the highlighted box.\n    "
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }