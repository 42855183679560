<template>
  <v-alert
    prominent
    :type="isMdAndAbove ? 'error' : null"
    color="orange"
    class="text-left mt-3 black--text"
    :class="{
      'px-10': isMdAndAbove,
    }"
  >
    <div
      :class="{
        'pl-10': isMdAndAbove,
      }"
    >
      <h2>{{ getMaintenanceMessages.maintenanceMessage.headline }}</h2>
      <div
        class="pt-3"
        v-html="getMaintenanceMessages.maintenanceMessage.information"
      ></div>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "MaintenanceMessage",
  computed: {
    isMdAndAbove() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>
