import isFinite from "lodash/isFinite";
import { $dayjs } from "~/plugins/dayjs";

const formatters = {
  convertPoundToPennies: (amount) => Math.round(amount * 100),
  currency: (amount) => {
    if (isFinite(amount)) {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(amount);
    }

    return null;
  },

  currencyNoDecimal: (amount) => {
    if (isFinite(amount)) {
      const adjustedAmount = Math.trunc(amount);

      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 0,
      }).format(adjustedAmount);
    }

    return null;
  },

  date: (value) => {
    return $dayjs(value).format("ddd, DD MMM YYYY");
  },

  time: (value) => {
    return $dayjs(value).format("hh:mm A");
  },
  datetime: (value) => {
    return $dayjs(value)
      .tz("Europe/London")
      .format("ddd, DD MMM YYYY [at] h:mm A");
  },
};

export default (context, inject) => {
  inject("formatters", formatters);
};

export { formatters as $formatters };
