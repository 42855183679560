<template>
  <v-row>
    <v-col class="text-center text-body-1 font-weight-bold">
      <div>
        Your policy documents will be sent to:
        <span class="brandSecondary--text">{{ inputProposerEmail }}</span
        >.
      </div>
      <div>
        If you need to make any changes,
        <a
          @click="goToPrevPage"
          id="gtm--confirmationForm-back-link"
          data-zuko-id="zuko--confirmationForm-back-link"
          >Go Back</a
        >
        and make any necessary adjustments. Only click
        <span class="font-italic">Confirm and Continue </span>if all the above
        information is correct.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ConfirmationMessage",
};
</script>
