<template>
  <div :width="this.$vuetify.breakpoint.xs ? '75%' : '95%'" class="mt-n6">
    <v-card class="d-flex flex-column align-start my-4 px-4 pb-4" tile flat>
      <v-row style="width: 100%">
        <RegistrationNumber
          :value="inputRegistrationNumber"
          disabled
          class="reg-container"
        />
        <v-col cols="12" sm="auto" class="d-flex flex-column py-0 py-md-3">
          <v-card-title class="font-weight-bold pt-0 mt-2 px-0 card-detail"
            >{{ getVehicleManufacturer }}
            {{ getVehicleModelDescription }}</v-card-title
          >
          <v-card-subtitle class="font-weight-bold pb-2 px-0"
            >{{ getVehicleAbiProductionYears }},
            {{ getVehicleFuel?.long.toUpperCase() }}, {{ getVehicleCc }}CC
          </v-card-subtitle>
        </v-col>
        <v-col cols="auto" class="d-flex align-end justify-end py-0 py-md-3">
          <a
            color="primary"
            class="text-decoration-underline font-weight-bold px-0 px-md-0 text-body-1 mb-2"
            text
            @click="goToPrevPage"
            id="gtm--coverDetailsForm-change-vehicle"
            data-zuko-id="zuko--coverDetailsForm-change-vehicle"
            >Change Vehicle</a
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "VehicleInfoCard",
};
</script>

<style lang="scss" scoped>
.reg-container::v-deep {
  @media (max-width: 600px) {
    width: 100%;
    position: relative;
    left: 14px;
    top: 30px;
    margin-bottom: 16px;
  }
  @media (min-width: 600px) {
    transform: scale(0.6);
    position: relative;
    right: 50px;
    top: 18px;
  }
  .v-input {
    padding-top: 15px;
    padding-left: 10px;
  }
  .v-text-field__details {
    display: none;
  }
}
.card-detail {
  word-break: break-word;
}
</style>
