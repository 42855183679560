var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "licence-details-info-container rounded pa-4 d-flex justify-center",
    },
    [
      _c("v-icon", { staticClass: "icon mr-4" }, [
        _vm._v("mdi-card-account-details-outline "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "\n    We need your driving licence to confirm your identity and driving record.\n    "
        ),
        _c(
          "button",
          {
            attrs: { tabindex: "-1", type: "button" },
            on: {
              click: function ($event) {
                return _vm.popout()
              },
            },
          },
          [_vm._m(0)]
        ),
      ]),
      _vm._v(" "),
      _vm.popoutLoaded
        ? _c("LicenceDetailsPopout", {
            attrs: { isProvisional: _vm.isProvisional },
            on: {
              close: function ($event) {
                return _vm.popout()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("b", [_c("u", [_vm._v("Where is my licence number?")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }