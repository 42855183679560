const defaults = require("~/assets/json/defaults.json");

const initialState = () => ({
  opex: [],
  opexPremiumTotal: 0,
  opexSending: false,
  ...defaults.opex,
});
export const state = initialState;

export const getters = {
  getOpex: (state) => state.opex,
  getIsOpexSending: (state) => state.opexSending,
  hasBreakdownOpex: (state) => !!state.opex?.GSBDOWN,
  hasExcessReductionOpex: (state) => !!state.opex?.GSEXCESS,
  hasLegalExpensesOpex: (state) => !!state.opex?.GSLEGAL,
  getBreakdownOpex: (state) => state.opex?.GSBDOWN,
  getExcessReductionOpex: (state) => state.opex?.GSEXCESS,
  getLegalExpensesOpex: (state) => state.opex?.GSLEGAL,
  hasSelectedOpex: (state) => {
    return (
      !!state.opex?.GSBDOWN || !!state.opex?.GSEXCESS || !!state.opex?.GSLEGAL
    );
  },
  getSelectedBreakdownCover: (state) => state.selectedBreakdownCover,
  getSelectedExcessProtection: (state) => state.selectedExcessProtection,
  getSelectedLegalExpenses: (state) => state.selectedLegalExpenses,
  getOpexPremiumTotal: (state, getters) => {
    const opexItems = [
      {
        condition: getters.getSelectedBreakdownCover,
        amount: state.opex?.GSBDOWN?.amount || 0,
      },
      {
        condition: getters.getSelectedExcessProtection,
        amount: state.opex?.GSEXCESS?.amount || 0,
      },
      {
        condition: getters.getSelectedLegalExpenses,
        amount: state.opex?.GSLEGAL?.amount || 0,
      },
    ];

    return opexItems.reduce((total, item) => {
      if (item.condition) {
        total += Number((item.amount / 100).toFixed(2));
      }
      return total;
    }, 0);
  },
};

export const mutations = {
  setOpex: (state, value) => {
    state.opex = value;
  },
  setSelectedBreakdownCover: (state, value) => {
    state.selectedBreakdownCover = value;
  },
  setSelectedExcessProtection: (state, value) => {
    state.selectedExcessProtection = value;
  },
  setSelectedLegalExpenses: (state, value) => {
    state.selectedLegalExpenses = value;
  },
  setOpexSending: (state, value) => {
    state.opexSending = value;
  },
  resetState: (state) => {
    Object.assign(state, {
      ...initialState(),
    });
  },
};
export const actions = {
  async mapRecall({ commit, dispatch, getters, rootGetters }, opex) {
    let allFieldsPresent = true;

    const checkAndCommit = (commitName, value) => {
      if (value !== undefined && value !== null) {
        commit(commitName, value);
      } else {
        allFieldsPresent = false;
      }
    };
    checkAndCommit("setSelectedBreakdownCover", opex?.selectedBreakdownCover);
    checkAndCommit(
      "setSelectedExcessProtection",
      opex?.selectedExcessProtection
    );
    checkAndCommit("setSelectedLegalExpenses", opex?.selectedLegalExpenses);

    return allFieldsPresent;
  },
  async sendOpex({ commit, getters, rootGetters }) {
    commit("setOpexSending", true);
    const opex = [
      {
        GSBDOWN: getters.getSelectedBreakdownCover ? "ADDED" : "REMOVED",
        GSEXCESS: getters.getSelectedExcessProtection ? "ADDED" : "REMOVED",
        GSLEGAL: getters.getSelectedLegalExpenses ? "ADDED" : "REMOVED",
      },
    ];
    await this.$goshorty.opexUpdate({
      sessionId: rootGetters["modules/session/sessionId"],
      webReference: rootGetters["quote/getWebReference"],
      opex: opex,
      policyeventreference:
        rootGetters["quote/getSelectedQuote"].policyEventReference,
    });
    commit("setOpexSending", false);
  },
};
