var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", [
        _c(
          "div",
          [
            _c("v-select", {
              attrs: {
                items: _vm.getDateTimeHours,
                rules: [(v) => !!v || "Please select a valid value for hour"],
                placeholder: "Hour",
                "validate-on-blur": "",
                "no-data-text": "Please select a date first",
                id: `${_vm.id}-hour`,
                "data-zuko-id": `${_vm.dataZukoId}-hour`,
                "menu-props": { contentClass: `${_vm.id}-hour` },
              },
              model: {
                value: _vm.selectedDateTimeHour,
                callback: function ($$v) {
                  _vm.selectedDateTimeHour = $$v
                },
                expression: "selectedDateTimeHour",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("v-col", [
        _c(
          "div",
          [
            _c("v-select", {
              attrs: {
                items: _vm.getDateTimeMinutes,
                rules: [(v) => !!v || "Please select a valid value for minute"],
                placeholder: "Minute",
                "validate-on-blur": "",
                "no-data-text": "Please select an hour first",
                id: `${_vm.id}-minute`,
                "data-zuko-id": `${_vm.dataZukoId}-minute`,
                "menu-props": { contentClass: `${_vm.id}-minute` },
              },
              model: {
                value: _vm.selectedDateTimeMinute,
                callback: function ($$v) {
                  _vm.selectedDateTimeMinute = $$v
                },
                expression: "selectedDateTimeMinute",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }