import * as Sentry from "@sentry/vue";

export default async ({ $axios, $browser, $config, store }, inject) => {
  let clientIp;

  const api = {
    sendRequest: async (where, what, retries = 3, delay = 2000) => {
      const sessionId = store.getters["modules/session/sessionId"];

      if (!where || !sessionId || !what) {
        return {
          success: false,
          error: "Missing parameters where, what or session ID.",
        };
      }

      const location = `${process.env.FRONT_END_DOMAIN}api/${where}/`;
      let result = null;
      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          result = await $axios.post(location, what, {
            headers: {
              "X-SESSION-ID": sessionId,
            },
          });

          if (result.data.success) {
            return { success: true, data: result.data.data };
          } else {
            return { success: false, error: result.data.error };
          }
        } catch (error) {
          if (attempt === retries) {
            api.uiexception(where, error.message, error, {
              error,
              request: what,
              response: result,
            });
            return { success: false, error: error.message };
          } else {
            await new Promise((resolve) => setTimeout(resolve, delay));
            delay *= 2;
          }
        }
      }
    },
    getClientIp: async () => {
      let response = null;
      try {
        response = await $axios.get($config.ipLookupURL, {
          timeout: $config.ipLookupTimeout,
        });
        return response.data.ip;
      } catch (error) {
        return "192.168.0.1";
      }
    },
    uiexception: async (tag, message, error, payload) => {
      Sentry.setTag("service", tag);
      Sentry.captureException(error);
      return api.sendRequest("uiexception", {
        category: tag,
        message,
        json_payload: payload,
      });
    },
    /* Old Functions: Need to be converted to run from the server */
    address: async (postcode) => {
      let response = null;
      try {
        response = await $axios.get(
          `/postcode/${postcode}/${store.getters["modules/session/sessionId"]}`
        );
        return response.data.addresses;
      } catch (error) {
        if (!(error.response && error.response.status === 404)) {
          api.uiexception("postcode", error.message, error, {
            error,
            request: postcode,
            response,
          });
        }
        throw error;
      }
    },
    checkout: {
      init: async ({ orderAmount, opexAmount, orderCode }) => {
        const body = {
          order_amount: orderAmount,
          order_code: orderCode,
          session_id: store.getters["modules/session/sessionId"],
        };

        if (opexAmount > 0) {
          body.opex_amount = opexAmount;
        }

        let response = null;
        try {
          response = await $axios.post("/checkout", body);
          if (response.data.errorType) {
            throw new Error(response.data.errorType);
          }

          return response.data.url;
        } catch (error) {
          if (error.message !== "TMX test failed") {
            api.uiexception("checkout", error.message, error, {
              error,
              request: body,
              response,
            });
          }
          throw error;
        }
      },
    },
    consent: async (body) => {
      let response = null;
      try {
        response = await $axios.post(`/consent`, body);
        return response.data;
      } catch (error) {
        api.uiexception("consent", error.message, error, {
          error,
          request: body,
          response,
        });
        throw error;
      }
    },
    consentUpdate: async ({ form }) => {
      const body = {
        email: form.email,
        emailConsent: form.emailConsent,
        phoneConsent: form.phoneConsent,
        sessionId: form.sessionId,
      };
      let response = null;
      try {
        response = await $axios.post(`/consentUpdate`, body);
        return response.data;
      } catch (error) {
        api.uiexception("consentUpdate", error.message, error, {
          error,
          request: body,
          response,
        });
        throw error;
      }
    },
    emailMatch: async ({ email, guid }) => {
      const body = {
        email_address: email,
        guid: guid,
        sessionId: store.getters["modules/session/sessionId"],
      };

      let response = null;
      try {
        response = await $axios.post(`/emailMatch`, body);
        return response.data;
      } catch (error) {
        api.uiexception("emailMatch", error.message, error, {
          error,
          request: body,
          response,
        });
        throw error;
      }
    },
    getWhiteLabelTheme: async (gsBrand, goidst) => {
      let response = null;
      try {
        response = await $axios.get(`/fetchBrand`, {
          params: { gsbrand: gsBrand, goidst: goidst },
        });
        return response.data;
      } catch (error) {
        api.uiexception("fetchBrand", error.message, error, {
          error,
          request: {
            gsbrand: gsBrand,
            goidst,
          },
          response,
        });
        throw error;
      }
    },
    insuranceIntelligence: {
      step: async (payload, activeSchemes) => {
        await store.dispatch("journey/updateClientIp");

        let body = {
          sessionId: store.getters["modules/session/sessionId"],
          clientIp: store.getters["journey/getClientIP"],
          browserId: $browser.get(),
          ...payload,
        };

        if (body.stepOverride) {
          body.currentStep = body.stepOverride;
          delete body.stepOverride;
        }

        body.customer_session_id = body.sessionId;
        body.customer_device_fingerprint = body.browserId;
        body.customer_ip_address = store.getters["journey/getClientIP"];
        body.module = body.policyTypeCode;
        body.customer_surname = body.proposerNameSurname;

        if (activeSchemes) {
          // Might need to sort?!
          activeSchemes.forEach((scheme, index) => {
            const prefix = `quote_${index + 1}_`;

            body[`${prefix}admin_fee`] =
              scheme.front_end.premiums.admin_fee || "";
            body[`${prefix}cover_reason`] =
              body.duqsDuq459ShortResponse ||
              body.duqsDuq936ShortResponse ||
              "";
            body[`${prefix}customer_age`] = scheme.p_Proposer_age;
            body[`${prefix}customer_gender`] = body.proposerGender;
            body[`${prefix}customer_licence_type`] = body.proposerLicenceType;
            body[`${prefix}customer_postcode`] = body.proposerAddressPostCode;
            body[`${prefix}displayed`] = scheme.front_end.include_in_results
              ? 1
              : 0;
            body[`${prefix}duration`] = body.humanized_duration;
            body[`${prefix}duration_hours`] = body.duration_in_hours;
            body[`${prefix}insurer_code`] = scheme.insurer_code;
            body[`${prefix}insurer_description`] =
              scheme.front_end.insurer_name;
            body[`${prefix}licence_years`] = body.proposerLicenceYears;
            body[`${prefix}number_of_drivers`] =
              body.has_additional_driver === "false" ? 1 : 2;
            body[`${prefix}premium`] =
              scheme.front_end.premiums.max_discounted_premium == "99999"
                ? "99999.0"
                : String(scheme.front_end.premiums.max_discounted_premium);
            body[`${prefix}quoted`] = scheme.front_end.include_in_results
              ? 1
              : 0;
            body[`${prefix}quote_reason`] =
              scheme.front_end.no_quote_reason || "Quoted";
            body[`${prefix}scheme_code`] = scheme.scheme_code;
            body[`${prefix}scheme_description`] = scheme.names.strata_name;
            body[`${prefix}vehicle_make`] = body.vehicleManufacturer;
            body[`${prefix}vehicle_model`] = body.vehicleModelDescription;
            body[`${prefix}vehicle_value`] = body.vehicleValue;
            body[`${prefix}vehicle_year`] = body.vehicleYearOfManufacture;
          });
        }

        let response = null;
        try {
          response = await $axios.post("/insurance-intelligence/step", body);
          return response.data;
        } catch (error) {
          api.uiexception("insuranceIntelligence", error.message, error, {
            error,
            request: body,
            response,
          });
          throw error;
        }
      },
    },
    licence: async ({
      policyHolderDLN,
      policyHolderDeclaredLicenceType,
      policyInceptionDate,
      namedDriverDLN,
      namedDriverDeclaredLicenceType,
      policyHolderOccupation,
    }) => {
      const body = {
        policyHolderDLN,
        policyHolderDeclaredLicenceType,
        policyInceptionDate,
        policyHolderOccupation,
      };

      if (namedDriverDLN) {
        body.namedDriverDLN = namedDriverDLN;
      }

      if (namedDriverDeclaredLicenceType) {
        body.namedDriverDeclaredLicenceType = namedDriverDeclaredLicenceType;
      }

      let response = null;
      try {
        response = await $axios.post("/myLicence/", body);
        return response.data;
      } catch (error) {
        api.uiexception("myLicence", error.message, error, {
          error,
          request: body,
          response,
        });
        throw error;
      }
    },
    logger: async ({ level, message, data }) => {
      const body = {
        data,
        debugLevel: level,
        domain: $config.domain,
        ipAddress: clientIp,
        message,
        sessionId: store.getters["modules/session/sessionId"],
      };

      try {
        await $axios.post("/logger", body);
        return true;
      } catch (error) {
        Sentry.setTag("service", `/logger`);
        Sentry.captureException(error);
        return false;
      }
    },
    quote: {
      get: async (quoteRequest) => {
        quoteRequest.session_id = store.getters["modules/session/sessionId"];
        quoteRequest.browserId = $browser.get();

        let response = null;
        try {
          response = await $axios.post("/quote", quoteRequest);
          return { success: true, data: response.data };
        } catch (error) {
          api.uiexception("quote", error.message, error, {
            error,
            request: quoteRequest,
            response,
          });
          return { success: false };
        }
      },
      index: {
        update: async ({ policyEventReference }) => {
          const browserId = $browser.get();

          let response = null;
          try {
            response = await $axios.put(
              `/quote/index/${store.getters["modules/session/sessionId"]}`,
              {
                policyEventReference,
                browserId,
              }
            );

            return response.data;
          } catch (error) {
            api.uiexception("quoteIndex", error.message, error, {
              error,
              request: {
                policyEventReference,
                browserId,
              },
              response,
            });
            throw error;
          }
        },
      },
    },
    recall: async ({ webReference, dob, extend }) => {
      const sessionId = store.getters["modules/session/sessionId"];

      let response = null;
      try {
        response = await $axios.get(
          `/recall/${webReference}/${dob}/${sessionId}/${
            extend ? "extend" : "donotextend"
          }`
        );

        return { proposalForm: response.data, recalled: response.data };
      } catch (error) {
        if (!(error.response && error.response.status === 404)) {
          api.uiexception("recall", error.message, error, {
            error,
            request: {
              webReference,
              dob,
              sessionId,
              extend,
            },
            response,
          });
        }
        throw error;
      }
    },
    recallott: async ({ ott }) => {
      const sessionId = store.getters["modules/session/sessionId"];
      const body = {
        sessionId,
        ott,
      };
      let response = null;
      try {
        response = await $axios.post(`/recallOtt`, body);
        return response.data;
      } catch (error) {
        api.uiexception("recallOtt", error.message, error, {
          error,
          request: body,
          response,
        });
        throw error;
      }
    },
    analytics: async ({ webReference }) => {
      const body = {
        session_id: store.getters["modules/session/sessionId"],
        webReference,
      };

      let response = null;
      try {
        response = await $axios.post(`/googleAnalytics`, body);
        return response.data;
      } catch (error) {
        api.uiexception("googleAnalytics", error.message, error, {
          error,
          request: body,
          response,
        });
        throw error;
      }
    },
    customerindex: async ({ webReference }) => {
      const body = {
        session_id: store.getters["modules/session/sessionId"],
        webReference,
      };

      let response = null;
      try {
        response = await $axios.post(`/customerIndex`, body);
        return response.data;
      } catch (error) {
        api.uiexception("customerIndex", error.message, error, {
          error,
          request: body,
          response,
        });
        throw error;
      }
    },
    vehicle: {
      registration: async (reg) => {
        let response = null;
        try {
          Sentry.setTag("registration", reg.toUpperCase());
          response = await $axios.get(`/vehicle/${reg}`);
          return response.data;
        } catch (error) {
          if (
            !error.response ||
            (error.response.status !== 404 &&
              error.response.status !== 400 &&
              error.response.status !== 421)
          ) {
            api.uiexception("vehicleRegistration", error.message, error, {
              error,
              request: reg,
              response,
            });
          }

          throw error;
        }
      },
      make: async () => {
        let response = null;
        try {
          response = await $axios.get(
            `/vehicles/make/${store.getters["modules/session/sessionId"]}`
          );
          return response.data.vehicle_manufacturer_list;
        } catch (error) {
          api.uiexception("vehicleMake", error.message, error, {
            error,
            request: {},
            response,
          });
          throw error;
        }
      },
      model: async (make) => {
        const body = {
          sessionID: store.getters["modules/session/sessionId"],
          manufacturer: make,
        };

        let response = null;
        try {
          response = await $axios.post(`/vehicles/make/`, body);
          return response.data.vehicle_model_list;
        } catch (error) {
          api.uiexception("vehicleModel", error.message, error, {
            error,
            request: body,
            response,
          });
          throw error;
        }
      },
      description: async (make, model) => {
        const body = {
          sessionID: store.getters["modules/session/sessionId"],
          manufacturer: make,
          model: model,
        };
        let response = null;
        try {
          response = await $axios.post(`/vehicles/description/`, body);
          const filters = { ...response.data.filters };
          const result = response.data.vehicle_list_for_ui.map((item) => {
            let output = {
              value: item.abi_code,
              details: item,
              text: item.model + " (" + item.from + "-" + item.to + ")",
            };
            return output;
          });
          return { descriptions: result, filters };
        } catch (error) {
          api.uiexception("vehicleDescription", error.message, error, {
            error,
            request: body,
            response,
          });
          throw error;
        }
      },
    },
    wrapUp: async (webReference) => {
      let response = null;
      try {
        response = await $axios.get(`/wrap-up/${webReference}`);
        return response.data;
      } catch (error) {
        api.uiexception("wrapUp", error.message, error, {
          error,
          request: webReference,
          response,
        });
        return { success: false };
      }
    },
    opexUpdate: async ({
      sessionId,
      webReference,
      opex,
      policyeventreference,
    }) => {
      const body = {
        sessionId,
        webReference,
        opex,
        policyeventreference,
      };
      let response = null;
      try {
        response = await $axios.post(`/opexUpdate`, body);
        return response.data;
      } catch (error) {
        api.uiexception("opexUpdate", error.message, error, {
          error,
          request: body,
          response,
        });
        throw error;
      }
    },
  };
  inject("goshorty", api);
  return api;
};
