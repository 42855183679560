<template>
  <div>
    <BaseButtonToggle
      v-if="getManualModels.length < 5"
      :value="value"
      :items="getManualModels"
      :zukoId="zukoId"
      :wrapButtons="getManualModels.length > 3 && !isMobile"
      :wrapAll="isMobile"
      @input="$emit('input', $event)"
    />
    <v-autocomplete
      v-else
      :items="getManualModels"
      :value="value"
      :rules="rules"
      return-object
      placeholder="Please select"
      :data-zuko-id="zukoId"
      @change="$emit('input', $event)"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "VehicleModel",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Please select a valid value for vehicle model."],
  }),
};
</script>
