var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        {
          staticClass:
            "brandBackgroundLight brandDarkGrey--text text-h6 text-center",
        },
        [_vm._t("title")],
        2
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "brandBackgroundLight" },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }