import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _243e1a4f = () => interopDefault(import('../pages/acceptance.vue' /* webpackChunkName: "pages/acceptance" */))
const _5dd21d2d = () => interopDefault(import('../pages/confirmation.vue' /* webpackChunkName: "pages/confirmation" */))
const _9bd14c38 = () => interopDefault(import('../pages/cover-details.vue' /* webpackChunkName: "pages/cover-details" */))
const _6d43297e = () => interopDefault(import('../pages/licence-details.vue' /* webpackChunkName: "pages/licence-details" */))
const _5ac592ce = () => interopDefault(import('../pages/manual-lookup.vue' /* webpackChunkName: "pages/manual-lookup" */))
const _5c7d3684 = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _5565270a = () => interopDefault(import('../pages/payment-issue.vue' /* webpackChunkName: "pages/payment-issue" */))
const _2d9176f4 = () => interopDefault(import('../pages/payment-success.vue' /* webpackChunkName: "pages/payment-success" */))
const _08521cad = () => interopDefault(import('../pages/personal-details.vue' /* webpackChunkName: "pages/personal-details" */))
const _00a2fc0c = () => interopDefault(import('../pages/quote-recall.vue' /* webpackChunkName: "pages/quote-recall" */))
const _17e44d22 = () => interopDefault(import('../pages/quotes.vue' /* webpackChunkName: "pages/quotes" */))
const _6aeb7009 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _2cba0a7d = () => interopDefault(import('../pages/additional-driver/licence-details.vue' /* webpackChunkName: "pages/additional-driver/licence-details" */))
const _37b75b8e = () => interopDefault(import('../pages/additional-driver/personal-details.vue' /* webpackChunkName: "pages/additional-driver/personal-details" */))
const _69d25d8a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/acceptance",
    component: _243e1a4f,
    name: "acceptance"
  }, {
    path: "/confirmation",
    component: _5dd21d2d,
    name: "confirmation"
  }, {
    path: "/cover-details",
    component: _9bd14c38,
    name: "cover-details"
  }, {
    path: "/licence-details",
    component: _6d43297e,
    name: "licence-details"
  }, {
    path: "/manual-lookup",
    component: _5ac592ce,
    name: "manual-lookup"
  }, {
    path: "/payment",
    component: _5c7d3684,
    name: "payment"
  }, {
    path: "/payment-issue",
    component: _5565270a,
    name: "payment-issue"
  }, {
    path: "/payment-success",
    component: _2d9176f4,
    name: "payment-success"
  }, {
    path: "/personal-details",
    component: _08521cad,
    name: "personal-details"
  }, {
    path: "/quote-recall",
    component: _00a2fc0c,
    name: "quote-recall"
  }, {
    path: "/quotes",
    component: _17e44d22,
    name: "quotes"
  }, {
    path: "/unsubscribe",
    component: _6aeb7009,
    name: "unsubscribe"
  }, {
    path: "/additional-driver/licence-details",
    component: _2cba0a7d,
    name: "additional-driver-licence-details"
  }, {
    path: "/additional-driver/personal-details",
    component: _37b75b8e,
    name: "additional-driver-personal-details"
  }, {
    path: "/",
    component: _69d25d8a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
