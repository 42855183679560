<template>
  <v-row>
    <v-col cols="6" sm="8" md="7" class="pr-2"
      ><v-text-field
        :value="value"
        :rules="rules"
        :id="`${tagId}-input`"
        :data-zuko-id="`${zukoId}-input`"
        @input="$emit('input', $event)"
        @blur="handleFindAddress()"
        placeholder="Postcode"
        :data-sentry-mask="true"
      ></v-text-field
    ></v-col>
    <v-col
      cols="6"
      sm="4"
      md="5"
      :class="this.$vuetify.breakpoint.xs && 'pb-4'"
      class="d-flex flex-column align-center pl-0"
    >
      <v-btn
        rounded
        large
        block
        :loading="loading"
        class="mt-2 find-address-btn brandPrimary brandButtonText--text"
        max-width="100%"
        :id="`${tagId}-btn`"
        :data-zuko-id="`${zukoId}-btn`"
        @click="handleFindAddress"
      >
        <span class="brandButtonText--text text-h6"
          ><v-icon v-show="showIcon" class="pb-1 btn-icon">mdi-magnify </v-icon
          >Find address</span
        >
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PersonalPostcode",
  props: {
    tagId: {
      type: String,
    },
    zukoId: {
      type: String,
    },
    value: {
      type: String,
    },
    pronoun: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          this.validatePostcode(v) ||
          `Please enter a valid value for ${this.pronoun} postcode.`,
      ],
    };
  },
  computed: {
    showIcon() {
      return this.$vuetify.breakpoint.width >= 380;
    },
  },
  methods: {
    handleFindAddress() {
      if (this.validatePostcode(this.value)) {
        this.$emit("findAddress");
      }
    },
    validatePostcode(v) {
      const postcodeRegex =
        /^\s*(([A-Z]{1,2}\d[A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)\s*$/i;
      return postcodeRegex.test(v);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-error-msg {
  font-size: 12px;
  color: #ff7c5c;
}
.find-address-btn {
  @media only screen and (max-width: 425px) {
    max-height: 44px;
  }
  @media only screen and (min-width: 425px) {
    max-height: 56px;
  }
}
</style>
