import { render, staticRenderFns } from "./Email.vue?vue&type=template&id=3b244668&"
import script from "./Email.vue?vue&type=script&lang=js&"
export * from "./Email.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/buy.v2.goshorty.co.uk/httpdocs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b244668')) {
      api.createRecord('3b244668', component.options)
    } else {
      api.reload('3b244668', component.options)
    }
    module.hot.accept("./Email.vue?vue&type=template&id=3b244668&", function () {
      api.rerender('3b244668', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/personal/Email.vue"
export default component.exports