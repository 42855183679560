<template>
  <v-text-field
    :value="value"
    :rules="disabled ? [] : rules"
    @input="$emit('input', $event)"
    @blur="$emit('triggerMatch')"
    placeholder="Please enter your email"
    ref="input"
    validate-on-blur
    @paste.prevent
    type="email"
    :data-zuko-id="zukoId"
    :data-sentry-mask="true"
    :disabled="disabled"
  ></v-text-field>
</template>

<script>
export default {
  name: "PersonalEmail",
  props: {
    value: {
      type: String,
      default: () => null,
    },
    zukoId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rules() {
      return [
        (v) => !!v || "Please enter a valid value for your email.",
        (v) =>
          /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) ||
          "Please enter a valid value for your email.",
      ];
    },
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event);
      this.checkValidation();
    },
    checkValidation() {
      const isValid = this.rules.every((rule) => rule(this.value) === true);
      this.$emit("valid", isValid);
    },
  },
  watch: {
    value() {
      this.checkValidation();
    },
  },
};
</script>
