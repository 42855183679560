export default ({ $gtm }, inject) => {
  const api = {
    purchase: async (payload) => {
      $gtm.push(payload);
      return payload;
    },
  };

  inject("analytics", api);
  return api;
};
