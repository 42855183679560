var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      items: _vm.getManualFilteredDescriptions,
      value: _vm.value,
      rules: _vm.rules,
      "return-object": "",
      placeholder: "Please select",
    },
    on: {
      change: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "w-full py-2 font-weight-black" }, [
              _vm._v("\n      " + _vm._s(item.details.model) + "\n      "),
              _c("div", { staticClass: "caption" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      `${item.details.from}-${item.details.to}, ${
                        item.details.fuel
                      }${
                        _vm.isManualElectric
                          ? ""
                          : ", " + item.details.engine_size
                      }, ${item.details.type}${
                        _vm.isManualCar
                          ? ", " +
                            item.details.transmission +
                            ", " +
                            item.details.doors +
                            " doors"
                          : ""
                      }`
                    ) +
                    "\n      "
                ),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }