var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.getCoverShouldShowOtherLengths || _vm.isTweak
        ? _c("CoverLengthSelection", {
            attrs: {
              value: _vm.value,
              "cover-length-items": _vm.fullItems,
              id: _vm.id,
              zukoId: _vm.zukoId,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          })
        : _c("CoverLengthButtons", {
            attrs: {
              value: _vm.value,
              "cover-length-items": _vm.buttonList,
              id: _vm.id,
              zukoId: _vm.zukoId,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }