<template>
  <v-dialog
    :value="isWarningVisible"
    overlay-opacity="0.8"
    fullscreen
    persistent
  >
    <v-row class="pt-10">
      <v-col cols="11" md="6" class="mx-auto" align="center">
        <v-card>
          <v-toolbar
            color="#dd2c00"
            class="text-h6 white--text font-weight-bold"
          >
            Are you still there?
            <v-spacer />
          </v-toolbar>

          <v-card-text
            class="blue--text text--darken-2 text-h5 font-weight-bold text-center mt-2"
          >
            You’re so close… but for security reasons your session will reset at
            {{ resetTime }}
            <p>Complete your quote before then and be on your way!</p>
          </v-card-text>
          <v-card-actions class="justify-center pb-8">
            <a
              @click="onContinue"
              class="button purple--text text--darken-4 text-h5 font-weight-bold px-10"
              color="white"
            >
              Continue
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseSessionModal",
  computed: {
    isWarningVisible: {
      get() {
        return this.$store.getters["modules/session/isWarningVisible"];
      },
      set(value) {
        this.$store.commit("modules/session/isWarningVisible", value);
      },
    },
    resetTime() {
      return this.$store.getters["modules/session/resetTime"];
    },
  },
  methods: {
    onContinue() {
      this.isWarningVisible = false;
      this.$store.dispatch("modules/session/extend");
    },
  },
};
</script>

<style scoped>
.button {
  font-family: "Font Theme Serif Bold", serif;
  background: var(--v-white-base);
  color: var(--v-brandText-base);
  position: relative;
  border-radius: 5rem;
  background-clip: padding-box;
  border: solid 2px var(--v-brandText-base);
  font-weight: bold;
  font-size: 1rem;
  padding: 0.8rem 1.2rem;
  margin-top: 10px;
}
</style>
