<template>
  <v-app light :style="cssProps">
    <v-main v-if="!pageLoading">
      <BaseNavigation />
      <v-container ref="topOfPage">
        <FormProgressBar :value="getCurrentProgress" />
        <v-row>
          <v-col>
            <TrustpilotWidget v-if="!isWhiteLabeled" />
          </v-col>
        </v-row>
        <MaintenanceWarningBar v-if="isMaintenanceWarning" />
        <MaintenanceMessage v-if="isMaintenance" />
        <v-row class="mt-0 mt-md-2">
          <v-col class="pt-0 pt-md-2"
            ><Nuxt v-if="!isMaintenanceClosed"
          /></v-col>
        </v-row>
      </v-container>
      <BaseFooter />
    </v-main>
    <BaseLoader />
    <BaseDebugger />
    <BaseModal />
    <BaseSessionModal />
  </v-app>
</template>

<script>
export default {
  name: "DefaultLayout",
  data: () => ({
    isLoading: true,
  }),
  async mounted() {
    try {
      await this.$store.dispatch("modules/session/start");
      await this.$store.dispatch(
        "modules/maintenancedetails/updateMaintenance"
      );
      await this.$store.dispatch("modules/datetimedetails/fetchDateTimes");
      await this.runBrandCheck();
      await this.$store.dispatch("journey/updateClientIp");

      this.$store.dispatch(
        "modules/querydetails/updateQueryString",
        this.$route.query
      );
      this.$store.commit("journey/setDomain", this.$config.domain);
      const sessionId = this.$store.getters["modules/session/sessionId"];
      this.$threatMatrix.initialize(sessionId);

      document.addEventListener("click", this.doInteraction);
      document.addEventListener("keydown", this.doInteraction);

      this.isLoading = false;
      this.$store.commit("journey/setJourneyIsLoading", false);
      this.$store.commit("modules/loader/hide");
    } catch (error) {
      console.error("Error during mounted lifecycle hook:", error);
    }

    if (!this.$route?.path?.includes("quote-recall")) {
      this.$goshorty.insuranceIntelligence.step(this.getQuoteData);
    }

    this.$nextTick(() => {
      const viewportWidth = window.innerWidth;
      if (viewportWidth < 768) {
        window.scrollTo(0, 120);
      } else if (viewportWidth > 768) {
        window.scrollTo(0, 130);
      } else {
        window.scrollTo(0, 168);
      }
    });
  },
  computed: {
    isMaintenanceClosed() {
      return this.$store.getters[
        "modules/maintenancedetails/isMaintenanceClosed"
      ];
    },
    isMaintenanceWarning() {
      return this.$store.getters[
        "modules/maintenancedetails/isMaintenanceWarning"
      ];
    },
    isMaintenance() {
      return this.$store.getters["modules/maintenancedetails/isMaintenance"];
    },
    cssProps() {
      const themeColours = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColours[`--v-${color}-base`] =
          this.$vuetify.theme.themes.light[color];
      });
      return themeColours;
    },
    getCurrentProgress() {
      return this.$store.getters["journey/getCurrentProgress"];
    },
    pageLoading() {
      return this.$store.getters["journey/getJourneyIsLoading"];
    },
  },
  methods: {
    async runBrandCheck() {
      const { query } = this.$route;
      const gsbrand = query.gsbrand?.replace(/\s/g, "");
      const goidst = query.goidst?.replace(/\s/g, "");

      if (gsbrand || goidst) {
        const { branding, tracking } = await this.$goshorty.getWhiteLabelTheme(
          gsbrand,
          goidst
        );

        if (branding?.brandFound && branding?.brandEnabled) {
          this.$route.query.gsbrand = branding.brandCode;
          this.$store.commit("layout/setWhiteLabeledTheme", branding);
          this.setWhiteLabeledVuetifyTheme(branding);
          this.$store.commit("layout/setIsWhiteLabeled");
        } else {
          delete this.$route.query.gsbrand;
        }

        if (tracking?.goidstFound) {
          this.$route.query.goidst = tracking.goidstCode;
        } else {
          delete this.$route.query.goidst;
        }

        this.sanitizeQueryParameters();
      }
    },
    setWhiteLabeledVuetifyTheme(branding) {
      Object.keys(branding.brandColours).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = branding.brandColours[i];
      });
    },
    sanitizeQueryParameters() {
      const paramsObject = this.$route.query;
      const objectToQueryString = (obj) => {
        const keyValuePairs = [];
        for (const key in obj) {
          if (obj?.[key] !== null && obj?.[key] !== undefined) {
            keyValuePairs.push(
              encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
            );
          } else if (obj[key] === null) {
            keyValuePairs.push(encodeURIComponent(key) + "=" + "");
          }
        }
        return keyValuePairs.join("&");
      };
      const baseUrl = `${window.location.origin}${this.$route.path}`;
      const queryString = objectToQueryString(paramsObject);
      const urlWithParams =
        queryString.length >= 1 ? baseUrl + "?" + queryString : baseUrl;
      window.history.replaceState(null, "", urlWithParams);
    },
    doInteraction() {
      this.checkForErrors();
    },
  },
  watch: {
    getCurrentStepNumber(step) {
      if (
        step !== 8 &&
        step !== 0 &&
        this.$store.getters["modules/session/sessionId"]
      ) {
        this.$goshorty.insuranceIntelligence.step(this.getQuoteData);
      }
    },
  },
};
</script>

<style lang="scss">
.v-btn__content {
  font-weight: normal;
  width: 100%;
  display: block;
}
.v-btn:not(.v-btn--round).v-size--default {
  font-size: 18px;
  font-weight: bold;
  display: block;
  height: 67px;
}
.outline {
  margin: 1rem;
  padding: 1rem 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 95%;

  @media (min-width: 1026px) {
    padding: 2rem 1rem;
  }
}
.v-form {
  width: 95%;

  @media (min-width: 426px) {
    width: 83%;
  }
}
.v-text-field .v-label--active {
  transform: translateY(-28px) scale(1) !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}

.v-text-field input,
.v-select__selection,
.v-list-item__content {
  color: #202132 !important;
  font-family: "Font Theme DemiBold", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 22px !important;
  overflow: hidden !important;

  @media (min-width: 768px) {
    font-size: 18px;
    overflow: visible !important;
  }

  &::placeholder {
    font-size: 18px !important;
    color: #909099 !important;
    -webkit-text-fill-color: #909099 !important;
  }
}
.v-list-item__content {
  font-weight: 400;
}
.v-text-field input {
  line-height: 24px !important;
  padding: 4px 0 !important;
}
.smallText {
  font-size: 10px;
}
</style>
