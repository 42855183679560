<template>
  <v-text-field
    :value="value"
    :rules="rules"
    :data-zuko-id="zukoId"
    placeholder="Vehicle weight in kg or tons"
    @input="$emit('input', $event)"
  ></v-text-field>
</template>

<script>
export default {
  name: "VehicleWeight",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    rules: [
      (v) =>
        (v && /^\d+(\.\d+)?$/.test(v) && parseFloat(v) !== 0) ||
        "Please enter a valid value for vehicle weight that is not zero.",
    ],
  }),
};
</script>
