var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "cover-details-container" },
    [
      _c(
        "FormTitle",
        { class: _vm.fullScreen ? "pb-2" : "" },
        [_vm._t("title")],
        2
      ),
      _vm._v(" "),
      _vm.hasSubtitle
        ? _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8", md: "6", xl: "4" } },
                [
                  _c(
                    "div",
                    { staticClass: "text-h6 text-center" },
                    [_vm._t("subtitle")],
                    2
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-0 mt-md-2 text-body-2 text-md-body-1",
          class: _vm.$vuetify.breakpoint.lgAndUp ? "mx-16" : "",
          attrs: { justify: "center", align: "center" },
        },
        [
          !_vm.fullScreen
            ? _c(
                "v-col",
                {
                  staticClass:
                    "cover-details-container__cover-details-col pt-6",
                  class: { outline: !_vm.noOutline },
                  style: _vm.backgroundColor && {
                    backgroundColor: _vm.backgroundColor,
                  },
                  attrs: { cols: "12", sm: "12", md: "8", xl: "6" },
                },
                [_vm._t("default")],
                2
              )
            : _c(
                "v-col",
                {
                  staticClass: "cover-details-container__cover-details-col",
                  class: { outline: !_vm.noOutline },
                  style: _vm.backgroundColor && {
                    backgroundColor: _vm.backgroundColor,
                  },
                  attrs: { cols: "12" },
                },
                [_vm._t("default")],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }