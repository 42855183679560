<template>
  <v-select
    :items="items"
    :value="value"
    :rules="rules"
    return-object
    placeholder="Please select"
    :data-zuko-id="zukoId"
    @change="$emit('input', $event)"
  ></v-select>
</template>

<script>
export default {
  name: "VehicleOwner",
  props: {
    isUnder21: {
      type: Boolean,
      default: false,
    },
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    rules: [
      (v) =>
        (!!v && !!v.value) || "Please select a valid value for vehicle owner.",
    ],
  }),
  computed: {
    items() {
      if (this.isUnder21) {
        return this.$selectors.vehicle.owner.under21;
      } else {
        return this.$selectors.vehicle.owner.standard;
      }
    },
  },
};
</script>
