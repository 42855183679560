<template>
  <div>
    <BaseButtonToggle
      :value="value"
      :applyBorder="hasError"
      :items="$selectors.personal.titles"
      :wrapButtons="$vuetify.breakpoint.xs"
      :zukoId="zukoId"
      @input="$emit('input', $event)"
      notMandatory
    ></BaseButtonToggle>
    <div
      class="error--text"
      style="font-size: 12px; margin-top: -10px"
      v-if="hasError"
    >
      Please select a valid value for {{ pronoun }} title.
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalTitle",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    pronoun: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    hasError: false,
  }),
  methods: {
    forceValidation() {
      this.hasError = !this.value;
    },
  },
  watch: {
    value() {
      this.forceValidation();
    },
  },
};
</script>
