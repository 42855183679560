<template>
  <div>
    <v-select
      :items="items"
      :value="value"
      return-object
      :rules="rules"
      placeholder="Please select"
      :menu-props="{
        maxHeight: '320px',
      }"
      :data-zuko-id="zukoId"
      @change="$emit('input', $event)"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "CoverReason",
  props: {
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
      default: () => {
        return {};
      },
    },
  },
  computed: {
    items() {
      if (this.hasQueryImpound) {
        return this.$selectors.cover.reasons.impound;
      } else if (this.hasQueryLearner) {
        return this.$selectors.cover.reasons.learner;
      } else if (this.isVan) {
        return this.$selectors.cover.reasons.van;
      } else {
        return this.$selectors.cover.reasons.car;
      }
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          (!!v && !!v.value) ||
          "Please select a valid value for the cover reason.",
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.items?.length === 1) {
        this.value = this.items[0];
      }
    }, 10);
  },
};
</script>
