<template>
  <a :href="link" class="logo-link nuxt-link" data-nuxt-link>
    <img v-if="isSvg" :src="logo" :alt="alt" height="40" width="200" />
    <img v-else :src="logo" :alt="alt" width="200" />
  </a>
</template>

<script>
export default {
  data() {
    return {
      logo: null,
      alt: null,
      isSvg: false,
      link: null,
    };
  },
  beforeMount() {
    const whiteLabeledTheme =
      this.$store.getters["layout/getWhiteLabeledTheme"];
    this.logo = whiteLabeledTheme.brandLogo.url;
    this.alt = whiteLabeledTheme.brandName;
    this.isSvg = this.logo.includes(".svg");
    this.link = `${window.location.origin}/${window.location.search}`;
  },
};
</script>
