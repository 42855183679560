var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.getManualTypes.length < 5
        ? _c("BaseButtonToggle", {
            attrs: {
              value: _vm.value,
              items: _vm.getManualTypes,
              zukoId: _vm.zukoId,
              wrapButtons: _vm.getManualTypes.length > 3 && !_vm.isMobile,
              wrapAll: _vm.isMobile,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          })
        : _c("v-autocomplete", {
            attrs: {
              items: _vm.getManualTypes,
              value: _vm.value,
              rules: _vm.rules,
              "return-object": "",
              placeholder: "Please select",
              "data-zuko-id": _vm.zukoId,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }