var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c("v-text-field", {
            attrs: {
              value: _vm.value,
              rules: _vm.rules,
              counter: 8,
              maxLength: 8,
              id: `${_vm.id}-ni`,
              "validate-on-blur": "",
              "data-zuko-id": `${_vm.zukoId}-ni`,
            },
            on: {
              keypress: function ($event) {
                return _vm.isNumber($event)
              },
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }