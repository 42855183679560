<template>
  <BaseButtonToggle
    :value="value"
    @input="$emit('input', $event)"
    :items="$selectors.under21.drivingHours"
    :zukoId="zukoId"
  />
</template>

<script>
export default {
  name: "Under21DrivingHours",
  props: {
    value: {
      type: String,
    },
    zukoId: {
      type: String,
      required: true,
    },
  },
};
</script>
