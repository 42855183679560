<template>
  <div>
    <hr v-if="!unsubscribe" />
    <div v-if="loading" class="my-10 text-center">
      <v-progress-circular indeterminate color="brandPrimary" size="60" />
    </div>
    <div v-else-if="!unsubscribe">
      <h2 class="marketing-capture-heading text-center">
        Would you like to receive our special <br />offers & discounts?
      </h2>
      <p class="text-center">
        Tick the boxes below to hear from us with tips, news<br />
        and offers you won't want to miss.
      </p>
    </div>
    <div v-else>
      <p class="brandText--text text-center">
        Select how you want to hear from us.
      </p>
    </div>
    <template v-if="!loading">
      <div style="width: 120px; margin-left: auto; margin-right: auto">
        <div class="d-flex align-center brandText--text">
          <div style="width: 100px">By Email</div>
          <input
            :checked="inputConsentEmail"
            @change="$emit('email', $event)"
            type="checkbox"
            class="checkbox"
          />
        </div>
        <div class="d-flex align-center p-0 mb-5 mt-2 brandText--text">
          <div style="width: 100px">By SMS</div>
          <input
            :checked="inputConsentSMS"
            @change="$emit('sms', $event)"
            type="checkbox"
            class="checkbox"
          />
        </div>
      </div>
      <div v-if="hasPreviousConsent" class="text-center mb-3 text-caption">
        Just to let you know, you previously updated your marketing opt-in
        status on
        {{ consentLastUpdated }}.
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "MarketingCaptureForm",
  props: {
    unsubscribe: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasPreviousConsent: {
      type: Boolean,
      default: false,
    },
    consentLastUpdated: {
      type: String,
      default: "",
    },
    inputConsentEmail: {
      type: Boolean,
      default: false,
    },
    inputConsentSMS: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.marketing-capture-heading {
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 900;
  font-size: 18pt;
  line-height: 17pt;
}
.checkbox {
  height: 20px;
  width: 20px;
}
</style>
