export default ({ app, store }) => {
  app.router.beforeEach((to, from, next) => {
    /* Set the entrance route if not yet set */
    const entranceRoute = store.getters["journey/getEntranceRoute"];
    if (!entranceRoute) {
      store.commit("journey/setEntranceRoute", to.path);
    }

    /* Fetch the to route */
    const routeTo = store.getters["journey/getSteps"].find(
      (s) => s.page === to.path || s.page + "/" === to.path
    );

    /* Fetch the from route */
    const routeFrom = store.getters["journey/getSteps"].find(
      (s) => s.page === from.path || s.page + "/" === from.path
    );

    const forwardButtonClicked =
      store.getters["journey/getForwardButtonClicked"];

    /* Protect the payment routes */
    if (routeFrom && (routeFrom.id === "14" || routeFrom.id === "14.1")) {
      next({
        path: routeFrom.page,
        query: to.query,
      });
      return;
    }

    if (routeTo && routeFrom) {
      if (routeTo.id > routeFrom.id && !forwardButtonClicked) {
        next({
          path: routeFrom.page,
          query: to.query,
        });
        store.dispatch("journey/changeCurrentStep", routeFrom.id);
      } else {
        store.dispatch("journey/changeCurrentStep", routeTo.id);
        store.commit("journey/setForwardButtonClicked", false);
        next();
      }
    } else {
      next();
    }
  });
};
