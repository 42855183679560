var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { style: _vm.cssProps, attrs: { light: "" } },
    [
      !_vm.pageLoading
        ? _c(
            "v-main",
            [
              _c("BaseNavigation"),
              _vm._v(" "),
              _c(
                "v-container",
                { ref: "topOfPage" },
                [
                  _c("FormProgressBar", {
                    attrs: { value: _vm.getCurrentProgress },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          !_vm.isWhiteLabeled
                            ? _c("TrustpilotWidget")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isMaintenanceWarning
                    ? _c("MaintenanceWarningBar")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isMaintenance ? _c("MaintenanceMessage") : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-0 mt-md-2" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0 pt-md-2" },
                        [!_vm.isMaintenanceClosed ? _c("Nuxt") : _vm._e()],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("BaseFooter"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("BaseLoader"),
      _vm._v(" "),
      _c("BaseDebugger"),
      _vm._v(" "),
      _c("BaseModal"),
      _vm._v(" "),
      _c("BaseSessionModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }