export default {
  data() {
    return {
      isCheckingErrors: false,
    };
  },
  methods: {
    async checkForErrors({ shouldScroll = false, timeout = true } = {}) {
      const checkErrors = () => {
        return new Promise((resolve) => {
          this.journeyCurrentErrors =
            this.$errorManager.checkForErrors(shouldScroll);
          resolve();
        });
      };
      await checkErrors();
      if (timeout) {
        await new Promise((resolve) =>
          setTimeout(async () => {
            await checkErrors();
            resolve();
          }, 10)
        );
      }

      return true;
    },
    async goToNextPage() {
      await this.$store.dispatch("modules/session/extend");
      this.$store.dispatch("journey/goToNextPage", { route: this.$route });
    },
    async goToPrevPage() {
      await this.$store.dispatch("modules/session/extend");
      this.$store.dispatch("journey/goToPrevPage", { route: this.$route });
    },
    async goToQuotes() {
      await this.$store.dispatch("modules/session/extend");
      this.$store.dispatch("journey/goToQuotes", { route: this.$route });
    },
  },
  mounted() {
    this.checkForErrors();
    setTimeout(() => {
      this.$store.commit("journey/setIsNavigating", false);
    }, 500);
  },
  computed: {
    //Get
    getAdditionalAgeAtStartOfPolicy() {
      return this.$store.getters[
        "modules/additional/personaldetails/getAgeAtStartOfPolicy"
      ];
    },
    getAdditionalDateOfBirth() {
      return this.$store.getters[
        "modules/additional/personaldetails/getDateOfBirthISO"
      ];
    },
    getAdditionalDateOfBirthHumanized() {
      return this.$store.getters[
        "modules/additional/personaldetails/getInputDateOfBirthHumanized"
      ];
    },
    getAdditionalLicenceExport() {
      return this.$store.getters[
        "modules/additional/personaldetails/getLicenceExport"
      ];
    },
    getAdditionalSelectedAddress() {
      return this.$store.getters[
        "modules/additional/personaldetails/getSelectedAddress"
      ];
    },
    getCoverShouldShowOtherLengths() {
      return this.$store.getters[
        "modules/coverdetails/getShouldShowOtherLengths"
      ];
    },
    getCurrentStepNumber() {
      return this.$store.getters["journey/getCurrentStepNumber"];
    },
    getDateTimeDates() {
      return this.$store.getters["modules/datetimedetails/getDates"];
    },
    getDateTimeHours() {
      return this.$store.getters["modules/datetimedetails/getHours"];
    },
    getDateTimeIsUpdating() {
      return this.$store.getters["modules/datetimedetails/isUpdating"];
    },
    getDateTimeMinutes() {
      return this.$store.getters["modules/datetimedetails/getMinutes"];
    },
    getDateTimeCalculatedDates() {
      return this.$store.getters["modules/datetimedetails/getCalculatedDates"];
    },
    getJourneyServerDate() {
      return this.$store.getters["journey/getServerDate"];
    },
    getJourneyIsTweaked() {
      return this.$store.getters["journey/getIsTweaked"];
    },
    getJourneyIsNavigating() {
      return this.$store.getters["journey/getIsNavigating"];
    },
    getMaintenanceMessages() {
      return this.$store.getters[
        "modules/maintenancedetails/getMaintenanceMessages"
      ];
    },
    getManualDoors() {
      return this.$store.getters["modules/manualsearchdetails/getDoors"];
    },
    getManualEngineSizes() {
      return this.$store.getters["modules/manualsearchdetails/getEngineSizes"];
    },
    getManualFilteredDescriptions() {
      return this.$store.getters[
        "modules/manualsearchdetails/getFilteredDescriptions"
      ];
    },
    getManualFuel() {
      return this.$store.getters["modules/manualsearchdetails/getFuel"];
    },
    getManualLoaders() {
      return this.$store.getters["modules/manualsearchdetails/getLoaders"];
    },
    getManualMakes() {
      return this.$store.getters["modules/manualsearchdetails/getMakes"];
    },
    getManualModels() {
      return this.$store.getters["modules/manualsearchdetails/getModels"];
    },
    getManualShowers() {
      return this.$store.getters["modules/manualsearchdetails/getShowers"];
    },
    getManualTransmissions() {
      return this.$store.getters[
        "modules/manualsearchdetails/getTransmissions"
      ];
    },
    getManualTypes() {
      return this.$store.getters["modules/manualsearchdetails/getTypes"];
    },
    getManualYears() {
      return this.$store.getters["modules/manualsearchdetails/getYears"];
    },
    getProposerAgeAtStartOfPolicy() {
      return this.$store.getters[
        "modules/personaldetails/getAgeAtStartOfPolicy"
      ];
    },
    getProposerConsentChecked() {
      return this.$store.getters["modules/personaldetails/getConsentChecked"];
    },
    getProposerConsentLastUpdated() {
      return this.$store.getters[
        "modules/personaldetails/getConsentLastUpdated"
      ];
    },
    getProposerConsentLoading() {
      return this.$store.getters["modules/personaldetails/getConsentLoading"];
    },
    getProposerDateOfBirthHumanized() {
      return this.$store.getters[
        "modules/personaldetails/getInputDateOfBirthHumanized"
      ];
    },
    getProposerDateOfBirth() {
      return this.$store.getters["modules/personaldetails/getDateOfBirthISO"];
    },
    getProposerLicenceDurationInMonthsAtStartOfPolicy() {
      return this.$store.getters[
        "modules/licencedetails/getLicenceDurationInMonthsAtStartOfPolicy"
      ];
    },
    getProposerLicenceExport() {
      return this.$store.getters["modules/personaldetails/getLicenceExport"];
    },
    getProposerLicenceSearchLoading() {
      return this.$store.getters[
        "modules/licencedetails/getLicenceIsSearching"
      ];
    },
    getProposerSelectedAddress() {
      return this.$store.getters["modules/personaldetails/getSelectedAddress"];
    },
    getQuoteData() {
      return this.$store.getters["quote/getQuoteData"];
    },
    getQuoteFilteredSchemes() {
      return this.$store.getters["quote/getFilteredSchemes"];
    },
    getQuotePaymentLink() {
      return this.$store.getters["quote/getPaymentLink"];
    },
    getQuotePromoText() {
      return this.$store.getters["quote/getPromoText"];
    },
    getQuoteWebReference() {
      return this.$store.getters["quote/getWebReference"];
    },
    getQuoteWrapUpComplete() {
      return this.$store.getters["quote/getWrapUpComplete"];
    },
    getQuoteWrapUpOtt() {
      return this.$store.getters["quote/getWrapUpOtt"];
    },
    getQuoteWrapUpResult() {
      return this.$store.getters["quote/getWrapUpResult"];
    },
    getQuerySecret() {
      return this.$store.getters["modules/querydetails/getSecret"];
    },
    getRecallVehicleLookupStatus() {
      return this.$store.getters["modules/recall/getRecallVehicleLookupStatus"];
    },
    getRecallAllPassed() {
      return this.$store.getters["modules/recall/getRecallAllPassed"];
    },
    getSelectedDateObject() {
      return this.$store.getters[
        "modules/datetimedetails/getSelectedDateObject"
      ];
    },
    getSelectedQuote() {
      return this.$store.getters["quote/getSelectedQuote"];
    },
    getSelectedQuoteAdminFee() {
      return this.$formatters.currency(
        this.getSelectedQuote?.front_end?.premiums?.admin_fee
      );
    },
    getSelectedQuoteIPIDUrl() {
      return this.getSelectedQuote?.front_end?.content?.pdf?.ipid_url;
    },
    getSelectedQuotePolicyWordingUrl() {
      return this.getSelectedQuote?.front_end?.content?.pdf?.policy_wording_url;
    },
    getSelectedQuotePremium() {
      return this.getSelectedQuote?.front_end?.premiums?.insurer_premium;
    },
    getSelectedQuotePremiumFormatted() {
      return this.$formatters.currency(this.getSelectedQuotePremium);
    },
    getSelectedQuoteTobaUrl() {
      return this.getSelectedQuote?.front_end?.content?.pdf?.toba_url;
    },
    getSelectedQuoteTotalToPay() {
      return this.getSelectedQuote?.front_end?.premiums?.total_to_pay;
    },
    getSelectedQuoteTotalToPayFormatted() {
      return this.$formatters.currency(this.getSelectedQuoteTotalToPay);
    },
    getSelectedQuoteTotalToPayWithOpex() {
      const quote = this.getSelectedQuoteTotalToPay;
      const opex = this.getOpexTotalPremiums;
      return this.$formatters.currency(quote + opex);
    },
    getSelectedQuotePremiumWithOpex() {
      const quote = this.getSelectedQuotePremium;
      const opex = this.getOpexTotalPremiums;
      return Number(quote) + Number(opex);
    },
    getSelectedQuotePremiumWithOpexFormatted() {
      return this.$formatters.currency(this.getSelectedQuotePremiumWithOpex);
    },
    getIsOpexSending() {
      return this.$store.getters["modules/opex/getIsOpexSending"];
    },
    getOpexBreakdownFrontend() {
      return this.$store.getters["modules/opex/getBreakdownOpex"]?.front_end;
    },
    getOpexExcessProtectionFrontend() {
      return this.$store.getters["modules/opex/getExcessReductionOpex"]
        ?.front_end;
    },
    getOpexLegalExpensesFrontend() {
      return this.$store.getters["modules/opex/getLegalExpensesOpex"]
        ?.front_end;
    },
    getOpexBreakdownPremium() {
      return Number(
        (
          this.$store.getters["modules/opex/getBreakdownOpex"]?.amount / 100
        ).toFixed(2)
      );
    },
    getOpexBreakdownPremiumFormatted() {
      return this.$formatters.currency(this.getOpexBreakdownPremium);
    },
    getOpexBreakdownAdminFee() {
      return Number(
        (
          this.$store.getters["modules/opex/getBreakdownOpex"]
            ?.commission_amount / 100
        ).toFixed(2)
      );
    },
    getOpexExcessProtectionPremium() {
      return Number(
        (
          this.$store.getters["modules/opex/getExcessReductionOpex"]?.amount /
          100
        ).toFixed(2)
      );
    },
    getOpexExcessProtectionPremiumFormatted() {
      return this.$formatters.currency(this.getOpexExcessProtectionPremium);
    },
    getOpexExcessProtectionAdminFee() {
      return Number(
        (
          this.$store.getters["modules/opex/getExcessReductionOpex"]
            ?.commission_amount / 100
        ).toFixed(2)
      );
    },
    getOpexLegalExpensesPremium() {
      return Number(
        (
          this.$store.getters["modules/opex/getLegalExpensesOpex"]?.amount / 100
        ).toFixed(2)
      );
    },
    getOpexLegalExpensesPremiumFormatted() {
      return this.$formatters.currency(this.getOpexLegalExpensesPremium);
    },
    getOpexLegalExpensesAdminFee() {
      return Number(
        (
          this.$store.getters["modules/opex/getLegalExpensesOpex"]
            ?.commission_amount / 100
        ).toFixed(2)
      );
    },
    getOpexTotalPremiums() {
      let total = 0;
      if (this.selectedOpexBreakdownCover) {
        total += this.getOpexBreakdownPremium;
      }
      if (this.selectedExcessProtection) {
        total += this.getOpexExcessProtectionPremium;
      }
      if (this.selectedLegalExpenses) {
        total += this.getOpexLegalExpensesPremium;
      }
      return total;
    },
    getOpexTotalPremiumsFormatted() {
      return this.$formatters.currency(this.getOpexTotalPremiums);
    },
    getSelectedQuoteAcceptanceCriteria() {
      return this.getSelectedQuote?.front_end?.content?.html
        ?.acceptance_criteria;
    },
    getVehicleAbiProductionYears() {
      return this.$store.getters[
        "modules/vehicledetails/getVehicleAbiProductionYears"
      ];
    },
    getVehicleBodyStyle() {
      return this.$store.getters["modules/vehicledetails/getVehicleBodyStyle"];
    },
    getVehicleCc() {
      return this.$store.getters["modules/vehicledetails/getVehicleCc"];
    },
    getVehicleFuel() {
      return this.$store.getters["modules/vehicledetails/getVehicleFuel"];
    },
    getVehicleManufacturer() {
      return this.$store.getters[
        "modules/vehicledetails/getVehicleManufacturer"
      ];
    },
    getVehicleModelDescription() {
      return this.$store.getters[
        "modules/vehicledetails/getVehicleModelDescription"
      ];
    },
    getWhiteLabeledBrandName() {
      return this.$store.getters["layout/getWhiteLabeledTheme"]?.brandName;
    },
    // Has
    hasReg() {
      return this.$store.getters["modules/querydetails/hasReg"];
    },
    hasAdditionalDriver() {
      return this.$store.getters["journey/hasAdditionalDriver"];
    },
    hasCompletedVehicleSearch() {
      return this.$store.getters["modules/vehicledetails/hasCompletedSearch"];
    },
    hasInputRegistrationNumber() {
      return this.$store.getters[
        "modules/vehicledetails/hasInputRegistrationNumber"
      ];
    },
    hasLicenceCache() {
      return this.$store.getters[
        "modules/coverdetails/hasSelectedCoverReasonCache"
      ];
    },
    hasManuallySetValue() {
      return this.$store.getters["modules/vehicledetails/hasManuallySetValue"];
    },
    hasManuallySetWeight() {
      return this.$store.getters["modules/vehicledetails/hasManuallySetWeight"];
    },
    hasOpexBreakdown() {
      return this.$store.getters["modules/opex/hasBreakdownOpex"];
    },
    hasOpexLegalExpenses() {
      return this.$store.getters["modules/opex/hasLegalExpensesOpex"];
    },
    hasOpexExcessReduction() {
      return this.$store.getters["modules/opex/hasExcessReductionOpex"];
    },
    hasOpexSelected() {
      return this.$store.getters["modules/opex/hasSelectedOpex"];
    },
    hasPaymentLink() {
      return this.$store.getters["quote/hasPaymentLink"];
    },
    hasProposerPreviousConsent() {
      return this.$store.getters["modules/personaldetails/hasPreviousConsent"];
    },
    hasQueryImpound() {
      return this.$store.getters["modules/querydetails/hasImpound"];
    },
    hasQueryLearner() {
      return this.$store.getters["modules/querydetails/hasLearner"];
    },
    hasQueryWebreference() {
      return this.$store.getters["modules/querydetails/hasWebreference"];
    },
    hasQuerySecret() {
      return this.$store.getters["modules/querydetails/hasSecret"];
    },
    hasQuoteFilteredSchemes() {
      return this.$store.getters["quote/hasFilteredSchemes"];
    },
    hasQuotePromo() {
      return this.$store.getters["quote/hasPromo"];
    },
    hasQuoteWrapUpOtt() {
      return this.$store.getters["quote/hasWrapUpOtt"];
    },
    hasVehicleGrossWeight() {
      return this.$store.getters[
        "modules/vehicledetails/hasVehicleGrossWeight"
      ];
    },
    hasVehicleValue() {
      return this.$store.getters["modules/vehicledetails/hasVehicleValue"];
    },
    hasOpex() {
      return (
        Object.entries(this.$store.getters["modules/opex/getOpex"]).length !== 0
      );
    },
    // Inputs
    journeyCurrentErrors: {
      get() {
        return this.$store.getters["journey/getCurrentErrors"];
      },
      set(value) {
        this.$store.commit("journey/setCurrentErrors", value);
      },
    },
    inputRegistrationNumber: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getInputRegistrationNumber"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/vehicledetails/setInputRegistrationNumber",
          value
        );
      },
    },
    inputAdditionalLicenceNumberUK: {
      get() {
        return this.$store.getters[
          "modules/additional/licencedetails/getInputLicenceNumberUK"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/licencedetails/setInputLicenceNumberUK",
          value
        );
      },
    },
    inputAdditionalLicenceNumberNI: {
      get() {
        return this.$store.getters[
          "modules/additional/licencedetails/getInputLicenceNumberNI"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/licencedetails/setInputLicenceNumberNI",
          value
        );
      },
    },
    inputAdditionalLicenceNumberOther: {
      get() {
        return this.$store.getters[
          "modules/additional/licencedetails/getInputLicenceNumberOther"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/licencedetails/setInputLicenceNumberOther",
          value
        );
      },
    },
    inputAdditionalPersonalMobileNumber: {
      get() {
        return this.$store.getters[
          "modules/additional/personaldetails/getInputMobileNumber"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/personaldetails/setInputMobileNumber",
          value
        );
      },
    },
    inputAdditionalPersonalFirstName: {
      get() {
        return this.$store.getters[
          "modules/additional/personaldetails/getInputFirstName"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/personaldetails/setInputFirstName",
          value
        );
      },
    },
    inputAdditionalPersonalSurname: {
      get() {
        return this.$store.getters[
          "modules/additional/personaldetails/getInputSurname"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/personaldetails/setInputSurname",
          value
        );
      },
    },
    inputAdditionalPersonalOccupation: {
      get() {
        return this.$store.getters[
          "modules/additional/personaldetails/getInputOccupation"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/personaldetails/setInputOccupation",
          value
        );
      },
    },
    inputProposerConsentEmail: {
      get() {
        return this.$store.getters[
          "modules/personaldetails/getInputConsentEmail"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/personaldetails/setInputConsentEmail",
          value
        );
      },
    },
    inputProposerConsentSMS: {
      get() {
        return this.$store.getters[
          "modules/personaldetails/getInputConsentSMS"
        ];
      },
      set(value) {
        this.$store.commit("modules/personaldetails/setInputConsentSMS", value);
      },
    },
    inputProposerLicenceNumberUK: {
      get() {
        return this.$store.getters[
          "modules/licencedetails/getInputLicenceNumberUK"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/licencedetails/setInputLicenceNumberUK",
          value
        );
      },
    },
    inputProposerLicenceNumberNI: {
      get() {
        return this.$store.getters[
          "modules/licencedetails/getInputLicenceNumberNI"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/licencedetails/setInputLicenceNumberNI",
          value
        );
      },
    },
    inputProposerLicenceNumberOther: {
      get() {
        return this.$store.getters[
          "modules/licencedetails/getInputLicenceNumberOther"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/licencedetails/setInputLicenceNumberOther",
          value
        );
      },
    },
    inputProposerPersonalMobileNumber: {
      get() {
        return this.$store.getters[
          "modules/personaldetails/getInputMobileNumber"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/personaldetails/setInputMobileNumber",
          value
        );
      },
    },
    inputProposerPersonalFirstName: {
      get() {
        return this.$store.getters["modules/personaldetails/getInputFirstName"];
      },
      set(value) {
        this.$store.commit("modules/personaldetails/setInputFirstName", value);
      },
    },
    inputProposerPersonalSurname: {
      get() {
        return this.$store.getters["modules/personaldetails/getInputSurname"];
      },
      set(value) {
        this.$store.commit("modules/personaldetails/setInputSurname", value);
      },
    },
    inputProposerPersonalOccupation: {
      get() {
        return this.$store.getters[
          "modules/personaldetails/getInputOccupation"
        ];
      },
      set(value) {
        this.$store.commit("modules/personaldetails/setInputOccupation", value);
      },
    },
    inputProposerEmail: {
      get() {
        return this.$store.getters["modules/personaldetails/getInputEmail"];
      },
      set(value) {
        this.$store.commit("modules/personaldetails/setInputEmail", value);
      },
    },
    inputProposerEmailConfirm: {
      get() {
        return this.$store.getters[
          "modules/personaldetails/getInputEmailConfirm"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/personaldetails/setInputEmailConfirm",
          value
        );
      },
    },
    inputRecallWebreference: {
      get() {
        return this.$store.getters["modules/recall/getInputWebReference"];
      },
      set(value) {
        return this.$store.commit("modules/recall/setInputWebReference", value);
      },
    },
    inputVehicleOwnerFirstname: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getInputVehicleOwnerFirstname"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/vehicledetails/setInputVehicleOwnerFirstname",
          value
        );
      },
    },
    inputVehicleOwnerSurname: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getInputVehicleOwnerSurname"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/vehicledetails/setInputVehicleOwnerSurname",
          value
        );
      },
    },
    inputVehicleAnnuallyInsured: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getSelectedVehicleAnnuallyInsured"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/vehicledetails/setSelectedVehicleAnnuallyInsured",
          value
        );
      },
    },
    // Is
    isAdditionalLicenceSearchLoading() {
      return this.$store.getters[
        "modules/additional/licencedetails/getLicenceIsSearching"
      ];
    },
    isBetween19And20() {
      return this.$store.getters["journey/isBetween19And20"];
    },
    isCoverDays() {
      return this.$store.getters["modules/coverdetails/isDays"];
    },
    isCoverHours() {
      return this.$store.getters["modules/coverdetails/isHours"];
    },
    isCoverWeeks() {
      return this.$store.getters["modules/coverdetails/isWeeks"];
    },
    isImpound() {
      return this.$store.getters["journey/isImpound"];
    },
    isRecall() {
      return this.$store.getters["journey/getIsRecall"];
    },
    isImmediate() {
      return this.$store.getters["modules/datetimedetails/isImmediate"];
    },
    isLearningNotOwnCar() {
      return this.$store.getters["modules/vehicledetails/isLearningNotOwnCar"];
    },
    isLearnerDriver() {
      return this.$store.getters["journey/isLearnerDriver"];
    },
    isManualCar() {
      return this.$store.getters["modules/manualsearchdetails/isCar"];
    },
    isManualElectric() {
      return this.$store.getters["modules/manualsearchdetails/isElectric"];
    },
    isManualLookup() {
      return this.$store.getters[
        "modules/vehicledetails/getVehicleIsManualLookup"
      ];
    },
    isMobile() {
      const { xs, sm } = this.$vuetify.breakpoint;
      return xs || sm;
    },
    isQuoteLoading() {
      return this.$store.getters["quote/getIsLoading"];
    },
    isVan() {
      return this.$store.getters["modules/vehicledetails/getVehicleIsVan"];
    },
    isWhiteLabeled() {
      return this.$store.getters["layout/isWhiteLabeled"];
    },
    // Selected
    selectedAdditionalAddressSameAsMainDriver: {
      get() {
        return this.$store.getters[
          "modules/additional/personaldetails/getSelectedAddressSameAsMainDriver"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "modules/additional/personaldetails/changeSelectedAddressSameAsMainDriver",
          value
        );
      },
    },
    selectedAdditionalDriver: {
      get() {
        return this.$store.getters["journey/getAdditionalDriver"];
      },
      set(value) {
        this.$store.commit("journey/setAdditionalDriver", value);
      },
    },
    selectedAdditionalLicenceCountry: {
      get() {
        return this.$store.getters[
          "modules/additional/licencedetails/getSelectedCountry"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/additional/licencedetails/setSelectedCountry",
          value
        );
      },
    },
    selectedAdditionalLicenceIssuedCountry: {
      get() {
        return this.$store.getters[
          "modules/additional/licencedetails/getSelectedIssuedCountry"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/additional/licencedetails/setSelectedIssuedCountry",
          value
        );
      },
    },
    selectedAdditionalLicenceType: {
      get() {
        return this.$store.getters[
          "modules/additional/licencedetails/getSelectedLicence"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/additional/licencedetails/setSelectedLicence",
          value
        );
      },
    },
    selectedAdditionalLicenceDuration: {
      get() {
        return this.$store.getters[
          "modules/additional/licencedetails/getSelectedDuration"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/additional/licencedetails/setSelectedDuration",
          value
        );
      },
    },
    selectedAdditionalPersonalTitle: {
      get() {
        return this.$store.getters[
          "modules/additional/personaldetails/getSelectedTitle"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/personaldetails/setSelectedTitle",
          value
        );
      },
    },
    selectedAdditionalRelationship: {
      get() {
        return this.$store.getters[
          "modules/additional/personaldetails/getSelectedRelationship"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/additional/personaldetails/setSelectedRelationship",
          value
        );
      },
    },
    selectedCoverDurationLength: {
      get() {
        return this.$store.getters[
          "modules/coverdetails/getSelectedCoverLength"
        ];
      },
      set(event) {
        if (event.value === "Other") {
          this.$store.commit(
            "modules/coverdetails/setShouldShowOtherLengths",
            true
          );
          return;
        }
        this.$store.commit(
          "modules/coverdetails/setSelectedCoverLength",
          event
        );
        this.$store.dispatch("journey/changeJourneyIsTweaked", true);
      },
    },
    selectedCoverDurationType: {
      get() {
        return this.$store.getters[
          "modules/coverdetails/getSelectedCoverDurationType"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "modules/coverdetails/changeSelectedCoverDurationType",
          value
        );
        this.$store.dispatch("journey/changeJourneyIsTweaked", true);
      },
    },
    selectedCoverReason: {
      get() {
        return this.$store.getters[
          "modules/coverdetails/getSelectedCoverReason"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "modules/coverdetails/changeSelectedCoverReason",
          value
        );
      },
    },
    selectedProposerLicenceCountry: {
      get() {
        return this.$store.getters["modules/licencedetails/getSelectedCountry"];
      },
      set(value) {
        return this.$store.commit(
          "modules/licencedetails/setSelectedCountry",
          value
        );
      },
    },
    selectedProposerLicenceIssuedCountry: {
      get() {
        return this.$store.getters[
          "modules/licencedetails/getSelectedIssuedCountry"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/licencedetails/setSelectedIssuedCountry",
          value
        );
      },
    },
    selectedProposerLicenceType: {
      get() {
        return this.$store.getters["modules/licencedetails/getSelectedLicence"];
      },
      set(value) {
        return this.$store.dispatch(
          "modules/licencedetails/changeSelectedLicence",
          value
        );
      },
    },
    selectedProposerLicenceDuration: {
      get() {
        return this.$store.getters[
          "modules/licencedetails/getSelectedDuration"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/licencedetails/setSelectedDuration",
          value
        );
      },
    },
    selectedProposerCarryingPassengers: {
      get() {
        return this.$store.getters[
          "modules/personaldetails/getSelectedCarryingPassengers"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/personaldetails/setSelectedCarryingPassengers",
          value
        );
      },
    },
    selectedProposerDrivingLocations: {
      get() {
        return this.$store.getters[
          "modules/personaldetails/getSelectedDrivingLocations"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/personaldetails/setSelectedDrivingLocations",
          value
        );
      },
    },
    selectedProposerDrivingHours: {
      get() {
        return this.$store.getters[
          "modules/personaldetails/getSelectedDrivingHours"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/personaldetails/setSelectedDrivingHours",
          value
        );
      },
    },
    selectedProposerPersonalTitle: {
      get() {
        return this.$store.getters["modules/personaldetails/getSelectedTitle"];
      },
      set(value) {
        this.$store.commit("modules/personaldetails/setSelectedTitle", value);
      },
    },
    selectedStartDate: {
      get() {
        return this.$store.getters["modules/datetimedetails/getSelectedDate"];
      },
      set(value) {
        this.$store.dispatch("modules/datetimedetails/changeSelectedDate", {
          date: value,
        });
        this.$store.dispatch("journey/changeJourneyIsTweaked", true);
      },
    },
    selectedVehicleKeeper: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getSelectedVehicleKeeper"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/vehicledetails/setSelectedVehicleKeeper",
          value
        );
      },
    },
    selectedVehicleOwner: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getSelectedVehicleOwner"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/vehicledetails/setSelectedVehicleOwner",
          value
        );
      },
    },
    selectedVehicleUsage: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getSelectedVehicleUsage"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/vehicledetails/setSelectedVehicleUsage",
          value
        );
      },
    },
    selectedVehicleLearnerOwner: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getSelectedLearnerOwner"
        ];
      },
      set(value) {
        return this.$store.commit(
          "modules/vehicledetails/setSelectedLearnerOwner",
          value
        );
      },
    },
    selectedVehicleAddressSameAsMainDriver: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getSelectedAddressSameAsMainDriver"
        ];
      },
      set(value) {
        return this.$store.dispatch(
          "modules/vehicledetails/changeSelectedAddressSameAsMainDriver",
          value
        );
      },
    },
    selectedOpexBreakdownCover: {
      get() {
        return this.$store.getters["modules/opex/getSelectedBreakdownCover"];
      },
      set(value) {
        this.$store.commit("modules/opex/setSelectedBreakdownCover", value);
        this.$store.dispatch("modules/opex/sendOpex");
      },
    },
    selectedExcessProtection: {
      get() {
        return this.$store.getters["modules/opex/getSelectedExcessProtection"];
      },
      set(value) {
        this.$store.commit("modules/opex/setSelectedExcessProtection", value);
        this.$store.dispatch("modules/opex/sendOpex");
      },
    },
    selectedLegalExpenses: {
      get() {
        return this.$store.getters["modules/opex/getSelectedLegalExpenses"];
      },
      set(value) {
        this.$store.commit("modules/opex/setSelectedLegalExpenses", value);
        this.$store.dispatch("modules/opex/sendOpex");
      },
    },
    selectedDateTimeHour: {
      get() {
        return this.$store.getters["modules/datetimedetails/getSelectedHour"];
      },
      set(value) {
        this.$store.dispatch(
          "modules/datetimedetails/changeSelectedHour",
          value
        );
        this.$store.dispatch("journey/changeJourneyIsTweaked", true);
      },
    },
    selectedDateTimeMinute: {
      get() {
        return this.$store.getters["modules/datetimedetails/getSelectedMinute"];
      },
      set(value) {
        this.$store.dispatch(
          "modules/datetimedetails/changeSelectedMinute",
          value
        );
        this.$store.dispatch("journey/changeJourneyIsTweaked", true);
      },
    },
    //Show
    showUnder21Questions() {
      return this.$store.getters["journey/showUnder21Questions"];
    },
    // Vehicle
    vehicleGrossWeight: {
      get() {
        return this.$store.getters[
          "modules/vehicledetails/getVehicleGrossWeight"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/vehicledetails/setVehicleGrossWeight",
          value
        );
      },
    },
    vehicleManualDescription: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedVehicle"
        ];
      },
      set(value) {
        this.$store.commit(
          "modules/manualsearchdetails/setSelectedVehicle",
          value
        );
        this.$store.dispatch(
          "modules/vehicledetails/setVehicleDetails",
          value.details.vehicle_transformed
        );
      },
    },
    vehicleManualDoors: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedDoors"
        ];
      },
      set(value) {
        this.$store.dispatch("modules/manualsearchdetails/changeDoors", value);
      },
    },
    vehicleManualEngineSize: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedEngineSize"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "modules/manualsearchdetails/changeEngineSize",
          value
        );
      },
    },
    vehicleManualFuel: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedFuel"
        ];
      },
      set(value) {
        this.$store.dispatch("modules/manualsearchdetails/changeFuel", value);
      },
    },
    vehicleManualMake: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedMake"
        ];
      },
      set(value) {
        this.$store.dispatch("modules/manualsearchdetails/changeMake", value);
      },
    },
    vehicleManualModel: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedModel"
        ];
      },
      set(value) {
        this.$store.dispatch("modules/manualsearchdetails/changeModel", value);
      },
    },
    vehicleManualTransmission: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedTransmission"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "modules/manualsearchdetails/changeTransmission",
          value
        );
      },
    },
    vehicleManualType: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedType"
        ];
      },
      set(value) {
        this.$store.dispatch("modules/manualsearchdetails/changeType", value);
      },
    },
    vehicleManualYear: {
      get() {
        return this.$store.getters[
          "modules/manualsearchdetails/getSelectedYear"
        ];
      },
      set(value) {
        this.$store.dispatch("modules/manualsearchdetails/changeYear", value);
      },
    },
    vehicleValue: {
      get() {
        return this.$store.getters["modules/vehicledetails/getVehicleValue"];
      },
      set(value) {
        this.$store.commit("modules/vehicledetails/setVehicleValue", value);
      },
    },
  },
};
