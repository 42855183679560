<template>
  <div>
    <CoverLengthSelection
      v-if="getCoverShouldShowOtherLengths || isTweak"
      :value="value"
      :cover-length-items="fullItems"
      :id="id"
      :zukoId="zukoId"
      @input="$emit('input', $event)"
    />
    <CoverLengthButtons
      v-else
      :value="value"
      :cover-length-items="buttonList"
      :id="id"
      :zukoId="zukoId"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "CoverDurationLength",
  props: {
    id: {
      type: String,
      required: true,
    },
    zukoId: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, Number],
    },
    isTweak: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonList() {
      if (!this.isLearnerDriver && this.isCoverWeeks) {
        return this.fullItems;
      }
      return [
        this.fullItems[0],
        this.fullItems[2],
        this.fullItems[4],
        { value: "Other" },
      ];
    },
    fullItems() {
      if (this.isCoverHours) {
        return this.generateHours();
      } else if (this.isCoverDays) {
        return this.generateDays();
      } else if (this.isCoverWeeks) {
        return this.generateWeeks();
      } else {
        return [];
      }
    },
  },
  methods: {
    generateHours() {
      return this.isLearnerDriver
        ? this.$selectors.cover.hours.slice(0, 6)
        : this.$selectors.cover.hours;
    },
    generateDays() {
      return this.$selectors.cover.days;
    },
    generateWeeks() {
      return this.isLearnerDriver
        ? this.$selectors.cover.weeks
        : this.$selectors.cover.weeks.slice(0, 4);
    },
  },
  mounted() {
    if (
      this.value &&
      this.buttonList.some((button) => button.hours === this.value.hours)
    ) {
      this.$store.commit(
        "modules/coverdetails/setShouldShowOtherLengths",
        false
      );
    } else if (
      this.value &&
      this.fullItems.some((button) => button.hours === this.value.hours)
    ) {
      this.$store.commit(
        "modules/coverdetails/setShouldShowOtherLengths",
        true
      );
    } else {
      let selectedCoverLength;

      if (this.isCoverHours) {
        selectedCoverLength = this.$defaults.coverDurations.hours;
      } else if (this.isCoverWeeks) {
        selectedCoverLength = this.$defaults.coverDurations.weeks;
      } else {
        selectedCoverLength = this.$defaults.coverDurations.days;
      }

      this.$store.commit(
        "modules/coverdetails/setSelectedCoverLength",
        selectedCoverLength
      );
    }
  },
};
</script>
