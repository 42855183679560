import { $dayjs } from "~/plugins/dayjs";

export const state = () => ({
  heartbeatFailures: 0,
  heartbeatInterval: 30,
  heartbeatIntervalId: null,
  heartbeatFailureLimit: 5,
  isResetting: false,
  isWarningVisible: false,
  sessionId: null,
  status: null,
  ttl: null,
});

export const getters = {
  heartbeatFailureLimit: (state) => state.heartbeatFailureLimit,
  heartbeatFailures: (state) => state.heartbeatFailures,
  heartbeatInterval: (state) => state.heartbeatInterval,
  heartbeatIntervalId: (state) => state.heartbeatIntervalId,
  isHeartbeatFailureLimitReached: (state) =>
    state.heartbeatFailures >= state.heartbeatFailureLimit,
  isPolling: (state) => Boolean(state.heartbeatIntervalId),
  isResetting: (state) => state.isResetting,
  isWarningVisible: (state) => state.isWarningVisible,
  sessionId: (state) => state.sessionId,
  startedAt: (state) => {
    if (!state.sessionId) return null;

    const [, , startTime] = state.sessionId.split("-");

    return $dayjs(parseInt(startTime, 16)).toISOString();
  },
  status: (state) => state.status,
  resetAt(state) {
    if (state.ttl === null) return null;

    return $dayjs().add(state.ttl, "millis").toISOString();
  },
  resetTime(state) {
    if (state.ttl === null) return null;

    return $dayjs()
      .add(state.ttl, "millis")
      .goshortyDateTime()
      .format("h:mm a");
  },
  ttl: (state) => state.ttl,
};

export const mutations = {
  finish(state) {
    state.sessionId = null;
    state.isResetting = true;
  },
  heartbeatFailureLimit(state, value) {
    state.heartbeatFailureLimit = parseInt(value);
  },
  heartbeatFailures(state, value) {
    state.heartbeatFailures = value;
  },
  heartbeatInterval(state, value) {
    state.heartbeatInterval = parseInt(value);
  },
  heartbeatIntervalId(state, value) {
    state.heartbeatIntervalId = value;
  },
  incrementHeartbeatFailures(state) {
    state.heartbeatFailures++;
  },
  initialize(state) {
    state.heartbeatFailures = 0;
    state.heartbeatIntervalId = null;
    state.sessionId = null;
    state.status = null;
    state.ttl = null;
  },
  isResetting(state, value) {
    state.isResetting = value;
  },
  isPolling(state, value) {
    state.isPolling = value;
  },
  isWarningVisible(state, value) {
    state.isWarningVisible = value;
  },
  response(state, { ttl, status }) {
    state.status = status;
    state.ttl = ttl;
  },
  sessionId(state, sessionId) {
    state.sessionId = sessionId;
  },
  status(state, value) {
    state.status = value;
  },
  ttl(state, value) {
    state.ttl = value;
  },
};

export const actions = {
  abandon({ commit, getters }, hasTmxBlocked) {
    clearTimeout(getters.heartbeatIntervalId);
    commit("isWarningVisible", false);
    commit("isResetting", true);

    // Reset
    commit("modules/additional/licencedetails/resetState", {}, { root: true });
    commit("modules/licencedetails/resetState", {}, { root: true });
    commit("modules/additional/personaldetails/resetState", {}, { root: true });
    commit("modules/personaldetails/resetState", {}, { root: true });
    commit("modules/coverdetails/resetState", {}, { root: true });
    commit("modules/manualsearchdetails/resetState", {}, { root: true });
    commit("modules/vehicledetails/resetState", {}, { root: true });
    commit("modules/datetimedetails/resetState", {}, { root: true });
    commit("modules/opex/resetState", {}, { root: true });
    commit("modules/querydetails/setReg", "", { root: true });
    commit("journey/setIsRecall", false, { root: true });
    commit("initialize");

    const { reg, ...query } = this.$router.currentRoute.query;
    const newQuery = hasTmxBlocked
      ? { ...query, hasTmxBlocked: true }
      : { ...query, hasExpired: true };

    this.$router.push({
      path: "/",
      query: newQuery,
    });
  },

  async extend({ commit, getters }) {
    const response = await this.$axios.put("/session", null, {
      headers: {
        "X-SESSION-ID": getters.sessionId,
      },
    });
    commit("response", response.data);
  },
  finish({ commit, getters }) {
    clearTimeout(getters.heartbeatIntervalId);
    commit("initialize");
  },
  async heartbeat({ commit, getters, dispatch }) {
    if (getters.isResetting) {
      return;
    }

    const intervalId = setTimeout(async () => {
      if (getters.sessionId !== null) {
        try {
          const response = await this.$axios.get("/session", {
            headers: {
              "X-SESSION-ID": getters.sessionId,
            },
          });
          commit("response", response.data);
          if (response.data.status === "WARNING") {
            commit("isWarningVisible", true);
          }

          commit("heartbeatFailures", 0);
        } catch (error) {
          if (error.response?.status === 403) {
            dispatch("abandon");
          } else {
            commit("incrementHeartbeatFailures");
            if (getters["isHeartbeatFailureLimitReached"]) {
              dispatch("abandon");
            }
          }
        }
      }

      dispatch("heartbeat");
    }, getters.heartbeatInterval * 1000);

    commit("heartbeatIntervalId", intervalId);
  },
  async start({ commit, dispatch }) {
    const hostId = this.$config.hostId;
    const softwareVersion = this.$config.softwareVersion;
    const startTime = $dayjs().valueOf().toString(16);
    const random = Math.floor(Math.random() * 1000000000).toString(16);
    const sessionId = `${hostId}-${softwareVersion}-${startTime}-${random}`;

    commit("heartbeatInterval", this.$config.session.interval);
    commit("heartbeatFailureLimit", this.$config.session.failureLimit);
    commit("sessionId", sessionId);
    commit("heartbeatFailures", 0);
    commit("isResetting", false);

    dispatch("heartbeat");

    if (this.$sentry) {
      this.$sentry.setTag("session_id", sessionId);
    }
  },
};
