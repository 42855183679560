var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", { staticClass: "text-center text-body-1 font-weight-bold" }, [
        _c("div", [
          _vm._v("\n      Your policy documents will be sent to:\n      "),
          _c("span", { staticClass: "brandSecondary--text" }, [
            _vm._v(_vm._s(_vm.inputProposerEmail)),
          ]),
          _vm._v(".\n    "),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("\n      If you need to make any changes,\n      "),
          _c(
            "a",
            {
              attrs: {
                id: "gtm--confirmationForm-back-link",
                "data-zuko-id": "zuko--confirmationForm-back-link",
              },
              on: { click: _vm.goToPrevPage },
            },
            [_vm._v("Go Back")]
          ),
          _vm._v(
            "\n      and make any necessary adjustments. Only click\n      "
          ),
          _c("span", { staticClass: "font-italic" }, [
            _vm._v("Confirm and Continue "),
          ]),
          _vm._v("if all the above\n      information is correct.\n    "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }