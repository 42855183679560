<template>
  <div class="licence-number-container">
    <v-row class="ukfields">
      <v-col cols="4">
        <v-text-field
          :value="value.licencePart1"
          @input="handleInput('1', $event, 5)"
          @blur="hasInput1 = true"
          @focus="hasInput1 = false"
          ref="licence-input-0"
          :rules="[licencePart1Valid]"
          :counter="5"
          :disabled="loading"
          validate-on-blur
          :id="`${id}-uk-1`"
          :data-zuko-id="`${zukoId}-uk-1`"
          maxLength="5"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :value="value.licencePart2"
          @input="handleInput('2', $event, 6)"
          @blur="hasInput2 = true"
          @focus="hasInput2 = false"
          ref="licence-input-1"
          :rules="[licencePart2Valid]"
          :counter="6"
          :disabled="loading"
          validate-on-blur
          :id="`${id}-uk-2`"
          :data-zuko-id="`${zukoId}-uk-2`"
          maxLength="6"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :value="value.licencePart3"
          @input="handleInput('3', $event, 5)"
          @blur="hasInput3 = true"
          @focus="hasInput3 = false"
          ref="licence-input-2"
          :rules="[licencePart3Valid]"
          :counter="5"
          :disabled="loading"
          validate-on-blur
          :id="`${id}-uk-3`"
          :data-zuko-id="`${zukoId}-uk-3`"
          maxLength="5"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="error--text" style="font-size: 12px" v-if="showError">
      Please enter a valid value for {{ pronoun }} driving licence number.
    </div>
    <div
      v-if="hasTriggeredError && !showNotFound && !showExpired"
      style="display: none"
      :id="`${id}-uk-corrected`"
      :data-zuko-id="`${zukoId}-uk-corrected`"
    ></div>
    <v-row
      v-if="showExpired || showNotFound || showLicenceError"
      :id="`${id}-error`"
      :data-zuko-id="`${zukoId}-error`"
      class="error--text"
    >
      <v-col cols="12">
        <BaseError v-if="showLicenceError" class="error--text"
          >There has been an issue validating that licence number.</BaseError
        >
        <BaseError v-else-if="showExpired" class="error--text"
          >It looks like your licence is expired, please enter a valid driving
          licence number.</BaseError
        >
        <BaseError v-else-if="showNotFound" class="error--text"
          >We could not find a licence matching the licence number entered
          above.</BaseError
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LicenceNumberUK",
  props: {
    value: {
      type: [Object, String],
      default: () => ({
        licencePart1: "",
        licencePart2: "",
        licencePart3: "",
      }),
    },
    id: {
      type: String,
    },
    zukoId: {
      type: String,
      required: true,
    },
    licenceDetails: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    pronoun: {
      type: String,
      default: "your",
    },
    showExpired: {
      type: Boolean,
      default: false,
    },
    showNotFound: {
      type: Boolean,
      default: false,
    },
    showLicenceError: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasInput1: false,
      hasInput2: false,
      hasInput3: false,
      hasTriggeredError: false,
    };
  },
  computed: {
    licencePart1Valid() {
      return (
        this.value?.licencePart1?.toUpperCase() ===
        this.licenceDetails?.surname
          .replace(/[^0-9a-z]/gi, "")
          .replace(/^Mac/i, "MC")
          .padEnd(5, "9")
          .substring(0, 5)
          .toUpperCase()
      );
    },
    licencePart2Valid() {
      const { dob, title } = this.licenceDetails || {};
      const month = ["Mrs", "Miss", "Ms"].includes(title)
        ? (parseInt(dob?.substring(5, 7)) + 50).toString()
        : dob?.substring(5, 7);
      const value = `${dob?.substring(2, 3)}${month}${dob?.substring(
        8,
        10
      )}${dob?.substring(3, 4)}`;
      return this.value?.licencePart2 === value;
    },
    licencePart3Valid() {
      return (
        this.value?.licencePart3?.length === 5 &&
        this.value?.licencePart3
          .toUpperCase()
          .startsWith(
            this.licenceDetails?.firstname.substring(0, 1).toUpperCase()
          )
      );
    },
    licenceValid() {
      return (
        this.licencePart1Valid &&
        this.licencePart2Valid &&
        this.licencePart3Valid
      );
    },
    showError() {
      return (
        (this.hasInput1 && !this.licencePart1Valid) ||
        (this.hasInput2 && !this.licencePart2Valid) ||
        (this.hasInput3 && !this.licencePart3Valid)
      );
    },
  },
  methods: {
    forceValidation() {
      this.hasInput1 = true;
      this.hasInput2 = true;
      this.hasInput3 = true;
    },
    handleInput(part, event, counter) {
      this.$emit("input", {
        ...this.value,
        [`licencePart${part}`]: event.toUpperCase(),
      });
      if (event?.length === counter) {
        this.moveToNextInput(part);
      }
    },
    moveToNextInput(index) {
      const nextInput = this.$refs[`licence-input-${index}`];
      if (nextInput) {
        nextInput.$el.querySelector("input").focus();
      }
    },
  },
  watch: {
    licenceValid(newVal) {
      if (newVal) {
        this.$emit("licenceValid");
      }
    },
    showNotFound(newVal) {
      if (newVal) {
        this.hasTriggeredError = true;
      }
    },
    showExpired(newVal) {
      if (newVal) {
        this.hasTriggeredError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.licence-number-container::v-deep {
  .ukfields {
    padding: 12px;

    .col {
      padding: 0;

      .v-text-field__slot {
        border: 0 !important;
        border-radius: 0;
      }

      .v-counter {
        color: white;
      }

      &:first-child {
        .error--text {
          .v-text-field__slot {
            border: none !important;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 2px solid red; /* Example border */
              border-right: 4px solid red;
              border-radius: 8px 0 0 8px !important;
              box-sizing: border-box; /* Include padding and border */
              pointer-events: none;
            }
          }
        }

        .v-counter {
          margin-right: 8px;
        }

        .v-text-field__slot {
          border-radius: 8px 0 0 8px !important;
        }
      }

      &:last-child {
        .error--text {
          .v-text-field__slot {
            border: none !important;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 2px solid red; /* Example border */
              border-left: 4px solid red;
              border-radius: 0 8px 8px 0 !important;
              box-sizing: border-box; /* Include padding and border */
              pointer-events: none;
            }
          }
        }

        .v-text-field__slot {
          border-radius: 0 8px 8px 0 !important;
        }

        .v-text-field__slot::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-left: 4px solid #bdbdbd; /* Example border */
          box-sizing: border-box; /* Include padding and border */
          pointer-events: none;
        }
      }

      &:not(:first-child):not(:last-child) {
        .error--text {
          .v-text-field__slot {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 2px solid red; /* Example border */
              border-left: 4px solid red;
              border-right: 4px solid red;
              box-sizing: border-box; /* Include padding and border */
              pointer-events: none;
            }
          }
        }
      }

      // Adjustments for middle child based on error state of first and last child
      &:first-child:has(.error--text)
        ~ .col:nth-of-type(2)
        .v-text-field__slot::before {
        border-left: none;
      }

      // Adjustments for middle child based on error state of first and last child
      &:nth-last-of-type(2):has(.error--text)
        ~ .col:last-child
        .v-text-field__slot::before {
        border-left: none;
      }

      &:nth-of-type(2) {
        .v-text-field__slot::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-left: 4px solid #bdbdbd; /* Example border */
          box-sizing: border-box; /* Include padding and border */
          pointer-events: none;
        }

        .v-counter {
          margin-right: 8px;
        }
      }

      .v-messages__message {
        display: none;
      }

      .error--text {
        .v-text-field__slot::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid red; /* Example border */
          box-sizing: border-box; /* Include padding and border */
          pointer-events: none;
        }
      }
    }
  }
  .red-text {
    color: red;
    display: block;
    width: 100%;
  }

  div.theme--light ::v-deep div.v-counter {
    color: var(--v-brandText-base) !important;
  }
}
</style>
