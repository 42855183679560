import * as Sentry from "@sentry/vue";

export const state = () => ({
  // Tracking Params
  gspar1: "",
  gspar2: "",
  goidst: "Website",
  gsbrand: "",
  gspromo: "",
  gsdscore: "",
  gsagency: "",
  // Journey Params
  learner: "",
  impound: "",
  reg: "",
  // Recall Params
  webreference: "",
  secret: "",
  regoverride: "",
  extendpolicy: "",
});

export const getters = {
  hasGspar1: (state) => !!state.gspar1?.length,
  hasGspar2: (state) => !!state.gspar2?.length,
  hasGoidst: (state) => !!state.goidst?.length,
  hasGsbrand: (state) => !!state.gsbrand?.length,
  hasGspromo: (state) => !!state.gspromo?.length,
  hasGsdscore: (state) => !!state.gsdscore?.length,
  hasGsagency: (state) => !!state.gsagency?.length,
  hasReg: (state) => !!state.reg?.length,

  getGspar1: (state) => state.gspar1,
  getGspar2: (state) => state.gspar2,
  getGoidst: (state) => state.goidst,
  getGsbrand: (state) => state.gsbrand,
  getGspromo: (state) => state.gspromo,
  getGsdscore: (state) => state.gsdscore,
  getGsagency: (state) => state.gsagency,
  getReg: (state) => state.reg,

  hasWebreference: (state) => !!state.webreference?.length,
  hasSecret: (state) => !!state.secret?.length,
  hasRegoverride: (state) => !!state.regoverride?.length,

  getWebreference: (state) => state.webreference,
  getSecret: (state) => state.secret,
  getRegoverride: (state) => state.regoverride,

  hasLearner: (state) => !!state.learner?.length,
  hasImpound: (state) => !!state.impound?.length,

  hasExtendpolicy: (state) => !!state.extendpolicy,
};

export const mutations = {
  setGspar1(state, value) {
    state.gspar1 = value;
  },
  setGspar2(state, value) {
    state.gspar2 = value;
  },
  setGoidst(state, value) {
    state.goidst = value;
  },
  setGsbrand(state, value) {
    state.gsbrand = value;
  },
  setGspromo(state, value) {
    state.gspromo = value;
  },
  setGsdscore(state, value) {
    state.gsdscore = value;
  },
  setGsagency(state, value) {
    state.gsagency = value;
  },
  setLearner(state, value) {
    state.learner = value;
  },
  setImpound(state, value) {
    state.impound = value;
  },
  setWebreference(state, value) {
    state.webreference = value;
  },
  setSecret(state, value) {
    state.secret = value;
  },
  setRegoverride(state, value) {
    state.regoverride = value;
  },
  setExtendpolicy(state, value) {
    state.extendpolicy = value;
  },
  setReg(state, value) {
    state.reg = value;
  },
};

export const actions = {
  async mapRecall({ commit, dispatch, getters, rootGetters }, riskData) {
    let allFieldsPresent = true;
    const checkAndCommit = (commitName, value) => {
      if (value !== undefined && value !== null) {
        commit(commitName, value);
      } else {
        allFieldsPresent = false;
      }
    };

    checkAndCommit("setGoidst", riskData?.userfieldsUserfieldGOIDSTResponse);

    return allFieldsPresent;
  },
  updateQueryString({ commit }) {
    const queryMap = {
      gspar1: "setGspar1",
      gspar2: "setGspar2",
      goidst: "setGoidst",
      gsbrand: "setGsbrand",
      gspromo: "setGspromo",
      gsdscore: "setGsdscore",
      gsagency: "setGsagency",
      learner: "setLearner",
      impound: "setImpound",
      webreference: "setWebreference",
      secret: "setSecret",
      regoverride: "setRegoverride",
      extendpolicy: "setExtendpolicy",
      reg: "setReg",
    };

    const queryString = this.$router.currentRoute.query;
    Object.keys(queryString).forEach((key) => {
      if (queryMap[key] && queryString[key] !== null) {
        commit(queryMap[key], queryString[key]);
        if (key === "reg")
          commit(
            "modules/vehicledetails/setInputRegistrationNumber",
            queryString[key],
            { root: true }
          );
        if (key === "webreference")
          commit("modules/recall/setInputWebReference", queryString[key], {
            root: true,
          });
        Sentry.setTag(key.toLowerCase(), queryString[key]);
      }
    });
  },
};
