<template>
  <v-row>
    <v-col>
      <v-text-field
        :value="value"
        :rules="rules"
        :counter="8"
        :maxLength="8"
        @keypress="isNumber($event)"
        @input="$emit('input', $event)"
        :id="`${id}-ni`"
        validate-on-blur
        :data-zuko-id="`${zukoId}-ni`"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LicenceNumberNI",
  props: {
    value: {
      type: String,
    },
    id: {
      type: String,
    },
    zukoId: {
      type: String,
    },
    pronoun: {
      type: String,
      default: "your",
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          (v && /^\d{8}$/.test(v)) ||
          `Please enter a valid value for ${this.pronoun} licence number.`,
      ],
    };
  },
  methods: {
    isNumber: function (evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 47 || charCode > 57)) ||
        charCode === 46 ||
        charCode === 47
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
