<template>
  <div>
    <BaseButtonToggle
      :items="items"
      :value="value"
      @input="$emit('input', $event)"
      :zukoId="zukoId"
    />
    <FormLabelBox v-if="hasLicenceCache">
      <span>
        Selecting provisional will change your reason for cover to learning to
        drive.
      </span>
    </FormLabelBox>
  </div>
</template>

<script>
export default {
  name: "LicenceType",
  props: {
    value: {
      type: String,
    },
    zukoId: {
      type: String,
    },
    selectedCountry: {
      type: String,
    },
    isAdditional: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      if (
        this.isAdditional ||
        ["EU/EEA", "International"].includes(this.selectedCountry) ||
        this.isVan
      ) {
        return this.$selectors.licence.types.full;
      }
      if (this.isLearnerDriver && !this.isImpound && !this.hasLicenceCache) {
        return this.$selectors.licence.types.provisional;
      }
      return this.$selectors.licence.types.both;
    },
  },
};
</script>
