<template>
  <v-select
    :items="$selectors.licence.countries"
    :rules="rules"
    :value="value"
    @change="$emit('input', $event)"
    return-object
    placeholder="Please select"
    :data-zuko-id="zukoId"
  >
  </v-select>
</template>

<script>
export default {
  name: "LicenceCountry",
  props: {
    value: {
      type: [Object, String],
      default: () => ({}),
    },
    zukoId: {
      type: String,
      required: true,
    },
    pronoun: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          Object.keys(v).length !== 0 ||
          `Please select a valid value for ${this.pronoun} country of issue.`,
      ],
    };
  },
};
</script>
