<template>
  <BaseButtonToggle
    :items="items"
    :wrapButtons="isImpound"
    :value="value"
    @input="$emit('input', $event)"
    :zukoId="zukoId"
  />
</template>

<script>
export default {
  name: "LicenceIssuingCountry",
  props: {
    value: {
      type: String,
    },
    zukoId: {
      type: String,
    },
  },
  computed: {
    items() {
      if (this.isImpound) {
        return this.$selectors.licence.issuingCountries.impound;
      } else if (this.isLearnerDriver) {
        return this.$selectors.licence.issuingCountries.learner;
      } else {
        return this.$selectors.licence.issuingCountries.standard;
      }
    },
  },
};
</script>
