const defaults = require("~/assets/json/defaults.json");
const selectors = require("~/assets/json/selectors.json");

const initialState = () => ({
  selectedCoverReasonCache: null,
  ...defaults.coverDetails,
});
export const state = initialState;

function getReasonAndLength(riskData) {
  const mappings = {
    duqsDuq934ShortResponse: { isCar: true, isVan: false, isHours: false },
    duqsDuq935ShortResponse: { isCar: true, isVan: false, isHours: true },
    duqsDuq457ShortResponse: { isCar: false, isVan: true, isHours: false },
    duqsDuq458ShortResponse: { isCar: false, isVan: true, isHours: true },
  };

  for (const [key, { isCar, isVan, isHours }] of Object.entries(mappings)) {
    if (riskData?.[key]) {
      const length = Number(riskData[key]);
      const isWeeks = length > 6 && length % 7 === 0;
      const isDays = !isHours && !isWeeks && length > 0;

      const data = {
        isCar,
        isVan,
        isHours,
        isDays,
        isWeeks,
        length: riskData[key],
        reason: isCar
          ? riskData["duqsDuq936ShortResponse"]
          : riskData["duqsDuq459ShortResponse"],
      };
      return {
        success: true,
        data,
      };
    }
  }

  return {
    success: false,
    data: {},
  };
}


export const getters = {
  getSelectedCoverReason: (state) => state.selectedCoverReason,
  hasSelectedCoverReasonCache: (state) =>
    state.selectedCoverReasonCache !== null,
  getSelectedCoverReasonCache: (state) => state.selectedCoverReasonCache,
  getSelectedCoverDurationType: (state, getters, rootState, rootGetters) => {
    if (rootGetters["journey/isImpound"]) {
      return { value: 24, text: "Days" };
    } else {
      return state.selectedCoverDurationType;
    }
  },
  getSelectedCoverLength: (state, getters, rootState, rootGetters) => {
    if (rootGetters["journey/isImpound"]) {
      return {
        abbr: "30",
        hours: 720,
        value: "30 days",
      };
    } else {
      return state.selectedCoverLength;
    }
  },
  getShouldShowOtherLengths: (state) => state.shouldShowOtherLengths,
  isHours: (state, getters) =>
    getters.getSelectedCoverDurationType?.text?.toLowerCase() === "hours",
  isDays: (state, getters) =>
    getters.getSelectedCoverDurationType?.text?.toLowerCase() === "days",
  isWeeks: (state, getters) =>
    getters.getSelectedCoverDurationType?.text?.toLowerCase() === "weeks",
};

export const mutations = {
  setSelectedCoverReason(state, value) {
    state.selectedCoverReason = value;
  },
  setSelectedCoverReasonCache(state, value) {
    state.selectedCoverReasonCache = value;
  },
  setSelectedCoverDurationType(state, value) {
    state.selectedCoverDurationType = value;
  },
  setSelectedCoverLength(state, value) {
    state.selectedCoverLength = value;
  },
  setShouldShowOtherLengths(state, value) {
    state.shouldShowOtherLengths = value;
  },
  resetState: (state) => {
    Object.assign(state, {
      ...initialState(),
    });
  },
};

export const actions = {
  async mapRecall({ commit, dispatch, getters, rootGetters }, riskData) {
    let allFieldsPresent = true;
    const checkAndCommit = (commitName, value) => {
      if (value !== undefined && value !== null) {
        commit(commitName, value);
      } else {
        allFieldsPresent = false;
      }
    };

    const { success: coverDataSuccess, data: coverData } =
      getReasonAndLength(riskData);
    if (coverDataSuccess) {
      if (coverData.reason) {
        if (coverData.reason === "I" || coverData.reason === "J") {
          checkAndCommit(
            "setSelectedCoverReason",
            selectors.cover.reasons.learner.find((t) => t.value === "I/J")
          );
        } else if (coverData.reason === "Q") {
          checkAndCommit(
            "setSelectedCoverReason",
            selectors.cover.reasons.impound.find(
              (t) => t.value === coverData.reason
            )
          );
        } else if (coverData.isVan) {
          checkAndCommit(
            "setSelectedCoverReason",
            selectors.cover.reasons.van.find(
              (t) => t.value === coverData.reason
            )
          );
        } else {
          checkAndCommit(
            "setSelectedCoverReason",
            selectors.cover.reasons.car.find(
              (t) => t.value === coverData.reason
            )
          );
        }
      } else {
        allFieldsPresent = false;
      }
      if (!rootGetters["journey/isImpound"]) {
        if (coverData.isHours) {
          checkAndCommit(
            "setSelectedCoverDurationType",
            selectors.cover.types.find((t) => t.text === "Hours")
          );
          checkAndCommit(
            "setSelectedCoverLength",
            selectors.cover.hours.find((t) => t.abbr === coverData.length)
          );
        } else if (coverData.isDays) {
          checkAndCommit(
            "setSelectedCoverDurationType",
            selectors.cover.types.find((t) => t.text === "Days")
          );
          checkAndCommit(
            "setSelectedCoverLength",
            selectors.cover.days.find((t) => t.abbr === coverData.length)
          );
        } else {
          checkAndCommit(
            "setSelectedCoverDurationType",
            selectors.cover.types.find((t) => t.text === "Weeks")
          );
          checkAndCommit(
            "setSelectedCoverLength",
            selectors.cover.weeks.find(
              (t) => t.abbr === String(Number(coverData.length) / 7)
            )
          );
        }
      }
    } else {
      allFieldsPresent = false;
    }

    return allFieldsPresent;
  },
  changeSelectedCoverDurationType({ commit }, type) {
    commit("setShouldShowOtherLengths", false);
    commit("setSelectedCoverDurationType", type);
    const t = type?.text?.toLowerCase();
    if (defaults.coverDurations[t]) {
      commit("setSelectedCoverLength", defaults.coverDurations[t]);
    }
  },
  changeSelectedCoverReason({ commit, getters, rootGetters }, reason) {
    const isLearnerPrev = rootGetters["journey/isLearnerDriver"];
    commit("setSelectedCoverReason", reason);
    commit("setSelectedCoverReasonCache", null);
    const isLearnerNew = rootGetters["journey/isLearnerDriver"];
    const getDuration = getters["getSelectedCoverLength"];

    if (isLearnerPrev) {
      commit("modules/licencedetails/setSelectedLicence", "Full", {
        root: true,
      });
    }

    if (getters.isWeeks) {
      const durationValue = getDuration.value;

      if (!isLearnerPrev && isLearnerNew) {
        if (durationValue === "3 weeks") {
          commit("setSelectedCoverLength", {
            abbr: "3",
            hours: 504,
            value: "3 weeks",
          });
        } else if (durationValue === "4 weeks") {
          commit("setShouldShowOtherLengths", true);
        }
      } else if (isLearnerPrev && !isLearnerNew) {
        commit("setShouldShowOtherLengths", false);
        if (getDuration.hours > 672) {
          commit("setSelectedCoverLength", defaults.coverDurations.weeks);
        }
      }
    } else if (getters.isHours) {
      if (!isLearnerPrev && isLearnerNew && getDuration.hours > 6) {
        commit("setShouldShowOtherLengths", false);
        commit("setSelectedCoverLength", defaults.coverDurations.hours);
      }
    }
  },
  switchToLearner({ commit, getters }, value) {
    if (getters.getSelectedCoverReason?.value === "Q") {
      return;
    }

    if (!["I", "J", "I/J"].includes(getters.getSelectedCoverReason?.value)) {
      commit("setSelectedCoverReasonCache", getters.getSelectedCoverReason);
    }
    commit("setSelectedCoverReason", {
      text: "Learning to Drive",
      value: "I/J",
    });
  },
  switchFromLearner({ commit, getters }, value) {
    if (getters.getSelectedCoverReason?.value === "Q") {
      return;
    }

    if (getters.getSelectedCoverReasonCache) {
      commit("setSelectedCoverReason", getters.getSelectedCoverReasonCache);
      commit("setSelectedCoverReasonCache", null);
    }
  },
};
