var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mt-n6",
      attrs: { width: this.$vuetify.breakpoint.xs ? "75%" : "95%" },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-column align-start my-4 px-4 pb-4",
          attrs: { tile: "", flat: "" },
        },
        [
          _c(
            "v-row",
            { staticStyle: { width: "100%" } },
            [
              _c("RegistrationNumber", {
                staticClass: "reg-container",
                attrs: { value: _vm.inputRegistrationNumber, disabled: "" },
              }),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column py-0 py-md-3",
                  attrs: { cols: "12", sm: "auto" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "font-weight-bold pt-0 mt-2 px-0 card-detail",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.getVehicleManufacturer) +
                          "\n          " +
                          _vm._s(_vm.getVehicleModelDescription)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-subtitle",
                    { staticClass: "font-weight-bold pb-2 px-0" },
                    [
                      _vm._v(
                        _vm._s(_vm.getVehicleAbiProductionYears) +
                          ",\n          " +
                          _vm._s(_vm.getVehicleFuel?.long.toUpperCase()) +
                          ", " +
                          _vm._s(_vm.getVehicleCc) +
                          "CC\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-end justify-end py-0 py-md-3",
                  attrs: { cols: "auto" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "text-decoration-underline font-weight-bold px-0 px-md-0 text-body-1 mb-2",
                      attrs: {
                        color: "primary",
                        text: "",
                        id: "gtm--coverDetailsForm-change-vehicle",
                        "data-zuko-id": "zuko--coverDetailsForm-change-vehicle",
                      },
                      on: { click: _vm.goToPrevPage },
                    },
                    [_vm._v("Change Vehicle")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }