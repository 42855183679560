<template>
  <BaseCard class="confirmation-vehicle-details-container">
    <template #title>Vehicle & Insurance Details</template>
    <v-list class="brandBackgroundLight">
      <v-list-item-group v-for="(item, index) of items" :key="index">
        <v-list-item disabled class="flex-wrap justify-start px-0 text-body-2">
          <v-list-item-content
            class="confirmation-details confirmation-label pb-0 px-0"
          >
            <v-list-item-title class="text-wrap">{{
              item.text
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-content
            class="confirmation-details d-flex flex-column align-start pt-0 px-0 pb-2"
          >
            <div class="insurance-name font-weight-bold text-body-2">
              {{ item.value }}
            </div>
            <v-img
              v-show="item.img"
              class="my-1"
              :src="item.img"
              max-height="50px"
              max-width="100px"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-1" />
      </v-list-item-group>
    </v-list>
  </BaseCard>
</template>

<script>
export default {
  name: "ConfirmationVehicleAndInsuranceDetails",
  computed: {
    items() {
      return [
        {
          text: "Registration",
          value: this.inputRegistrationNumber.toUpperCase(),
        },
        {
          text: "Vehicle",
          value: `${this.getVehicleModelDescription}, ${this.getVehicleCc}cc, ${this.getVehicleFuel?.long}`,
        },
        {
          text: "Insurer",
          value: this.getSelectedQuote?.front_end?.insurer_name,
        },
        {
          text: "Cover type",
          value: this.isImpound
            ? "Third Party Only Impounded Vehicle Release Insurance"
            : "Comprehensive",
        },
        {
          text: "Length of cover",
          value: this.isImpound
            ? `${this.selectedCoverDurationLength.value} Mandatory`
            : this.selectedCoverDurationLength.value,
        },
        {
          text: "Cover to start",
          value: this.isImmediate
            ? "Immediate Start"
            : this.getDateTimeCalculatedDates?.calculated_start_date_humanized,
        },

        {
          text: "Cover to end",
          value: this.getDateTimeCalculatedDates?.calculated_end_date_humanized,
        },
        {
          text: "Compulsory excess",
          value: this.$formatters.currencyNoDecimal(
            this.getSelectedQuote?.front_end?.values?.excess
          ),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-vehicle-details-container::v-deep {
  border-color: #7890b9 !important;
  border-radius: 0px;
  .v-list-item__content {
    font-size: 1rem !important;
    align-self: start;
    padding: 0px !important;

    .v-list-item__title {
      font-family: "Font Theme Regular", sans-serif !important;
    }
  }

  .insurance-name {
    flex: 1 0 50%;
  }

  .confirmation-details {
    min-width: 100%;

    @media (min-width: 475px) {
      min-width: 50%;
    }
  }

  .confirmation-label {
    color: #000 !important;
  }

  .text-h6 {
    font-weight: bold;
  }
}
</style>
